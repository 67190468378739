<template>
  <div v-if="active" class="popup-project popup-vendor-user">
    <div class="wrap-popup-box">
      <div v-click-outside="close" class="popup-box">
        <button id="popup-close" class="btn-close" @click="close">
          <i class="icon-cross" />
        </button>
        <div class="popup-box-welcome">
          <div class="popup-box__col-left">
            <h3 class="popup-box__col-left__title">
              Sorry, this functionality is only available for verified hoteliers
            </h3>
            <p>The project dashboard is a free tool that is only available to verified hoteliers to make adopting new technology easier by streamlining their research and simplifying their communication workflow.  </p>
            <button class="button button-close-popup-vendor" @click="close">
              Close window
            </button>
          </div>
          <div class="popup-box__col-right">
            <img src="/images/welcom-chat.svg">
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import vClickOutside from 'v-click-outside'

export default {

  directives: {
    clickOutside: vClickOutside.directive
  },
  props: [
    'activeModalQueue'
  ],

  data: () => ({
    title: 'userIsVendor',
    show: false
  }),

  computed: {
    active () {
      return this.activeModalQueue === 'modal-is-vendor'
    }
  },

  methods: {
    close () {
      this.$parent.shiftModalQueue()
    }
  }
}
</script>
