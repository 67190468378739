<template>
  <div class="software-categories-posts-load-more">
    <div class="software-categories-posts">
      <article class="software-categories-post" v-for="category in categories" :key="category.id" >
        <div>
          <h3 v-html="translate(category, 'name') + ' ' + htrLanguage.companyCategory.finder || 'finder'"></h3>
          <p class="featured-software__box__content">
            {{ htrLanguage.home.compare || 'compare' }} {{ category.products_count }}  {{ htrLanguage.home.products_for_an_instant_shortlist || 'products_for_an_instant_shortlist' }}
          </p>
          <a class="btn-green" :href="`/quiz/integrate_with?category_id=${category.parent_id}&subcategory_id=${category.id }`" v-prevent-is-vendor>
            {{ (htrLanguage.quiz.get_started_next_step || 'get_started_next_step').charAt(0).toUpperCase() + (htrLanguage.quiz.get_started_next_step || 'get_started_next_step').toLowerCase().slice(1) }} 
            <i class="icon-sarrow-right"></i>
          </a>
        </div>
      </article>
    </div>
    <span class="load-more">
      <button v-show="page < lastPage" @click="load">{{ htrLanguage.company.load_more || 'load_more' }}</button>
    </span>
  </div>
</template>

<script>
import axios from 'axios'

export default {
  props: {
    categoryId: {
      default: ''
    }
  },

  data: () => ({
    categories: [],
    page: 1,
    lastPage: 2
  }),

  computed: {
    htrLanguage() {
      if (window && window.i18n) {
        const { companyCategory, company, home, quiz } = window.i18n
        if (companyCategory && company && home && quiz) {
          return { companyCategory, company, home, quiz }
        }
      }
      return { companyCategory: {}, company: {}, home: {}, quiz: {} }
    }
  },

  methods: {
    translate (entry, field) {
      let translation = entry[field]
      if (entry.translations && entry.translations.length > 0) {
        if (window.Laravel.languageLocale) {
          const searchTranslation = entry.translations.find((findTranslate) => findTranslate.language_id === window.Laravel.languageLocale.id)
          if (searchTranslation) {
            translation = searchTranslation[field]
          }
        }
      }
      return translation
    },
    load () {
      this.page++
      axios.get(`/api/popular-categories?page=${this.page}&category_id=${this.categoryId}`)
        .then(res => {
          this.lastPage = res.data.last_page
          this.categories = [...this.categories, ...res.data.data]
        })
    }
  }
}
</script>
