<template>
  <div class="modal-lead__body">
    <div class="progress-bar">
      <div class="active" :style="{ width: (countFullRequiredFields.length * (100/(requiredFields.length))) + '%'}" />
    </div>
    <div class="box-hotelier">
      <div class="form-row">
        <div class="form-group" :class="{success: form.first_name, error: !form.first_name && shouldShowErrorMessages}">
          <input v-model="form.first_name" class="form-control" :class="{'is-empty': !form.first_name}">
          <span class="field__title">First name</span>
        </div>
        <div class="form-group" :class="{success: form.last_name, error: !form.last_name && shouldShowErrorMessages}">
          <input v-model="form.last_name" class="form-control" :class="{'is-empty': !form.last_name}">
          <span class="field__title">Last name</span>
        </div>
      </div>

      <div class="form-group" :class="{success: form.title, error: !form.title && shouldShowErrorMessages}">
        <input v-model="form.title" class="form-control" :class="{'is-empty': !form.title}">
        <span class="field__title">Job title/role</span>
      </div>

      <div class="form-group" :class="{success: form.company, error: !form.company && shouldShowErrorMessages}">
        <places-autocomplete :value="location" :class-names="{'is-empty': !form.company}" @input="updateLocation" />
        <span class="field__title">Hotel/Company</span>
      </div>

      <div class="form-group"
           :class="{success: form.website, error: !form.website && shouldShowErrorMessages || shouldShowErrorMessagesWebsite}">
        <input v-model="form.website" class="form-control" :class="{'is-empty': !form.website}">
        <span class="field__title">Company website</span>
      </div>

      <div class="form-group"
           :class="{success: form.email && isBusinessEmail, error: !form.email && shouldShowErrorMessages || shouldShowErrorMessagesEmail}">
        <input v-model="form.email" class="form-control" :class="{'is-empty': !form.email}">
        <span class="field__title">Work Email</span>
      </div>
      <p class="error" :class="{'active': shouldShowErrorMessagesEmail || shouldShowErrorMessagesWebsite}">
        <span v-if="shouldShowErrorMessagesEmail">{{ errorMessageEmail }}</span>
        <span v-if="shouldShowErrorMessagesWebsite">{{ errorMessageWebsite }}</span>
      </p>
    </div>
  </div>
</template>

<script>
import checkEmail from '../../../../../mixins/checkEmail'

export default {
  mixins: [
    checkEmail
  ],

  props: [
    'type',
    'leadId',
    'value'
  ],

  data () {
    return {
      requiredFields: [
        'first_name',
        'last_name',
        'website',
        'title',
        'company',
        'email'
      ],

      form: {},

      shouldShowErrorMessagesEmail: false,
      shouldShowErrorMessagesWebsite: false,
      shouldShowErrorMessages: false,
      errorMessageEmail: null,
      errorMessageWebsite: null
    }
  },

  computed: {
    isBusinessEmail () {
      return this.checkBusinessEmail(this.form.email)
    },
    isWebsiteUrl () {
      const websiteRegex = /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([-.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/
      return websiteRegex.test(this.form.website)
    },
    countFullRequiredFields () {
      return this.requiredFields.filter(key => !!this.form[key])
    },
    title () {
      switch (this.type) {
        case 'request_demo':
          return 'Where should we send your demo details?'

        case 'request_quote':
          return 'Where should we send your price quote details?'

        case 'find_reference':
          return 'Where should we send your reference information?'
      }

      return null
    },
    location () {
      return {
        name: this.form.company
      }
    }
  },

  watch: {
    value: {
      handler () {
        this.reset()
      },
      deep: true
    },

    form () {
      this.$emit('input', this.form)
    }
  },

  mounted () {
    this.reset()
  },

  methods: {
    reset () {
      if (this.value !== this.form) this.form = this.value
      this.$emit('update:ready')
    },
    nextStep () {
      this.shouldShowErrorMessages = false
      this.shouldShowErrorMessagesEmail = false
      this.shouldShowErrorMessagesWebsite = false

      if (this.hasFilledAllRequiredFields() && this.hasIsNotBusinessEmail() && this.hasIsNotWebsiteUrl()) {
        this.$emit('complete', {
          params: this.form,
          callback: () => {
            this.shouldShowErrorMessagesEmail = true
            this.errorMessageEmail = 'The email has already been taken.'
          }
        })
      }
    },
    hasFilledAllRequiredFields () {
      for (let index = 0; index < this.requiredFields.length; index++) {
        if (!this.form[this.requiredFields[index]]) {
          this.shouldShowErrorMessages = true
          return false
        }
      }
      return true
    },
    hasIsNotBusinessEmail () {
      if (this.form.email && !this.isBusinessEmail) {
        this.shouldShowErrorMessagesEmail = true
        this.errorMessageEmail = 'Personal email detected. Please use a work email address or login to your account to access premium content.'
        return false
      }
      return true
    },
    hasIsNotWebsiteUrl () {
      if (this.form.website && !this.isWebsiteUrl) {
        this.shouldShowErrorMessagesWebsite = true
        this.errorMessageWebsite = 'Website address is not correct.'
        return false
      }

      return true
    },
    updateLocation (value) {
      this.form.company = value.name || null
      this.form.city = value.city || null
      this.form.country = value.country || null
      this.form.state = value.state || null
      this.form.company_phone = value.phone || null
    }
  }

}
</script>
