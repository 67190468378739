<template>
  <div class="modal-lead__body modal-lead__body-step-three">
    <p class="notification">
      <i class="icon-envelope2" /> Success! Click the verification link sent to your email
    </p>
    <p>
      Click the verification link sent to {{ form.email }} to complete your request.
    </p>
    <div class="form-group">
      <input v-model="form.email" class="form-control" placeholder="Email"
             :class="{success: form.email, error: shouldShowErrorMessagesEmail}">
      <button @click="resend">
        RESEND
      </button>
    </div>
    <p v-if="shouldShowErrorMessagesEmail" class="error">
      {{ errorMessageEmail }}
    </p>
    <div class="recommend-box">
      <span class="recommend-box__title">Want to save time finding out which {{ category ? category.name : '' }} vendor is best for your hotel?</span>
      <a :href="quizUrl" class="btn">Take the quiz</a>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import checkEmail from '../../../../../mixins/checkEmail'

export default {
  mixins: [
    checkEmail
  ],

  props: [
    'company',
    'product',
    'categoryId',
    'value',
    'type'
  ],

  data: () => ({
    form: {},

    shouldShowErrorMessagesEmail: false,
    errorMessageEmail: null
  }),

  computed: {
    ...mapGetters([
      'categoryById'
    ]),
    category () {
      return this.categoryById(this.categoryId)
    },
    isBusinessEmail () {
      return this.checkBusinessEmail(this.form.email)
    },
    title () {
      switch (this.type) {
        case 'request_demo':
          return 'demo details'

        case 'request_quote':
          return 'price quote'
      }

      return null
    },
    quizUrl () {
      if (!this.category) return '/quiz'

      if (this.category.parent_id) {
        return `/quiz/integrate_with?category_id=${this.category.parent_id}&subcategory_id=${this.category.id}`
      } else {
        return `/quiz/subcategory?category_id=${this.category.id}`
      }
    }
  },

  watch: {
    value () {
      this.reset()
    },
    form () {
      this.$emit('input', this.form)
    }
  },

  mounted () {
    this.reset()
  },

  methods: {
    reset () {
      if (this.value !== this.form) this.form = this.value
    },
    resend () {
      this.shouldShowErrorMessagesEmail = false

      if (this.hasIsNotBusinessEmail()) {
        this.$emit('resend-verify', {
          params: this.form,
          callback: () => {
            this.shouldShowErrorMessagesEmail = true
            this.errorMessageEmail = 'The email has already been taken.'
          }
        })
      }
    },

    hasIsNotBusinessEmail () {
      if (this.form.email && !this.isBusinessEmail) {
        this.shouldShowErrorMessagesEmail = true
        this.errorMessageEmail = 'Personal email detected. Please use a work email address or login to your account to access premium content.'
        return false
      }
      return true
    }
  }
}
</script>
