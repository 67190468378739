<template>
  <div>
    <company-picker @selected="step2"></company-picker>
  </div>
</template>

<script>
  import CompanyPicker from '../Common/CompanyPicker.vue'
  export default {
    components: {
      CompanyPicker
    },
    computed: {
      codeLanguage() {
        if (window.Laravel.languageLocale) {
          return '/' + window.Laravel.languageLocale.code
        }
        return ''
      }
    },
    methods: {
      step2 (company) {
        window.location = this.codeLanguage + '/write-a-review/' + company.slug
      }
    }
  }
</script>
