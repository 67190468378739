<template>
  <div>
    <div
      :is="component"
      :company="company"
      :uuid-update-request="uuidUpdateRequest"
      v-model="form"
      @flush="flush"
      @nextStep="nextStep">
    </div>
  </div>
</template>

<script>
  import {assign} from 'lodash'
  import axios from 'axios'
  import swal from 'sweetalert2'

  import Step0 from './WriteRecommendation/Step0.vue'
  import Step1 from './WriteRecommendation/Step1.vue'
  import Step2 from './WriteRecommendation/Step2.vue'
  import Step3 from './WriteRecommendation/Step3.vue'
  import Step4 from './WriteRecommendation/Step4.vue'


  export default {
    props: ['company', 'recommendation', 'uuid'],
    data() {
      return {
        submitting: false,
        form: {
          products: [],
          what_does_well: null,
          difference: null,
          step: 1,
          user_info: {}
        },
        uuidUpdateRequest: null
      }
    },
    created() {
      if (this.recommendation && this.company) {
        this.flush()
        this.form = assign(this.form, this.recommendation)
        this.form.step = 1
        this.persist()
        this.uuidUpdateRequest = this.uuid
      }
      this.restore()
    },
    watch: {
      form() {
        if(this.form.step < 4) this.persist()
      }
    },
    methods: {
      restore() {
        let stored = JSON.parse(localStorage.getItem(this.storageKey));
        if(stored) {
          if(!stored.id && stored.step > 1) {
            this.flush()
          } else if (stored.id && !this.recommendation && stored.status === 'published') {
            this.flush()
          } else {
            this.form = assign({}, this.form, stored)
          }
        }
      },
      persist() {
        localStorage.setItem(this.storageKey, JSON.stringify(this.form))
      },
      flush() {
        localStorage.removeItem(this.storageKey)
      },
      nextStep() {
        if(this.submitting) return;

        this.submitting = true;
        let dataToSend = assign({
          uuid_update_request: this.uuidUpdateRequest
        }, this.form)
        axios.post(`/api/companies/${this.company.id}/recommendation-process/step-${this.form.step}`, dataToSend)
          .then(res => {
            this.form = assign(this.form, res.data)

            if(this.form.step === 3 && res.data.is_verified) {
              swal('Success!', 'Your recommendation is sent', 'success').then(() => window.location.href = '/')
            } else {
              this.form.step++;
            }
          })
          .catch(err => swal('Oops!', 'Error ocurred', 'error'))
          .then(() => this.submitting = false);

      }
    },
    computed: {
      storageKey() {
        return `recommendation-${this.company?.id}`
      },
      component() {
        if(!this.company) return Step0;
        if(this.form.step === 2) return Step2;
        if(this.form.step === 3) return Step3;
        if(this.form.step === 4) return Step4;
        return Step1
      }
    }
  }
</script>
