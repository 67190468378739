export default class StickyNavCompare {
  constructor () {
    if ($('.wrap-sticky-products').length) {
      window.addEventListener('scroll', this.navSticky.bind(this))
      window.addEventListener('scroll', this.navActive.bind(this))
    }
  }

  navSticky () {
    const nav = $('.wrap-sticky-products')
    let navStart = $('.compare-nav').offset().top
    let scroll = $(document).scrollTop()
    if (scroll > navStart) {
      nav.addClass('active')
    }
    if (scroll < navStart) {
      nav.removeClass('active')
    }
  }

  navActive() {
    const navLinks = document.querySelectorAll(".compare-nav-link");

    navLinks.forEach(link => {
      const id = $(link).attr('aria-controls');
      const section = document.getElementById(id);
      let fromTop = window.pageYOffset;

      if (
        section.offsetTop - 270 <= fromTop &&
        section.offsetTop + section.offsetHeight - 270 >= fromTop
      ) {
        link.classList.add("active");
      } else {
        link.classList.remove("active");
      }
    });
  }
}
