<template>
  <div v-if="isOpen" class="wrap-popup-box popup-video">
    <div v-click-outside="close" class="popup-box">
      <div class="lds-spinner">
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
      </div>
      <iframe width="460" height="230" :src="url" frameborder="0" allowfullscreen="true" tabindex="-1" />
    </div>
  </div>
</template>

<script>
import { EventBus } from '../../../event-bus'
import vClickOutside from 'v-click-outside'

export default {
  directives: {
    clickOutside: vClickOutside.directive
  },

  data: () => ({
    isOpen: false,
    video: false,

    videoObj: {}
  }),

  computed: {
    url () {
      if (this.videoObj.type === 'youtube') {
        return '//www.youtube.com/embed/' + this.videoObj.id
      }

      if (this.videoObj.type === 'vimeo') {
        return '//player.vimeo.com/video/' + this.videoObj.id
      }

      return null
    }
  },

  created () {
    EventBus.$on('open-modal-video', this.openModal)

    window.addEventListener('keydown', this.closeOnEscape)
  },

  methods: {
    openModal (video) {
      this.video = video
      this.setVideoObj()

      this.isOpen = true
    },
    close () {
      this.isOpen = false
    },
    setVideoObj () {
      this.video.match(/(http:|https:|)\/\/(player.|www.)?(vimeo\.com|youtu(be\.com|\.be|be\.googleapis\.com))\/(video\/|embed\/|watch\?v=|v\/)?([A-Za-z0-9._%-]*)(&\S+)?/)

      let type = null

      if (RegExp.$3.indexOf('youtu') > -1) {
        type = 'youtube'
      } else if (RegExp.$3.indexOf('vimeo') > -1) {
        type = 'vimeo'
      }

      this.videoObj = {
        type: type,
        id: RegExp.$6
      }
    },
    closeOnEscape (e) {
      if (this.isOpen && e.keyCode === 27) this.close()
    }
  }
}
</script>
