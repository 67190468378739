<template>
  <div class="about-you" v-if="dataAboutYou">
    <h2 class="box-about-you__title">
      {{ htrLanguage.writeReview.hotel_verification_details || 'hotel_verification_details' }}
    </h2>
    <p class="box-about-you__subtitle">
      {{ htrLanguage.writeReview.hotel_verification_details_subtitle || 'hotel_verification_details_subtitle' }}
    </p>
    <h3 class="about-you__title">
      {{ htrLanguage.writeReview.about_your_property || 'about_your_property' }}
    </h3>
    <div class='about-property'>
      <htr-tripadvisor-autocomplete
        height="!h-[37px]"
        width="!w-[252px]"
        :options="`!text-[#929292] mr-[10px] !bg-[#FFFFFFFF] !pt-[15px] ${dataAboutYou.hotel ? '!border !border-[#8bc145]' : ''}
          ${(!dataAboutYou.hotel && shouldShowErrors) ? '!border !border-[#d20027]' : ''}`"
        :placeholder="`${htrLanguage.writeReview.enter_your_hotel || 'enter_your_hotel'} (or mgmt co) AAA`"
        :default-value="cleanHotelString(dataAboutYou.hotel)"
        hotel-name-input="string"
        :custom-value="true"
        @valueUpdated="updateInputField"
        @newHotelSelected="newHotelSelected"
      />
      <div
        :class="{
          row: true,
          validation: true,
          error: !dataAboutYou.hotelTypes && shouldShowErrors,
          success: dataAboutYou.hotelTypes }">
        <div class='box-select'>
          <select v-model="dataAboutYou.hotelTypes" @change="updateValue($event, $event, 'updatedHotelTypes')">
            <option :value="null" disabled hidden>
              {{ htrLanguage.writeReview.select_property_type || 'select_property_type' }}
            </option>
            <option v-for="(value, key) in userData.hotelTypeOptions" :key="key" :value="key">
              {{ htrLanguage.company['hotel_type_' + key] || 'hotel_type_' }}
            </option>
          </select>
        </div>
        <input v-model="dataAboutYou.hotelTypes" name="hotel_type" type="hidden">
      </div>
      <div
        :class="{ row: true, validation: true, error: ! dataAboutYou.propertySize && shouldShowErrors , success: dataAboutYou.propertySize }">
        <div class='box-select'>
          <select v-model="dataAboutYou.propertySize" @change="updateValue($event, $event.target.value, 'propertySize')">
            <option :value="null" disabled hidden>
              {{ htrLanguage.writeReview.number_of_rooms || 'number_of_rooms' }}
            </option>
            <option v-for="option in dictionary.property_size" :key="option.value" :value="option.value">
              {{ htrLanguage.reports['property_size_' + option.value] || 'property_size_' }}
            </option>
          </select>
        </div>
        <input v-model="dataAboutYou.propertySize" name="property_size" type="hidden">
      </div>
    </div>
  </div>
</template>
<script>
import UserFieldInput from './UserFieldInput.vue'

export default {
  components: {
    UserFieldInput
  },

  props: ['userData', 'dataAboutYou', 'shouldShowErrors'],

  computed: {
    htrLanguage() {
      if (window && window.i18n) {
        const { writeReview, company, reports } = window.i18n
        if (writeReview && company && reports) {
          return { writeReview, company, reports }
        }
      }
      return { writeReview: {}, company: {}, reports: {} }
    },
    dictionary () {
      return window.Laravel.dictionaries
    },
    departamentKey () {
      let departments = this.userData.departamentOptions
      return Object.keys(departments).find(key => departments[key] === this.dataAboutYou.department)
    },
    isMobile () {
      if (/(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|ipad|iris|kindle|Android|Silk|lge |maemo|midp|mmp|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows (ce|phone)|xda|xiino/i.test(navigator.userAgent) ||
          /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(navigator.userAgent.substr(0,4))) {
        return true
      }
      return false
    }
  },

  methods: {
    updateMultiselectObject (value, key, field) {
      this.$emit('valueUpdates', { value, field })
    },
    updateValue (event, value, field) {
      this.$emit('valueUpdates', { value, field })
    },
    updateInputField (data) {
      this.updateValue(null, data.value, 'hotel')
    },
    getOptionLabel (option, options) {
      return (options[option]) ? options[option] : null
    },
    newHotelSelected (data) {
      this.$emit('newHotelSelected', data)
    },
    cleanHotelString(hotelString) {
      if (typeof hotelString !== 'string') {
        return null
      }
      const cleanedHotel = hotelString.split(',').map(part => part.trim()).filter(part => part)[0];
      return cleanedHotel || "";
    }
  }
}
</script>
<style scoped>
.paddingRight0px {
  padding-right: 0px !important;
  margin-right: 0px !important;
}
</style>
