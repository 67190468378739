<template>
  <div class="form-content form-content-recommendations">
    <div class="container flex-container">
      <div class="recommendation-finish">
        <img src="/images/img01.svg">
        <h3 class="recommendation-finish__title">Click the verification link in your inbox</h3>
        <p class="recommendation-finish__subtitle">Click the link sent to your email to publish your recommendation</p>
        <form class="recommendation-email">
          <input v-model="email">
          <div class="tooltip">
            <input type="submit" value="RESEND" class="btn btn-green-1" @click.prevent="send" v-if="!sending">
            <input type="submit" value="SENDING..." class="btn btn-green-1" @click.prevent="" v-if="sending">
            <p>You must use a company domain email to create a free account</p>
          </div>
        </form>
        <p class="recommendation-finish__footer">Be sure to check spam, on the off-chance it’s been filtered. You can
          also directly write in via the 24/7 on-site live chat for any assistance.
        </p>
      </div>
    </div>
  </div>
</template>

<script>
  import axios from 'axios'
  import swal from 'sweetalert2'
  export default {
    props: ['value', 'company'],
    data() {
      return {
        email: null,
        id: null,
        sending: false,
      }
    },
    created() {
      this.email = this.value.user_info.email
      this.id = this.value.id
      this.$emit('flush')
    },
    methods: {
      send() {
        if(this.sending) return;
        this.sending = true;

        axios.post(`/api/recommendations/${this.id}/resend-verification`, {email: this.email})
          .then(res => swal('Sent!', 'Verification email sent!', 'success'))
          .catch(() => swal('Oops!', 'Error occured! Please check the email address', 'error'))
          .then(() => this.sending = false)
      }
    }
  }
</script>
