// MODAL ACCEPT INVITATION

window.htr_showModalReviewSuccess = function () {
  let modalContainerElement = $('#modal-review-success')
  if (!modalContainerElement.length) {
    return 0
  }
  modalContainerElement.append(getModal())

  /* HTML Manners */

  function getModal () {
    return `
        <div class="modalDiv" id="modal-review-success_container">
          <div class="imgDivs icon-hold">
            <img src="/images/icon-check.svg" alt="" width="60">
          </div>
          <h1 class="h1Verified">Your review has been verified</h1>
        </div>
        <div class="modalWrap" id="modal-review-success_wrapper"/>
    `
  }

  /* Events */

  window.addEventListener('click', (event) => {
    if (event.target.id && event.target.id === 'modal-review-success_wrapper') {
      $('#modal-review-success').empty()
    }
  })
}

// END  MODAL ACCEPT INVITATION
