<template>
  <div>
    <div class="product-posts product-posts-view-more">
      <article v-for="product in products" :key="product.id" class="product-post">
        <a :href="currentLanguage + product.url" class="product-post__link" />
        <div class="img">
          <a :href="currentLanguage + product.url">
            <img v-if="product.company.logo" :src="product.company.logo" :alt="product.name" width="74" height="75">
            <img v-else src="/images/company-default-logo.png" :alt="product.name" width="74" height="75">
          </a>
        </div>
        <div>
          <div class="product-post__header">
            <div>
              <h3><a :href="currentLanguage + product.url">{{ product.name }}</a></h3>
              <div v-if="product.reviews_count >=10" class="rating-info">
                <a class="no-decoration" :href="currentLanguage + product.url" target="_blank">
                  <div class="stars">
                    <div v-for="rating in 5" :key="rating" class="star">
                      <img v-if="product.recommend_rank / 20 >= rating" src="/images/star-full-blue.svg" width="19">
                      <img
                        v-else-if="product.recommend_rank / 20 >= (rating-1) + 0.2 && product.recommend_rank / 20 < rating"
                        src="/images/star-half-blue.svg" width="19">
                      <img v-else src="/images/star-empty-blue.svg" width="19">
                    </div>
                  </div>
                  <span class="reviews-number-info">{{ product.reviews_count }} {{ htrLanguage.reviews_lower_case || 'reviews_lower_case' }}</span>
                </a>
              </div>
            </div>
            <div v-if="product.reviews_count >=10" class="score">
              <b>{{ product.public_ht_score }}
                <img src="/images/bg-ht-score-active.svg">
              </b>
              <span>{{ htrLanguage.ht_score || 'ht_score' }}</span>
            </div>
          </div>
          <a :href="product.category.url" class="product-post__category" v-html="translate(product.category, 'name')" />
        </div>
      </article>
    </div>
    <span class="load-more">
      <button v-show="page < lastPage" @click="load">{{ htrLanguage.view_more || 'view_more' }}</button>
    </span>
  </div>
</template>

<script>
import axios from 'axios'
import { EventBus } from '../../event-bus'

export default {
  props: {
    categoryId: {
      default: ''
    }
  },

  data: () => ({
    products: [],
    page: 1,
    lastPage: 2
  }),

  computed: {
    htrLanguage () {
      if (window && window.i18n && window.i18n.home) {
        return window.i18n.home
      }
      return {}
    },
    currentLanguage () {
      if (window && window.Laravel && window.Laravel.languageLocale && window.Laravel.languageLocale.code !== 'en') {
        return '/' + window.Laravel.languageLocale.code
      }
      return ''
    }
  },

  methods: {
    translate (entry, field) {
      let translation = entry[field]
      if (entry.translations && entry.translations.length > 0) {
        if (window.Laravel.languageLocale) {
          const searchTranslation = entry.translations.find((findTranslate) => findTranslate.language_id === window.Laravel.languageLocale.id)
          if (searchTranslation) {
            translation = searchTranslation[field]
          }
        }
      }
      return translation
    },
    load () {
      this.page++

      axios.get(`/api/popular-products?page=${this.page}&category_id=${this.categoryId}`)
        .then(res => {
          axios.get(`/api/popular-products/unprepared-url?page=${this.page}&category_id=${this.categoryId}`)
            .then(res2 => {
              this.lastPage = res.data.last_page
              this.products = [...this.products, ...res.data.data]
              this.products.forEach(product => {
                if (product.htscorev2_rating_scores && product.htscorev2_rating_scores.public_ht_score) {
                  product.public_ht_score = Math.floor(product.htscorev2_rating_scores.public_ht_score)
                  delete product.htscorev2_rating_scores
                } else {
                  product.public_ht_score = 0
                }
                res2.data.forEach((productWithUrl) => {
                  if (productWithUrl && productWithUrl.id === product.id) {
                    product.url = productWithUrl.url
                  }
                })
              })
            })
        })
    },
    openLeadModal (categoryId, productId) {
      EventBus.$emit('open-modal-sidebar', {
        type: 'modal-lead',
        params: {
          categoryId: categoryId,
          productId: productId,
          type: 'request_demo'
        }
      })
    }
  }
}
</script>
