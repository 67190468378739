<template>
  <modal :opened="visible">
    <div class="htr-form modal-access modal-access-sent">
      <div class="icon-hold">
        <img src="/images/email-sent-modal.svg" alt="HotelTechReport">
      </div>
      <h2>Click the verification link sent to your inbox to verify your email</h2>
      <a href="#" class="btn btn-blue" @click.prevent="closeModal()">Back to Hotel Tech Report</a>
    </div>
  </modal>
</template>
<script>
export default {
  props: ['visible'],
  methods: {
    closeModal () {
      window.store.showEmailConfirmationRequestedModal = false
    }
  }
}
</script>
