<template>
  <div v-if="review" class="all-content-submitted">
    <div>
      <top-navigation-sticky
        percent-advanced="100"
      />
    </div>
    <div class="container flex-container">
      <div class="review-form">
        <div class="success-message">
          <div class="icon-hold !mb-5">
            <img class="m-auto" src="/images/icon-check.svg" width="60" alt="">
          </div>
          <div v-if="review.is_verified">
            <h1>{{ htrLanguage.writeReview.thanks_for_your_review || 'thanks_for_your_review' }}</h1>
          </div>
          <div v-else>
            <h1>{{ htrLanguage.writeReview.check_your_inbox || 'check_your_inbox' }}</h1>
            <h5>{{ htrLanguage.writeReview.your_verification_sent_to || 'your_verification_sent_to' }}</h5>
            <div class="inputs-email">
              <input v-model="userEmail" name="email" type="text" :placeholder="htrLanguage.writeReview.type_your_email || 'type_your_email'" :class="{ 'form-control': true}">
              <button class="btn btn-green" @click="reSendLink">
                {{ htrLanguage.company.resend || 'resend' }}
              </button>
            </div>
            <p v-if="emailSent" class="alertEmail">
              {{ htrLanguage.writeReview.sending_email || 'sending_email' }}...
            </p>
            <p>{{ htrLanguage.writeReview.sending_email_footer || 'sending_email_footer' }}</p>
          </div>
        </div>
      </div>

      <form class="container-form-update">
        <div class="review-form">
          <img src="/images/amazon_gifcard.jpeg" class="amazon-gif-card">
          <h1 v-if="countWords > 0" class="text-center">
            {{ (htrLanguage.writeReview.add_words_review_title || 'add_words_review_title').replace(':count_words', countWords) }}
          </h1>
          <h1 v-else class="text-center">
            {{ htrLanguage.writeReview.add_words_review_subtitle || 'add_words_review_subtitle' }}
          </h1>
          <div v-if="shouldShowErrorMessages" class="error-alert">
            {{ errorMessage }}
          </div>

          <div class="inputs-form">
            <div class="content-area content-form-submitted">
              <div :class="{ row: true, validation: true, success: shouldShowSuccess('likeMost'), error: shouldShowError('likeMost') }">
                <div class="title-pannel">
                  <label for="likeMost" :class="{ 'text-big': true, required: 'true' }">
                    <span>
                      {{ htrLanguage.writeReview.what_do_you_like_most_1 || 'what_do_you_like_most_1' }} <strong>{{ htrLanguage.writeReview.what_do_you_like_most_2 || 'what_do_you_like_most_2' }}</strong>?
                    </span>
                  </label>
                </div>

                <textarea v-model="likeMost" :placeholder="`${htrLanguage.writeReview.type_here || 'type_here'}...`" name="likeMost" :class="{ 'form-control': true, large: 'large' }" @change="updateValue" />

                <span class="number-limit">
                  <span :class="{ 'text-danger': isLessThan100Characters('likeMost') }">{{ numberOfCharacters('likeMost') }} {{ htrLanguage.writeReview.characters || 'characters' }} ({{ htrLanguage.writeReview.character_minimum || 'character_minimum' }})</span>
                </span>
              </div>

              <div :class="{ row: true, validation: true, success: shouldShowSuccess('likeLeast'), error: shouldShowError('likeLeast') }">
                <div class="title-pannel">
                  <label for="likeLeast" :class="{ 'text-big': true, required: 'true' }">
                    <span>
                      {{ htrLanguage.writeReview.what_do_you_think_1 || 'what_do_you_think_1' }} <strong>{{ htrLanguage.writeReview.what_do_you_think_2 || 'what_do_you_think_2' }}</strong>
                    </span>
                  </label>
                </div>

                <textarea v-model="likeLeast" :placeholder="`${htrLanguage.writeReview.type_here || 'type_here'}...`" name="likeLeast" :class="{ 'form-control': true, large: 'large' }" @change="updateValue" />

                <span class="number-limit">
                  <span :class="{ 'text-danger': isLessThan100Characters('likeLeast') }">{{ numberOfCharacters('likeLeast') }} {{ htrLanguage.writeReview.characters || 'characters' }} ({{ htrLanguage.writeReview.character_minimum || 'character_minimum' }})</span>
                </span>
              </div>
            </div>

            <div>
              <div class="btn-hold text-center">
                <button class="btn btn-green btn-large submitButton" @click="updateReview" :disabled="updatingReview">
                  {{ htrLanguage.writeReview.update_my_review || 'update_my_review' }}
                </button>
              </div>
              <p v-if="updatingReview" class="alertEmail">
                {{ htrLanguage.writeReview.updating_review || 'updating_review' }}...
              </p>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>
<style scoped>
  .error-alert {
    width: 95%;
  }
  .alertEmail {
    background: #58886a;
    color: white !important;
    border-radius: 6px;
    width: 50%;
    justify-content: center;
    text-align: center;
    margin-left: 25% !important;
    height: 30px;
    padding-top: 7px;
    margin-bottom: 20px !important;
  }

  .flex-container {
    width: 54%;
  }

  .success-message img {
    width: 56px;
    margin-bottom: 8px;
  }
  .success-message p {
    margin: 0px;
    color: #777;
  }
  .submitButton {
    margin-bottom: 19px;
  }
  .inputs-email {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    height: 46px;
    margin-bottom: 19px;
  }
  .inputs-email input {
    width: 250px;
    height: 100%;
  }
  .inputs-email button {
    height: 100%;
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
    margin: 0px;
  }
  .content-form-submitted {
    padding: 19px 24px;
    border-top: none;
  }
  input.btn-green {
    background: #6FBD8C;
    height: 41px;
    display: flex;
    justify-content: center;
    min-width: 232px;
    align-items: center;
  }
  .container-form-update {
    margin-top: 20px;
  }
  .container-form-update .review-form {
    align-items: center;
    display: flex;
    flex-direction: column;
  }
  .amazon-gif-card {
    width: 228px;
    margin-bottom: 26px;
  }
  .inputs-form {
    width: 100%;
  }
</style>
<script>
import axios from 'axios'
import TopNavigationSticky from './TopNavigationSticky.vue'

export default {
  components: { TopNavigationSticky },
  props: ['reviewId', 'linkEmail'],
  data () {
    return {
      email: null,
      likeMost: null,
      likeLeast: null,
      shouldShowErrorMessages: false,
      requiredFields: ['likeMost', 'likeLeast'],
      moreThanHundredCharacters: {
        likeMost: false,
        likeLeast: false
      },
      errorMessage: null,
      review: {},
      emailSent: false,
      updatingReview: false,
      userEmail: ''
    }
  },
  computed: {
    htrLanguage() {
      if (window && window.i18n) {
        const { writeReview, company, layout } = window.i18n
        if (writeReview && company && layout) {
          return { writeReview, company, layout }
        }
      }
      return { writeReview: {}, company: {}, layout: {} }
    },
    countWords () {
      const sizeLikeLeast = this.likeLeast ? this.likeLeast.split(' ').filter(word => word !== '').length : 0
      const sizeLikeMost = this.likeMost ? this.likeMost.split(' ').filter(word => word !== '').length : 0
      const missingWords = 350 - (sizeLikeLeast + sizeLikeMost)
      return missingWords < 0 ? 0 : missingWords
    }
  },
  created () {
    this.userEmail = this.linkEmail
    axios.get(`/api/reviews-register-information/${this.reviewId}`).then(({ data }) => {
      this.review = JSON.parse(JSON.stringify(data))
      if (this.review) {
        this.likeMost = this.review.pros
        this.likeLeast = this.review.cons
      }
    })
  },
  methods: {
    updateValue () {
      if (this.hasFilledAllRequiredFields()) {
        this.shouldShowErrorMessages = false
      }
    },
    numberOfCharacters (fieldName) {
      if (this[fieldName]) return this[fieldName].length
      return 0
    },
    isLessThan100Characters (fieldName) {
      if (this.numberOfCharacters(fieldName) < 75 && this.numberOfCharacters(fieldName) > 0) {
        this.updateMinimumLengthValidation({
          name: fieldName,
          moreThanHundredCharacters: false
        })

        return true
      }

      this.updateMinimumLengthValidation({
        name: fieldName,
        moreThanHundredCharacters: true
      })

      return false
    },
    shouldShowSuccess (fieldName) {
      return !this.isLessThan100Characters(fieldName) && this[fieldName]
    },
    shouldShowError (fieldName) {
      return this.shouldShowSuccess(fieldName)
        ? false
        : this.shouldShowErrorMessages
    },
    updateMinimumLengthValidation (data) {
      this.moreThanHundredCharacters[data.name] = data.moreThanHundredCharacters
    },
    hasFilledAllRequiredFields () {
      for (let index = 0; index < this.requiredFields.length; index++) {
        if (this[this.requiredFields[index]] === null || this[this.requiredFields[index]] === '') {
          this.errorMessage = this.htrLanguage.writeReview.please_fill_out_fields || 'please_fill_out_fields'
          return false
        }
      }

      for (let key in this.moreThanHundredCharacters) {
        if (this.moreThanHundredCharacters[key] === false) {
          this.errorMessage = this.htrLanguage.writeReview.please_filled_minium_length || 'please_filled_minium_length'
          return false
        }
      }

      return true
    },
    reSendLink () {
      this.emailSent = true
      axios.post(`/api/reviews-register-information/send-verification-email/${this.reviewId}`, { email: this.userEmail }).then((data) => {
        this.emailSent = false
      })
    },
    updateReview (event) {
      event.preventDefault()
      this.shouldShowErrorMessages = false
      if (!this.hasFilledAllRequiredFields()) {
        this.shouldShowErrorMessages = true
      } else {
        this.updatingReview = true
        axios.post(`/api/reviews-register-information/update-review/${this.reviewId}`, { review_id: this.reviewId, cons: this.likeLeast, pros: this.likeMost }).then((data) => {
          this.updatingReview = false
        })
      }
    }
  }
}
</script>
