<template>
  <nav class="sub-nav style01">
    <div class="container  sub-nav-container">
      <button class="sub-nav-toggle">
        {{ activeSectionName }}
      </button>
      <ul class="sub-nav-categories-list">
        <li :class="{'active': !value}">
          <a href="#" @click.prevent="sectionSelect(null)">All</a>
        </li>
        <li v-for="section in sections" :key="section.key" :class="{'active': value === section.key}">
          <a href="#" @click.prevent="sectionSelect(section.key)">
            {{ section.name }}
          </a>
        </li>
      </ul>
    </div>
  </nav>
</template>

<script>
export default {
  props: {
    value: {
      default: null
    }
  },

  data: () => ({
    sections: [
      { key: 'categories', name: 'Categories' },
      { key: 'products', name: 'Products' },
      { key: 'articles', name: 'Articles' },
      { key: 'resources', name: 'Reports & Guides' }
    ]
  }),

  computed: {
    activeSectionName () {
      const activeSection = this.sections.find(section => section.key === this.value)
      return activeSection ? activeSection.name : 'All'
    }
  },

  methods: {
    sectionSelect (value) {
      this.$emit('input', value)
    }
  }
}
</script>
