<template>
  <div>
    <nav class="vendor-navbar">
      <div class="navbar-left">
        <div class="logo">
          <a :href="`/dashboard/${company.slug}/leads`" target="_blank">
            <img src="/images/logo-xs.svg" width="30">
          </a>
        </div>
        <div class="body-navbar">
          <div>
            <a class="topLeftButtons" :href="`/dashboard/${company.slug}/overview`" target="_blank">Dashboard</a>
          </div>
          <div>
            <a class="topLeftButtons" :href="`/dashboard/${company.slug}/reviews/links`" target="_blank">Reviews</a>
          </div>
          <div>
            <a class="topLeftButtons" :href="`/dashboard/${company.slug}/leads`" target="_blank">Leads</a>
            <span v-if="roomsInboxCount > 0">•</span>
          </div>
          <div v-if="isAwardsActive">
            <button class="topLeftButtons" @click="toggleLeaderboard()">
              HotelTechAwards
            </button>
            <span v-if="hotelTechAwardsCircle">•</span>
          </div>
          <div>
            <a class="topLeftButtons" href="https://help.hoteltechreport.com/" target="_blank">Help</a>
          </div>
        </div>
      </div>
      <div class="navbar-right">
        <p>What's new?</p>
        <div class="announcekit-widget" />
        <div class="membershipPlan mouse-pointer" @click="redirectToBilling">
          <p class="membership">
            MEMBERSHIP
          </p>
          <p class="planName">
            {{ plan.dashboard_name }}
          </p>
        </div>
        <button v-if="plan.selectedPrice && plan.selectedPrice.price == '0.00'" class="btn-upgrade">
          <span>
            <a :href="billingUrl" target="_blank" class="upgradeMembershipText">
              Upgrade Membership
            </a>
          </span>
        </button>
        <img :src="company.logo" width="50">
      </div>
    </nav>
    <hotel-tech-awards :open-modal="isHotelTechAwardsActive" :categories="categories" :company-slug="company.slug" />
      <hotel-tech-awards-disclaimer v-if="isHotelTechAwardsActive" />
  </div>
</template>
<style scoped>
  .announcekit-widget {
    margin-left: 5px;
    margin-top: -3px;
  }
  .btn-upgrade {
    border-radius: 3px;
    border: 2px solid #6FBD8C;
    background-color: #6FBD8C;
    color: white;
    font-size: 12px;
    font-weight: 700;
    padding: 12px 16px;
    transition: 0.3s linear;
    cursor: pointer;
    margin-right: 24px;
  }
  .btn-upgrade:hover {
    background: #6FBD8C;
    color: #fff;
  }
  h4 {
    margin: 0px;
  }
  .vendor-navbar {
    display: flex;
    justify-content: space-between;
    background: white;
    border-bottom: 3px solid #636CAE;
  }
  .navbar-left {
    display: flex;
  }
  .logo {
    margin-left: 20px;
    padding: 0px 13px;
    display: flex;
    align-items: center;
    cursor: pointer;
  }
  .mouse-pointer {
    cursor: pointer;
  }
  .body-navbar {
    display: flex;
  }
  .body-navbar div {
    margin: 0px 17px;
    position: relative;
    border-bottom: 6px solid transparent;
  }
  .body-navbar div:hover {
    box-sizing: border-box;
    border-bottom: 6px solid #636CAE;
  }
  .body-navbar div span {
    position: absolute;
    top: -8px;
    right: -9px;
    color: #d87336;
    font-size: 41px;
  }
  .topLeftButtons {
    padding: 20px 0px 16px 0px;
    font-size: 14.5px;
    display: block;
    color: #3c3c3c;
    outline: none;
    font-weight: 500;
    border: none;
    background: none;
    letter-spacing: 0.3pt;
  }
  .navbar-right {
    display: flex;
    align-items: center;
    padding-right: 145px;
  }
  .navbar-right p {
    color: #8a8a8a;
    margin: 0px;
    font-weight: 600;
    font-size: 14px;
    margin-right: 7px;
  }
  .navbar-right .circle {
    background: #8a8a8a;
    padding: 9px;
    border-radius: 50%;
  }
  .navbar-right button {
    margin: 0px 23px;
  }
  .membership {
    font-size: smaller !important;
  }
  .membershipPlan {
    flex-direction: column;
    align-items: flex-end;
    padding-right: 10px;
    font-weight: bold;
    margin-left: 10px;
  }
  .planName {
    text-align: center;
    color: #4C5ECF !important;
  }
  .upgradeMembershipText {
    font-weight: bold !important;
    color: WHITE !important
  }
</style>
<script>
import HotelTechAwards from './HotelTechAwards.vue'
import HotelTechAwardsDisclaimer from './HotelTechAwardsDisclaimer.vue'
import eventBus from '../../../../dashboard/eventBus.js'
import PlanMixins from '../../../../mixins/MembershipPlan/Plans.js'
import DealMixins from '../../../../mixins/MembershipPlan/Deals.js'
import { mapActions, mapGetters } from 'vuex'
import axios from 'axios'

export default {
  components: {
    HotelTechAwards,
    HotelTechAwardsDisclaimer
  },
  mixins: [
    PlanMixins,
    DealMixins
  ],
  props: [ 'company' ],
  data () {
    return {
      isHotelTechAwardsActive: false,
      categories: []
    }
  },
  computed: {
    ...mapGetters([
      'roomsInboxCount'
    ]),
    isAwardsActive () {
      if (window.Laravel && (window.Laravel.awardsEnabled === 1 || window.Laravel.awardsEnabled === 2)) {
        return true
      }
      return false
    },
    billingUrl () {
      return `${window.location.origin}/dashboard/${this.company.slug}/settings/billing`
    },
    hotelTechAwardsCircle () {
      let productNotRegistered = false
      if (this.categories && this.categories.length > 0) {
        this.categories.forEach(({ product }) => {
          if (product && !product.registered) {
            productNotRegistered = true
          }
        })
      }
      return productNotRegistered
    },
    plan () {
      return this.computedPlan
    }
  },
  created () {
    window.announcekit.push({
      'widget': 'https://announcekit.app/widget/4t3k64',
      'selector': '.announcekit-widget',
      'version': 2
    })
    axios.get(`/api/awards/leaderboard?company_id=${this.company.id}`).then((res) => {
      Object.keys(res.data).forEach(key => {
        this.categories.push(res.data[key])
      })
    })
  },
  mounted () {
    eventBus.listen('toggleLeaderboard', () => this.isHotelTechAwardsActive = !this.isHotelTechAwardsActive)
  },
  methods: {
    ...mapActions([
    ]),
    redirectToBilling () {
      window.open(this.billingUrl)
    },
    toggleLeaderboard () {
      eventBus.fire('toggleLeaderboard')
    }
  }
}
</script>
