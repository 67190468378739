<template>
  <div class="content__box recommend-section">
    <div class="content__box__header">
      <div class="box__header__left-col">
        <h3>{{ htrLanguage.company.expert_q_and_a || 'expert_q_and_a' }}</h3>
      </div>
    </div>


    <div class="recommend__content">
      <div class="recommend__content__list">
        <template v-for="(label, key) in questions">
          <div class="recommend__item">
            <div class="recommend__item__box">
              <b>{{ htrLanguage.company.question || 'question' }}:</b>
              <p class="recommend__item__question">{{ htrLanguage.company['question_' + key] || 'question_' }}</p>
            </div>
            <div class="recommend__item__box">
              <b>{{ htrLanguage.company.answer || 'answer' }}:</b>
              <div>
                <p class="recommend__item__answer">
                  <read-more :text="translate(recommendation, key)" :max-chars="300" more-str="See more" class="recommend__text"></read-more>
                </p>
                <p class="recommend__item__info">
                  {{ htrLanguage.company.by || 'by' }} {{ recommendation.user_info.first_name }} {{recommendation.user_info.last_name }} ({{recommendation.user_info.company}}) {{ htrLanguage.company.on || 'on' }} {{ recommendation.created_at | dateFormat('MMMM D, YYYY') }}
                </p>
                <a v-if="sorted.length > 1 && expanded_question !== key" href="#" @click.prevent="expand(key)" class="recommend__item__toggle-btn">
                  <i class="icon-chevron-down"></i> {{ htrLanguage.company.see_more_answers || 'see_more_answers' }} ({{ sorted.length - 1 }})</a>
              </div>
            </div>

            <div class="recommend__item__all-answer" v-if="expanded_question == key">
              <h4>{{ htrLanguage.company.showing_all_answers_to || 'showing_all_answers_to' }}: "{{ htrLanguage.company['question_' + key] || 'question_' }}"</h4>
              <div v-for="recommend in allButFirst" :key="recommend.id">
                <recommendation
                  :recommend="recommend"
                  :company="company"
                  :createdAtRecommend="recommend.created_at | dateFormat('MMMM D, YYYY')"
                  :keyField="key"
                />
              </div>
              <button class="recommend__item__all-answer__btn" @click.prevent="collapse">{{ htrLanguage.home.view_less || 'view_less' }}</button>
            </div>
          </div>
        </template>
      </div>

      <div class="recommend__content__aside">
        <div>
          <div class="recommend__content__aside__header">
            <div class="recommend__content__photos">
              <img v-for="{user_info} in topThree" :src="user_info.avatar || `/images/default-avatar.png`">
            </div>
            <span v-if="sorted.length > 3">+ {{ sorted.length - 3 }} {{ htrLanguage.company.recommend_lower_case || 'recommend_lower_case' }}</span>
          </div>
          <div>
            <span class="recommend__content__aside__title">{{ htrLanguage.company.recommended_by || 'recommended_by' }}</span>
            <div class="recommend__content__author" v-for="{user_info} in sidebarReviews">
              <img :src="user_info.avatar || `/images/default-avatar.png`">
              <div>
                <b>{{ user_info.first_name }} {{user_info.last_name }}</b>
                <span><span v-html="user_info.title"></span> @ {{ user_info.company }}</span>
              </div>
            </div>
            <div class="recommend__content__aside__show_more" v-if="sorted.length > 5">
              <a href="#" @click.prevent="sidebarExpanded = !sidebarExpanded">
                <span v-if="!sidebarExpanded">{{ htrLanguage.company.show_more || 'show_more' }} ({{ this.sorted.length - 5 }})</span>
                <span v-else>{{ htrLanguage.company.show_less || 'show_less' }}</span>
              </a>
            </div>
          </div>
        </div>
        <div class="recommend__content__aside__footer">
          <span class="center">{{ htrLanguage.company.recommend_footer || 'recommend_footer' }} {{ company ? company.name : '' }}?</span>
          <a v-if="company && company.hasOwnProperty(`slug`)" :href="`/recommend/${company.slug}`" target="_blank">{{ htrLanguage.company.leave_a_recommendation || 'leave_a_recommendation' }}</a>
        </div>
      </div>
    </div>
  </div>
</template>
<style scoped>
.center {
  text-align: center;
}
</style>
<script>
  import { orderBy } from 'lodash'
  import Recommendation from './Recommendation.vue'

  export default {
    components: { Recommendation },
    props: ['recommendations', 'company'],
    data () {
      return {
        sidebarExpanded: false,
        expanded_question: null,
        experiencesDictionary: {
          'tried': 'has tried the',
          'demoed': 'has seen a demo of the',
          'occasional': 'occassionaly uses the',
          'regular': 'regularly uses the',
          'testimonials': 'heard from clients about the'
        },
        questions: {
          what_does_well: 'What does this product or service do well?',
          difference: 'What differentiates this product or service from the competition?',
          advice: 'Based on your experience with this product or service, if you could give one piece of advice to a hotelier considering this product or service, what would it be?'
        }
      }
    },
    methods: {
      translate (entry, field) {
        let translation = entry[field]
        if (entry.translations && entry.translations.length > 0) {
          const searchTranslation = entry.translations.find((findTranslate) => findTranslate.language_id === this.idLanguageLocale)
          if (searchTranslation) {
            translation = searchTranslation[field]
          }
        }
        return translation
      },
      expand (question) {
        this.expanded_question = question
      },
      collapse () {
        this.expanded_question = null
      }
    },
    computed: {
      htrLanguage() {
        if (window && window.i18n) {
          const { home, company } = window.i18n
          if (home && company) {
            return { home, company }
          }
        }
        return { home: {}, company: {} }
      },
      idLanguageLocale () {
        if (window.Laravel.languageLocale) {
          return window.Laravel.languageLocale.id
        }
        return undefined
      },
      sorted () {
        return orderBy(this.recommendations, 'created_at', 'desc')
      },
      recommendation () {
        return this.sorted[0]
      },
      topThree () {
        return this.sorted.slice(0, 3)
      },
      sidebarReviews () {
        if(this.sidebarExpanded) return this.sorted;
        else return this.sorted.slice(0, 5)
      },
      allButFirst () {
        return this.sorted.slice(1)
      }
    }
  }
</script>
