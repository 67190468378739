<template>
  <div>
    <modal v-if="invitation" :opened="shown" :disableClickOutside="true">
      <div class="closeIcon" @click="shown = false">
        <i class="fa fa-times" aria-hidden="true"></i>
      </div>
      <div class="htr-form modal-access">
        <h2>Create a free vendor account</h2>
        <p class="modal-access__description">
          Create a free account to claim {{ form.company_name }}’s profile and accept {{ sourceCompany.name }}’s
          invitation to connect
        </p>

        <p class="sectionTitle">
          PERSONAL DETAILS
        </p>
        <div class="form-box">
          <div class="form-groups">
            <label class="form-group"
                   :class="{'not-empty': v$.form.first_name.$model, 'error': v$.form.first_name.$invalid && submitted}">
              <input v-model="v$.form.first_name.$model" type="text" class="form-control">
              <span class="custom-label">first name</span>
            </label>
            <label class="form-group"
                   :class="{'not-empty': v$.form.last_name.$model, 'error': v$.form.last_name.$invalid && submitted}">
              <input v-model="v$.form.last_name.$model" type="text" class="form-control">
              <span class="custom-label">last name</span>
            </label>
          </div>
          <label class="form-group" :class="{'not-empty': v$.form.email.$model, 'error': hasEmailError  }">
            <input v-model="v$.form.email.$model" type="text" class="form-control">
            <span class="custom-label">professional email</span>

            <span v-if="v$.form.email.$model && hasEmailError && isPersonalEmail">Personal email detected. Please enter your work email</span>
          </label>
          <label class="form-group form-group-checkbox">
            <input v-model="form.subscribed" type="checkbox">
            <span class="custom-checkbox"/>
            Sign up for theRewind, Hotel Tech Report's monthly newsletter
          </label>
        </div>
        <p class="sectionTitle">
          COMPANY DETAILS
        </p>
        <div class="form-box">
          <label class="form-group" :class="{'not-empty': v$.form.company_name.$model, 'error': v$.form.company_name.$invalid && submitted  }">
            <input v-model="v$.form.company_name.$model" type="text" class="form-control">
            <span class="custom-label">COMPANY NAME</span>
          </label>
          <div class="form-groups">
            <label class="form-group"
                   :class="{'not-empty': v$.form.company_year_founded.$model, 'error': v$.form.company_year_founded.$invalid && submitted}">
              <input v-model="v$.form.company_year_founded.$model" type="text" class="form-control">
              <span class="custom-label">YEAR FOUNDED</span>
            </label>
            <label class="form-group"
                   :class="{'not-empty': v$.form.company_employees_count.$model, 'error': v$.form.company_employees_count.$invalid && submitted}">
              <input v-model="v$.form.company_employees_count.$model" type="number" class="form-control">
              <span class="custom-label">EMPLOYEE COUNT/COMPANY SIZE</span>
            </label>
          </div>
          <div class="form-group">
            <file-uploader ref="uploader" v-model="form.company_logo" crop="512x512" class="button is-small is-block" @done="() => {}">
              <p :class="{borderSet: hasCompanyLogo, borderNotSet: !hasCompanyLogo && !submitted, borderNotSetAfterSubmit: !hasCompanyLogo && submitted}" class="companyLogoUploadField">
                <i class="fa fa-upload" aria-hidden="true"></i>
                <span>Upload company logo (png, jpg)</span>
              </p>

              <div v-if="hasCompanyLogo" class="companyLogoText">
                <p class="logoText">File uploaded: {{ form.company_logo  }}</p>
              </div>
            </file-uploader>
          </div>
        </div>
        <p class="sectionTitle">
          SELECT REGIONS YOU SERVICE
        </p>
        <div class="form-box">
          <div class="form-groups borderRadius2px" :class="{borderNotSetAfterSubmit: !hasMarkets && submitted}">
            <table class="regionCheckBoxes">
              <tr>
                <td>
                  <label class="form-group-checkbox">
                    <input v-model="form.markets.na" type="checkbox" >
                    <span class="custom-checkbox"/>
                      North America
                  </label>
                </td>
                <td>
                  <label class="form-group-checkbox">
                    <input v-model="form.markets.ap" type="checkbox">
                    <span class="custom-checkbox"/>
                      Asia pacific
                  </label>
                </td>
                <td>
                  <label class="form-group-checkbox">
                    <input v-model="form.markets.europe" type="checkbox">
                    <span class="custom-checkbox"/>
                      Europe
                  </label>
                </td>
              </tr>
              <tr>
                <td>
                  <label class="form-group-checkbox">
                    <input v-model="form.markets.me" type="checkbox">
                    <span class="custom-checkbox"/>
                      Middle East
                  </label>
                </td>
                <td>
                  <label class="form-group-checkbox">
                    <input v-model="form.markets.sa" type="checkbox">
                    <span class="custom-checkbox"/>
                      South America
                  </label>
                </td>
                <td>
                  <label class="form-group-checkbox">
                    <input v-model="form.markets.africa" type="checkbox">
                    <span class="custom-checkbox"/>
                      Africa
                  </label>
                </td>
              </tr>
            </table>
          </div>
        </div>
        <div class="regionDisclaimer">
          <p>
          Selecting your regions of service will enable you to
          <a href="https://help.hoteltechreport.com/en/articles/3584174-what-are-direct-vs-matched-leads" target="_blank">
            connect with leads
          </a>
          in that region as you
          <a href="https://help.hoteltechreport.com/en/articles/4346051-why-reviews-are-so-critical-to-your-success" target="_blank">
            start improving your reputation
          </a>
          and collecting reviews.
          </p>
        </div>
        <a class="btn btn-blue" @click.prevent="register" v-if="!loading">Create account and claim profile</a>
        <a class="btn btn-blue" v-else>Loading...</a>
        <p>Already have an account? <a href="/login">Login</a></p>
        <p class="form-group__note">By clicking the button above you agree to our <a
          href="https://help.hoteltechreport.com/legal/terms-conditions" target="_blank">Terms of Use</a></p>
      </div>
    </modal>
  </div>
</template>
<style scoped>
.borderNotSet {
  border: 1px solid #e2e2e2;
}
.borderRadius2px {
  border-radius: 2px;
}
.borderNotSetAfterSubmit {
 border: 1px solid #ff8a8a;
 background: #fff7f7;
}
.borderSet {
  border: 1px solid #4C5ECF;
}
.closeIcon {
  position: absolute;
  top: 15px;
  right: 22px;
  font-size: 24px;
  color: #b5b5b5;
}
.companyLogoUploadField{
  padding: 13px;
  border-radius: 2px;
}
.companyLogoText {
  text-align: left;
  width: 200px;
}
.logoText {
  font-size: 12px !important;
  color: #4FB267 !important;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.sectionTitle {
  font-size: 14px !important;
  font-weight: bold;
  font-family: $base-font-family !important;
  text-align: left;
  color: black !important;
  margin-left: 65px !important;
}
.form-box a {
  background: white !important;
  padding: 0px;
  width: 100%;
}
.regionCheckBoxes {
  width: 100%;
  border-collapse: separate;
  border-spacing: 10px;
}
.regionCheckBoxes td {
  width: 33.33%;
}
.regionDisclaimer {
  background: #F5F5F5;
  margin: 0px 66px;
  padding: 5px;
}
.regionDisclaimer p {
  color: #949494 !important;
  font-size: 12px !important;
  font-family: $base-font-family !important;
  text-align: left;
}
.regionDisclaimer p a {
  font-size: 12px !important;
}
</style>

<script>
import { useVuelidate } from '@vuelidate/core'
import { required, email } from '@vuelidate/validators'
  import checkEmail from '../../../../mixins/checkEmail'
  import HasErrors from '../../../../mixins/HasErrors.vue'
  import { mapActions, mapGetters } from 'vuex'
  import swal from 'sweetalert2'

  export default {
    mixins: [
      checkEmail,
      HasErrors
    ],
    props: ['invitationId'],
    setup () {
      return { v$: useVuelidate() }
    },
    data () {
      return {
        form: {
          first_name: '',
          last_name: '',
          email: '',
          subscribed: true,
          product_id: null,
          company_name: '',
          company_employees_count: undefined,
          company_year_founded: '',
          company_logo: '',
          invitation_id: undefined,
          markets: {
              na: false,
              ap: false,
              europe: false,
              me: false,
              sa: false,
              africa: false
          }
        },
        shown: false,
        submitted: false,
        loading: false
      }
    },
    created () {
      this.fetchIntegrationInvitationById(this.invitationId).then((res) => {
        if (res.data.used_at) {
          swal({
            title: 'This link is no longer available!',
            html: 'The invitation you are trying to use has been used.',
            type: 'warning',
            showCancelButton: false,
            confirmButtonText: 'back to HotelTechReport.com'
          })
          this.shown = false
        } else {
          this.form.email = res.data.email
          this.form.product_id = res.data.target_product_id
          this.form.invitation_id = this.invitationId
          let nameParts = res.data.name.split(' ')
          this.form.first_name = nameParts.pop()
          this.form.last_name = nameParts.join(' ')
          Array.from(this.targetProduct.markets).forEach((market) => {
            this.form.markets[market] = true
          })
          this.form.company_name = this.targetCompany.name
          this.form.company_year_founded = this.targetCompany.year_founded
          this.form.company_logo = this.targetCompany.logo
          this.shown = true
        }
      })
    },
    validations: {
      form: {
        first_name: { required },
        last_name: { required },
        email: { required, email },
        company_name: { required },
        company_year_founded: { required  },
        company_employees_count: { required },
        company_logo: { required },
      }
    },
    computed: {
      ...mapGetters([
        'integrationInvitationById',
        'companyById',
        'productById'
      ]),
      hasCompanyLogo () {
        return this.form.company_logo !== '' && this.form.company_logo !== null && this.form.company_logo !== undefined
      },
      hasMarkets () {
        return Object.values(this.form.markets).reduce((accumulator, current) => accumulator | current)
      },
      invitation () {
        return this.integrationInvitationById(this.invitationId)
      },
      sourceProduct () {
        return this.productById(this.invitation.source_product_id)
      },
      sourceCompany () {
        return this.companyById(this.sourceProduct.company_id)
      },
      targetProduct () {
        return this.productById(this.invitation.target_product_id)
      },
      targetCompany () {
        return this.companyById(this.targetProduct.company_id)
      },
      isPersonalEmail () {
        return !this.checkBusinessEmail(this.form.email)
      },
      hasEmailError () {
        return this.submitted && (this.isPersonalEmail || this.v$.form.email.$invalid || this.error('email'))
      }
    },
    methods: {
      ...mapActions([
        'fetchIntegrationInvitationById',
        'postUnauthenticatedCompanyClaim'
      ]),
      register () {
        this.submitted = true
        if (this.v$.$invalid || this.loading) {
          return
        }
        // We dont have a validator for the markets data model
        // so we check that at least one has been checked
        if (!this.hasMarkets){
          swal({
            title: 'Regions of service are really important',
            html: 'Please check your regions of service.',
            type: 'warning',
            showCancelButton: false,
            confirmButtonText: 'back'
          })
          return
        }
        this.loading = true

        this.resetErrors()
        this.postUnauthenticatedCompanyClaim(this.form).then(() => {
          window.store.showEmailConfirmationRequestedModal = true
          this.shown = false
        }).catch(err => {
          if (err.response && err.response.data) this.setErrors(err.response.data.errors)
        }).then(res => {
          this.loading = false
        })
      }
    }
  }
</script>
