<template>
  <div class="wrap-popup-box modal-close-lead">
    <div class="popup-box">
      <button class="btn-close" @click="$emit('cancel')">
        <i class="icon-cross" />
      </button>
      <h4 class="popup-box__title">
        Want to find out which {{ category.name }} is best for your hotel?
      </h4>
      <a :href="quizUrl" target="_blank" class="btn-quiz" @click="$emit('confirm')">Take the quiz</a>
      <button class="btn-cancel" @click="$emit('confirm')">
        No thanks
      </button>
      <a v-if="category.buyers_guide" class="link-guide" target="_blank" :href="buyersGuideUrl" @click="$emit('confirm')">
        <img src="/images/flag.svg">
        <p>Want to check out free {{ category.name }} reports and guides?</p>
        <i class="icon-sarrow-right" />
      </a>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  props: [
    'categoryId'
  ],
  computed: {
    ...mapGetters([
      'categoryById'
    ]),
    buyersGuideUrl () {
      return JSON.parse(this.category.buyers_guide).url
    },
    category () {
      return this.categoryById(this.categoryId)
    },
    quizUrl () {
      if (!this.category) return '/quiz'

      if (this.category.parent_id) {
        return `/quiz/integrate_with?category_id=${this.category.parent_id}&subcategory_id=${this.category.id}`
      } else {
        return `/quiz/subcategory?category_id=${this.category.id}`
      }
    }
  }
}
</script>
