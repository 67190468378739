export default class ProductSidebar {

  constructor () {
    this.currentSection = undefined

    if ($('#product-sidebar').length) {
      window.addEventListener('scroll', this.sidebarActive.bind(this))
      window.addEventListener('scroll', this.sidebarSticky.bind(this))
    }

    if($('#profiles-mobile-top-bar-slider').length) {
      window.addEventListener('scroll', this.horizontalNavBarActive.bind(this))
    }

    this.toggleDropdown()
  }

  horizontalNavBarActive (e) {
    $('.scrollactive-item-mobile').each((item, el) => {
      const id = $(el).attr('aria-controls')

      if (id && this.elementInViewport(id)) {

        $('.scrollactive-item-mobile').parent().removeClass('is-active')
        $(el).parent().addClass('is-active')
        if (this.currentSection !== el) {
          $(el).parent().get(0).scrollIntoView({
            inline: 'center'
          })
          this.currentSection = el
        } 
      }
    })
  }

  sidebarSticky () {
    let sidebarStart = $('#sidebar-profile').offset().top
    let scroll = $(document).scrollTop()
    let footerStart = $('#footer').offset().top
    let sidebarWidth = $('#sidebar-profile').width()
    if (scroll > sidebarStart) {
      $('#product-sidebar').addClass('active')
      $('#product-sidebar').css('width', sidebarWidth)
    }
    if (scroll < sidebarStart) {
      $('#product-sidebar').removeClass('active')
    }
    if (scroll < sidebarStart || scroll >= (footerStart - 600)) {
      $('#product-sidebar').removeClass('active')
    }
  }

  sidebarActive () {
    $('.scrollactive-item-desktop').each((item, el) => {
      const id = $(el).attr('aria-controls')

      if (id && this.elementInViewport(id)) {
        $('.scrollactive-item-desktop.is-active').removeClass('is-active')
        $(el).addClass('is-active')
      }
    })
  }

  elementInViewport (id) {
    let el = document.getElementById(id)
    if(!el) return false;

    let top = el.offsetTop
    let height = el.offsetHeight

    return (
      top - 150 <= window.pageYOffset &&
      (top + height) >= (window.pageYOffset)
    )
  }

  toggleDropdown () {
    $('.dropdown-menu-product__btn').on('click', () => {
      $('.dropdown-menu-product').toggleClass('active')
    })
    $('.sidebar-profile__nav a').on('click', () => {
      $('.dropdown-menu-product').toggleClass('active')
    })
  }
}
