<template>
  <modal :opened="true">
    <i class="icon-envelope-1" />
    <h1>Please verify your email</h1>
    <p>To verify your account, please make sure this is your correct business email. Once you submit, we will send an email to this address with a link to verify your account.</p>

    <template v-if="success">
      <span class="ty-text">Thank you. Please check your&nbsp;inbox.</span>
    </template>
    <template v-else>
      <div class="input-addon">
        <input v-model="verification_email" type="email" class="input-field">
        <a class="btn btn-green" @click="submit">Submit</a>
      </div>
    </template>
  </modal>
</template>

<script>
import axios from 'axios'
import swal from 'sweetalert2'

export default {
  props: ['email'],
  data () {
    return {
      success: false,
      verification_email: null
    }
  },
  created () {
    this.verification_email = this.email
  },
  methods: {
    submit () {
      axios.post('/api/user/email-verifications', { email: this.verification_email })
        .then(() => window.location.reload())
        .catch(err => {
          let message = "Sorry, there was an error.<br> We're already looking into it!"
          swal('Oops!', err.response.data.message || message, 'error')
        })
    }
  }
}
</script>