<template>
  <div class="content__box product-section">
    <div class="content__box__header">
      <div class="content__box__left-col">
        <h3>{{ htrLanguage.company.popular || 'popular' }} {{ product.name }} {{ htrLanguage.layout.integrations || 'integrations' }}</h3>
      </div>
      <div class="box__header__right-col">
        <a href="https://hapicloud.io" target="_blank" class="wrap-banner-integrate">
          <div class="banner-integrate">
            <span>{{ htrLanguage.company.need_a_custom_integration || 'need_a_custom_integration' }}</span>
            <img src="/images/banner__image.svg" class="banner__image">
          </div>
        </a>
      </div>
    </div>
    <template v-if="integrations.length">
      <div class="product-posts">
        <integration-tile v-for="integration in integrations" :key="integration.id" :product-id="product.id"
                          :integration="integration" />
      </div>
      <a :class="'btn btn-hold btn-hold_' + codeLanguage" :href="`/${company.slug}/integrations`">
        {{ htrLanguage.home.view_all || 'view_all' }} ({{ integrationsCount }} {{ company.name }} {{ htrLanguage.layout.integrations || 'integrations' }})
      </a>
    </template>

    <div v-else class="no-content">
      <div class="text-wrap">
        <div class="icon-hold">
          <img src="/images/icon-plug.svg" alt="" width="37">
        </div>
        <span class="txt">{{ htrLanguage.company.integrations_no_content_1 || 'integrations_no_content_1' }} {{ product.name }} {{ htrLanguage.company.integrations_no_content_2 || 'integrations_no_content_2' }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import IntegrationTile from '../../partials/integrations/IntegrationTile.vue'

export default {
  components: {
    IntegrationTile
  },

  props: {
    integrationsCount: {
      type: Number,
      required: true
    }
  },

  data () {
    return {
      filter: [],
      featured: [],
      shown: 3
    }
  },

  computed: {
    ...mapGetters([
      'integrationsByProductId',
      'productById',
      'categoryById'
    ]),
    htrLanguage() {
      if (window && window.i18n) {
        const { home, layout, company } = window.i18n
        if (home && layout && company) {
          return { home, layout, company }
        }
      }
      return { home: {}, layout: {}, company: {} }
    },
    codeLanguage() {
      if (window.Laravel.languageLocale) {
        return window.Laravel.languageLocale.code
      }
      return 'en'
    },
    company () {
      return window.company.company
    },
    product () {
      return window.company.product
    },
    category () {
      return window.company.category
    },
    integrations () {
      return this.integrationsByProductId(this.product.id)
    }
  },

  created () {
    this.fetchProductIntegrations({ id: this.product.id, options: { is_product_page: true } })
  },

  methods: {
    ...mapActions([
      'fetchProductIntegrations'
    ])
  }
}
</script>
