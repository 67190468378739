<template>
  <section class="box-search">
    <h2 class="box-search__title">Reports & Guides <span>({{ items.length }} results)</span></h2>

    <div class="posts">
      <article v-for="resource in itemsFiltered" :key="resource.id" class="post">
        <div v-if="isBuyersGuide(resource)">
          <a :href="resource.url" class="post-link" target="_blank"></a>
          <div class="img">
            <a :href="resource.url" :style="{'background-image': `url(${resource.seo_background_url})`}"></a>
          </div>
          <div class="des">
            <div>
              <a :href="resource.category_url" class="post__category">
                {{ resource.category_name }}
              </a>
              <p class="des-shot">
                {{ resource.title }}
              </p>
            </div>
            <div v-if="resource.sponsor_product_id" class="sponsored-info">
              Sponsored by
              <a :href="resource.product_url">
                {{ resource.company_name }}
              </a>
            </div>
          </div>
        </div>

        <div v-if="isReport(resource)">
          <a :href="resource.url" class="post-link" target="_blank"></a>
          <div class="img">
            <a :href="resource.url" :style="{'background-image': `url(${resource.seo_background_url})`}"
               target="_blank"></a>
          </div>
          <div class="des">
            <div v-if="resource.category_id">
              <a :href="resource.category_url" class="post__category" target="_blank">
                {{ resource.category_name }}
              </a>
            </div>
            <div>
              <p class="des-shot">{{ resource.title }}</p>
            </div>
            <div v-if="resource.sponsor_product_id" class="sponsored-info">
              Sponsored by <a :href="resource.product_url">{{ resource.company_name }}</a>
            </div>
          </div>
        </div>
      </article>
    </div>

    <div v-if="canShowMore" class="load-more">
      <button @click="load">
        View more
      </button>
    </div>
  </section>
</template>

<script>
import arrayPagination from '../Mixins/arrayPagination'
import {mapGetters} from "vuex";

export default {
  mixins: [
    arrayPagination
  ],


  computed: {
    ...mapGetters(['getResources']),
    items () {
      return this.getResources
    },
  },

  data: () => ({
    countOnFirstPage: 9,
    countOnPage: 18,
  }),

  methods: {
    isBuyersGuide (resource) {
      return resource.type === 'buyers-guides'
    },
    isReport (resource) {
      return resource.type === 'reports'
    }
  }
}
</script>
