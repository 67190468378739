<template>
  <form id="creditForm" :action="`/claims/${claimRequest}`" method="POST">
    <div class="form-wrap">
      <div class="input-cols">
        <div class="input-hold">
          <input v-model="firstName" type="text" name="firstName" class="form-control required" placeholder="First name">
        </div>
        <div class="input-hold">
          <input v-model="lastName" type="text" name="lastName" class="form-control required" placeholder="Last name">
        </div>
      </div>
      <div class="input-hold">
        <input v-model="company.name" type="text" name="company[name]" class="form-control required" placeholder="Company">
      </div>
      <div class="input-hold">
        <input v-model="email" type="text" name="email" class="form-control required" placeholder="Work Email">
      </div>
      <div class="input-hold">
        <input id="companyWebsite" v-model="company.website" type="text" name="company[website]" class="form-control required" placeholder="https://yourcompany.com">
      </div>
      <div class="input-hold">
        <input v-model="title" type="text" name="title" class="form-control required" placeholder="Title">
      </div>
      <div class="input-hold">
        <textarea v-model="bio" class="form-control required" name="bio" placeholder="Short Bio" />
      </div>
      <div class="input-file">
        <file-uploader v-model="photo" crop="150x150" class="btn-upload">
          Upload File
        </file-uploader>
        <div v-if="photo" class="file">
          <a :href="photo" target="_blank"><img :src="photo" width="24"></a>
        </div>
        <input v-model="photo" type="hidden" name="photo">
      </div>
      <div class="input-hold">
        <textarea v-model="additionalNotes" name="additionalNotes" class="form-control small" placeholder="Additional notes/comments for approval of article (optional)" />
      </div>
      <div class="checkbox-row">
        <label class="checkbox">
          <input v-model="terms" name="terms" type="checkbox">
          <span :style="{ color: hasBeenSubmitted && ! terms ? 'red' : 'black' }" class="txt">By submitting content you agree to our terms and conditions... </span>
        </label>
      </div>
      <div class="btn-hold">
        <input type="submit" class="btn btn-green" value="Submit" @click.prevent="submit">
      </div>
    </div>
  </form>
</template>
<script>
export default {
  props: ['postId', 'claimRequest'],
  data () {
    return {
      firstName: window.creditPrefilledData.firstName,
      lastName: window.creditPrefilledData.lastName,
      email: window.creditPrefilledData.email,
      company: {
        name: window.creditPrefilledData.company,
        website: window.creditPrefilledData.companyWebsite
      },
      title: window.creditPrefilledData.title,
      bio: window.creditPrefilledData.bio,
      photo: window.creditPrefilledData.photo,
      newPhoto: null,
      additionalNotes: '',
      terms: false,
      fileFrame: null,
      hasBeenSubmitted: false
    }
  },
  watch: {
    newPhoto () { this.photo = this.newPhoto }
  },
  mounted () {
    jQuery('#creditForm').validate()

    jQuery('#companyWebsite').rules('add', {
      required: true
    })
  },
  methods: {
    submit () {
      this.hasBeenSubmitted = true
      if (jQuery('#creditForm').valid() && this.terms) {
        document.getElementById('creditForm').submit()
      }
    }
  }
}
</script>
