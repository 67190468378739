<template>
  <div :class="{ row: true, validation: true, success: shouldShowSuccess(), error: shouldShowError() }">
    <slot name="btn" />
    <div class="title-pannel">
      <label :for="fieldName" :class="{ 'text-big': true, required: required == 'true' }">
        <slot name="label" />
      </label>
    </div>

    <template v-if="type === 'placeAutocomplete'">
      <slot name="input-icon" />
      <input :id="fieldName" :ref="fieldName" v-model="company" type="text" :placeholder="placeholder"
             :maxlength="maxCharacter" :name="fieldName" :class="{ 'form-control': true, large: size == 'large' }"
             @blur="hideTooltip()" @focus="showTooltip()" @change="valueChanged(isForcingGooglePlaces ? null : company)">
      <input id="city" type="hidden" name="hotel[city]">
      <input id="country" type="hidden" name="hotel[country]">
      <input id="country_iso_code" type="hidden" name="hotel[country_iso_code]">
      <input id="state" type="hidden" name="hotel[state]">
      <input id="region" type="hidden" name="hotel[region]">
      <input id="company" type="hidden" name="hotel[company]">
      <input id="phone" type="hidden" name="hotel[phone]">
      <input id="company_website" type="hidden" name="hotel[company_website]">
    </template>
    <template v-else-if="type === 'multiselect'">
      <multiselect :id="fieldName" v-model="value" :options="options" :multiple="true" :close-on-select="false"
                   :clear-on-select="false" :preserve-search="true" :placeholder="placeholder" track-by="id"
                   label="value" />
      <!-- TODO: to be removed...-->
      <input :id="`${fieldName}-hidden`" type="hidden" :name="fieldName">
    </template>
    <textarea v-else :id="fieldName" :ref="fieldName" v-model="value" :placeholder="placeholder"
              :maxlength="maxCharacter" :name="fieldName" :class="{ 'form-control': true, large: size == 'large' }"
              :readonly="disabledInput" @blur="hideTooltip()" @focus="showTooltip()" />

    <span v-if="minCharacter" class="number-limit">
      <span :class="{ 'text-danger': isLessThan100Characters() }">{{ numberOfCharacters() }} characters ({{ minCharacter }} character minimum)</span>
    </span>

    <slot name="tooltip" />
  </div>
</template>

<script>
import Multiselect from 'vue-multiselect'
import axios from 'axios'
// import 'vue-multiselect/dist/vue-multiselect.min.css'
export default {
  components: {
    Multiselect
  },

  props: [
    'maxCharacter',
    'minCharacter',
    'required',
    'size',
    'fieldName',
    'placeholder',
    'forceShowError',
    'forceShowSuccess',
    'type',
    'dataSource',
    'val',
    'disabledInput',
    'enableStoreCompanyWithoutGooglePlaces'
  ],
  data () {
    return {
      value: null,
      options: [],
      company: this.val,
      isForcingGooglePlaces: true
    }
  },

  watch: {
    value () {
      if (this.type !== 'placeAutocomplete') {
        this.valueChanged()
      }
    }
  },
  created () {
    if (this.val) this.value = this.val
    this.tooltip = $(this.$el).find('.alert-popup')
    this.tooltip.hide()
  },
  mounted () {
    if (this.type === 'multiselect') {
      axios.get(`${this.dataSource}`).then(response => {
        this.options = response.data.data.map(item => {
          return {
            id: item.id,
            value: item.name
          }
        })
      })
    } else if (this.type === 'placeAutocomplete') {
      this.isForcingGooglePlaces = !this.enableStoreCompanyWithoutGooglePlaces
      this.initAutocomplete()
    }
  },
  methods: {
    initAutocomplete () {
      if (window.google) {
        const autocomplete = new google.maps.places.Autocomplete(this.$refs[this.fieldName])
        autocomplete.addListener('place_changed', () => {
          this.valueChanged(autocomplete.getPlace())
        })
      } else {
        this.valueChanged(this.company)
      }
    },
    showTooltip () {
      $(this.$el).find('.alert-popup').fadeIn(200)
    },
    hideTooltip () {
      $(this.$el).find('.alert-popup').fadeOut(200)
    },
    removeQueryParams (url) {
      if (typeof url !== 'string' || url.length === 0) return url
      return url.split('?')[0]
    },
    shouldShowSuccess () {
      if (this.minCharacter) {
        return !this.isLessThan100Characters() && this.value
      }

      if (this.forceShowSuccess) return this.forceShowSuccess

      return Array.isArray(this.value) ? this.value.length : this.value
    },
    shouldShowError () {
      return this.shouldShowSuccess()
        ? false
        : this.forceShowError
    },
    numberOfCharacters () {
      if (this.type === 'placeAutocomplete') {
        this.value = document.getElementById(this.fieldName).value
      }

      if (this.value) return this.value.length

      return 0
    },
    valueChanged (value) {
      //If user deletes the value, set it to null to force validation
      if (value === "") {
        this.value = null
      }
      if (!value) {
        value = this.value
      }
      if (!window.google) {
        value = this.company
      }

      // Remove query params from website
      if (value && this.type === 'placeAutocomplete' && value.website) {
        value.website = this.removeQueryParams(value.website)
      }
      this.$emit('valueUpdated', {
        name: this.fieldName,
        value: this.type === 'multiselect' ? value.map(item => item.id) : value,
        greaterThanMin: this.minCharacter ? this.numberOfCharacters() >= this.minCharacter : true
      })

      // TODO: to be removed...
      if (this.type === 'multiselect') {
        document.getElementById(`${this.fieldName}-hidden`).value = JSON.stringify(value.map(item => item.id))
      } else if (this.type === 'placeAutocomplete' && value && window.google) {
        const locationMappings = {
          locality: 'city',
          country: 'country',
          route: 'region',
          administrative_area_level_1: 'state'
        }

        let companyName = value.name || null
        if (companyName === null && this.enableStoreCompanyWithoutGooglePlaces) {
          companyName = this.company
        }

        document.getElementById('city').value = null
        document.getElementById('country').value = null
        document.getElementById('country_iso_code').value = null
        document.getElementById('region').value = null
        document.getElementById('state').value = null
        document.getElementById('company').value = companyName || null
        document.getElementById('company_website').value = value.website || null
        document.getElementById('phone').value = value.international_phone_number || value.formatted_phone_number || null

        if (value.address_components) {
          value.address_components.forEach((item) => {
            if (locationMappings.hasOwnProperty(item.types[0])) {
              if (item.types[0] === locationMappings.country){
                document.getElementById('country_iso_code').value = item.short_name || null
              }
              document.getElementById(locationMappings[item.types[0]]).value = item.long_name || null
            }
          })
        }

        if (value.name) {
          this.company = value.name
        }
      }
    },
    isLessThan100Characters () {
      if (this.numberOfCharacters() < this.minCharacter && this.numberOfCharacters() > 0 && this.$props.minCharacter > 0) {
        this.$emit('valueLessThan100', {
          name: this.fieldName,
          moreThanHundredCharacters: false
        })

        return true
      }

      this.$emit('valueLessThan100', {
        name: this.fieldName,
        moreThanHundredCharacters: true
      })

      return false
    },
    setValue (value) {
      this.value = value
    }
  }
}
</script>
