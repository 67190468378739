<script>
import { mapActions } from 'vuex'
import IntegrationCard from './Integrations/IntegrationCard.vue'

export default {
  components: { IntegrationCard },
  props: ['productId', 'categoryId'],
  data () {
    return {
      loading: false,
      page: 1,
      lastPage: 0,
      integrations: []
    }
  },
  computed: {
    authenticated () {
      return !!window.Laravel.userId
    },
    hasMore () {
      return this.page < this.lastPage
    }
  },
  created () {
    this.load()
  },
  methods: {
    ...mapActions(['fetchIntegrationOpportunities']),
    loadMore () {
      this.page++
      this.load()
    },
    load () {
      this.loading = true
      this.fetchIntegrationOpportunities({ per_page: 12, product_id: this.productId, category_id: this.categoryId, page: this.page }).then(res => {
        this.integrations = [...this.integrations, ...res.data.data]
        this.lastPage = res.data.last_page
        this.loading = false
      })
    }
  }
}
</script>
