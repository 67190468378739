<template>
  <div v-cloak class="company-map">
    <div class="company-map__left-col">
      <div class="company-map__box">
        <div ref="map" class="map" style="width: 100%; height: 100%;" />
      </div>
    </div>
    <div class="company-map__right-col">
      <span class="company-map__title">{{ htrLanguage.company.markets_served || 'markets_served' }}</span>
      <ul class="company-map__box-list-title">
        <li v-for="market in productMarkets" :key="market.value" :class="{active: markets.includes(market.value)}">
          {{ market.label }}
        </li>
      </ul>
      <span class="company-map__title">{{ htrLanguage.company.verified_markets || 'verified_markets' }}</span>
      <ul class="company-map__box-list-title">
        <li v-for="country in countries.slice(0, showCount)" :key="country" class="active">
          {{ country }}
        </li>
      </ul>
      <div v-if="countries.length > 9">
        <button v-if="showCount < countries.length" class="list-title__more" @click="showCount = countries.length">
          {{ htrLanguage.home.view_more || 'view_more' }} ({{ countries.length }})
        </button>
        <button v-else class="list-title__more" @click="showCount = 9">
          {{ htrLanguage.home.view_less || 'view_less' }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { uniq, map } from 'lodash'

export default {
  props: [
    'accessToken',
    'reviewsIds',
    'markets'
  ],

  data: () => ({
    map: null,
    showCount: 9,
    countAddedPoints: null
  }),

  computed: {
    ...mapGetters([
      'reviewsByIds'
    ]),
    htrLanguage() {
      if (window && window.i18n) {
        const { home, company } = window.i18n
        if (home && company) {
          return { home, company }
        }
      }
      return { home: {}, company: {} }
    },
    allReviews () {
      return this.reviewsByIds(this.reviewsIds)
    },
    coordinates () {
      return this.allReviews
        .filter(review => review.hotel && review.hotel.lat && review.hotel.long)
        .map(review => {
          return {
            type: 'Feature',
            geometry: {
              type: 'Point',
              coordinates: [review.hotel.long, review.hotel.lat]
            },
            light: {
              anchor: 'viewport',
              color: 'white',
              intensity: 0.4
            }
          }
        })
    },
    geojson () {
      return {
        type: 'FeatureCollection',
        features: this.coordinates
      }
    },
    countries () {
      return uniq(map(this.allReviews.filter(review => review.hotel), review => review.hotel.country))
    },
    productMarkets () {
      return window.Laravel.dictionaries.markets.sort(
        (a, b) => this.markets.indexOf(b.value) - this.markets.indexOf(a.value)
      )
    }
  },
  
  watch: {
    reviewsIds () {
      try {
        this.addPoints()
      } catch (e) {}
    }
  },

  mounted () {
    this.initMap()
  },

  methods: {
    initMap () {
      if (!window.mapboxgl) return false

      window.mapboxgl.accessToken = this.accessToken

      this.map = new window.mapboxgl.Map({
        container: this.$refs.map,
        style: 'mapbox://styles/mapbox/light-v9',
        scrollZoom: false,
        center: [12.158297504112625, 5.227918284501293], 
        zoom: 0
      })

      this.map.on('load', this.addPoints)
    },
    addPoints () {
      if (this.map && this.reviewsIds.length && this.reviewsIds.length !== this.countAddedPoints) {
        this.map.addLayer({
          id: 'points',
          type: 'circle',
          source: {
            type: 'geojson',
            data: this.geojson
          },
          paint: {
            'circle-radius': 5,
            'circle-color': '#4F7AD3'
          }
        })
        this.countAddedPoints = this.reviewsIds.length
      }
    }
  }
}
</script>
