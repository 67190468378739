<template>
  <div>
    <div class="categoriesKeywordsTitle">
      Popular keywords for inspiration to help you save time
    </div>
    <ul class="removeDots pl-[40px] mt-[10px]">
      <li v-for="category in categoriesWithViewMore" :key="category.id" class="listItem">
        <div :class="haveAtleastOneWord(category.keywords) ? 'checkedIcon' : 'uncheckedIcon'" />
        <div class="categoriesKeywordsTitle">
          {{ category.name }}
        </div>
        <div v-if="category.keywords && category.keywords.length > 0">
          <span v-for="(keyword, index) in category.keywords" :key="keyword + index" :class="isWordUsed(keyword) ? 'mentionInfo colorGreen' : 'mentionInfo'">
            #{{ keyword }}
          </span>
          <div v-if="category.keywordsCompleted.length > 10 && !category.viewMore" class="viewMore" @click="viewMoreWords(category)">
            {{ viewMore }}
          </div>
          <div v-else-if="category.keywordsCompleted.length > 10" class="viewMore" @click="viewLessWords(category)">
            {{ viewLess }}
          </div>
        </div>
      </li>
    </ul>
    <div class="mentionInfo">
      💡Mention at least one keyword about the products you have experience with to make sure your review counts towards each of the products you selected
    </div>
  </div>
</template>
<style scoped>
.categoriesKeywordsTitle {
  font-weight: 600 !important;
  font-size: 14px !important;
  line-height: 1.3 !important;
}
.colorGreen {
  color: #62b96c !important;
}
.listItem {
  margin-left: -10px !important;
  margin-bottom: 20px !important;
}
.mentionInfo {
  font-style: italic !important;
  font-size: 12px !important;
  color: #818181;
}
.removeDots {
  list-style-type: none !important;
}
.viewMore {
  font-size: 12px !important;
  color: #8d8d8d !important;
  cursor: pointer !important;
}
</style>
<script>
export default {
  props: ['categories', 'reviewText', 'viewMore', 'viewLess'],
  data () {
    return {
      categoriesWithViewMore: []
    }
  },
  watch: {
    categories () {
      this.setCategories()
    }
  },
  mounted () {
    this.setCategories()
  },
  methods: {
    setCategories () {
      this.categoriesWithViewMore = this.categories.map((category) => {
        let foundCategory = this.categoriesWithViewMore.find(cat => cat.id === category.id)
        if (!foundCategory) {
          category.viewMore = false

          if (category.keywords && category.keywords.length > 0) {
            let arrayWords = JSON.parse(category.keywords)
            category.keywordsCompleted = arrayWords
            if (arrayWords.length > 10) {
              arrayWords = arrayWords.slice(0, 10)
            }
            category.keywords = arrayWords
          } else {
            category.keywords = []
            category.keywordsCompleted = []
          }
          return category
        } else {
          return foundCategory
        }
      })
    },
    isWordUsed (keyword) {
      const words = this.reviewText.replace(/[.,?\/#!$%\^&\*;:{}=\-_`~()]/g, '').toLowerCase().split(' ')
      if (!keyword.includes(' ') && words.includes(keyword.toLowerCase())) { // if keyword have an space, compare with the full string
        return true
      } else if (keyword.includes(' ') && this.reviewText.includes(keyword.toLowerCase())) {
        return true
      }
      return false
    },
    viewMoreWords (category) {
      category.viewMore = true
      category.keywords = category.keywordsCompleted
    },
    viewLessWords (category) {
      category.viewMore = false
      category.keywords = category.keywordsCompleted.slice(0, 10)
    },
    haveAtleastOneWord (words) {
      if (!this.reviewText || !words || words.length === 0) {
        return false
      } else {
        for (let i = 0; i < words.length; i += 1) {
          if (this.reviewText.includes(words[i])) {
            return true
          }
        }
      }
    }
  }
}
</script>
