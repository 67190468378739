<template>
  <div>
    <div id="form-area" class="review-form-area">
      <div class="heading">
        <slot name="header">
          <h2>{{ htrLanguage.writeReview.select_a_provider_to_review || 'select_a_provider_to_review' }}</h2>
        </slot>
      </div>
      <div class="filter-pannel">
        <div class="search-row">
          <strong class="label">{{ htrLanguage.layout.search || 'search' }}</strong>
          <div class="search-box">
            <button type="submit">
              <i class="icon-magnifier" />
            </button>
            <input v-model="query.term" type="search" :placeholder="`${htrLanguage.writeReview.company_or_product || 'company_or_product'} (ex. Alice, Nor1, etc…)`"
                   @keyup="fetchCompanies()">
          </div>
        </div>
        <strong class="label">{{ (htrLanguage.company.filter || 'filter').charAt(0).toUpperCase() + (htrLanguage.company.filter || 'filter').slice(1) }}</strong>
        <ul class="filter-list">
          <li>
            <label class="checkbox">
              <input v-model="query.all" value="yes" type="checkbox">
              <span class="txt">{{ htrLanguage.layout.all || 'all' }}</span>
            </label>
          </li>
          <li v-for="(name, id) in categoryFilters" :key="id">
            <label class="checkbox">
              <input v-model="query.categories" :value="id" type="checkbox">
              <span class="txt">{{ name }}</span>
            </label>
          </li>
        </ul>
      </div>
      <div class="company-thumbnails">
        <span class="company-list-link">{{ htrLanguage.writeReview.do_not_see_the_product || 'do_not_see_the_product' }} <a
          data-paperform-id="list-new-product" data-popup-button="1" href="#">{{ htrLanguage.writeReview.click_here_to_list || 'click_here_to_list' }}</a>
        </span>
        <div v-if="searching" class="spinner">
          <div class="rect1" />
          <div class="rect2" />
          <div class="rect3" />
          <div class="rect4" />
          <div class="rect5" />
        </div>
        <div v-if="hasNoResults" class="no-result">
          <strong class="title">{{ (htrLanguage.buyersGuide.guides_and_resports_empty_title || 'guides_and_resports_empty_title').replace(':QUERY', query.term) }}</strong>
          <ul class="suggest-list">
            <li>{{ htrLanguage.buyersGuide.guides_and_resports_empty_list_1 || 'guides_and_resports_empty_list_1'  }}</li>
            <li>{{ htrLanguage.buyersGuide.guides_and_resports_empty_list_2 || 'guides_and_resports_empty_list_2'  }}</li>
          </ul>
          <div class="add-product">
            <h2>{{ htrLanguage.companyCategory.are_we_missing_a_product || 'are_we_missing_a_product'  }}</h2>
            <a href="" data-leadbox-popup="146e5b573f72a2:144734394946dc" class="btn btn-green">+ {{ htrLanguage.companyCategory.add_a_product || 'add_a_product'  }}</a>
          </div>
        </div>
        <ul class="thumb-list">
          <li v-for="company in companies" :key="company.id">
            <label v-if="!searching" @click="select(company)">
              <input type="checkbox">
              <span class="thumb">
                <span class="logo">
                  <img :src="company.logo" :alt="company.name">
                </span>
                <strong class="company-name">{{ company.name }}</strong>
                <span :class="'btn-review btn-review_' + codeLanguage">{{ (htrLanguage.layout.write_a_review || 'write_a_review').charAt(0).toUpperCase() + (htrLanguage.layout.write_a_review || 'write_a_review').slice(1).toLowerCase() }}</span>
              </span>
            </label>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    data () {
      return {
        companies: [],
        sent: 0,
        received: 0,
        query: {
          term: null,
          all: false,
          categories: []
        },
        searching: true,
        categoryFilters: window.filterableCategories
      }
    },
    computed: {
      htrLanguage() {
        if (window && window.i18n) {
          const { writeReview, layout, company, companyCategory, buyersGuide } = window.i18n
          if (writeReview && layout && company && companyCategory && buyersGuide) {
            return { writeReview, layout, company, companyCategory, buyersGuide }
          }
        }
        return { writeReview: {}, layout: {}, company: {}, companyCategory: {}, buyersGuide: {} }
      },
      codeLanguage() {
        if (window.Laravel.languageLocale) {
          return window.Laravel.languageLocale.code
        }
        return 'en'
      },
      computedCategories () {
        return this.query.categories
      },
      computedAll () {
        return this.query.all
      },
      hasNoResults () {
        return this.companies.length === 0 && !this.searching
      }
    },
    watch: {
      computedCategories () {
        this.fetchCompanies()
      },
      computedAll () {
        this.fetchCompanies()
      }
    },
    mounted () {
      this.fetchCompanies()
    },
    methods: {
      fetchCompanies () {
        this.searching = true

        let counter = this.sent + 1
        this.sent++

        $.get('/search-companies', { query: this.query }, data => {
          this.searching = false
          if (data.status === 'success') {
            if (counter > this.received) {
              this.companies = data.companies
              this.received = counter
            }
          }
        })
      },
      select(company) {
        this.$emit('selected', company)
      }
    }
  }
</script>


<style scoped>
  .spinner {
    margin: 100px auto;
    width: 50px;
    height: 40px;
    text-align: center;
    font-size: 10px;
  }

  .spinner > div {
    background-color: #4C5ECF;
    height: 100%;
    width: 6px;
    display: inline-block;

    -webkit-animation: sk-stretchdelay 1.2s infinite ease-in-out;
    animation: sk-stretchdelay 1.2s infinite ease-in-out;
  }

  .spinner .rect2 {
    -webkit-animation-delay: -1.1s;
    animation-delay: -1.1s;
  }

  .spinner .rect3 {
    -webkit-animation-delay: -1.0s;
    animation-delay: -1.0s;
  }

  .spinner .rect4 {
    -webkit-animation-delay: -0.9s;
    animation-delay: -0.9s;
  }

  .spinner .rect5 {
    -webkit-animation-delay: -0.8s;
    animation-delay: -0.8s;
  }

  @-webkit-keyframes sk-stretchdelay {
    0%, 40%, 100% {
      -webkit-transform: scaleY(0.4)
    }
    20% {
      -webkit-transform: scaleY(1.0)
    }
  }

  @keyframes sk-stretchdelay {
    0%, 40%, 100% {
      transform: scaleY(0.4);
      -webkit-transform: scaleY(0.4);
    }
    20% {
      transform: scaleY(1.0);
      -webkit-transform: scaleY(1.0);
    }
  }
</style>
