<template>
  <div v-show="show" id="unique-id" class="modal-wrapper">
    <div :class="customModalClass || 'modal'" v-click-outside="clickOutside">
      <slot />
    </div>
  </div>
</template>

<script>
import vClickOutside from 'v-click-outside'
export default {
  props: {
    opened: Boolean,
    onClose: Function,
    disableClickOutside: Boolean,
    customModalClass: {
      type: String,
      default: ''
    }
  },
  directives: {
    clickOutside: vClickOutside.directive
  },
  data () {
    return {
      show: false
    }
  },
  watch: {
    opened () {
      this.reset()
    }
  },
  mounted () {
    this.reset()
    document.body.appendChild(this.$el)
    window.addEventListener('keydown', this.closeOnEscape)
  },
  methods: {
    clickOutside() {
      if (this.disableClickOutside) {
        return
      }
      if(this.show) {
        this.close()
      }
    },
    reset () {
      this.show = !!this.opened
    },
    closeOnEscape (e) {
      if (this.show && e.keyCode === 27) this.close()
    },
    close () {
      this.show = false
      if (this.onClose) this.onClose()
    }
  }
}
</script>
