<template>
  <div class="integration-container">
    <aside v-show="!progress" class="integration-sidebar">
      <div>
        <h3 class="integration-sidebar__title">
          Categories
        </h3>
        <button class="sub-nav-toggle">
          By category
        </button>
        <ul class="sub-nav-categories-list">
          <li>
            <label class="checkbox">
              <input type="checkbox" value="All" :checked="filter.length === 0" @click.prevent="filter = []">
              <span class="fake-checkbox" />
              <span class="txt">All ({{ integrations.length }})</span>
            </label>
          </li>
          <li v-for="(ids, option) in categoryFilters" :key="option">
            <label class="checkbox">
              <input v-model="filter" type="checkbox" :value="option">
              <span class="fake-checkbox" />
              <span class="txt">{{ option }} ({{ ids.length }})</span>
            </label>
          </li>
        </ul>
      </div>
      <div v-if="productIds.length > 1">
        <h3 class="integration-sidebar__title">
          By product
        </h3>
        <button class="sub-nav-toggle-filter">
          By product
        </button>
        <ul class="sub-nav-product-list">
          <li v-for="product in sortedProduct" :key="product.id">
            <label class="checkbox">
              <input type="checkbox" :checked="productId === product.id" @click.prevent="productId = product.id">
              <span class="fake-checkbox" />
              <span class="txt">{{ product.name }} ({{ product.integrationsCount }})</span>
            </label>
          </li>
        </ul>
      </div>
    </aside>

    <div class="content">
      <div v-if="integrations.length">
        <div class="integration__header">
          <h3 class="integration__header__title">
            Showing {{ integrations.length }} {{ company.name }} integrations & preferred partners
          </h3>
          <a href="https://hapicloud.io" target="_blank" class="wrap-banner-integrate">
            <div class="banner-integrate">
              <span>Need a custom integration?</span>
              <img src="/images/banner__image.svg" class="banner__image">
            </div>
          </a>
        </div>
        <transition name="fade">
          <div class="product-posts">
            <integration-tile v-for="integration in filteredIntegrations" :key="integration.id" :product-id="productId"
                              :integration="integration" />
          </div>
        </transition>
      </div>
      <div v-else-if="progress">
        <span class="lds-spinner">
          <span v-for="i in 12" :key="i" />
        </span>
      </div>
      <div v-else-if="isLoading">
        Loading integrations
      </div>
      <div v-else>
        This product doesn't have integrations
      </div>
    </div>
  </div>
</template>

<script>
import IntegrationTile from '../partials/integrations/IntegrationTile.vue'
import { mapActions, mapGetters } from 'vuex'
import { orderBy } from 'lodash'

export default {
  components: {
    IntegrationTile
  },

  props: {
    company: {
      type: Object,
      required: true
    }
  },

  data: () => ({
    isLoading: true,
    progress: false,
    productId: [],
    filter: []
  }),

  computed: {
    ...mapGetters([
      'integrationsByProductId',
      'productById',
      'categoryById'
    ]),
    integrations () {
      return this.integrationsByProductId(this.productId)
    },
    productIds () {
      return this.products.map(product => product.id)
    },
    categoryFilters () {
      let filters = {}

      this.integrations.forEach(integration => {
        let relation = integration.products.find(p => p.id !== this.productId)

        if (relation) {
          let product = this.productById(relation.id)
          let category = this.categoryById(product.category_id)

          if (category.parent_id) category = this.categoryById(category.parent_id)

          filters[category.name] = filters[category.name] || []
          filters[category.name].push(integration.id)
        }
      })

      return filters
    },
    filteredIntegrations () {
      return orderBy(this.integrations
        .filter(integration => {
          if (!this.filter.length || this.filter.indexOf('All') > -1) return true

          let found = false

          this.filter.forEach(name => {
            found = found || this.categoryFilters[name].indexOf(integration.id) > -1
          })

          return found
        }), 'rank', 'desc')
    },
    products () {
      return this.company.products.map(product => Object.assign(product, {
        integrationsCount: this.countIntegrationsByProduct(product.id)
      }))
    },
    sortedProduct () {
      return orderBy(this.products, 'integrationsCount', 'desc')
    }
  },
  mounted () {
    let promises = []
    this.company.products.forEach((product) => {
      promises.push(this.fetchProductIntegrations({id: product.id}))
    })
    Promise.all(promises, () => {this.isLoading = false})
    this.setFirstProductId()
  },
  methods: {
    ...mapActions([
      'fetchProductIntegrations'
    ]),
    setFirstProductId () {
      if (this.productIds.length) this.productId = this.sortedProduct[0].id
    },
    countIntegrationsByProduct (productId) {
      return this.integrationsByProductId(productId).length
    }
  }
}
</script>
