<template>
  <div v-if="active" v-show="!isLoading" class="wrap-modal-lead">
    <div v-if="active === 'auth-user'" class="modal-create-account__tooltip">
      <img src="/images/icon-login.png">
      <span class="modal-create-account__tooltip__title">Used to logging in with Linkedin?</span>
      <p>Linkedin login has been removed from Hotel Tech Report.  If you are a vendor or hotelier and used to login with Linkedin, please click the ‘Forgot password?’ link  and you’ll be sent a link to create your password.</p>
      <a href="https://updates.hoteltechreport.com/linkedin-login-being-removed-from-hotel-tech-report-35F9C" target="_blank">Learn more about the update</a>
    </div>
    <div v-click-outside="closeOutside" class="modal-lead">
      <div :is="active" v-bind="params" :is-loading.sync="isLoading" @close="close" @open-popup="openPopup"
           @confirm="confirmClose" />
      <div v-if="isLoading" class="box-lds-spinner">
        <span class="lds-spinner">
          <span v-for="i in 12" :key="i" />
        </span>
      </div>
    </div>
    <popup-close v-if="confirmModal" ref="popupClose" :category-id="params.categoryId" @confirm="confirmClose"
                 @cancel="cancelClose" />
  </div>
</template>

<script>
import vClickOutside from 'v-click-outside'

import { EventBus } from '../../event-bus.js'

import ModalLead from './../../../htr/components/Utils/ModalSidebarTypes/ModalLead.vue'
import CreateAccount from './../../../htr/components/Utils/ModalSidebarTypes/CreateAccount.vue'
import AuthUser from './../../../htr/components/Utils/ModalSidebarTypes/AuthUser.vue'
import PopupClose from './../../../htr/components/Utils/PopupClosingConfirmation.vue'

export default {
  components: {
    ModalLead,
    CreateAccount,
    AuthUser,
    PopupClose
  },

  directives: {
    clickOutside: vClickOutside.directive
  },

  data: () => ({
    active: null,
    isLoading: false,
    params: {},
    isShowClosePopup: false,
    isClose: false,
    confirmModal: false
  }),

  computed: {
    isMobileDevice () {
      return (typeof window.orientation !== 'undefined') || (navigator.userAgent.indexOf('IEMobile') !== -1)
    }
  },

  mounted () {
    EventBus.$on('open-modal-sidebar', this.openPopup)
  },

  methods: {
    closeOutside () {
      if (!this.isShowClosePopup && !this.isMobileDevice) this.close()
    },
    close () {
      if (this.active === 'modal-lead') {
        if (!this.isClose) {
          this.isClose = true
          this.confirmModal = true
        } else {
          this.cancelClose()
          this.isClose = false
        }
      } else {
        this.active = null
        document.body.classList.remove('no-scrolling')
      }
    },
    confirmClose () {
      this.active = null
      this.confirmModal = false
      this.isClose = false
      document.body.classList.remove('no-scrolling')
    },
    cancelClose () {
      this.confirmModal = false
    },
    openPopup ({ type, params }) {
      if(window.Laravel.isVendor) {
        EventBus.$emit('add-modal-to-queue', 'modal-is-vendor')
        return;
      }

      this.active = type
      this.params = Object.assign({}, params)

      document.body.classList.add('no-scrolling')
    }
  }
}

</script>
