<template>
  <div>
    <div class="modal-lead__header">
      <h4 class="modal-lead__title">
        Enter your email to send a password reset link
      </h4>
    </div>
    <div class="modal-create-account-body">
      <div class="form-group" :class="{success: form.email && !type('email')}">
        <input v-model="form.email" type="email" :class="{'is-empty': !form.email, 'error': type('email')}">
        <span class="field__title">Work Email</span>
      </div>
      <button class="btn-blue" @click="resetPassword">
        Send password reset link
      </button>
    </div>
  </div>
</template>

<script>
import { map } from 'lodash'

export default {
  data: () => ({
    form: {},
    errors: []
  }),

  methods: {
    openSidebarModal (title) {
      this.$emit('open-sidebar-modal', title)
    },
    resetPassword () {
      this.$emit('error-message', null)

      this.$emit('reset-password', {
        params: this.form,
        callback: error => {
          let message = 'An Error Occurred'
          if (error.response.data.errors) {
            this.errors = error.response.data.errors
            message = map(error.response.data.errors, errors => errors.join(', ')).join('<br>')
          }

          this.$emit('error-message', message)
        }
      })
    },
    type (field) {
      return this.message(field)
    },
    message (field) {
      return this.errors[field] && this.errors[field].join(', ')
    }
  }
}
</script>