<template>
  <div v-if="isVisible">
    <div class="modalDiv">
      <div class="imgDivs icon-hold">
        <img src="/images/icon-check.svg" alt="" width="60">
      </div>
      <h1 class="h1Verified">Your review has been verified</h1>
    </div>
    <div class="modalWrap" @click="close" />
  </div>
</template>
<style scoped>
.h1Verified {
  margin-top: 2em;
  text-align: center;
}
.imgDivs {
  text-align: center;
  margin-top: 1em;
}
.modalDiv {
  background-color: white;
  width: 590px;
  height: 279px;
  position: fixed;
  padding: 40px;
  top: 40%;
  left: calc(50vw - 295px);
  z-index: 2000;
}
.modalWrap {
  background-color: #00000057;
  position: fixed;
  top: 0px;
  left: 0px;
  z-index: 1000;
  height: 100vh;
  width: 100vw;
}
</style>
<script>
export default {
  data () {
    return {
      isVisible: true
    }
  },
  methods: {
    close () {
      this.isVisible = false;
    }
  }
}
</script>
