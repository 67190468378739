<template>
  <div id="top-navbar" :style="{ top: `${headerHeight}px` }" class="bg-white w-full z-[10] h-[6px] fixed">
    <div class="progress-container">
      <div class="progress">
        <span class="start-progress-reviews" />
        <div class="wrap-progress-bar">
          <span :style="`width: ${percentAdvanced}%`" class="progress-bar" />
        </div>
      </div>
    </div>
  </div>
</template>
<style scoped>
.progress-container {
  margin-left: -15px;
  margin-right: -15px;
  margin-bottom: 40px;
}
</style>
<script>
import { mapActions } from 'vuex'

export default {
  props: ['percentAdvanced'],
  data() {
    return {
      ipIsVendor: false,
      headerHeight: 0
    }
  },
  computed: {
    htrLanguage() {
      if (window && window.i18n) {
        const { writeReview, companyCategory, quiz, company } = window.i18n
        if (writeReview && companyCategory && quiz && company) {
          return { writeReview, companyCategory, quiz, company }
        }
      }
      return { writeReview: {}, companyCategory: {}, quiz: {}, company: {} }
    },
    langCode() {
      return window.Laravel.languageLocale ? window.Laravel.languageLocale.code : 'en'
    }
  },
  created() {
    this.checkIpIsVendor().then(res => {
      this.ipIsVendor = res.is_vendor
    })
  },
  mounted() {
    this.headerHeight = document.querySelector('header').offsetHeight;
  },
  methods: {
    ...mapActions(['checkIpIsVendor']),
    getClass: function (code) {
      if (this.langCode === code) {
        return 'fa fa-check translate-check'
      }
      return ''
    }
  }
}
</script>
