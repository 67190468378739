export default {
  data: () => ({
    page: 1,
    countOnFirstPage: 10,
    countOnPage: 20
  }),

  computed: {
    itemsFiltered () {
      return this.items
        .slice(0, this.showCount)
    },
    showCount () {
      return this.countOnFirstPage + ((this.page - 1) * this.countOnPage)
    },
    canShowMore () {
      return this.showCount < this.items.length
    }
  },

  methods: {
    load () {
      this.page++
    }
  }
}
