<template>
  <div>
    <ul class="step-no">
      <li :class="{ active: step == 1, completed: step > 1 }">
        1
      </li>
      <li :class="{ active: step == 2, completed: step > 2 }">
        2
      </li>
      <li :class="{ active: step == 3, completed: step > 3 }">
        3
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  props: ['step']
}
</script>