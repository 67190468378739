<template>
  <article v-if="product" class="product-post">
    <div class="product-post__content">
      <div class="product-post__box-btn">
        <div class="img">
          <a :href="product.url">
            <img :src="company.logo ? company.logo : '/images/company-default-logo.png'" :alt="product.name" @error="imageLoadOnError" width="74" height="75">
          </a>
          <div v-if="!isVerified" :data-label="`${company.name} ${htrLanguage.product_not_integration || 'product_not_integration'}`" class="product-not-integration ptooltip">
            <div class="product-not-integration-icon" />
          </div>
        </div>
        <div class="product-post-btn">
          <button class="btn btn-green" :onclick="`window.htr_showModalPriceDemo('integrations-modal-container', ${ product.id }, ${ company.id }, 'request_demo')`">
            <span aria-hidden="true" class="icon-click" />
            {{ htrLanguage.get_demo || 'get_demo' }}
          </button>
          <a :href="product.url" class="btn btn-gray">{{ htrLanguage.learn_more || 'learn_more' }}</a>
        </div>
      </div>
      <h3><a :href="product.url">{{ product.name }}</a></h3>
      <a :href="category.url" class="product-post__category">{{ translate(category, 'name') }}</a>
      <div class="rating-info">
        <a class="no-decoration" :href="product.url">
          <div class="stars">
            <div v-for="rating in 5" :key="rating" class="star">
              <img v-if="rating <= product.recommend_rank / 20" src="/images/star-full-blue.svg" width="19">
              <img v-if="rating > product.recommend_rank / 20 && rating <= Math.round((Math.round((product.recommend_rank / 20) * 10) /10))"
                   src="/images/star-half-blue.svg" width="19">
              <img v-if="rating > product.recommend_rank / 20 && rating > Math.round((Math.round((product.recommend_rank / 20) * 10) /10))" src="/images/star-empty-blue.svg" width="19">
            </div>
          </div>
          <span class="reviews-number-info">{{ product.combined_reviews_count }} {{ htrLanguage.verified_reviews || 'verified_reviews' }}</span>
        </a>
      </div>
      <p class="product_desc">
        {{ translate(product, 'tagline') }}
      </p>
    </div>
  </article>
</template>

<script>
import { mapGetters } from 'vuex'
import { EventBus } from '../../../event-bus'

export default {
  props: {
    integration: {
      type: Object,
      required: true
    },
    productId: {
      type: Number,
      required: true
    }
  },

  computed: {
    ...mapGetters([
      'productById',
      'companyById',
      'categoryById'
    ]),
    htrLanguage() {
      if (window && window.i18n && window.i18n.company) {
        return window.i18n.company
      }
      return {}
    },
    relation () {
      return this.integration.products.find(p => p.id !== this.productId) || {}
    },
    product () {
      return this.productById(this.relation.id)
    },
    company () {
      return this.companyById(this.product.company_id) || {}
    },
    category () {
      return this.categoryById(this.product.category_id) || {}
    },
    isVerified () {
      return this.integration.status === 'verified'
    }
  },

  methods: {
    openLeadModal () {
      EventBus.$emit('open-modal-sidebar', {
        type: 'modal-lead',
        params: {
          categoryId: this.category.id,
          productId: this.product.id,
          type: 'request_demo'
        }
      })
    },
    translate (entry, field) {
      let translation = entry[field]
      if (entry.translations && entry.translations.length > 0) {
        if (window.Laravel.languageLocale) {
          const searchTranslation = entry.translations.find((findTranslate) => findTranslate.language_id === window.Laravel.languageLocale.id)
          if (searchTranslation) {
            translation = searchTranslation[field]
          }
        }
      }
      return translation
    },
    imageLoadOnError (event) {
      event.target.src = "/images/company-default-logo.png"
    }
  }
}
</script>
