<template>
  <div>
    <form class="review-form" @submit.prevent="submit">
      <steps-control :steps-count="2" :active-step="1" />

      <input ref="anonimity" type="hidden" name="is_anonymous">

      <div class="company-logo displayFlex">
        <img :src="companyLogo" alt="companyName">
      </div>
      <h1 class="text-center">
        Leave a review for {{ companyName }}
      </h1>

      <div class="textAlignCenter">
        <a class="underline" href="https://help.hoteltechreport.com/en/articles/987415-our-guidelines-for-hotelier-reviews" target="_blank">Read Our Review Guidelines</a>
      </div>

      <div v-if="shouldShowErrorMessages" class="error-alert">
        {{ errorMessage }}
      </div>

      <div v-if="products.length">
        <div class="product-checklist"
             :class="{ row: true, validation: true, success: form.selectedProducts.length, error: shouldShowErrorMessages && !form.selectedProducts.length }">
          <div class="text-big fw-bold title-pannel title">
            Which of {{ companyName }}'s products do you have experience with? (select all that apply)
          </div>
          <ul>
            <li v-for="product in products" :key="product.id">
              <label>
                <input v-model="form.selectedProducts" type="checkbox" name="products[]" :value="product.id">
                <span class="fake-checkbox" />
                <span class="txt">
                  <b>{{ product.name }}</b>
                  <b>{{ categoryById(product.category_id).name }}</b>
                </span>
              </label>
            </li>
          </ul>
        </div>
      </div>
      <div>
        <div class="content-area paddingTop30px">
          <user-field-input ref="titleOfYourReview" :force-show-error="shouldShowErrorMessages"
                            placeholder="Summarize your experience or highlight something unique about this vendor"
                            required="true" size="small" :disabled-input="!isTitleReviewEdit" max-character="75"
                            field-name="text" :val="form.text" @valueUpdated="updateValue">
            <span slot="label">
              <strong>Title of your review</strong>
            </span>
            <button v-if="!isTitleReviewEdit" slot="btn" class="btn-disabled" @click.prevent="isTitleReviewEdit = true">
              Edit
            </button>
          </user-field-input>

          <user-field-input :force-show-error="shouldShowErrorMessages"
                            :placeholder="`In 2-3 sentences or using bullet points please describe the benefits of working with this vendor. \n -What business problems are you solving with the product? \n -What benefits have you realized?`"
                            min-character="75" required="true" size="large" field-name="pros" :val="form.pros"
                            @valueLessThan100="updateMinimumLengthValidation" @valueUpdated="titleUpdate">
            <span slot="label">
              What <strong>do you like most</strong>?
            </span>
          </user-field-input>

          <user-field-input :force-show-error="shouldShowErrorMessages"
                            placeholder="In 2-3 sentences or using bullet points please describe aspects or features of the product or service that you think could be improved"
                            min-character="75" required="true" size="large" field-name="cons" :val="form.cons"
                            @valueLessThan100="updateMinimumLengthValidation" @valueUpdated="updateValue">
            <span slot="label">
              What do you think <strong>could be improved</strong>?
            </span>

            <div slot="tooltip" class="alert-popup">
              <a href="#" tabindex="-1" class="close" @click.prevent="hideTooltip"><i class="icon-cross" /></a>
              <h3>Your review can be anonymous</h3>
              <p>
                Nobody is perfect. Critical feedback helps us improve our products and services. Reviews can be
                published anonymously so please be honest.
              </p>
              <div class="avatar-pannel">
                <img src="/images/icon-avatar.svg" width="34" alt="">
                <span class="caption">Your review can be anonymous</span>
              </div>
            </div>
          </user-field-input>
        </div>

        <div>
          <div class="btn-hold text-center">
            <input type="submit" value="Submit your Review" class="btn btn-green btn-large"
                   @click="turnOffWindowWarningAndSubmit">
          </div>
        </div>
      </div>
    </form>
  </div>
</template>
<style scoped>
.underline {
  text-decoration: underline;
}
.paddingTop30px {
  padding-top: 30px;
}
</style>
<script>
import StepsControl from './StepsControl.vue'
import StarRating from '../StarRating.vue'
import UserFieldInput from '../UserFieldInput.vue'
import { mapActions, mapGetters } from 'vuex'

export default {

  components: {
    StepsControl,
    StarRating,
    UserFieldInput
  },

  filters: {
    productTitle (value) {
      return value.substring(value.indexOf('(') + 1, value.indexOf(')'))
    }
  },

  props: [
    'companyName',
    'companyId',
    'companyLogo',
    'value'
  ],

  data () {
    return {
      shouldShowErrorMessages: false,
      requiredFields: ['text', 'pros', 'cons'],
      moreThanHundredCharacters: {
        pros: true,
        cons: true
      },
      errorMessage: null,
      errorMessageEmail: null,
      initialized: false,
      isTitleReviewEdit: false,
      form: {}
    }
  },

  computed: {
    ...mapGetters([
      'productsByCompanyId',
      'categoryById'
    ]),
    products () {
      return this.productsByCompanyId(+this.companyId)
    }
  },

  watch: {
    value: {
      handler () {
        this.reset()
      },
      deep: true
    },
    form () {
      this.$emit('input', this.form)
    }
  },

  created () {
    this.reset()

    if (!this.form.advice) this.form.advice = this.form.pros.substring(0, 75)

    this.fetchProductsByCompanyId(+this.companyId).then(res => {
      this.initialized = true
    })
  },

  methods: {
    ...mapActions(['fetchProductsByCompanyId']),
    reset () {
      if (this.value !== this.form) this.form = this.value
    },
    titleUpdate (data) {
      if (data.value.length > 0) this.form.advice = data.value.substring(0, 75)
      this.updateValue(data)
    },
    updateValue (data) {
      this.form[data.name] = data.value
      if (this.hasFilledAllRequiredFields()) {
        this.shouldShowErrorMessages = false
      }
    },
    updateMinimumLengthValidation (data) {
      this.moreThanHundredCharacters[data.name] = data.moreThanHundredCharacters
    },
    hasFilledAllRequiredFields () {
      for (let index = 0; index < this.requiredFields.length; index++) {
        if (this.form[this.requiredFields[index]] === null || this.form[this.requiredFields[index]] === '') {
          this.errorMessage = 'Please fill out all the required fields.'
          return false
        }
      }

      for (let key in this.moreThanHundredCharacters) {
        if (this.moreThanHundredCharacters[key] === false) {
          this.errorMessage = 'Please make sure you have filled out the minimum length of each field.'
          return false
        }
      }

      if (!this.form.selectedProducts.length) {
        this.errorMessage = 'Please select at least one product.'
        return false
      }

      return true
    },
    hideTooltip (event) {
      $(event.target).parents('.alert-popup').fadeOut(200)
    },
    turnOffWindowWarningAndSubmit (event) {
      this.shouldShowErrorMessages = false
      if (!this.hasFilledAllRequiredFields()) {
        this.shouldShowErrorMessages = true
        this.scrollTo('.product')
        event.preventDefault()
      }
    },
    scrollTo (element) {
      let tag = $(element)
      $('html,body').animate({ scrollTop: tag.offset().top }, 'slow')
    },
    submit () {
      this.scrollTo('.product')
      this.$emit('update')
    }
  }
}
</script>
