<script>
import DownloadWizard from './BuyersGuides/DownloadWizard.vue'

export default {
  components: {
    DownloadWizard
  }

}
</script>
