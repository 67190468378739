<script>
import { EventBus } from '../../event-bus'
import axios from 'axios'

export default {
  props: [
    'productIds',
    'leadId'
  ],

  data: () => ({
    projectsProductIds: []
  }),

  mounted () {
    this.projectsProductIds = [...this.productIds]
  },

  methods: {
    requestDemo (categoryId, productId) {
      axios.post('/api/leadsv2/get-information-create-lead-product-from-lead', { product_id: productId, lead_id: this.leadId }).then((data) => {
        this.projectsProductIds.push(productId)
        /*EventBus.$emit('open-modal-sidebar', {
          type: 'modal-lead',
          params: {
            categoryId: categoryId,
            productId: productId,
            type: 'request_demo',
            isWithoutRedirect: true
          }
        })*/
      })
    },
    requestedProduct (productId) {
      return this.projectsProductIds.includes(productId)
    }
  }
}
</script>