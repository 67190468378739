<template>
  <div class="aside__download aside__download-last-step">
    <a class="btn-back" href="#" @click.prevent="$emit('prevStep')">{{ htrLanguage.quiz.back || 'back' }}</a>
    <p class="notification">
      <i class="icon-envelope2" /> {{ htrLanguage.buyersGuide.success_buyers_guide_sent || 'success_buyers_guide_sent' }} {{ value.email }}
    </p>
    <p class="aside__download__title">
      {{ htrLanguage.buyersGuide.want_to_save_the_time || 'want_to_save_the_time' }}
    </p>
    <a href="/quiz" class="btn btn-green">{{ (htrLanguage.home.take_the_quiz || 'take_the_quiz').charAt(0).toUpperCase() + (htrLanguage.home.take_the_quiz || 'take_the_quiz').toLowerCase().slice(1) }}</a>

    <div v-if="sponsorCompany" class="box-lead">
      <div class="box-img">
        <img :src="sponsorCompany.logo">
      </div>
      <div>
        <span class="box-lead__title">{{ htrLanguage.buyersGuide.special_thanks_1 || 'special_thanks_1' }} {{ sponsorCompany.name }}!</span>
        <p class="box-lead__description">
          {{ sponsorCompany.name }} {{ htrLanguage.buyersGuide.lead_description_1 || 'lead_description_1' }} {{ categoryName }} {{ htrLanguage.buyersGuide.lead_description_2 || 'lead_description_2' }}
        </p>
        <button @click="createLead('ask_a_question')">
          {{ htrLanguage.buyersGuide.ask_them_a_question || 'ask_them_a_question' }} <i class="icon-sarrow-right" />
        </button>
        <button @click="createLead('request_demo')">
          {{ htrLanguage.buyersGuide.connect_for_a_demo || 'connect_for_a_demo' }} <i class="icon-sarrow-right" />
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import { EventBus } from '../../../../event-bus'

export default {
  props: ['value', 'productId', 'categoryId', 'buyersGuideId', 'categoryName'],

  computed: {
    ...mapGetters([
      'productById',
      'companyById'
    ]),
    htrLanguage() {
      if (window && window.i18n) {
        const { buyersGuide, quiz, home } = window.i18n
        if (buyersGuide && quiz && home) {
          return { buyersGuide, quiz, home }
        }
      }
      return { buyersGuide: {}, quiz: {}, home: {} }
    },
    sponsorProduct () {
      return this.productById(parseInt(this.productId))
    },
    sponsorCompany () {
      return this.sponsorProduct ? this.companyById(this.sponsorProduct.company_id) : null
    }
  },

  mounted () {
    if (this.productId && !this.sponsorProduct) {
      this.isLoading = true
      this.fetchProductById(this.productId).finally(() => (this.isLoading = false))
    }
    ga('send', 'event', 'lead request', 'submit', 'guide download')
  },

  methods: {
    ...mapActions([
      'fetchProductById'
    ]),

    createLead (type) {
      if (this.projectId && type !== 'ask_a_question') {
        this.createLeadRedirectPost(type)
      } else {
        EventBus.$emit('open-modal-sidebar', {
          type: 'modal-lead',
          params: {
            categoryId: parseInt(this.categoryId),
            productId: parseInt(this.productId),
            type: type
          }
        })
      }

      this.$emit('close')
    },
    createLeadRedirectPost (source) {
      let form = document.createElement('form')

      form.method = 'post'
      form.action = `/projects/${this.projectId}/rooms`

      const data = { source: source, product_id: this.productId }

      for (let name in data) {
        let input = document.createElement('input')
        input.type = 'hidden'
        input.name = name
        input.value = data[name]
        form.appendChild(input)
      }

      document.body.appendChild(form)

      form.submit()
    }
  }
}
</script>
