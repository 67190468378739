<template>
  <div class="review-box">
    <div class="review-box__header">
      <div class="review-box__header__row">
        <div class="review-box__header__col">
          <div class="review-box__header__info">
            <p>{{ review.date | dateFormatUTC('MMMM DD, YYYY') }}</p>
          </div>
          <h3>{{ product.name }} {{ htrLanguage.company.review || 'review' }}: {{ translate(review, 'text', null, true) | limit(110) }}</h3>
          <div v-if="userInfo.title || hotelTypeName || userInfo.location" class="review-box__header__info">
            <p>
              {{ userInfo.title }}
              <span v-if="hotelTypeName">{{ htrLanguage.home.from || 'from' }} {{ hotelTypeName }}</span>
              <span v-if="userInfo.location">{{ htrLanguage.company.in || 'in' }} {{ userInfo.location }}</span>
              <i class="icon-check4" />
            </p>
          </div>
        </div>
        <div class="recommend-box">
          <span class="recommend-box__title">{{ htrLanguage.company.recommend_camel_case || 'recommend_camel_case' }}</span>
          <i :class="['icon-circle-like-white', {'dislike': review.nps <= 6 }]" />
          <b class="recommend-box__mark">{{ review.nps }}/10</b>
        </div>
      </div>
    </div>
    <div class="review-stars">
      <div class="profile-box-mark">
        <div class="profile-box-mark-stars">
          <div v-for="rating in 5" :key="rating" class="star">
            <img v-if="rating <= Math.ceil(overall) && rating > overall" src="/images/star-half-blue.svg">
            <img v-if="rating <= Math.ceil(overall) && rating <= overall" src="/images/star-full-blue.svg">
            <img v-if="rating> Math.ceil(overall)" src="/images/star-empty-blue.svg">
          </div>
        </div>
      </div>
    </div>
    <div class="review-box_translate" v-if="canSeeOriginalVersion()">
      <button class="button-white-google_translate" @click="alreadyLanguageOriginal = true">{{ htrLanguage.company.view_original || 'view_original' }}</button>
      <div class="text-google_translate">
        <span>Google</span>&nbsp;<span>Translate</span>
      </div>
    </div>
    <div class="review-box__content">
      <div class="review-box__left-col">
        <div class="review-box__content-like">
          <h3>{{ htrLanguage.company.what_do_you_like_most || 'what_do_you_like_most' }}</h3>
          <p ref="safeProps" :class="{'review-content-short': !isShortText}" v-html="translate(review, 'pros', 'safe_pros', true)" />

          <a v-if="isChangePros && !isShortText" href="#" @click.prevent="toggleShortText('pros')">{{ htrLanguage.company.read_more || 'read_more' }}</a>
          <a v-if="isShortText" href="#" @click.prevent="toggleShortText('pros')">{{ htrLanguage.company.less || 'less' }}</a>
        </div>
        <h3>{{ htrLanguage.company.what_do_you_dislike || 'what_do_you_dislike' }}</h3>
        <p ref="safeCons" :class="{'review-content-short': !isShortTextCons}" v-html="translate(review, 'cons', 'safe_cons', true)" />
        <a v-if="isChangeCons && !isShortTextCons" href="#" @click.prevent="toggleShortText('cons')">{{ htrLanguage.company.read_more || 'read_more' }}</a>
        <a v-if="isShortTextCons" href="#" @click.prevent="toggleShortText('cons')">{{ htrLanguage.company.less || 'less' }}</a>
      </div>
      <div class="review-box__right-col">
        <div class="review-box__stats-list">
          <div class="stats-item">
            <span class="stats-item__title">{{ htrLanguage.company.customer_support || 'customer_support' }}</span>
            <div v-if="review.support > 0" class="stats-item__mark-line">
              <div class="stats-item__mark-line-box">
                <div :style="{ width: (review.support*100)/5 + '%'}" />
              </div>
              <span class="stats-item__mark">{{ review.support }}</span>
            </div>
            <div v-else class="stats-item__mark-line stats-item__mark-line__na">
              <div class="stats-item__mark-line-box" />
              <span class="stats-item__mark">n/a</span>
            </div>
          </div>
          <div class="stats-item">
            <span class="stats-item__title">{{ htrLanguage.company.ease_of_use || 'ease_of_use' }}</span>
            <div v-if="review.ease_of_use > 0" class="stats-item__mark-line">
              <div class="stats-item__mark-line-box">
                <div :style="{ width: (review.ease_of_use*100)/5 + '%'}" />
              </div>
              <span class="stats-item__mark">{{ review.ease_of_use }}</span>
            </div>
            <div v-else class="stats-item__mark-line stats-item__mark-line__na">
              <div class="stats-item__mark-line-box" />
              <span class="stats-item__mark">n/a</span>
            </div>
          </div>
          <div class="stats-item">
            <span class="stats-item__title">{{ htrLanguage.company.value_for_money || 'value_for_money' }}</span>
            <div v-if="review.roi > 0" class="stats-item__mark-line">
              <div class="stats-item__mark-line-box">
                <div :style="{ width: (review.roi*100)/5 + '%'}" />
              </div>
              <span class="stats-item__mark">{{ review.roi }}</span>
            </div>
            <div v-else class="stats-item__mark-line stats-item__mark-line__na">
              <div class="stats-item__mark-line-box" />
              <span class="stats-item__mark">n/a</span>
            </div>
          </div>
          <div class="stats-item">
            <span class="stats-item__title">{{ htrLanguage.company.implementation || 'implementation' }}</span>
            <div v-if="review.onboarding > 0" class="stats-item__mark-line">
              <div class="stats-item__mark-line-box">
                <div :style="{ width: (review.onboarding*100)/5 + '%'}" />
              </div>
              <span class="stats-item__mark">{{ review.onboarding }}</span>
            </div>
            <div v-else class="stats-item__mark-line stats-item__mark-line__na">
              <div class="stats-item__mark-line-box" />
              <span class="stats-item__mark">n/a</span>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div v-if="canRespond" v-show="responseEditing">
      <div class="respond-review-row">
        <strong class="title">{{ htrLanguage.company.respond_to_this_reviews || 'respond_to_this_reviews' }}</strong>
        <textarea id="" v-model="response" name="" cols="30" rows="10" />
        <div class="btns-holder">
          <a href="#" @click.prevent="responseEditing = false">Cancel</a>
          <a href="#" class="btn btn-purple" @click.prevent="saveReviewResponse">
            <span v-if="!reviewResponse">{{ htrLanguage.company.post_response || 'post_response' }}</span>
            <span v-else>{{ htrLanguage.company.update_response || 'update_response' }}</span>
          </a>
        </div>
      </div>
    </div>

    <div v-if="canRespond">
      <div v-if="hasRule('review_manager_respond_reviews')" class="box-btn">
        <a v-if="!responseEditing" href="#" class="review-question" @click.prevent="responseEditing = !responseEditing">
          <i><img src="/images/icon-respond-review.svg"> </i><span v-if="!reviewResponse">{{ htrLanguage.company.respond_to_review || 'respond_to_review' }}</span><span v-else>{{ htrLanguage.company.edit_response || 'edit_response' }}</span>
        </a>
      </div>
      <div v-else class="box-btn-review-disabled">
        <div class="htr-tooltip sponsor">
          <div class="htooltip">
            <div class="review-question disabled">
              <i><img src="/images/icon-respond-review.svg"></i> {{ htrLanguage.company.respond_to_review || 'respond_to_review' }}
            </div>
            <div class="tooltiptext">
              <span>{{ htrLanguage.company.premium_feature_upper_case || 'premium_feature_upper_case' }}
                <br><br><a href='https://partners.hoteltechreport.com/premium/' class='link-premium' target='_blank'>{{ htrLanguage.company.learn_more || 'learn_more' }} ></a>
              </span>
            </div>
          </div>
        </div>
        <span><img src="/images/icon-lock-premium.svg"> {{ htrLanguage.company.premium_feature_camel_case || 'premium_feature_camel_case' }}</span>
      </div>
    </div>
    <div v-else class="box-btn">
      <slot />
    </div>

    <div v-if="reviewResponse && hasRule('review_manager_respond_reviews')" class="review-response-block expanded">
      <div class="info-row">
        <img src="/images/arrow-review.svg" width="12" alt="">
        <span>{{ htrLanguage.company.on_camel_case || 'on_camel_case' }} {{ reviewResponse.created_at | dateFormat('MMMM DD, YYYY') }} {{ respondentName }} {{ htrLanguage.home.from || 'from' }} {{ company.name }} {{ htrLanguage.company.replied || 'replied' }}:</span>
      </div>
      <div class="response-content">
        <div class="text-content">
          <read-more :text="translate(reviewResponse, 'text', null, true)" type="review" :pre-space="true" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import Premium from '../Mixins/Premium.vue'

export default {
  mixins: [
    Premium
  ],

  props: [
    'review',
    'product'
  ],

  data () {
    return {
      response: null,
      responseEditing: false,
      isShortText: false,
      isShortTextCons: false,
      showLink: false,
      isChangeCons: false,
      isChangePros: false,
      alreadyLanguageOriginal: false
    }
  },

  computed: {
    ...mapGetters(['companyById', 'userById', 'reviewResponseByReviewId']),
    htrLanguage() {
      if (window && window.i18n) {
        const { home, company } = window.i18n
        if (home && company) {
          return { home, company }
        }
      }
      return { home: {}, company: {} }
    },
    idLanguageLocale () {
      if (window.Laravel.languageLocale) {
        return window.Laravel.languageLocale.id
      }
      return undefined
    },
    canRespond () {
      return window.Laravel.canMangeProduct
    },
    reviewResponse () {
      return this.reviewResponseByReviewId(this.review.id)
    },
    company () {
      return this.companyById(this.product.company_id)
    },
    user () {
      return this.userById(this.review.user_id)
    },
    userInfo () {
      if (this.review.user_info) {
        return (this.review.hotel) ? Object.assign({}, this.review.user_info, {
          location: (this.review.hotel.city)
            ? `${this.review.hotel.city} (${this.review.hotel.country})` : this.review.hotel.country
        }) : this.review.user_info
      }

      return this.user
    },
    hotelTypeName () {
      if (this.review.hotel && this.review.hotel.hotel_type) {
        let type = this.dictionaries.hotel_types.find(item => item.value === this.review.hotel.hotel_type)
        return (type) ? type.label : null
      }

      return null
    },
    respondentName () {
      let author = this.userById(this.reviewResponse.author_id)
      let name = author.first_name
      if (author.last_name) {
        name += ` ${author.last_name[0]}.`
      }
      return name
    },
    overall () {
      return this.review.nps / 2
    },
    dictionaries () {
      return window.Laravel.dictionaries
    }
  },

  mounted () {
    this.$nextTick(() => {
      this.isChangePros = this.$refs.safeProps.clientHeight > 45
      this.isChangeCons = this.$refs.safeCons.clientHeight > 45
    })
  },

  created () {
    if (this.reviewResponse) this.response = this.translate(this.reviewResponse, 'text', null, true)
  },

  methods: {
    ...mapActions(['createReviewResponseByReviewId']),
    canSeeOriginalVersion () {
      if (this.alreadyLanguageOriginal) {
        return false
      }

      if (this.review.base_language_id && this.review.base_language_id !== this.idLanguageLocale) {
        return true
      }

      if (!this.idLanguageLocale) {
        return false
      }

      if (!this.review.base_language_id && this.review.translations.find(translation => translation.language_id === this.idLanguageLocale)) {
        return true
      }

      return false
    },
    translate (entry, field, alternativeField, withLanguageOriginal) {
      let translation = entry[alternativeField || field]
      if (entry.translations && entry.translations.length > 0) {
        const searchTranslation = entry.translations.find((findTranslate) => findTranslate.language_id === (this.alreadyLanguageOriginal && withLanguageOriginal ? entry.base_language_id : this.idLanguageLocale))
        if (searchTranslation) {
          translation = searchTranslation[field]
        }
      }
      return translation
    },
    saveReviewResponse () {
      this.createReviewResponseByReviewId({ id: this.review.id, text: this.response })
        .then(() => (this.responseEditing = false))
        .catch(this.handleError)
    },
    toggleShortText (type) {
      if (type === 'pros') {
        this.isShortText = !this.isShortText
      }

      if (type === 'cons') {
        this.isShortTextCons = !this.isShortTextCons
      }
    }
  }
}
</script>
