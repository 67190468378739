<template>
  <div>
    <div id="form-area" class="review-form-area">
      <div class="heading">
        <h2>Select a product to compare</h2>
      </div>
      <div class="filter-pannel">
        <div class="search-row">
          <strong class="label">Search</strong>
          <div class="search-box">
            <button type="submit">
              <i class="icon-magnifier" />
            </button>
            <input v-model="query.term" type="search" placeholder="Company or product (ex. Alice, Nor1, etc…)" @keyup="search">
          </div>
        </div>
        <strong class="label">Filter</strong>
        <ul class="filter-list">
          <li>
            <label class="checkbox">
              <input v-model="query.all" value="yes" type="checkbox">
              <span class="txt">All</span>
            </label>
          </li>
          <li v-for="(name, id) in categoryFilters" :key="name">
            <label class="checkbox">
              <input v-model="query.categories" :value="id" type="checkbox">
              <span class="txt">{{ name }}</span>
            </label>
          </li>
        </ul>
      </div>
      <div class="company-thumbnails">
        <span class="company-list-link">Don't see the product you want to review? <a data-paperform-id="list-new-product" data-popup-button="1" href="#">Click here to list a company</a>
        </span>
        <div v-if="searching" class="spinner">
          <div class="rect1" />
          <div class="rect2" />
          <div class="rect3" />
          <div class="rect4" />
          <div class="rect5" />
        </div>
        <div v-if="hasNoResults" class="no-result">
          <strong class="title">Your search for {{ query.term }} did not return any results. here are some
            suggestions:</strong>
          <ul class="suggest-list">
            <li>Make sure all words are spelled correctly</li>
            <li>Try different keywords</li>
          </ul>
          <div class="add-product">
            <h2>Are we missing a product?</h2>
            <a href="" data-leadbox-popup="146e5b573f72a2:144734394946dc" class="btn btn-green">+ Add a Product</a>
          </div>
        </div>
        <ul v-if="!searching" class="thumb-list">
          <li v-for="company in companies" :key="company.id">
            <label @click="compare(company.product_slug)">
              <input type="checkbox">
              <span class="thumb">
                <span class="logo">
                  <img :src="company.logo" :alt="company.post_title">
                </span>
                <strong class="company-name">{{ company.post_title }}</strong>
                <span class="btn-review">Compare</span>
              </span>
            </label>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data () {
    return {
      companies: [],
      sent: 0,
      received: 0,
      query: {
        term: null,
        all: false,
        categories: []
      },
      searching: true,
      categoryFilters: window.filterableCategories
    }
  },
  computed: {
    computedCategories () {
      return this.query.categories
    },
    computedAll () {
      return this.query.all
    },
    hasNoResults () {
      return this.companies.length === 0 && !this.searching
    }
  },
  watch: {
    computedCategories () {
      this.search()
    },
    computedAll () {
      this.search()
    }
  },
  mounted () {
    this.search()
  },
  methods: {
    search () {
      this.searching = true

      let counter = this.sent + 1
      this.sent++

      $.get('/search', { query: this.query }, data => {
        this.searching = false
        if (data.status === 'success') {
          if (counter > this.received) {
            this.companies = data.companies
            this.received = counter
          }
        }
      })
    },
    compare (slug) {
      window.location = '/compare/' + slug
    }
  }
}
</script>
<style scoped>
    .spinner {
        margin: 100px auto;
        width: 50px;
        height: 40px;
        text-align: center;
        font-size: 10px;
    }

    .spinner > div {
        background-color: #4C5ECF;
        height: 100%;
        width: 6px;
        display: inline-block;
        margin: 0 1px;

        -webkit-animation: sk-stretchdelay 1.2s infinite ease-in-out;
        animation: sk-stretchdelay 1.2s infinite ease-in-out;
    }

    .spinner .rect2 {
        -webkit-animation-delay: -1.1s;
        animation-delay: -1.1s;
    }

    .spinner .rect3 {
        -webkit-animation-delay: -1.0s;
        animation-delay: -1.0s;
    }

    .spinner .rect4 {
        -webkit-animation-delay: -0.9s;
        animation-delay: -0.9s;
    }

    .spinner .rect5 {
        -webkit-animation-delay: -0.8s;
        animation-delay: -0.8s;
    }

    @-webkit-keyframes sk-stretchdelay {
        0%, 40%, 100% { -webkit-transform: scaleY(0.4) }
        20% { -webkit-transform: scaleY(1.0) }
    }

    @keyframes sk-stretchdelay {
        0%, 40%, 100% {
            transform: scaleY(0.4);
            -webkit-transform: scaleY(0.4);
        }  20% {
               transform: scaleY(1.0);
               -webkit-transform: scaleY(1.0);
           }
    }
</style>
