<template>
  <div class="backgroundWhite borderSolidGray">
    <div class="height40px">
      <div class="tableTitle">
        {{`${year} ${categoryName} Finalists`}}
      </div>
    </div>
    <table>
      <tr v-if="subcategory.finalists.length > 0" v-for="subcategory in subcategories" :key="subcategory.id">
        <td class="width60percent fontSize14px">
          {{ subcategory.subcategory_name }}
        </td>
        <td>
          <span v-for="finalist in orderFinalists(subcategory.finalists)" :key="finalist.id" class="finalistListProduct">
            <a class="hyperlinkColor" :href="finalist.url" target="_blank">
              {{ finalist.name }}
            </a>
          </span>
        </td>
      </tr>
    </table>
  </div>
</template>
<style scoped>
  .finalistListProduct {
    font-size: 14px;
    text-decoration: underline;
    margin: 2px;
  }
  
  table {
    border-collapse: collapse;
    border-spacing: 0;
    width: 100%;
    border-top: transparent;
  }

  th, td {
    text-align: left;
    padding: 0.8em;
  }

  tr:nth-child(odd) {
    background-color: #f2f2f2;
  }

  .tableTitle {
    font-weight: bold;
    font-size: 14px;
    padding: 1em;
  }
</style>

<script>
import { orderBy } from 'lodash'

export default {
  props: [
    'subcategories',
    'year',
    'categoryName'
  ],
  methods: {
    orderFinalists (finalists) {
      return orderBy(finalists, 'name')
    }
  }
}
</script>
