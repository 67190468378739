<template>
  <div>
  <div v-if="type === 'topRatedProducts'">
      <span v-html="formattedString">{{ text }}</span>
      <br>
      <a :href="`#${categoryIdDescription}`">
        <button class="btn-more-less fontSize12px" @click="toggle">
          <span>{{ htrLanguage.read_more || 'read_more' }}</span>
        </button>
      </a>
    </div>
    <div v-else-if="type === 'quote'">
      <q :class="preSpace ? 'preserveWhiteSpaces' : ''">{{ formattedString }}</q>
      <button class="btn-more-less" @click="toggle">
        <span v-if="!isReadMore && !checkStr">{{ htrLanguage.read_more || 'read_more' }}</span>
        <span v-if="isReadMore">{{ htrLanguage.less || 'less' }}</span>
      </button>
    </div>
    <div v-else-if="type === 'review'">
      <pre :class="applyCssClasses()">
        {{ parsedText }}
      </pre>
      <button class="btn-more-less" @click="toggle">
        <span v-if="!isReadMore && !checkStr">{{ htrLanguage.read_more || 'read_more' }}</span>
        <span v-if="isReadMore">{{ htrLanguage.less || 'less' }}</span>
      </button>
    </div>
    <div v-else>
      <div v-if="!readMoreKeepGoing">
        <p :class="preSpace ? 'preserveWhiteSpaces' : ''">
          {{ formattedString }}
        </p>
        <button class="btn-more-less" @click="toggle">
          <span v-if="!isReadMore && !checkStr">{{ htrLanguage.read_more || 'read_more' }}</span>
          <span v-if="isReadMore">{{ htrLanguage.less || 'less' }}</span>
        </button>
      </div>
      <div v-else>
        <p :class="{preserveWhiteSpaces: preSpace}">
          {{ formattedString }}
          <button class="btn-more-less" @click="toggle">
            <span v-if="!isReadMore && !checkStr">{{ htrLanguage.read_more || 'read_more' }}</span>
            <span v-if="isReadMore">{{ htrLanguage.less || 'less' }}</span>
          </button>
        </p>
      </div>
    </div>
    <slot></slot>
  </div>
</template>
<style scoped>
.fontSize12px {
  font-size: 12px;
}
.preserveWhiteSpaces {
  white-space: pre-line;
  margin-top: 0px !important
}
.displayFlex {
  display: flex;
}
</style>
<script>
import htmlEntityDecode from '../../../mixins/htmlEntityDecode'

export default {
  mixins: [
    htmlEntityDecode
  ],
  props: {
    text: {
      required: true
    },
    maxChars: {
      type: Number,
      default: 150
    },
    type: {
      default: ''
    },
    preSpace: {
      default: false
    },
    readMoreKeepGoing: {
      default: false
    },
    categoryIdDescription: { //This is only for toprated products component to make read more button scroll to a category description
      default: ''
    }
  },

  data: () => ({
    isReadMore: false,
    isShotStr: false
  }),

  computed: {
    htrLanguage() {
      if (window && window.i18n && window.i18n.company) {
        return window.i18n.company
      }
      return {}
    },
    formattedString () {
      if (!this.isReadMore && this.parsedText.length > this.maxChars) {
        return this.parsedText.substring(0, this.maxChars) + '...'
      }
      return this.parsedText
    },
    checkStr () {
      return this.isShotStr
    },
    parsedText () {
      return this.htmlEntityDecode(this.text)
    }
  },
  mounted () {
    if (this.parsedText.length < this.maxChars) {
      this.isShotStr = true
    }
  },
  methods: {
    toggle () {
      if ((this.htrLanguage.less || 'less') !== null || (this.htrLanguage.less || 'less') !== '') this.isReadMore = !this.isReadMore
    },
    applyCssClasses () {
      let classes = 'review-answer'
      if (this.preSpace) {
        classes += ' preserveWhiteSpaces'
      }
      if (this.isReadMore) {
        classes += ' review-all'
      }
      return classes
    }
  }
}

</script>
