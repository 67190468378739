<template>
  <form class="integration-search-panel">
    <strong class="title"><i class="icon-magnifier" /><span>Search for an integration</span></strong>
    <div class="form">
      <label>I am looking&nbsp;for </label>
      <multiselect v-model="category" :options="categoryOptions" label="name" track-by="name"
                  placeholder="ex. Revenue Management System" group-values="cats" group-label="name"
                  :group-select="false" searchable />
      <label>that integrates&nbsp;with</label>
      <div class="input-search">
        <products-autocomplete v-model="product" placeholder="ex. Protel PMS" prefetch="1" />
      </div>
      <input type="submit" value="search" class="marginLeft10px btn btn-purple" @click.prevent="search">
    </div>
  </form>
</template>
<style scoped>
.multiselect__content-wrapper {
  background-color: white !important;
}

.marginLeft10px {
  margin-left: 10px;
}
</style>
<script>
import { mapGetters, mapActions } from 'vuex'
import { each } from 'lodash'
import Multiselect from 'vue-multiselect'

export default {
  components: { Multiselect },

  props: ['categoryId', 'productId'],

  data () {
    return {
      category: null,
      product: null
    }
  },

  computed: {
    ...mapGetters(['rootCategories', 'subcategoriesByCategoryId', 'categoryById', 'productById']),
    categoryOptions () {
      let options = []
      each(this.rootCategories, root => {
        let group = { name: root.name, cats: [] }
        each(this.subcategoriesByCategoryId(root.id), cat => group.cats.push(cat))
        options.push(group)
      })
      return options
    }
  },

  mounted () {
    this.fetchCategories().then(() => {
      if (this.categoryId) this.category = this.categoryById(this.categoryId)
    })
    if (this.productId) {
      this.fetchProductById(this.productId).then(() => {
        this.product = this.productById(this.productId)
      })
    }
  },

  methods: {
    ...mapActions(['fetchCategories', 'fetchProductById']),
    search () {
      let product = this.product ? this.product.slug : 'any'
      let category = this.category ? this.category.slug : 'any'

      window.location.href = `/integrations/search/${product}/${category}/#search-bar`
    }
  }
}
</script>
