<template>
  <div id="autocomplete-tripadvisor">
    <tripadvisorAutocomplete v-model="tripadvisorHotel" :options="tripadvisorHotels.items"
      :extra-options="`border-top-left-right-radius bg-white ${height} ${width} ${options}`"
      label="name" track-by="name" :loading="tripadvisorHotels.loading" @search-change="searchhotelTripadvisor"
      @empty="tripadvisorHotel = null" :placeholder="placeholder" @input="selectItem" :hotel-name-input="hotelNameInput"
      :custom-value="customValue" @newHotelSelected="newHotelSelected" />
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

import tripadvisorAutocomplete from '../../../tailwind_components/inputs/HtrTripadvisorAutocomplete.vue'

export default {
  components: {
    tripadvisorAutocomplete,
  },
  props: {
    width: {
      type: String,
      default: '!w-full',
    },
    height: {
      type: String,
      default: '!h-[40px]',
    },
    options: {
      type: String,
      default: '',
    },
    placeholder: {
      type: String,
      default: 'Search for a hotel',
    },
    defaultValue: {
      type: Object,
      default: function () {
        return {};
      }
    },
    hotelNameInput: {
      type: String,
      default: 'json',
    },
    customValue: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      tripadvisorHotel: {
        name: '',
      },
      selected: null,
    };
  },
  computed: {
    ...mapGetters([
      'tripadvisorHotels',
    ]),
  },
  mounted() {
    this.fetchTripadvisorHotels({
      query: '',
    })
  },
  created() {
    if (this.defaultValue) {
      this.tripadvisorHotel = this.defaultValue
    }
  },
  methods: {
    ...mapActions([
      'fetchTripadvisorHotels',
    ]),
    searchhotelTripadvisor(search) {
      this.fetchTripadvisorHotels({
        query: search,
      })
    },
    selectItem(selectedItem) {
      this.selected = selectedItem
      this.$emit('valueUpdated', { value: this.selected })
    },
    newHotelSelected(hotel) {
      this.$emit('newHotelSelected', hotel)
    },
  }
}
</script>
