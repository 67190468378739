<template>
  <div class="row validation">
    <div class="title-pannel">
      <div class="htooltip">
        <label class="text-big required"><strong>Release date</strong></label>
        <div class="tooltiptext">
          <span>Press releases are posted within 48-hours. Please write in via live chat for faster posting</span>
        </div>
      </div>
    </div>
    <datepicker
      name="release_date"
      input-class="form-control required"
      format="yyyy-MM-dd" />
  </div>
</template>

<script>
import Datepicker from 'vuejs-datepicker'

export default {
  components: {
    Datepicker
  }
}
</script>
