<template>
  <div />
</template>

<script>
import swal from 'sweetalert2'

export default {
  props: {
    title: {
      type: String,
      required: true
    },
    text: {
      type: String,
      default: null
    }
  },

  mounted () {
    swal({
      title: this.title,
      text: this.text,
      type: 'success',
      showCancelButton: false
    })
  }
}
</script>
