<template>
  <div>
    <top-rated-products v-for="category in categories" :key="`topRatedKey${category.order}`" :category="category" :id="'category' + category.order" />
    <category-description v-for="category in categories" :key="`categoryDescriptionKey${category.order}`"
      :category-name="category.name"
      :category-year="category.year"
      :category-order="category.order"
      :id="`categoryDescription${category.order}`"
    />
  </div>
</template>
<script>
import TopRatedProducts from './TopRatedProducts.vue'
import CategoryDescription from './CategoryDescription.vue'

export default {
  components: {
    TopRatedProducts,
    CategoryDescription
  },
  props: [
    'categoriesString'
  ],
  computed: {
    categories () {
      let categoriesAux = Object.values(JSON.parse(this.categoriesString))
      categoriesAux.sort((a,b) => (a.order > b.order) ? 1 : ((b.order > a.order) ? -1 : 0))
      return categoriesAux
    }
  }
}
</script>
