import swal from 'sweetalert2'
class NonVendorsOnly {
  static init () {
    $('.non-vendor-only').on('click', (event) => {
      event.preventDefault()

      let firstName = $(event.target).data('first-name')
      swal({
        title: 'Sorry ' + firstName + ' this functionality is only for hoteliers',
        html: 'To protect your company\'s privacy, only verified hoteliers can access demo and pricing information.  <a target="_blank" href="https://help.hoteltechreport.com/common-policies-and-procedures/what-happens-when-a-user-requests-demo-or-pricing-for-a-product">Learn more</a>',
        type: 'error',
        showCancelButton: false,
        confirmButtonText: 'back to HotelTechReport.com'
      })
    })
  }
}

export default NonVendorsOnly