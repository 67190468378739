<template>
  <div class="info-badge hidden-xs !w-[352px]">
    <div v-if="countWords >= 40" class="sticky-grader !w-[352px]">
      <div class="container-sidebar review-grader">
        <div class="grader-body">
          <h1 class="title-body">
            {{ htrLanguage.writeReview.review_grader || 'review_grader' }}
          </h1>
          <hr>
          <div class="grader-words">
            <p class="qualification" :class="`qua-${qualification.toLowerCase()}`">
              {{ qualification }}
            </p>
            <div class="words">
              <p>{{ `${countWords} ${countWords === 1 ? (htrLanguage.writeReview.word || 'word') : (htrLanguage.writeReview.words || 'words')}` }}</p>
              <div class="words-progress">
                <div :class="`qua-${qualification.toLowerCase()}`" :style="{ width: `${countWords >= 350 ? '100' : (countWords / 349) * 100}%`, color: '#fff' }" />
              </div>
              <p v-if="qualification != 'A+'" class="nextGradeText">
                {{ nextGradeWords }} {{ htrLanguage.writeReview.more_words || 'more_words' }} {{ nextGrade }}
              </p>
            </div>
          </div>
          <div>
            <div class="grader-content-row">
              <div>
                <h3>{{ htrLanguage.writeReview.expertise || 'expertise' }}</h3>
              </div>
              <div>
                <p class="option" :class="qualification === '-' ? 'option-selected' : ''">
                  n/a
                </p>
              </div>
              <div>
                <p class="option" :class="qualification.toLowerCase() === 'b' ? 'option-selected qua-b' : ''">
                  {{ htrLanguage.writeReview.novice || 'novice' }}
                </p>
              </div>
              <div>
                <p class="option" :class="qualification.toLowerCase() === 'a-' ? 'option-selected qua-a-' : ''">
                  {{ htrLanguage.writeReview.veteran || 'veteran' }}
                </p>
              </div>
              <div>
                <p class="option" :class="qualification.toLowerCase() === 'a+' ? 'option-selected qua-a+' : ''">
                  {{ htrLanguage.writeReview.expert || 'expert' }}
                </p>
              </div>
            </div>
          </div>
        </div>
        <div class="grader-footer">
          <h5 class="footer-title">
            {{ htrLanguage.writeReview.footer_review_garden_title || 'footer_review_garden_title' }}
          </h5>
          <p>{{ htrLanguage.writeReview.footer_review_garden_description || 'footer_review_garden_description' }}</p>
        </div>
      </div>
      <div class="container-sidebar review-winner">
        <p class="emoji-money">
          💰
        </p>
        <div class="info-win">
          <h2>{{ htrLanguage.writeReview.win_review_garden_title || 'win_review_garden_title' }}</h2>
          <p>{{ htrLanguage.writeReview.win_review_garden_description || 'win_review_garden_description' }}<a href="https://help.hoteltechreport.com/en/articles/4357534-monthly-review-giveaway-details" target="_blank">{{ htrLanguage.company.learn_more || 'learn_more' }}</a></p>
        </div>
      </div>
    </div>
  </div>
</template>
<style scoped>
  .info-badge {
    margin-top: 497px;
  }
  .sticky-active {
    width: 341.6px;
    position: fixed;
    top: -10px;
    margin-top: 70px;
  }
  .container-sidebar {
    border-radius: 5px;
    border: 1px solid #c7c7c7;
    background: #fff;
    margin-top: 19px;
  }
  .review-grader {
    text-align: center;
    font-size: 14px;
    color: #6b6b6b;
  }
  .review-winner {
    padding: 19px 13px;
    display: flex;
    align-items: center;
  }
  .emoji-money {
    font-size: 37px;
    margin-right: 10px !important;
  }
  .info-win h2 {
    font-size: 15px;
    font-weight: bold;
    margin: 0px;
    margin-bottom: 5px;
  }
  .info-win p {
    font-size: 13px;
    color: #2b2b2b;
  }
  .info-win a {
    color: #2b2b2b;
    margin-left: 7px;
    text-decoration: revert;
  }
  .grader-body {
    padding: 20px 23px 8px 23px;
  }
  .grader-body hr {
    border-color: #ffffff;
    opacity: 0.7;
  }
  .nextGradeText {
    color: #ababab;
    font-size: 10px;
  }
  .title-body {
    margin: 0px;
    font-size: 17px;
    font-weight: bold;
    text-align: left;
    color: #373737;
  }
  .grader-words {
    display: flex;
    width: 100%;
    margin: 12px 0px 17px 0px;
    flex-wrap: nowrap;
    justify-content: space-around;
    align-items: center;
  }
  .qualification {
    min-width: 57px;
    font-size: 28px;
    margin-right: 15px;
    border-radius: 17px;
    background: #cecece;
    font-weight: bold;
    color: #fff;
  }
  .words {
    width: 100%;
    margin-left: 11px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
  .words-progress {
    width: 100%;
    height: 11px;
    background: #f5f5f5;
    border: solid 1px #bdbdbd;
    margin-top: 3px;
  }
  .words-progress > div {
    height: 100%;
    background: #cecece;
  }
  .grader-content-row {
    display: flex;
    flex-wrap: nowrap;
    margin: 6px 0px;
    justify-content: space-between;
  }
  .grader-content-row > div {
    text-align: left;
  }
  .grader-content-row h3 {
    font-weight: bold;
    font-size: 12px;
  }
  .grader-content-row .option {
    padding: 2px 6px;
    border-radius: 12px;
    font-size: 12px;
  }
  .option-selected {
    background: #cecece;
    color: #fff;
    font-weight: bold;
  }
  .grader-footer {
    text-align: left;
    background: #f9f9f9;
    border-bottom-left-radius: 5px;
    padding: 14px 22px;
    border-bottom-right-radius: 5px;
    border-top: solid 1px #c7c7c7;
  }
  .grader-footer p {
    font-size: 13px;
  }
  .footer-title {
    font-size: 14px;
    font-weight: bold;
    margin-bottom: 3px;
  }
  .qua-a\+ {
    background: #78a65a !important;
  }
  .qua-a- {
    background: #9dc284 !important;
  }
  .qua-b {
    background: #fad978 !important;
  }
</style>
<script>
$(document).ready(() => {
  let topGrader, bottomSidebar
  $(window).on('resize', () => {
    if ($('.sticky-grader').hasClass('sticky-active')) {
      $('.sticky-grader').css('width', $('.inner').width())
    }
  })
  $(window).on('scroll', () => {
    if ($(window).scrollTop() > topGrader) {
      // Sticky
      bottomSidebar = $('.info-badge').offset().top + $('.info-badge').height()
      if (!$('.sticky-grader').hasClass('sticky-active')) {
        $('.sticky-grader').addClass('sticky-active')
        $('.sticky-grader').css('width', $('.inner').width())
      }

      // Sticky limit down
      if (($(window).scrollTop() + $('.sticky-grader').height()) > bottomSidebar) {
        $('.sticky-grader').css('top', `${(bottomSidebar - ($(window).scrollTop() + $('.sticky-grader').height())) - 10}px`)
      } else {
        $('.sticky-grader').css('top', '-10px')
      }
    } else if (window.countWords >= 40) {
      // Not sticky the conditional is needed because the sticky grader element won't be shown if the words are less than 20
      topGrader = $('.sticky-grader').offset().top
      if ($('.sticky-grader').hasClass('sticky-active')) {
        $('.sticky-grader').css('width', 'auto')
        $('.sticky-grader').removeClass('sticky-active')
      }
    }
  })
})
export default {
  props: ['isAnonymous', 'countWords'],
  data () {
    return {
      is_anonymous: false
    }
  },
  created() {
    window.countWords = this.countWords
  },
  computed: {
    htrLanguage() {
      if (window && window.i18n) {
        const { writeReview, quiz, company } = window.i18n
        if (writeReview && quiz && company) {
          return { writeReview, quiz, company }
        }
      }
      return { writeReview: {}, quiz: {}, company: {} }
    },
    qualification () {
      if (this.countWords >= 40 && this.countWords < 150) {
        return 'B'
      } else if (this.countWords >= 150 && this.countWords < 350) {
        return 'A-'
      } else if (this.countWords >= 350) {
        return 'A+'
      }

      return '-'
    },
    nextGrade () {
      switch (this.qualification) {
        case 'B':
          return 'A-'
        case 'A-':
          return 'A+'
        case 'A+':
          return undefined
        default:
          return 'B'
      }
    },
    nextGradeWords () {
      switch (this.nextGrade) {
        case 'B':
          return 40 - this.countWords
        case 'A-':
          return 150 - this.countWords
        case 'A+':
          return 350 - this.countWords
        default:
          return 0
      }
    }
  },
  watch: {
    isAnonymous () {
      this.is_anonymous = this.isAnonymous
    }
  }
}
</script>
