<template>
  <div class="company__news">
    <a v-for="element in elements" :key="element.id" :href="`https://cdn.hsyndicate.org/e/${element.id}`"
       target="_blank">
      <div>
        <p class="company__news__date">{{ publishedAt(element).format('MMMM DD, YYYY') }} - <span
          class="company__news__type">{{ element.type }}</span></p>
        <h3 class="company__news__title">{{ element.source.name }}</h3>
        <p class="company__news__description">{{ element.source.summary }}</p>
      </div>
    </a>
    <a v-if="notLoadedCount > 0 && !fetching" class="company__news-action" href="#" @click.prevent="loadMore()">Read
      More ({{ notLoadedCount }})</a>
    <a v-if="notLoadedCount > 0 && fetching" class="company__news-action" href="#" @click.prevent="">Loading</a>
    <div class="company__news__footer">
      <span>Powered by</span>
      <a href="https://www.hospitalitynet.org/" target="_blank">
        <img src="/images/hospitality-logo.jpg">
      </a>
    </div>
  </div>
</template>

<script>
import moment from 'moment'
import { mapActions, mapGetters } from 'vuex'

export default {
  props: ['company'],

  data () {
    return {
      total: 0,
      size: 4,
      page: 0,
      fetching: false
    }
  },

  computed: {
    ...mapGetters(['newsByCompanyId']),
    elements () {
      return this.newsByCompanyId(this.company.id)
    },
    notLoadedCount () {
      return this.total - this.elements.length
    }
  },

  mounted () {
    this.fetchNews()
  },

  methods: {
    ...mapActions(['fetchCompanyNews']),
    loadMore () {
      if (this.fetching) return false

      this.page++
      this.fetchNews()
    },
    fetchNews () {
      if (this.fetching) return false

      this.fetching = true

      let params = {
        id: this.company.id,
        from: this.page * this.size,
        take: this.size
      }

      this.fetchCompanyNews(params)
        .then(res => (this.total = res.data.total))
        .finally(() => (this.fetching = false))
    },
    publishedAt (element) {
      return moment(element.source.published)
    }
  }
}
</script>
