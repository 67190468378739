<template>
  <div class="aside__download aside__download-last-step">
    <a class="btn-back" href="#" @click.prevent="$emit('prevStep')">Back</a>
    <p class="notification">
      <i class="icon-envelope2" /> Success! Your report was sent to {{ value.email }}
    </p>
    <p class="aside__download__title">
      Want to save the time finding the right vendor by getting free custom recommendations?
    </p>
    <a href="/quiz" class="btn btn-green">Take the quiz</a>

    <div v-if="sponsorCompany" class="box-lead">
      <div class="box-img">
        <img :src="sponsorCompany.logo">
      </div>
      <div>
        <span class="box-lead__title">Special Thanks to {{ sponsorCompany.name }}!</span>
        <p class="box-lead__description">
          {{ sponsorCompany.name }} is a thought leader in the {{ categoryName }}
          category and helped us create this free guide.
        </p>
        <button @click="createLead('ask_a_question')">
          Ask them a question <i class="icon-sarrow-right" />
        </button>
        <button @click="createLead('request_demo')">
          Connect for a demo <i class="icon-sarrow-right" />
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import { EventBus } from '../../../../event-bus'
import axios from 'axios'

export default {
  props: ['value', 'productId', 'categoryId', 'reportId', 'categoryName'],

  computed: {
    ...mapGetters([
      'productById',
      'companyById'
    ]),
    sponsorProduct () {
      return this.productById(parseInt(this.productId))
    },
    sponsorCompany () {
      return this.sponsorProduct ? this.companyById(this.sponsorProduct.company_id) : null
    }
  },

  mounted () {
    if (this.productId && !this.sponsorProduct) {
      this.isLoading = true
      this.fetchProductById(this.productId).finally(() => (this.isLoading = false))
    }
  },

  methods: {
    ...mapActions([
      'fetchProductById'
    ]),
    submit () {
      axios.post(`/api/reports/${this.reportId}/downloads`, this.value)
    },
    createLead (type) {
      if (this.projectId && type !== 'ask_a_question') {
        this.createLeadRedirectPost(type)
      } else {
        EventBus.$emit('open-modal-sidebar', {
          type: 'modal-lead',
          params: {
            categoryId: parseInt(this.categoryId),
            productId: parseInt(this.productId),
            type: type
          }
        })
      }

      this.$emit('close')
    },
    createLeadRedirectPost (source) {
      let form = document.createElement('form')

      form.method = 'post'
      form.action = `/projects/${this.projectId}/rooms`

      const data = { source: source, product_id: this.productId }

      for (let name in data) {
        let input = document.createElement('input')
        input.type = 'hidden'
        input.name = name
        input.value = data[name]
        form.appendChild(input)
      }

      document.body.appendChild(form)

      form.submit()
    }
  }
}
</script>
