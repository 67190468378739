<template>
  <div>
    <div class="category-row">
      <h3>Categories</h3>
      <ul class="list">
        <li class="all">
          <label>
            <input type="checkbox" :checked="!categorySelected" value @change="categorySelect">
            <span class="fake-checkbox" />
            <span class="txt">All</span>
          </label>
        </li>
        <li v-for="category in categories" :key="category.id">
          <label>
            <input type="checkbox" :value="category.slug" :checked="categorySelected == category.slug"
                   @change="categorySelect">
            <span class="fake-checkbox" />
            <span class="txt">{{ category.name }}</span>
          </label>
        </li>
      </ul>
    </div>
    <div v-if="categorySelected" class="category-row">
      <h3>Sub-Categories</h3>
      <ul v-for="category in categories" v-show="categorySelected == category.slug" :key="category.id" class="list">
        <li v-for="subCategory in category.sub" :key="subCategory.id" :class="{'all': subCategory.name == 'All'}">
          <label>
            <input type="checkbox" :value="subCategory.slug" :checked="subCategorySelected == subCategory.slug"
                   @change="subCategorySelect">
            <span class="fake-checkbox" />
            <span class="txt">{{ subCategory.name }}</span>
          </label>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  props: [
    'categorySelected',
    'subCategorySelected'
  ],

  computed: {
    categories () {
      return (window.companiesLaravel) ? window.companiesLaravel.categories : []
    }
  },

  methods: {
    categorySelect (event) {
      if (event.target.value === this.categorySelected) {
        return this.$emit('category-select', '')
      }

      return this.$emit('category-select', event.target.value)
    },
    subCategorySelect (event) {
      if (event.target.value === this.subCategorySelected) {
        return this.$emit('sub-category-select', '')
      }

      return this.$emit('sub-category-select', event.target.value)
    }
  }
}
</script>
