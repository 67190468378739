<template>
  <div class="inner-hold">
    <div class="category-row">
      <h3>Categories</h3>
      <ul class="list">
        <li>
          <label>
            <input type="checkbox" :checked="!categorySelected" value @change="categorySelect">
            <span class="fake-checkbox" />
            <span class="txt">All</span>
          </label>
        </li>
        <li v-for="category in categories" :key="category.id">
          <label>
            <input type="checkbox" :value="category.slug" :checked="categorySelected == category.slug"
                   @change="categorySelect">
            <span class="fake-checkbox" />
            <span class="txt">{{ category.name }}</span>
          </label>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  props: [
    'categorySelected'
  ],

  computed: {
    categories () {
      return (window.postsLaravel) ? window.postsLaravel.categories : []
    }
  },

  methods: {
    categorySelect (event) {
      if (event.target.value === this.categorySelected) {
        return this.$emit('category-select', '')
      }

      return this.$emit('category-select', event.target.value)
    }
  }
}
</script>
