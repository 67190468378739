<template>
  <div>
    <div>
      <top-navigation-sticky
        :percent-advanced="percentedAvancedStepTwo"
      />
    </div>
    <div class="container flex-container !max-w-screen-lg">
      <form class="container-step" :action="formAction" method="POST">
        <review-sidebar :count-words="countWords" />
        <div class="marginContainerReview">
          <input v-model="selectedProducts" type="hidden" name="products[]">
          <input v-model="likeMostField" type="hidden" name="likeMost">
          <input v-model="likeLeastField" type="hidden" name="likeLeast">
          <input v-model="experienceField" type="hidden" name="experience">
          <h1 class="text-center titleReview">
            {{ htrLanguage.writeReview.leave_a_review_for || 'leave_a_review_for' }} {{ companyName }}
          </h1>
          <div class="text-center">
            <a href="https://help.hoteltechreport.com/en/articles/987415-our-guidelines-for-hotelier-reviews" target="_blank" class="review-guidelines">{{ htrLanguage.writeReview.read_our_review_guidelines || 'read_our_review_guidelines' }}</a>
          </div>
          <br>
          <div class="review-form">
            <input type="hidden" name="likelyToRecommend" :value="likelyToRecommend">
            <div class="company-logo">
              <img :src="companyLogo" alt="companyName">
            </div>
            <div v-if="shouldShowErrorMessages" class="error-alert">
              {{ errorMessage }}
            </div>
            <div
              :class="{ 'recommend-block': true, validation: true, error: shouldShowErrorMessages && ! likelyToRecommend, success: likelyToRecommend }">
              <div class="flexBox backgroundAreYouAHotelier">
                <div>
                  <p class="areYouTitle">
                    {{ htrLanguage.writeReview.tech_partner_recomendation_question || 'tech_partner_recomendation_question' }}
                  </p>
                  <p class="areYouDescription">
                    {{ htrLanguage.writeReview.tech_partner_recomendation_description || 'tech_partner_recomendation_description' }}
                  </p>
                </div>
                <div>
                  <a class="btn btn-blue buttonLeaveReview" target="_blank" :href="`/recommend/${ companySlug }`">
                    {{ htrLanguage.writeReview.tech_partner_recomendation_button || 'tech_partner_recomendation_button' }}
                  </a>
                </div>
              </div>
              <strong class="title">
                {{ (htrLanguage.writeReview.how_likely_recommend || 'how_likely_recommend').replace(':company_name', companyName) }}
              </strong>
              <ul class="recommend-rating" :class="isRtlDirection ? 'setLtrDirection' : ''">
                <li v-for="rating in potentialRatings" :key="rating" :class="{ selected: rating == likelyToRecommend }">
                  <a href="#" @click.prevent="updateRecommendedRating(rating)">{{ rating }}</a>
                </li>
              </ul>
              <ul class="rating-caption">
                <li>{{ htrLanguage.writeReview.not_likely || 'not_likely' }}</li>
                <li>{{ htrLanguage.writeReview.neutral || 'neutral' }}</li>
                <li>{{ htrLanguage.writeReview.likely || 'likely' }}</li>
              </ul>
            </div>

            <div v-if="products.length && step1completed" class="productSelection">
              <div class="product-checklist productsList"
                   :class="{ row: true, validation: true, success: selectedSubcategories.length, error: shouldShowErrorMessages && !selectedSubcategories.length }">
                <div class="text-big fw-bold title-pannel title">
                  {{ (htrLanguage.writeReview.which_of_company_products || 'which_of_company_products').replace(':company_name', companyName) }}
                </div>
                <ul>
                  <li v-for="subcategory in subcategories" :key="subcategory.id" class="liProducts">
                    <label class="labelProducts">
                      <input v-model="selectedSubcategories" type="checkbox" name="categories[]" :value="subcategory.id">
                      <span :class="selectedSubcategories && selectedSubcategories.length > 0 && selectedSubcategories.some((sub) => sub === subcategory.id) ? 'txt productButtonSelected' : 'txt productButtons'">
                        <b :class="selectedSubcategories && selectedSubcategories.length > 0 && selectedSubcategories.some((sub) => sub === subcategory.id) ? 'colorWhite productName !font-normal' : 'productName !font-normal'">{{ translate(subcategory, 'name') }}</b>
                      </span>
                    </label>
                  </li>
                </ul>
              </div>
              <div v-if="step1completed && step2completed" class="paddingContainer">
                <div class="displayFlex">
                  <div class="content-area marginTop25px">
                    <div :class="{ row: true, validation: true, success: (experience ? true : false), error: shouldShowErrorMessages && !experience }">
                      <div class="title-pannel">
                        <label for="likeMost" :class="{ 'text-big': true, required: 'true' }">
                          <span>
                            {{ htrLanguage.writeReview.title_of_your_review || 'title_of_your_review' }} <strong>{{ htrLanguage.writeReview.title_of_your_review || 'title_of_your_review' }}</strong>?
                          </span>
                        </label>
                      </div>
                      <div
                        id="experienceFieldId"
                        :class="getClassExperience()"
                        :placeholder="`${htrLanguage.writeReview.placeholder_title || 'placeholder_title'}`"
                        contenteditable="true"
                        @keyup="listenExperienceEditable"
                        @paste.prevent="pasteListener"
                      />
                    </div>

                    <div :class="{ row: true, validation: true, success: shouldShowSuccess('likeMost'), error: shouldShowError('likeMost') }">
                      <div class="title-pannel">
                        <label for="likeMost" :class="{ 'text-big': true, required: 'true' }">
                          <span>
                            {{ htrLanguage.writeReview.what_do_you_like_most_1 || 'what_do_you_like_most_1' }} <strong>{{ htrLanguage.writeReview.what_do_you_like_most_2 || 'what_do_you_like_most_2' }}</strong>?
                          </span>
                        </label>
                      </div>
                      <div
                        id="likeMostFieldId"
                        :class="getClass('likeMost')"
                        :placeholder="`${htrLanguage.writeReview.placeholder_you_like_most_1 || 'placeholder_you_like_most_1'} \n -${htrLanguage.writeReview.placeholder_you_like_most_2 || 'placeholder_you_like_most_2'} \n -${htrLanguage.writeReview.placeholder_you_like_most_3 || 'placeholder_you_like_most_3'}`"
                        contenteditable="true"
                        @keyup="listenLikeMostEditable"
                        @paste.prevent="pasteListener"
                      />

                      <span class="number-limit">
                        <span :class="{ 'text-danger': isLessThan20Words('likeMost') }">{{ numberOfWords('likeMost') }} {{ htrLanguage.writeReview.words || 'words' }} ({{ htrLanguage.writeReview.words_minimum || 'words_minimum' }})</span>
                      </span>
                    </div>
                    <div :class="{ row: true, validation: true, success: shouldShowSuccess('likeLeast'), error: shouldShowError('likeMost') }">
                      <div class="title-pannel">
                        <label for="likeLeast" :class="{ 'text-big': true, required: 'true' }">
                          <span>
                            {{ htrLanguage.writeReview.what_do_you_think_1 || 'what_do_you_think_1' }} <strong>{{ htrLanguage.writeReview.what_do_you_think_2 || 'what_do_you_think_2' }}</strong>?
                          </span>
                        </label>
                      </div>
                      <div
                        id="likeLeastFieldId"
                        :class="getClass('likeLeast')"
                        :placeholder="`${htrLanguage.writeReview.placeholder_you_think || 'placeholder_you_think'}`"
                        contenteditable="true"
                        @keyup="listenLikeLeastEditable"
                        @paste.prevent="pasteListener"
                      />

                      <div slot="tooltip" class="alert-popup">
                        <a href="#" tabindex="-1" class="close" @click.prevent="hideTooltip"><i class="icon-cross" /></a>

                        <h3>{{ htrLanguage.writeReview.review_anonymous || 'review_anonymous' }}</h3>
                        <p>{{ htrLanguage.writeReview.review_anonymous_description }}</p>
                        <div class="avatar-pannel">
                          <img src="/images/icon-avatar.svg" width="34" alt="">
                          <span class="caption">{{ htrLanguage.writeReview.review_anonymous || 'review_anonymous' }}</span>
                        </div>
                      </div>

                      <span class="number-limit">
                        <span :class="{ 'text-danger': isLessThan20Words('likeLeast') }">{{ numberOfWords('likeLeast') }} {{ htrLanguage.writeReview.words || 'words' }} ({{ htrLanguage.writeReview.words_minimum || 'words_minimum' }})</span>
                      </span>
                    </div>
                  </div>
                  <div class="categoryKeywords hideOnPhone">
                    <categories-keywords
                      :categories="categoriesSelected"
                      :review-text="experience + ' ' + likeMost + ' ' + likeLeast"
                      :view-more="htrLanguage.writeReview.view_more"
                      :view-less="'View less'"
                    />
                  </div>
                </div>
                <div class="signin-with">
                  <h2>
                    {{ htrLanguage.writeReview.tell_us_about_yourself || 'tell_us_about_yourself' }}
                    <small>({{ htrLanguage.writeReview.tell_us_about_yourself_description || 'tell_us_about_yourself_description' }})</small>
                  </h2>

                  <div class="col-hold">
                    <div class="col"
                         :class="{ row: true, validation: true, success: auth.first_name, error: shouldShowErrorMessages && !auth.first_name }">
                      <div class="title-pannel">
                          <span class="label">{{ htrLanguage.writeReview.name || 'name' }}<span>*</span></span>
                          <input v-model="auth.first_name" type="text" name="first_name" class="form-control">
                      </div>
                    </div>
                    <div class="col"
                         :class="{ row: true, validation: true, success: auth.last_name, error: shouldShowErrorMessages && !auth.last_name }">
                      <div class="title-pannel">
                          <span class="label">{{ (htrLanguage.quiz.last_name_camel_case || 'last_name_camel_case').charAt(0).toUpperCase() + (htrLanguage.quiz.last_name_camel_case || 'last_name_camel_case').slice(1).toLowerCase() }}<span>*</span></span>
                          <input v-model="auth.last_name" type="text" name="last_name" class="form-control">
                      </div>
                    </div>
                  </div>
                  <div class="col-hold">
                    <div class="col"
                         :class="{ row: true, validation: true, success: auth.title, error: shouldShowErrorMessages && !auth.title }">
                      <div class="title-pannel">
                          <span class="label">{{ htrLanguage.company.job_title || 'job_title' }}<span>*</span></span>
                          <input v-model="auth.title" type="text" name="title" class="form-control">
                      </div>
                    </div>
                    <div v-if="!authenticated" class="col-hold row about-you about-you-step_2">
                      <div class="about-you__corporate">
                        <label>
                          <input v-model="auth.is_corporate" type="checkbox" name="is_corporate">
                          <span class="fake-checkbox" />
                          <span class="txt">{{ htrLanguage.writeReview.check_this_box || 'check_this_box' }}</span>
                        </label>
                      </div>
                    </div>
                  </div>
                  <div v-if="!authenticated" class="col-hold">
                    <div class="col" :class="{ row: true, validation: true, success: auth.email, error: (shouldShowErrorMessages && !auth.email) || !emailValid}">
                      <div class="title-pannel">
                          <span class="label">{{ (htrLanguage.company.work_email || 'work_email').charAt(0).toUpperCase() + (htrLanguage.company.work_email || 'work_email').slice(1).toLowerCase() }}<span>*</span></span>
                          <input
                            id="workEmail"
                            v-model="auth.email"
                            type="email" name="email"
                            class="form-control">
                      </div>
                    </div>
                    <span class="alert-email"><i class="icon-notification" />{{ htrLanguage.writeReview.must_be_a_work_email || 'must_be_a_work_email' }}</span>
                    <p v-if="shouldShowErrorMessages" class="error-alert">
                      {{ errorMessageEmail }}
                    </p>
                  </div>
                  <div v-if="isGenericEmail" class="col-hold is-generic-email">
                    <span>TIP: Use your email instead of a generic email to get instantly verified</span>
                  </div>
                  <div v-if="isPersonalEmail" class="col-hold is-personal-email">
                    <span>TIP: Use your work email to get instantly verified</span>
                  </div>
                  <div v-if="enteredEmail && !authenticated">
                    <div class="col-hold">
                      <div :class="{ validation: true, success: isEmailWorkHotelCompanyYes || isEmailWorkHotelCompanyNo, error: shouldShowErrorMessages && !isEmailWorkHotelCompanyYes && !isEmailWorkHotelCompanyNo }">
                        <div class="title-pannel">
                          <label class="aditionalTitle">
                            {{ htrLanguage.writeReview.is_your_work_email || 'is_your_work_email' }}
                          </label>
                        </div>
                      </div>
                    </div>
                    <div class="col-hold displayBlock">
                      <div class="about-you paddingBottom5px">
                        <div class="about-you__corporate">
                          <label class="displayFlex">
                            <input v-model="isEmailWorkHotelCompanyYes" class="marginLeft5px" name="emailWorkHotelCompanyYes" type="checkbox" @change="checkEmailHotelYes(isEmailWorkHotelCompanyYes)">
                            <span class="fake-checkbox" />
                            <span class="txt">{{ htrLanguage.companyCategory.yes || 'yes' }}</span>
                          </label>
                        </div>
                        <div class="about-you__corporate">
                          <label class="displayFlex">
                            <input v-model="isEmailWorkHotelCompanyNo" class="marginLeft5px" name="emailWorkHotelCompanyNo" type="checkbox" @change="checkEmailHotelNo(isEmailWorkHotelCompanyNo)">
                            <span class="fake-checkbox" />
                            <span class="txt">{{ htrLanguage.companyCategory.no || 'no' }}</span>
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div v-if="isEmailWorkHotelCompanyNo">
                    <div class="col-hold marginTop20px">
                      <div :class="{ validation: true, success:filledEasyOptions, error: shouldShowErrorMessages && !filledEasyOptions }">
                        <div class="title-pannel">
                          <label class="aditionalTitle">
                            {{ htrLanguage.writeReview.have_other_easy_options || 'have_other_easy_options' }}
                          </label>
                        </div>
                      </div>
                    </div>
                    <div class="col-hold displayBlock">
                      <div class="about-you paddingBottom5px">
                        <div class="about-you__corporate">
                          <label class="displayFlex">
                            <input v-model="easyOptionLinkedin" class="marginLeft5px" type="checkbox" @click="easyOptionLinkedin = !easyOptionLinkedin">
                            <span class="fake-checkbox" />
                            <span class="txt">{{ htrLanguage.writeReview.linkedin_profile_url || 'linkedin_profile_url' }}</span>
                          </label>
                        </div>
                        <div class="about-you__corporate">
                          <label class="displayFlex">
                            <input v-model="easyOptionInvoice" class="marginLeft5px" type="checkbox" @click="easyOptionInvoice = !easyOptionInvoice">
                            <span class="fake-checkbox" />
                            <span class="txt">{{ htrLanguage.writeReview.upload_a_recent_invoice || 'upload_a_recent_invoice' }}</span>
                          </label>
                        </div>
                        <div class="about-you__corporate">
                          <label class="displayFlex">
                            <input v-model="easyOptionScreenshot" class="marginLeft5px" type="checkbox" @click="easyOptionScreenshot = !easyOptionScreenshot">
                            <span class="fake-checkbox" />
                            <span class="txt">{{ htrLanguage.writeReview.upload_logged_in_screenshot || 'upload_logged_in_screenshot' }}</span>
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div v-if="easyOptionLinkedin && isEmailWorkHotelCompanyNo" class="marginBottom30px">
                    <div class="col-hold marginTop20px">
                      <div :class="{ validation: true, success: (linkedin? true : false), error: shouldShowErrorMessages && !linkedin }">
                        <div class="title-pannel">
                          <label class="aditionalTitle">
                            {{ htrLanguage.writeReview.please_paste_link_linkedin || 'please_paste_link_linkedin' }}
                          </label>
                        </div>
                      </div>
                    </div>
                    <div class="col-hold" :class="{ validation: true, error: shouldShowErrorMessages && !linkedin }">
                      <input v-model="linkedin" name="linkedin" type="text" placeholder="ex) linkedin.com/your-name" class="form-control width38percent">
                      <span class="alert-email"><i class="icon-notification" />{{ htrLanguage.writeReview.current_employment || 'current_employment' }}</span>
                      <br>
                    </div>
                  </div>
                  <div v-if="easyOptionInvoice && isEmailWorkHotelCompanyNo" class="marginBottom30px">
                    <div class="col-hold marginTop20px">
                      <div :class="{ validation: true, success: (invoice? true : false), error: shouldShowErrorMessages && !invoice }">
                        <div class="title-pannel">
                          <label class="aditionalTitle">
                            {{ htrLanguage.writeReview.please_upload_invoice || 'please_upload_invoice' }} {{ companyName }}
                          </label>
                        </div>
                      </div>
                    </div>
                    <div class="col-hold" :class="{ validation: true, error: shouldShowErrorMessages && !invoice }">
                      <input v-model="invoice" :placeholder="htrLanguage.writeReview.please_recent_invoice || 'please_recent_invoice'" class="form-control width38percent" readonly>
                      <div class="input-file">
                        <file-uploader v-model="invoice" class="btn-upload" not-images-only="true">
                          <span class="upload-btn" />
                        </file-uploader>
                        <input v-model="invoice" type="hidden" name="invoice">
                      </div>
                      <div>
                        <span class="alert-email paddingLeft0px marginLeft45px"><i class="icon-notification" />
                          {{ htrLanguage.writeReview.name_of_your_hotel || 'name_of_your_hotel' }}
                        </span>
                        <span class="alert-email underline marginLeft55px">
                          <a class="grayInA" href="https://help.hoteltechreport.com/en/articles/4346791-additional-hotelier-verification-options" target="_blank">
                            {{ htrLanguage.writeReview.see_example || 'see_example' }}
                          </a>
                        </span>
                      </div>
                      <br>
                    </div>
                  </div>
                  <div v-if="easyOptionScreenshot && isEmailWorkHotelCompanyNo" class="marginBottom30px">
                    <div class="col-hold marginTop20px">
                      <div :class="{ validation: true, success: (screenshot? true : false), error: shouldShowErrorMessages && !screenshot }">
                        <div class="title-pannel">
                          <label class="aditionalTitle">
                            {{ htrLanguage.writeReview.please_upload_screenshot || 'please_upload_screenshot' }} {{ companyName }}
                          </label>
                        </div>
                      </div>
                    </div>
                    <div class="col-hold" :class="{ validation: true, error: shouldShowErrorMessages && !screenshot }">
                      <input v-model="screenshot" :placeholder="htrLanguage.writeReview.please_screenshot || 'please_screenshot'" class="form-control width38percent" readonly>
                      <div class="input-file">
                        <file-uploader v-model="screenshot" class="btn-upload" not-images-only="true">
                          <span class="upload-btn" />
                        </file-uploader>
                        <input v-model="screenshot" type="hidden" name="screenshot">
                      </div>
                      <span class="alert-email paddingLeft0px marginLeft45px"><i class="icon-notification" />
                        {{ htrLanguage.writeReview.username_view || 'username_view' }}
                      </span>
                      <span class="alert-email underline">
                        <a class="grayInA" href="https://help.hoteltechreport.com/en/articles/4346791-additional-hotelier-verification-options" target="_blank">
                          {{ htrLanguage.writeReview.see_example || 'see_example' }}
                        </a>
                      </span>
                      <br>
                    </div>
                  </div>
                </div>
              </div>
              <div v-if="step1completed && step2completed" class="paddingContainer">
                <div class="col-hold">
                  <div :class="{ row: true, validation: true, success: (is_not_anonymous || hide_identity), error: (shouldShowErrorMessages && !is_not_anonymous && !hide_identity) || !emailValid}">
                    <div class="title-pannel">
                      <label class="aditionalTitle">
                        {{ htrLanguage.writeReview.privacy_preferences || 'privacy_preferences' }}
                        <span class="colorRed">*</span>
                      </label>
                    </div>
                  </div>
                </div>
                <div class="displayBlock">
                  <div class="about-you paddingBottom5px">
                    <div class="about-you__corporate backgroundWhite">
                      <label class="displayFlex">
                        <input v-model="hide_identity" name="hide_identity" type="checkbox">
                        <span class="fake-checkbox" />
                        <span class="txt">{{ htrLanguage.writeReview.anonymous_reviews || 'anonymous_reviews' }}</span>
                      </label>
                    </div>
                    <div class="about-you__corporate backgroundWhite">
                      <label class="displayFlex">
                        <input v-model="is_not_anonymous" name="is_not_anonymous" type="checkbox">
                        <span class="fake-checkbox" />
                        <span class="txt">{{ (htrLanguage.writeReview.allow_to_followup || 'allow_to_followup').replace(':company_name', companyName) }}</span>
                      </label>
                    </div>
                  </div>
                </div>
              </div>
              <p v-if="shouldShowErrorMessages" class="error-alert">
                {{ errorMessageEmail }}
              </p>
            </div>
            <div>
              <div v-if="step1completed && step2completed">
                <div>
                  <div class="btn-hold text-center">
                    <input :disabled="disableButton" type="submit" :value="htrLanguage.writeReview.submit_your_review || 'submit_your_review'" class="btn btn-green btn-large !bg-[#4c5ecf]"
                           @click="turnOffWindowWarningAndSubmit">
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>
<style scoped>
.areYouDescription {
  font-size: 12px;
  color: #38427E;
  letter-spacing: 0;
  line-height: 13px;
  max-width: 446px;
  margin-bottom: inherit;
}
.areYouTitle {
  font-size: 14px;
  color: #38427E;
  letter-spacing: 0;
  font-weight: 600;
  margin-bottom: inherit;
  text-transform: uppercase;
}
.backgroundAreYouAHotelier {
  padding: 8px 16px 14px 12px;
  margin-bottom: 36px;
  background: #F0F2FF;
}
.buttonLeaveReview {
  margin: 0 0 0 9px;
  padding: 10px 8px 10px 8px;
  min-width: 230px;
}
.success .title-pannel {
  color: #8bc145;
}
@media screen and (max-width: 769px) {
  .buttonLeaveReview {
    margin: inherit;
  }
}
.flexBox {
  display: flex;
  align-items: center;
}
.is-generic-email {
  background-color: #EFF2FF;
  color: #3445C2;
  font-size: 14px;
  margin-bottom: 10px;
}
.is-generic-email span {
  font-weight: 400;
  margin: 7px 9px 8px 10px;
}
.is-personal-email {
  background-color: #EFF2FF;
  color: #3445C2;
  font-size: 14px;
  margin-bottom: 10px;
}
.is-personal-email span {
  font-weight: 400;
  margin: 7px 9px 8px 10px;
}

.aditionalTitle {
  color: BLACK !important;
  font-weight: 600;
  font-size: 14px !important;
  margin-bottom: 5px;
}
.colorWhite {
  color: white !important;
}
.backgroundWhite {
  background-color: white !important;
}
.colorRed {
  color: red;
}
.content-area {
  border-top: none;
}
.displayBlock {
  display: block !important;
}
.displayFlex {
  display: flex !important
}
.displayNone {
  display: none;
}
.grayInA {
  color: #929292 !important;
}
.labelProducts {
  padding: 2.5px !important;
}
.liProducts {
  width: inherit !important;
}
.checkbox input:checked ~ .txt {
  font-weight: inherit !important;
}
.categoryKeywords {
  width: 315px;
  background-color: #fbfbfb;
  padding: 25px 30px 10px 10px;
  margin-right: -40px;
}
.marginBottom30px {
  margin-bottom: 30px;
}
.marginLeft5px {
  margin-left: 5px;
}
.marginLeft45px {
  margin-left: 45px;
}
.marginLeft55px {
  margin-left: 55px;
}
.marginTop20px {
  margin-top: 20px;
}
.marginContainerReview {
  margin-top: 40px;
  width: 778px;
}
.paddingBottom5px {
  padding-bottom: 25px !important;
}
.paddingLeft0px {
  padding-left: 0px !important;
}
.paddingContainer {
  padding: 0px 42px !important;
}
.productButtons {
  padding: 6px;
  line-height: 20px;
  border: solid 1px #DDDDDD;
  min-width: 100px;
  text-align: center;
  background-color: white !important;
  border-radius: 15px;
  cursor: pointer;
  color: #898989 !important;
}
.productButtons:hover {
  border: 2px solid #4C5ECF;
  color: #4C5ECF !important;
}
.productButtonSelected {
  padding: 6px 10px 6px 10px;
  min-width: 100px;
  text-align: center;
  border: solid 1px #DDDDDD;
  background-color: #4C5ECF;
  border-radius: 15px;
  cursor: pointer;
  line-height: 20px;
  color: white !important;
}
.titleReview {
  font-size: 24px;
  line-height: 28px;
  font-weight: 600;
  color: #474747;
}
.product-checklist {
  background-color: #f8f8ff;
  border-top: none;
}
.productName {
  color: inherit !important;
  font-size: 14px !important;
}
.setLtrDirection {
  direction: ltr;
}
.upload-btn {
  transition: background 0.3s linear;
  position: absolute;
  width: 30px;
  height: 31px;
  background: #47486e;
  border-radius: 0 3px 3px 0;
}
.upload-btn:hover {
  background: darken(#47486e, 10%);
}

.upload-btn:before {
  content: "\e96a";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font: 22px/1 "icomoon";
  color: white;
}
.underline {
  text-decoration: underline;
}
.width38percent {
  width: 39% !important;
}
.width42percent {
  width: 42% !important;
}
.width80percent {
  width: 80% !important;
}
.review-guidelines{
  text-align: center;
  color: #818181;
  text-decoration: underline;
  margin-bottom: 20px;
}
div.note {
  font-style: italic;
}
.linkPartnerRecommendations {
  color: #818181;
  text-decoration: none;
}
</style>
<script>
import UserFieldInput from './UserFieldInput.vue'
import ReviewSidebar from './ReviewSidebar.vue'
import CategoriesKeywords from './CategoriesKeywords.vue'
import TopNavigationSticky from './TopNavigationSticky.vue'
import { mapActions, mapGetters } from 'vuex'
import { each } from 'lodash'
import checkEmail from '../../../mixins/checkEmail.js'

export default {

  components: { UserFieldInput, ReviewSidebar, CategoriesKeywords, TopNavigationSticky },

  filters: {
    productTitle (value) {
      return value.substring(value.indexOf('(') + 1, value.indexOf(')'))
    }
  },

  mixins: [
    checkEmail
  ],
  props: ['companyName', 'companyId', 'companyLogo', 'redirectUrl', 'presetCategories', 'companySlug'],
  data () {
    return {
      disableButton: false,
      selectedProducts: [],
      is_not_anonymous: true,
      hide_identity: true,
      selectedSubcategories: [],
      is_anonymous: false,
      experienceHtml: null,
      experienceField: null,
      likelyToRecommend: null,
      likeMostHtml: null,
      likeMostField: null,
      likeLeastHtml: null,
      likeLeastField: null,
      shouldShowErrorMessages: false,
      potentialRatings: [10, 9, 8, 7, 6, 5, 4, 3, 2, 1, 0],
      requiredFields: ['likelyToRecommend', 'experience', 'likeMost', 'likeLeast'],
      moreThanFortyWords: {
        likeMost: false,
        likeLeast: false
      },
      errorMessage: null,
      errorMessageEmail: null,
      auth: { first_name: null, last_name: null, email: null, title: null },
      initialized: false,
      isTitleReviewEdit: false,
      enteredEmail: false,
      isEmailWorkHotelCompanyYes: false,
      isEmailWorkHotelCompanyNo: false,
      easyOptionLinkedin: false,
      easyOptionInvoice: false,
      easyOptionScreenshot: false,
      emailValid: true,
      invoice: '',
      screenshot: '',
      linkedin: '',
      interval: []
    }
  },

  computed: {
    ...mapGetters(['productsByCompanyId', 'categoryById', 'productById']),
    isRtlDirection () {
      return document &&
        document.body &&
        document.body.style &&
        document.body.style.direction &&
        document.body.style.direction === 'rtl'
    },
    isGenericEmail () {
      const genericEmails =
        ['commercial@', 'hotel@', 'accounting@', 'vacation@', 'bookings@', 'booking@', 'fom@', 'FOM@', 'management@', 'reception@', 'Fom@', 'Stay@', 'stay@', 'reservas@', 'manager@', 'contact@', 'it@', 'operations@', 'reservation@', 'fdmanager@', 'revenue@', 'hello@', 'Hello@', 'marketing@', 'mail@', 'sales@', 'reservations@', 'Reservations@', 'admin@', 'office@', 'gm@', 'Gm@', 'welcome@', 'info@', 'Info@']
      let isGeneric = false
      if (this.auth && this.auth.email && this.auth.email.length > 0) {
        genericEmails.every(item => {
          if (this.auth.email.includes(item)) {
            isGeneric = true
            return false
          }
          return true
        })
      }
      return isGeneric
    },
    isPersonalEmail () {
      const EMAIL_REGEX = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      const BUSINESS_EMAIL_REGEX = /^((([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))+@(?!qq.com)(?!yahoo.com)(?!gmail.com)(?!ymail.com)(?!hotmail.com)(?!yahoo.co.uk)(?!yahoo.fr)(?!yahoo.co.in)(?!aol.com)(?!abc.com)(?!xyz.com)(?!pqr.com)(?!rediffmail.com)(?!live.com)(?!outlook.com)(?!me.com)(?!msn.com)([\w-]+\.)+[\w-]{2,6})?$/

      return this.auth.email && this.auth.email.length > 0 && EMAIL_REGEX.test(this.auth.email) && !BUSINESS_EMAIL_REGEX.test(this.auth.email)
    },
    htrLanguage () {
      if (window && window.i18n) {
        const { writeReview, companyCategory, quiz, company } = window.i18n
        if (writeReview && companyCategory && quiz && company) {
          return { writeReview, companyCategory, quiz, company }
        }
      }
      return { writeReview: {}, companyCategory: {}, quiz: {}, company: {} }
    },
    likeMost () {
      if (!this.likeMostHtml) {
        return ''
      }
      return this.likeMostHtml.split('&nbsp;').join(' ').replace(/<[^>]*>?/gm, '').trim()
    },
    likeLeast () {
      if (!this.likeLeastHtml) {
        return ''
      }
      return this.likeLeastHtml.split('&nbsp;').join(' ').replace(/<[^>]*>?/gm, '').trim()
    },
    experience () {
      if (!this.experienceHtml) {
        return ''
      }
      return this.experienceHtml.split('&nbsp;').join(' ').replace(/<[^>]*>?/gm, '').trim()
    },
    step1completed () {
      if (this.likelyToRecommend || this.likelyToRecommend === 0) {
        return true
      }
      return false
    },
    step2completed () {
      if (this.selectedProducts && this.selectedProducts.length > 0) {
        return true
      }
      return false
    },
    idLanguageLocale () {
      if (window.Laravel.languageLocale) {
        return window.Laravel.languageLocale.id
      }
      return undefined
    },
    products () {
      return this.productsByCompanyId(+this.companyId)
    },
    subcategories () {
      let subCategories = {}
      if (this.products && this.products.length > 0) {
        this.products.forEach((product) => {
          if (!subCategories[product.category_id]) {
            subCategories[product.category_id] = {
              id: product.category_id,
              products: [product.id]
            }
          } else {
            subCategories[product.category_id].products.push(product.id)
          }
        })
        subCategories = Object.values(subCategories).map((sub) => {
          let subWithProducts = this.categoryById(Number(sub.id))
          subWithProducts.products = sub.products
          return subWithProducts
        })
      } else {
        subCategories = []
      }
      return subCategories
    },
    keywords () {
      if (!this.subcategories) {
        return []
      } else {
        let keywordsCompletedList = []
        this.subcategories.forEach((subcat) => {
          if (this.selectedSubcategories.find((subSelected) => subSelected === subcat.id)) {
            if (subcat.keywords) {
              JSON.parse(subcat.keywords).forEach((word) => {
                keywordsCompletedList.push(word.toLowerCase())
              })
            }
          }
        })
        return keywordsCompletedList
      }
    },
    authenticated () {
      return +window.Laravel.userId > 0
    },
    formAction () {
      let uuid = window.location.pathname.split('/write-a-review')[1].split('/')[2]
      if (uuid) {
        return `/review/step-2/${uuid}`
      }
      return '/review/step-2'
    },
    filledEasyOptions () {
      if (this.easyOptionLinkedin || this.easyOptionInvoice || this.easyOptionScreenshot) {
        return true
      }
      return false
    },
    emailUser () {
      return this.auth.email
    },
    countWords () {
      return (this.likeMost ? this.likeMost.split(' ').filter(word => word !== '').length : 0) + (this.likeLeast ? this.likeLeast.split(' ').filter(word => word !== '').length : 0)
    },
    categoriesSelected () {
      let categories = []
      if (this.selectedProducts && this.selectedProducts.length > 0) {
        for (let i = 0; i < this.selectedProducts.length; i += 1) {
          const product = this.productById(this.selectedProducts[i])
          if (product) {
            if (!categories.some(cat => cat.id === product.category_id)) {
              let category = this.categoryById(product.category_id)
              categories.push({
                id: product.category_id,
                name: this.translate(category, 'name'),
                keywords: category.keywords
              })
              if (categories.length > 3) {
                categories = categories.slice(1, 4)
              }
            }
          }
        }
      }
      return categories
    },
    percentedAvancedStepTwo () {
      let percent = 0
      const sum = 5
      if (this.likelyToRecommend && this.likelyToRecommend > 0) {
        percent += sum
      }
      if (this.selectedProducts && this.selectedProducts.length > 0) {
        percent += sum
      }
      if (this.experience) {
        percent += sum
      }
      if (this.moreThanFortyWords) {
        if (this.moreThanFortyWords.likeLeast) {
          percent += sum
        }
        if (this.moreThanFortyWords.likeMost) {
          percent += sum
        }
      }

      if (this.auth) {
        if (this.auth.first_name) {
          percent += sum
        }
        if (this.auth.last_name) {
          percent += sum
        }
        if (this.auth.email) {
          percent += sum
        }
        if (this.auth.title) {
          percent += sum
        }
      }

      if (this.authenticated) {
        percent += sum
      } else {
        if (this.isEmailWorkHotelCompanyYes) {
          percent += sum
        } else if (this.isEmailWorkHotelCompanyNo) {
          if (this.easyOptionLinkedin || this.easyOptionInvoice || this.easyOptionScreenshot) {
            percent += sum / 2
          }
          if (this.linkedin || this.invoice) {
            percent += sum / 2
          }
        }
      }
      return percent
    }
  },

  watch: {
    experience () {
      if (this.experience) {
        this.experienceField = this.experience.trim()
      }
    },
    likeMost () {
      if (this.likeMost) {
        this.likeMostField = this.likeMost.trim()
      }
    },
    likeLeast () {
      if (this.likeLeast) {
        this.likeLeastField = this.likeLeast.trim()
      }
      this.persistInStorage()
    },
    subcategories () {
      this.selectedSubcategories = JSON.parse(JSON.stringify(this.selectedSubcategories))
    },
    selectedSubcategories (newVal, oldVal) {
      this.selectedProducts = []
      if (this.selectedSubcategories && this.selectedSubcategories.length === 4) {
        this.selectedSubcategories = this.selectedSubcategories.slice(1, 4)
      }

      if (this.selectedSubcategories && this.selectedSubcategories.length === 1 && newVal.length === 1 && oldVal.length === 0) {
        this.createInterval()
      }
      this.subcategories.forEach((sub) => {
        if (this.selectedSubcategories.find((subSelected) => subSelected === sub.id)) {
          sub.products.forEach((prod) => this.selectedProducts.push(prod))
        }
      })
      this.persistInStorage()
    },
    selectedProducts () {
      this.persistInStorage()
    },
    emailUser () {
      this.enteredEmail = true
    },
    isEmailWorkHotelCompanyYes () {
      this.persistInStorage()
    },
    isEmailWorkHotelCompanyNo () {
      this.persistInStorage()
    },
    easyOptionLinkedin () {
      this.persistInStorage()
    },
    easyOptionInvoice () {
      this.persistInStorage()
    },
    easyOptionScreenshot () {
      this.persistInStorage()
    },
    invoice () {
      this.persistInStorage()
    },
    screenshot () {
      this.persistInStorage()
    },
    linkedin () {
      this.persistInStorage()
    },
    likeMostHtml () {
      this.persistInStorage()
    },
    likeLeastHtml () {
      this.persistInStorage()
    },
    experienceHtml () {
      this.persistInStorage()
    },
    'auth.first_name' () {
      this.persistInStorage()
    },
    'auth.last_name' () {
      this.persistInStorage()
    },
    'auth.email' () {
      this.persistInStorage()
    },
    'auth.title' () {
      this.persistInStorage()
    },
    'auth.is_corporate' () {
      this.persistInStorage()
    },
    likelyToRecommend () {
      this.persistInStorage()
    }
  },
  mounted () {
    this.createInterval()
  },
  created () {
    let i
    let id
    let restoreSubcategories = true
    this.selectedSubcategories = this.presetCategories || []
    if (this.selectedSubcategories.length > 0) {
      restoreSubcategories = false
    }
    this.restoreFromStorage(restoreSubcategories)
    this.fetchProductsByCompanyId(+this.companyId).then(res => {
      this.initialized = true
    })
  },
  methods: {
    ...mapActions(['fetchProductsByCompanyId']),
    translate (entry, field) {
      let translation = entry[field]
      if (entry.translations && entry.translations.length > 0) {
        const searchTranslation = entry.translations.find((findTranslate) => findTranslate.language_id === this.idLanguageLocale)
        if (searchTranslation) {
          translation = searchTranslation[field]
        }
      }
      return translation
    },
    restoreFromStorage (restoreSubcategories) {
      let cacheDate = new Date().getTime()
      if (window.localStorage.getItem('review.cacheDate')) {
        cacheDate = JSON.parse(window.localStorage.getItem('review.cacheDate'))
      }
      const currentDate = new Date().getTime()
      if (currentDate - cacheDate >= 7200000) {
        return
      }
      let fieldsArray = ['likelyToRecommend', 'experienceHtml', 'likeLeastHtml', 'likeMostHtml', 'isEmailWorkHotelCompanyYes', 'isEmailWorkHotelCompanyNo', 'easyOptionLinkedin', 'easyOptionInvoice', 'easyOptionScreenshot', 'invoice', 'screenshot', 'linkedin']
      if (!this.authenticated) {
        fieldsArray.push('auth')
      } else {
        this.updateAuthDataFromWindow()
      }

      if (restoreSubcategories) {
        fieldsArray.push('selectedSubcategories')
      }
      each(fieldsArray, (key) => {
        if (window.localStorage.getItem(`review.${key}`)) {
          this[key] = JSON.parse(window.localStorage.getItem(`review.${key}`)) || null
        }
      })
    },
    titleUpdate (data) {
      if (data.value.length > 0) this.$refs.titleOfYourReview.setValue(data.value.substring(0, 75))
      this.updateValue(data)
    },
    persistInStorage () {
      if (!this.initialized) return false
      this.cacheDate = new Date().getTime()
      let fieldsArray = ['likelyToRecommend', 'experienceHtml', 'likeLeastHtml', 'likeMostHtml', 'selectedSubcategories', 'cacheDate', 'isEmailWorkHotelCompanyYes', 'isEmailWorkHotelCompanyNo', 'easyOptionLinkedin', 'easyOptionInvoice', 'easyOptionScreenshot', 'invoice', 'screenshot', 'linkedin']
      if (!this.authenticated) {
        fieldsArray.push('auth')
      }
      each(fieldsArray, (key) => {
        window.localStorage.setItem(`review.${key}`, JSON.stringify(this[key]))
      })
    },
    getUrlVar (name) {
      return this.getUrlVars()[name]
    },
    getUrlVars () {
      var vars = []
      var hash
      var hashes = window.location.href.slice(window.location.href.indexOf('?') + 1).split('&')
      for (var i = 0; i < hashes.length; i++) {
        hash = hashes[i].split('=')
        var paramName = hash[0]
        var paramValue = hash[1]

        if (paramName.indexOf('[]') !== -1) {
          // if multiple params with the same name, create an array
          if (typeof vars[paramName] !== 'object') {
            vars[paramName] = []
          }
          vars[paramName].push(paramValue)
        } else {
          vars.push(paramName)
          vars[paramName] = paramValue
        }
      }
      return vars
    },
    updateValue (data) {
      this[data.name] = data.value
      if (this.hasFilledAllRequiredFields()) {
        this.shouldShowErrorMessages = false
      }
    },
    hasFilledAllRequiredFields () {
      for (let index = 0; index < this.requiredFields.length; index++) {
        if (this[this.requiredFields[index]] === null || this[this.requiredFields[index]] === '') {
          this.errorMessage = this.htrLanguage.writeReview.please_fill_out_fields || 'please_fill_out_fields'
          return false
        }
      }
      for (let key in this.moreThanFortyWords) {
        if (this.moreThanFortyWords[key] === false) {
          this.errorMessage = this.htrLanguage.writeReview.please_filled_minium_length || 'please_filled_minium_length'
          return false
        }
      }

      if (!this.selectedProducts.length) {
        this.errorMessage = this.htrLanguage.writeReview.please_one_product || 'please_one_product'
        return false
      }

      if (!this.auth.email || !this.auth.first_name || !this.auth.last_name || !this.auth.title) {
        this.errorMessage = this.htrLanguage.writeReview.please_fill_user_details || 'please_fill_user_details'
        return false
      }

      if (!this.authenticated) {
        if (document.getElementById('workEmail') && !document.getElementById('workEmail').checkValidity()) {
          this.errorMessage = this.htrLanguage.writeReview.please_valid_email || 'please_valid_email'
          this.emailValid = false
          return false
        } else {
          this.emailValid = true
        }
        if (!this.isEmailWorkHotelCompanyYes && !this.isEmailWorkHotelCompanyNo) {
          this.errorMessage = this.htrLanguage.writeReview.please_email_type || 'please_email_type'
          return false
        }

        if (this.isEmailWorkHotelCompanyNo) {
          if (!this.easyOptionLinkedin && !this.easyOptionInvoice && !this.easyOptionScreenshot) {
            this.errorMessage = this.htrLanguage.writeReview.please_check_verify_option || 'please_check_verify_option'
            return false
          }
          if (this.easyOptionLinkedin && !this.linkedin) {
            this.errorMessage = this.htrLanguage.writeReview.please_linkedin_profile || 'please_linkedin_profile'
            return false
          }

          if (this.easyOptionInvoice && !this.invoice) {
            this.errorMessage = this.htrLanguage.writeReview.please_recent_invoice || 'please_recent_invoice'
            return false
          }

          if (this.easyOptionScreenshot && !this.screenshot) {
            this.errorMessage = this.htrLanguage.writeReview.please_screenshot || 'please_screenshot'
            return false
          }
        }
      }
      return true
    },
    showTooltip () {
      $(this.$el).find('.alert-popup').fadeIn(200)
    },
    hideTooltip () {
      $(this.$el).find('.alert-popup').fadeOut(200)
    },
    turnOffWindowWarningAndSubmit (event) {
      this.disableButton = true
      this.shouldShowErrorMessages = false
      if (!this.hasFilledAllRequiredFields()) {
        this.disableButton = false
        this.shouldShowErrorMessages = true
        this.scrollTo('.product')
        event.preventDefault()
      } else {
        event.target.form.submit();
      }
    },
    scrollTo (element) {
      let tag = $(element)
      $('html,body').animate({ scrollTop: tag.offset().top }, 'slow')
    },
    checkEmailHotelYes (value) {
      if (value) {
        this.auth.isEmailWorkHotelCompany = true
        this.isEmailWorkHotelCompanyNo = false
      }
      this.auth.isEmailWorkHotelCompany = false
    },
    checkEmailHotelNo (value) {
      if (value) {
        this.auth.isEmailWorkHotelCompany = false
        this.isEmailWorkHotelCompanyYes = false
      }
    },
    numberOfWords (fieldName) {
      if (this[fieldName]) return this[fieldName].split(' ').filter(word => word !== '').length
      return 0
    },
    isLessThan20Words (fieldName) {
      if ((this.numberOfWords(fieldName) < 20 && this.numberOfWords(fieldName) > 0) || !this[fieldName]) {
        this.moreThanFortyWords[fieldName] = false
        return true
      }
      this.moreThanFortyWords[fieldName] = true
      return false
    },
    shouldShowSuccess (fieldName) {
      return !this.isLessThan20Words(fieldName) && this[fieldName]
    },
    shouldShowError (fieldName) {
      return this.shouldShowSuccess(fieldName)
        ? false
        : this.shouldShowErrorMessages
    },
    updateMinimumLengthWordsValidation (data) {
      this.moreThanFortyWords[data.name] = data.moreThanFortyWords
    },
    updateRecommendedRating (value) {
      this.likelyToRecommend = value
    },
    saveCaretPosition (context) {
      var selection = window.getSelection()
      var range = selection.getRangeAt(0)
      range.setStart(context, 0)
      var len = range.toString().length
      return { len, selection }
    },
    getTextNodeAtPosition (root, index) {
      const NODE_TYPE = NodeFilter.SHOW_TEXT
      var treeWalker = document.createTreeWalker(root, NODE_TYPE, function next (elem) {
        if (index > elem.textContent.length) {
          index -= elem.textContent.length
          return NodeFilter.FILTER_REJECT
        }
        return NodeFilter.FILTER_ACCEPT
      })
      var c = treeWalker.nextNode()
      return {
        node: c || root,
        position: index
      }
    },
    restore (context, len, selection) {
      var pos = this.getTextNodeAtPosition(context, len)
      selection.removeAllRanges()
      var range = new Range()
      if (!pos.node || (!pos.node.nodeValue)) {
        var child = context.children
        range = document.createRange()
        var sel = window.getSelection()
        range.setStart(child[child.length - 1], 1)
        range.collapse(true)
        sel.removeAllRanges()
        sel.addRange(range)
        return context.focus()
      }
      range.setStart(pos.node, pos.position)
      selection.addRange(range)

      selection.removeAllRanges()
      selection.addRange(range)
      context.focus()
    },
    setNewHtmlWhenPressingSpace (textInInput, keywords) {
      var newHTML = ''
      // Loop through words
      textInInput.replace(/[\s]+/g, ' ').trim().split(' ').forEach(function (val) {
        // If word is statement
        if (keywords.indexOf(val.trim().replace(/[.,?"\/#!$%\^&\*;:{}=\-_`~()]/g, '').toLowerCase()) > -1 || val.includes('999999')) { // replace punctuation marks and concatenated keywords
          if (val.includes('999999')) {
            let wordArray = val.replace(/-/g, ' ')
            wordArray = wordArray.split('999999')
            val = wordArray.splice(1, wordArray.length).join(' ')
          }
          newHTML += "<span class='colorGreen'>" + val + '&nbsp;</span>' // add the space at the end of each word
        } else {
          newHTML += "<span class='other'>" + val + '&nbsp;</span>'
        }
      })
      return newHTML
    },
    setNewHtmlWhenPressingAkeyButSpace (element, keywords) {
      let newHTML = ''
      element.textContent.replace(/[\s]+/g, ' ').trim().split(' ').forEach(function (val, index) {
        if (index === element.textContent.replace(/[\s]+/g, ' ').trim().split(' ').length) {
          if (keywords.indexOf(val.trim().replace(/[.,?\/#!$%\^&\*;:{}=\-_`~()]/g, '').toLowerCase()) > -1) { // if its the last word, do not put an space
            newHTML += "<span class='colorGreen'>" + val + '</span>'
          } else {
            newHTML += "<span class='other'>" + val + '</span>'
          }
        } else {
          if (keywords.indexOf(val.trim().replace(/[.,?\/#!$%\^&\*;:{}=\-_`~()]/g, '').toLowerCase()) > -1) {
            newHTML += "<span class='colorGreen'>" + val + '&nbsp;</span>'
          } else {
            newHTML += "<span class='other'>" + val + '&nbsp;</span>'
          }
        }
      })
      return newHTML
    },
    replaceDoubleWords (text, word) {
      return text.replace(new RegExp(String.fromCharCode(160), 'g'), ' ').replace(new RegExp('\\b' + word + '\\b', 'g'), `999999${word.replace(' ', '-')}`) // remove $nbsp added and match entire keyword
    },
    listenLikeMostEditable (event) {
      var element = document.getElementById('likeMostFieldId')
      var keywords = this.keywords
      if (event.keyCode == 32 || event.keyCode == 8 || event.keyCode == 37 || event.keyCode == 38 || event.keyCode == 39 || event.keyCode == 40) { // key 32 is space pressed
        let textInInput = element.textContent
        keywords.forEach((keyword) => {
          if (keyword.includes(' ')) {
            textInInput = this.replaceDoubleWords(textInInput, keyword)
          }
        })
        const newText = this.setNewHtmlWhenPressingSpace(textInInput, keywords)
        const { len, selection } = this.saveCaretPosition(element)
        element.innerHTML = (newText)
        this.likeMostHtml = newText
        this.restore(element, len, selection)
      } else {
        var newHTML = this.setNewHtmlWhenPressingAkeyButSpace(element, keywords)
        this.likeMostHtml = newHTML
      }
    },
    listenLikeLeastEditable (event) {
      var element = document.getElementById('likeLeastFieldId')
      var keywords = this.keywords
      if (event.keyCode == 32 || event.keyCode == 8 || event.keyCode == 37 || event.keyCode == 38 || event.keyCode == 39 || event.keyCode == 40) { // key 32 is space pressed
        let textInInput = element.textContent
        keywords.forEach((keyword) => {
          if (keyword.includes(' ')) {
            textInInput = this.replaceDoubleWords(textInInput, keyword)
          }
        })
        const newText = this.setNewHtmlWhenPressingSpace(textInInput, keywords)
        const { len, selection } = this.saveCaretPosition(element)
        element.innerHTML = (newText)
        this.likeLeastHtml = newText
        this.restore(element, len, selection)
      } else {
        var newHTML = this.setNewHtmlWhenPressingAkeyButSpace(element, keywords)
        this.likeLeastHtml = newHTML
      }
    },
    listenExperienceEditable (event) {
      var element = document.getElementById('experienceFieldId')
      var keywords = this.keywords
      if (event.keyCode == 32 || event.keyCode == 8 || event.keyCode == 37 || event.keyCode == 38 || event.keyCode == 39 || event.keyCode == 40) { // key 32 is space pressed
        let textInInput = element.textContent
        keywords.forEach((keyword) => {
          if (keyword.includes(' ')) {
            textInInput = this.replaceDoubleWords(textInInput, keyword)
          }
        })
        const { len, selection } = this.saveCaretPosition(element)
        const newText = this.setNewHtmlWhenPressingSpace(textInInput, keywords)
        element.innerHTML = (newText)
        this.experienceHtml = newText
        this.restore(element, len, selection)
      } else {
        var newHTML = this.setNewHtmlWhenPressingAkeyButSpace(element, keywords)
        this.experienceHtml = newHTML
      }
    },
    pasteListener (event) {
      const elementId = event.currentTarget.getAttribute('id')
      var keywords = this.keywords
      var pastedText = event.clipboardData.getData('text/plain')
      var element = document.getElementById(elementId)
      var storedText = element.textContent
      var text = storedText + pastedText

      text = text.replace(/[\s]+/g, ' ').trim()
      text = text + ' '
      keywords.forEach((keyword) => {
        if (keyword.includes(' ')) {
          pastedText = this.replaceDoubleWords(text, keyword)
        }
      })

      const newText = this.setNewHtmlWhenPressingSpace(text, keywords)
      const { len, selection } = this.saveCaretPosition(element)

      element.innerHTML = (newText)

      switch(elementId) {
        case 'likeLeastFieldId':
          this.likeLeastHtml = newText
          break;
        case 'likeMostFieldId':
          this.likeMostHtml = newText
          break;
        case 'experienceFieldId':
          this.experienceHtml = newText
          break;
      }
      this.restore(element, text.length, selection)
    },
    stopPolling () {
      this.interval.forEach((polling) => clearInterval(polling))
      this.interval = []
    },
    getClass (field) {
      let cssClasses = 'likeLeastEditable editorWithKeywords'
      if (this.moreThanFortyWords[field]) {
        cssClasses += ' borderSuccess'
      }
      if (!this.moreThanFortyWords[field] && this.shouldShowError(field)) {
        cssClasses += ' borderError'
      }
      return cssClasses
    },
    getClassExperience () {
      let cssClasses = 'likeLeastEditable editorWithKeywords experienceInput'
      if (this.experience) {
        cssClasses += ' borderSuccess'
      }
      if (!this.experience && this.shouldShowErrorMessages) {
        cssClasses += ' borderError'
      }
      return cssClasses
    },
    createInterval () { // This is a polling mechanism to manage when new div editable are loaded assign the cache/old info
      if (this.experienceHtml || this.likeLeastHtml || this.likeMostHtml) {
        this.interval.push(setInterval(() => {
          let domFound = document.getElementById('experienceFieldId')
          if (this.experienceHtml && domFound) {
            domFound.innerHTML = (this.experienceHtml)
            this.stopPolling()
          }
          domFound = document.getElementById('likeLeastFieldId')
          if (this.likeLeastHtml && domFound) {
            domFound.innerHTML = (this.likeLeastHtml)
            this.stopPolling()
          }
          domFound = document.getElementById('likeMostFieldId')
          if (this.likeMostHtml && domFound) {
            domFound.innerHTML = (this.likeMostHtml)
            this.stopPolling()
          }
        }, 1000))
      }
    },
    updateAuthDataFromWindow () {
      let haveDataOnWindow = false
      if (!this.authenticated) {
        return haveDataOnWindow
      }
      if (!window.Laravel.hasOwnProperty('user')) {
        return haveDataOnWindow
      }
      if (window.Laravel.user.email) {
        haveDataOnWindow = true
        this.auth.email = window.Laravel.user.email
      }
      if (window.Laravel.user.title) {
        haveDataOnWindow = true
        this.auth.title = window.Laravel.user.title
      }
      if (window.Laravel.user.first_name) {
        haveDataOnWindow = true
        this.auth.first_name = window.Laravel.user.first_name
      }
      if (window.Laravel.user.last_name) {
        haveDataOnWindow = true
        this.auth.last_name = window.Laravel.user.last_name
      }

      return haveDataOnWindow
    }
  }
}
</script>
