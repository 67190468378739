<template>
  <div class="modal-lead__body">
    <div class="modal-lead__buyers-guide">
      <img src="/images/lamp.png">
      <p>
        <span>SMART BUYER TIP</span>
        To make the best decision for your properties, gather info from at least 3 similar vendors to make sure
        you find the perfect fit and get the best deal.
      </p>
    </div>
    <span v-if="isLoader" class="lds-spinner">
      <span v-for="i in 12" :key="i" />
    </span>
    <div v-for="recommendedProduct in productsWhithEnabledDirectLeads" :key="recommendedProduct.id" class="modal-lead__guide__item">
      <a :href="product.url" target="_blank">
        <img class="logo" :src="recommendedProduct.company.logo">
      </a>
      <div class="modal-lead__guide__item__content">
        <a :href="recommendedProduct.url" target="_blank">
          <span class="modal-lead__guide__item__title">{{ recommendedProduct.name }}</span>
        </a>
        <div class="profile-box-mark-stars">
          <div v-for="rating in 5" :key="rating" class="star">
            <img v-if="recommendedProduct.recommend_rank / 20 >= rating" src="/images/star-full-blue.svg">
            <img v-else-if="recommendedProduct.recommend_rank / 20 >= (rating-1) + 0.2 && recommendedProduct.recommend_rank / 20 < rating"
                 src="/images/star-half-blue.svg">
            <img v-else src="/images/star-empty-blue.svg">
          </div>
          <a class="count-reviews" :href="`${recommendedProduct.url}#section-reviews`" target="_blank">
            {{ recommendedProduct.reviews_count }} verified reviews
          </a>
        </div>
        <p class="content__bottom">
          <i class="icon-like" /> {{ recommendedProduct.recommend_rank }}% recommended by {{ hotelTypeLabel }}
        </p>
      </div>
      <div class="modal-lead__guide__add">
        <label>
          <input v-model="additionalProductsIds" type="checkbox" :value="recommendedProduct.id">
          <span class="checkbox-custom">
            <i class="icon-cross" />
          </span>
          <span class="added">
            <i class="icon-check-4" />
          </span>
          <span class="checkbox-custom__text">Add Vendor</span>
          <span class="custom__text">Added</span>
        </label>
      </div>
    </div>

    <button v-if="!company.disable_direct_leads" class="btn-skip-step" @click="$emit('set-additional-products', null)">
      Skip this step
    </button>
  </div>
</template>

<script>
import axios from 'axios'
import { EventBus } from '../../../../event-bus'


export default {
  props: [
    'company',
    'product',
    'categoryId',
    'value',
    'extraProducts',
    'isStepWaittingForAction'
  ],

  data: () => ({
    additionalProductsIds: [],
    products: [],

    hotelType: null,
    isLoader: false
  }),

  computed: {
    hotelTypeLabel () {
      if (!this.hotelType) return null

      let dictionary = this.dictionary('hotel_types')
      let node = dictionary.find(el => el.value === this.hotelType)

      return node ? node.label : null
    },
    productsWhithEnabledDirectLeads () {
      let ans = this.products.filter((product) => product.company.disable_direct_leads === 0)
      return ans

    }
  },

  watch: {
    additionalProductsIds: function (argAdditionalProductsIds)  {
      if (argAdditionalProductsIds.length > 0 && this.isStepWaittingForAction) {
        EventBus.$emit('modal_lead_step_ready_for_action')
      } else {
        EventBus.$emit('modal_lead_step_wait_for_action')
      }
    }
  },

  mounted () {
    if(this.extraProducts) this.additionalProductsIds = this.extraProducts
    this.getSimilarTopProducts()
    this.hotelType = this.value.hotel_type
    this.isLoader = true
  },

  methods: {
    nextStep () {
      this.$emit('set-additional-products', this.additionalProductsIds)
    },
    getSimilarTopProducts () {
      axios.get(`/products/${this.product.id}/similar-top-products`, {params: { ids: this.extraProducts}})
        .then(res => {
          this.products = res.data
          this.isLoader = false
        })
    },
    dictionary (key) {
      return (window.Laravel) ? window.Laravel.dictionaries[key] : []
    }
  }
}
</script>
