<template>
  <div class="displayFlex borderSolidGray backgroundWhite productInformationContainer padding10px paddingBottom15px">
    <div class="widthLogoAwards">
      <img class="height75px marginLeft10px" v-if="subcategory.product_logo" :src="subcategory.product_logo">
    </div>
    <div class="widthProductInformationAwards positionRelative lineHeigth1p5 marginTop10px marginLeft20px">
      <div class="ellipsis">
        <a :href="subcategory.product_url" target="_blank">
          <span class="productName" >
            {{ subcategory.product_name }}
          </span>
        </a>
      </div>
      <div>
        <span v-if="subcategory.product_tagline" class="productDescription" >
          {{ subcategory.product_tagline.length > 77 ? `${subcategory.product_tagline.substring(0,74)}...` : subcategory.product_tagline }}
        </span>
      </div>
      <div class="ellipsis actionsProductTopRated cursorPointer">
        <span class="bottomButtons grayAnd12pxSize" >
          <i class="fa fa-star starAndThumbsUpSize" aria-hidden="true"></i>
          <span class="bold">
            {{ subcategory.reviews_count }}
          </span>
        </span>
        <span class="bottomButtons grayAnd12pxSize marginLeft9px cursorPointer" >
          <i class="fa fa-thumbs-up starAndThumbsUpSize" aria-hidden="true"></i>
          <span class="bold">
            {{ subcategory.recommend_rank }}%
          </span>
        </span>
        <a :href="subcategory.product_url" target="_blank">
          <span class="bottomButtons grayAnd12pxSize marginLeft9px cursorPointer" >
            <img src="/images/awards/top-right-diagonal.svg" class="thirdIconSize">
          </span>
        </a>
      </div>
      <div class="firstPlaceProductTopRated cursorPointer">
        <span class="ellipsis grayAnd12pxSize firstPlaceContainerProductTopRated">
          <img src="/images/winner-badge.png" class="height20px">
          <span>
            <a :href="subcategory.product_url" class="hyperlinkColor marginLeft10px doNotDisplayOnMobile" target="_blank">
              {{ subcategory.company_name }}
            </a>
            <span class="doNotDisplayOnMobile">won</span>
            1st Place in
            <a :href="subcategory.subcategory_url" class="hyperlinkColor" target="_blank">
              {{ subcategory.name }}
            </a>
          </span>
        </span>
      </div>
    </div>
    <div class="width20percent padding1p5em doNotDisplayOnMobile">
      <div class="marginLeft30px">
        <a :href="subcategory.product_url" target="_blank">
          <button class="width126px learnMoreButton">
            Learn more
          </button>
        </a>
      </div>
      <div class="textAlignCenter marginTop5px cursorPointer marginLeft45px">
        <a :href="shareLink" target="_blank">
          <span class="grayAnd12pxSize shareColor">
            <i class="fa fa-share" aria-hidden="true"></i>
            <span class ="bold">
              Share
            </span>
          </span>
        </a>
      </div>
    </div>
  </div>
</template>

<style scoped>
  .bottomButtons {
    border: solid 1px #c3c3c3;
    border-radius: 4px;
    padding: 0.4em;
  }
  .grayAnd12pxSize {
    font-size: 12px;
    color: #949494;
  }
  .learnMoreButton {
    font-size: 12px;
    height: 41px;
    color: #fff;
    border-radius: 3px;
    border: none;
    background: #40b682;
    padding: 8px 10px;
    font-family: $base-font-family !important;
    font-weight: 400;
    border-radius: 3px;
    min-width: 126px;
    text-transform: uppercase;
  }
  .learnMoreButton:hover {
    color: #fff;
    background-color: #256b4c;
  }

  .productDescription {
    font-size: 14px;
    color: #919191;
  }
  .productName {
    font-size: 16px;
    font-weight: bold;
    color: #262626;
  }
  .shareColor {
    color: #727272 !important;
  }
  .starAndThumbsUpSize {
    width: 10px;
    height: 16px;
  }
  .thirdIconSize {
    height: 12px;
    width: 12px;
  }
</style>

<script>
import { mapGetters } from 'vuex'

export default {
  props: [
    'subcategory'
  ],
  computed: {
    ...mapGetters(['companyById']),
    shareLink () {
      let url = window.location.href
      url = url.split('#')[0]
      url = url.split('?')[0]
      return `https://www.linkedin.com/sharing/share-offsite/?url=${url}/${this.subcategory.subcategory_id}`
    },
    company () {
      return this.companyById(product.company_id)
    }
  }
}
</script>
