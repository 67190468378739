<template>
  <div>
    <div :is="steps[currentStep]" ref="activeStep" v-model="form" :company-name="companyName" :company-id="companyId"
         :company-logo="companyLogo" @update="update" @submit="submit" />
  </div>
</template>

<script>
import Step2 from './Steps/Step2.vue'
import Step3 from './Steps/Step3.vue'
import StepSuccess from './Steps/StepSuccess.vue'
import { mapActions } from 'vuex'

export default {
  components: {
    Step2,
    Step3,
    StepSuccess
  },

  props: [
    'companyName',
    'companyId',
    'companyLogo',
    'review',
    'productIds',
    'reviewRequestId'
  ],

  data: () => ({
    steps: [
      'Step2',
      'Step3',
      'StepSuccess'
    ],
    currentStep: 0,
    form: null
  }),

  created () {
    this.form = Object.assign({}, {
      text: this.review.text,
      pros: this.review.pros,
      cons: this.review.cons,
      advice: this.review.advice,
      is_anonymous: this.review.is_anonymous,
      overall: this.review.overall,
      selectedProducts: this.productIds,

      roi: this.review.roi,
      ease_of_use: this.review.ease_of_use,
      support: this.review.support,
      onboarding: this.review.onboarding,
      nps: this.review.nps,

      userData: {
        experience_group: window.userInfo.yearsExperience,
        hotel_type: window.userInfo.hotelTypes,
        hotel_chainscale: window.userInfo.chainscale,
        is_corporate: window.userInfo.is_corporate,
        title: window.userInfo.title,
        department: window.userInfo.department,
        property_type: window.userInfo.propertyType,
        company: window.userInfo.company
      }
    })
  },

  methods: {
    ...mapActions([
      'updateUserReviewById'
    ]),
    update () {
      this.nextStep()
    },
    nextStep () {
      if (this.currentStep >= this.steps.length) return false
      this.currentStep++
    },
    submit () {
      let sendForm = Object.assign({}, this.form)
      if (this.reviewRequestId && this.reviewRequestId > 0) {
        sendForm.review_request_id = this.reviewRequestId
      }
      this.updateUserReviewById({
        id: this.review.id,
        data: sendForm
      })
        .finally(() => {
          window.location.reload()
        })
    }
  }
}
</script>
