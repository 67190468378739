<template>
  <div class="row validation">
    <div class="title-pannel">
      <label class="text-big required"><strong>Select Category</strong></label>
    </div>
    <input type="text" :style="{display: 'none'}" name="childparentcategoryforvalidation" :value="childParentCategory"
           class="required">
    <div class="cols-two">
      <div class="col">
        <select name="parent" class="parent-category">
          <option value="">
            Please select...
          </option>
          <option v-for="(category, termID) in parentCategories" :key="termID" :value="termID">
            {{ category }}
          </option>
        </select>
      </div>
      <div class="col">
        <select name="child" class="child-category">
          <option value="">
            Please select...
          </option>
          <option v-for="(category, termID) in childCategories" :key="termID" :value="termID">
            {{ category }}
          </option>
        </select>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      parentCategories: window.parentCategories,
      childCategories: [],
      parentCategoryID: null,
      childCategoryID: null
    }
  },
  computed: {
    childParentCategory () {
      return this.parentCategoryID && this.childCategoryID ? 'filled' : null
    }
  },
  mounted () {
    setTimeout(() => {
      jQuery(this.$el).find('.parent-category').niceSelect('update')
      jQuery(this.$el).find('.child-category').niceSelect('update')
    }, 1)
    jQuery(this.$el).find('.parent-category').change(event => {
      this.parentCategoryID = jQuery(this.$el).find('.parent-category').val()
      this.fetchChildCategories()
      setTimeout(() => {
        jQuery('#submit-blog-post-form').valid()
      }, 1)
    })
    jQuery(this.$el).find('.child-category').change(event => {
      this.childCategoryID = jQuery(this.$el).find('.child-category').val()
      setTimeout(() => {
        jQuery('#submit-blog-post-form').valid()
      }, 1)
    })
  },
  methods: {
    fetchChildCategories () {
      this.childCategoryID = null
      $.post(`/categories/${this.parentCategoryID}/children`, {}, data => {
        this.childCategories = data
        setTimeout(() => {
          jQuery(this.$el).find('.child-category').niceSelect('update')
        }, 1)
      })
    }
  }
}
</script>
