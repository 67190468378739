
export default {
  computed: {
    htmlEntitiesList () {
      return {
        'amp': '&',
        'apos': '\'',
        '#x27': '\'',
        '#x2F': '/',
        '#39': '\'',
        '#47': '/',
        'lt': '<',
        'gt': '>',
        'nbsp': ' ',
        'quot': '"',
        'lsquo': '‘',
        'rsquo': '’',
        'sbquo': '\'',
        'ldquo': '“',
        'rdquo': '“',
        'hellip': '…',
        'Agrave': 'À',
        'Aacute': 'Á',
        'Acirc': 'Â',
        'Atilde': 'Ã',
        'Auml': 'Ä',
        'Aring': 'Å',
        'AElig': 'Æ',
        'Ccedil': 'Ç',
        'Egrave': 'È',
        'Eacute': 'É',
        'Ecirc': 'Ê',
        'Euml': 'Ë',
        'Igrave': 'Ì',
        'Iacute': 'Í',
        'Icirc': 'Î',
        'Iuml': 'Ï',
        'ETH': 'Ð',
        'Ntilde': 'Ñ',
        'Ograve': 'Ò',
        'Oacute': 'Ó',
        'Ocirc': 'Ô',
        'Otilde': 'Õ',
        'Ouml': 'Ö',
        'Oslash': 'Ø',
        'Ugrave': 'Ù',
        'Uacute': 'Ú',
        'Ucirc': 'Û',
        'Uuml': 'Ü',
        'Yacute': 'Ý',
        'THORN': 'Þ',
        'szlig': 'ß',
        'agrave': 'à',
        'aacute': 'á',
        'acirc': 'â',
        'atilde': 'ã',
        'auml': 'ä',
        'aring': 'å',
        'aelig': 'æ',
        'ccedil': 'ç',
        'egrave': 'è',
        'eacute': 'é',
        'ecirc': 'ê',
        'euml': 'ë',
        'igrave': 'ì',
        'iacute': 'í',
        'icirc': 'î',
        'iuml': 'ï',
        'eth': 'ð',
        'ntilde': 'ñ',
        'ograve': 'ò',
        'oacute': 'ó',
        'ocirc': 'ô',
        'otilde': 'õ',
        'ouml': 'ö',
        'oslash': 'ø',
        'ugrave': 'ù',
        'uacute': 'ú',
        'ucirc': 'û',
        'uuml': 'ü',
        'yacute': 'ý',
        'thorn': 'þ',
        'yuml': 'ÿ',
        'iexcl': '¡',
        'cent': '¢',
        'pound': '£',
        'curren': '¤',
        'yen': '¥',
        'brvbar': '¦',
        'sect': '§',
        'uml': '¨',
        'copy': '©',
        'ordf': 'ª',
        'laquo': '«',
        'not': '¬',
        'shy': '­',
        'reg': '®',
        'macr': '¯',
        'deg': '°',
        'plusmn': '±',
        'sup2': '²',
        'sup3': '³',
        'acute': '´',
        'micro': 'µ',
        'para': '¶',
        'cedil': '¸',
        'sup1': '¹',
        'ordm': 'º',
        'raquo': '»',
        'frac14': '¼',
        'frac12': '½',
        'frac34': '¾',
        'iquest': '¿',
        'times': '×',
        'divide': '÷',
        'forall': '∀',
        'part': '∂',
        'exist': '∃',
        'empty': '∅',
        'nabla': '∇',
        'isin': '∈',
        'notin': '∉',
        'ni': '∋',
        'prod': '∏',
        'sum': '∑',
        'minus': '−',
        'lowast': '∗',
        'radic': '√',
        'prop': '∝',
        'infin': '∞',
        'ang': '∠',
        'and': '∧',
        'or': '∨',
        'cap': '∩',
        'cup': '∪',
        'int': '∫',
        'there4': '∴',
        'sim': '∼',
        'cong': '≅',
        'asymp': '≈',
        'ne': '≠',
        'equiv': '≡',
        'le': '≤',
        'ge': '≥',
        'sub': '⊂',
        'sup': '⊃',
        'nsub': '⊄',
        'sube': '⊆',
        'supe': '⊇',
        'oplus': '⊕',
        'otimes': '⊗',
        'perp': '⊥',
        'sdot': '⋅',
        '\\n': ''
      }
    }
  },
  methods: {
    htmlEntityDecode (text) {
      let self = this
      let preprocessedText = text.split('&nbsp;').join(' ') // Hacky solution for nbsp;
      return preprocessedText.replace(/&([^;]+);/gm, function (match, entity) {
        return self.htmlEntitiesList[entity] || match
      })
    }
  }
}
