export default class NewsSidebar {
  constructor () {
    if ($('.screenshots-slider').length) {
      this.slideProduct()
      if($('.screenshots-box-side-counter').length && $('.screenshots-box-side-counter-dinamic-number').length) {
        $('.screenshots-slider').on('afterChange', function(event, slick, currentSlide) {
          let actualSlideNumber = $('.screenshots-slider').slick('slickCurrentSlide') + 1
          $('.screenshots-box-side-counter-dinamic-number').text(actualSlideNumber)
        })
      }
    }
    if ($('.compare-box__screenshots').length) this.slideCompare()
  }

  slideProduct () {
    $('.screenshots-slider').slick({
      arrows: true,
      dots: true,
      infinite: true,
      dotsClass: 'slider__dots',
      customPaging: (slick, index) => {
        const image = $(slick.$slides[index]).find('img').attr('src')
        return '<img class="screenshots-dot" src="' + image + '" alt="" /> '
      },
      prevArrow: $('.prev-img'),
      nextArrow: $('.next-img'),
    })
  }

  slideCompare () {
    for (let i = 0; i <= 3; i++) {
      let slider = $(`.compare-screenshots-slider-${i}`)
      if (slider.length) {
        slider.slick({
          arrows: true,
          dots: false,
          infinite: false,
          prevArrow: $(`.prev-img-${i}`),
          nextArrow: $(`.next-img-${i}`)
        })
      }
    }
  }

}
