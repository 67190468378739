<template>
  <span v-if="company && authenticated" class="htooltip">
    <span v-if="hasEndorsed" @click.prevent="unendorse">
      <slot name="unendorse">
        <a href="#" class="bg-grey left-hand-icon"><i class="icon-endorse" /> ENDORSED {{ count }}</a>
      </slot>
    </span>
    <span v-else @click.prevent="endorse">
      <slot name="endorse">
        <a href="#" class="left-hand-icon"><i class="icon-endorse" /> ENDORSE {{ count }}</a>
      </slot>
    </span>
    <span class="tooltiptext">
      Vendors, partners and hoteliers endorse great products they like. Endorsements are displayed in product comparisons.
    </span>
  </span>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
  props: ['companyId'],

  computed: {
    ...mapGetters(['companyById']),
    company () {
      return this.companyById(+this.companyId)
    },
    authenticated () {
      return window.Laravel.userId
    },
    hasEndorsed () {
      return this.company.endorsers && this.company.endorsers.indexOf(window.Laravel.userId) > -1
    },
    count () {
      return this.company && this.company.endorsers && this.company.endorsers.length
    }
  },

  mounted () {
    if (!this.company) {
      this.fetchCompanyById(+this.companyId)
    }
  },

  methods: {
    ...mapActions(['fetchCompanyById', 'endorseCompany', 'unendorseCompany']),
    endorse () {
      this.endorseCompany(this.companyId)
    },
    unendorse () {
      this.unendorseCompany(this.companyId)
    }
  }
}
</script>
