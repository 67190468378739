<template>
  <div>
    <form class="review-form">
      <steps-control :steps-count="2" :active-step="2" />

      <div class="company-logo">
        <img :src="companyLogo" alt="companyName">
      </div>

      <h1 class="text-center">
        Complete your review of {{ companyName }} by rating the following
      </h1>

      <div v-if="shouldShowErrorMessages" class="error-alert">
        {{ errorMessage }}
      </div>

      <div class="product-rating product-rating-review">
        <h3>Product Ratings</h3>
        <ul class="product-rating-list">
          <li
            :class="{ validation: true, success: form.ease_of_use, error: shouldShowErrorMessages && ! form.ease_of_use }">
            <star-rating v-model="form.ease_of_use" type="step-3" title="Ease of Use" input-name="ease_of_use"
                         @rated="ratingUpdated" />
          </li>
          <li :class="{ validation: true, success: form.support, error: shouldShowErrorMessages && ! form.support }">
            <star-rating v-model="form.support" type="step-3" title="Customer Support" input-name="support"
                         @rated="ratingUpdated" />
          </li>
          <li :class="{ validation: true, success: form.roi, error: false }">
            <star-rating v-model="form.roi" type="step-3" optional="true" title="Value for money/ROI" input-name="roi"
                         @rated="ratingUpdated" />
          </li>
          <li :class="{ validation: true, success: form.onboarding, error: false }">
            <star-rating v-model="form.onboarding" type="step-3" optional="true" title="Implementation/Onboarding"
                         input-name="onboarding" @rated="ratingUpdated" />
          </li>
        </ul>
        <div v-if="hasPerfectScore() && ! hasClosedPerfectScoreTooltip" class="alert-popup" style="display: block;">
          <a href="#" class="close" @click.prevent="hideTooltip"><i class="icon-cross" /></a>
          <h3>Did you mean to leave all perfect scores?</h3>
          <p>
            Nobody is perfect, even the best products have areas they can improve in. Honest ratings not only help
            hoteliers like you, but they also help vendors improve their products.
          </p>
        </div>
      </div>

      <div
        :class="{ 'recommend-block': true, validation: true, error: shouldShowErrorMessages && ! form.nps, success: form.nps }">
        <strong class="title">
          How likely is it that you would recommend {{ companyName }} to a friend or colleague?
        </strong>
        <ul class="recommend-rating">
          <li v-for="rating in potentialRatings" :key="rating" :class="{ selected: rating == form.nps }">
            <a href="#" @click.prevent="updateRecommendedRating(rating)">{{ rating }}</a>
          </li>
        </ul>
        <ul class="rating-caption">
          <li>Not Likely</li>
          <li>Neutral</li>
          <li>Likely</li>
        </ul>
      </div>
      <about-you :should-show-errors="shouldShowErrorMessages" :user-data="userData" @valueUpdates="updateAboutYou" />
      <div class="btn-hold text-center">
        <input :disabled="disableButton" type="submit" class="btn btn-green btn-large" value="Finish and submit" @click="submitReview">
      </div>
      <span class="note">
        I certify that this review is based on my own experience and is my genuine opinion of this solution vendor in accordance with the
        <a href="https://help.hoteltechreport.com/en/articles/987415-our-guidelines-for-hotelier-reviews" target="_blank" class="more">Terms of Use</a>.         I am neither an employee of this vendor or one of its direct competitors and that Hotel Tech Report has a zero tolerance policy for fake reviews
        <a href="https://help.hoteltechreport.com/en/articles/987415-our-guidelines-for-hotelier-reviews" target="_blank" class="more">Read more</a>
      </span>
    </form>
  </div>
</template>

<script>
import StepsControl from './StepsControl.vue'
import StarRating from '../StarRating.vue'
import AboutYou from '../AboutYou.vue'

export default {
  components: {
    StepsControl,
    StarRating,
    AboutYou
  },

  props: [
    'companyName',
    'reviewRequestId',
    'companyLogo',
    'value'
  ],

  data () {
    return {
      disableButton: false,
      easeOfUse: null,
      roi: null,
      perfectScoreChecks: ['ease_of_use', 'support', 'roi', 'onboarding'],
      potentialRatings: [10, 9, 8, 7, 6, 5, 4, 3, 2, 1, 0],
      shouldShowErrorMessages: false,
      requiredFields: ['nps', 'ease_of_use', 'support'],
      errorMessage: null,
      hasClosedPerfectScoreTooltip: false,
      userInfoFieldsKeys: {
        updatedYearsExperience: 'experience_group',
        updatedHotelTypes: 'hotel_type',
        updatedChainscale: 'hotel_chainscale',
        is_corporate: 'is_corporate',
        title: 'title',
        department: 'department',
        propertyType: 'property_type'
      },
      form: {}
    }
  },

  computed: {
    userData () {
      return window.userInfo
    }
  },

  watch: {
    value: {
      handler () {
        this.reset()
      },
      deep: true
    },
    form () {
      this.$emit('input', this.form)
    }
  },

  created () {
    this.reset()
  },

  methods: {
    reset () {
      if (this.value !== this.form) this.form = this.value
    },
    hasFilledAllRequiredFields () {
      for (let index = 0; index < this.requiredFields.length; index++) {
        if (this.form[this.requiredFields[index]] === null || this.form[this.requiredFields[index]] === '') {
          this.errorMessage = 'Please fill out all the required fields.'
          return false
        }
      }

      for (let key in this.moreThanHundredCharacters) {
        if (this.moreThanHundredCharacters[key] === false) {
          this.errorMessage = 'Please make sure you have filled out the minimum length of each field.'
          return false
        }
      }

      return true
    },
    updateRecommendedRating (value) {
      this.form.nps = value
    },
    submitReview (e) {
      this.shouldShowErrorMessages = false
      this.disableButton = true
      e.preventDefault()
      if (!this.hasFilledAllRequiredFields()) {
        this.disableButton = false
        this.shouldShowErrorMessages = true
        this.scrollTo('.product')
      } else {
        this.$toast.open({
          duration: 5000,
          message: 'Your review has been updated, redirecting to product profile',
          type: 'is-success'
        })
        this.$emit('submit')
      }
    },
    updateValue (data) {
      this[data.name] = data.value

      if (this.hasFilledAllRequiredFields()) {
        this.shouldShowErrorMessages = false
      }
    },
    updateMinimumLengthValidation (data) {
      this.moreThanHundredCharacters[data.name] = data.moreThanHundredCharacters
    },
    ratingUpdated (data) {
      this[data.name] = data.rating
    },
    hasPerfectScore () {
      let hasPerfectScore = true
      this.perfectScoreChecks.forEach(item => {
        if (this[item] !== '5') {
          hasPerfectScore = false
        }
      })
      return hasPerfectScore
    },
    hideTooltip (event) {
      this.hasClosedPerfectScoreTooltip = true
      $(event.target).parents('.alert-popup').fadeOut(200)
    },
    scrollTo (element) {
      let tag = $(element)
      $('html,body').animate({ scrollTop: tag.offset().top }, 'slow')
    },
    updateAboutYou (data) {
      if (data.field === 'hotel' && data.value) {
        const location = data.value

        const locationMappings = {
          locality: 'city',
          country: 'country',
          route: 'region',
          administrative_area_level_1: 'state',
          administrative_area_level_3: 'city',
          sublocality_level_2: 'city'
        }

        this.form.userData.address = location.formatted_address
        this.form.userData.city = null
        this.form.userData.country = null
        this.form.userData.country_iso_code = null
        this.form.userData.state = null
        this.form.userData.region = null
        this.form.userData.phone = location.international_phone_number || location.formatted_phone_number || null
        this.form.userData.company_website = location.website
        this.form.userData.company = location.name || null

        for (let index in location.address_components) {
          let item = location.address_components[index]

          if (locationMappings.hasOwnProperty(item.types[0])) {
            if (item.types[0] === locationMappings.country){
              this.form.userData.country_iso_code = item.short_name || null
            }
            this.form.userData[locationMappings[item.types[0]]] = item.long_name || null
          }
        }
      } else {
        this.form.userData[this.userInfoFieldsKeys[data.field]] = data.value.target ? data.value.target.value : data.value
      }
    }
  }
}
</script>
