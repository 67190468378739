<template>
  <div class="form-content form-content-recommendations">
    <div class="container flex-container">
      <form class="review-form">
        <steps step="1" />

        <input ref="anonimity" type="hidden" name="is_anonymous">

        <div class="company-logo">
          <img :src="company.logo" :alt="company.name">
        </div>
        <h1 class="text-center">
          Leave a partner recommendation for {{ company.name }}
        </h1>

        <div v-if="shouldShowErrorMessages" class="error-alert">
          {{ errorMessage }}
        </div>
        <div class="flexBox backgroundAreYouAHotelier">
          <div>
            <p class="areYouTitle">
              ARE YOU A HOTELIER?
            </p>
            <p class="areYouDescription">
              This form is for partners and consultants to leave a recommendation.  Click the button to the right to leave a hotelier review.
            </p>
          </div>
          <div>
            <a class="btn btn-blue buttonLeaveReview" :href="`/write-a-review/${company.slug}`">
              Leave a hotelier review
            </a>
          </div>
        </div>

        <!-- Products -->
        <div v-if="products.length">
          <div class="product-checklist"
               :class="{ row: true, validation: true, success: value.products.length, error: shouldShowErrorMessages && !value.products.length }">
            <div class="text-big fw-bold title-pannel title">
              Select which of {{ company.name }}'s products do you would like to recommend
            </div>
            <ul>
              <li v-for="product in products" :key="product.id">
                <label>
                  <input v-model="selectedProducts" type="checkbox" name="products[]" :value="product.id">
                  <span class="fake-checkbox" />
                  <span class="txt">
                    <b>{{ product.name }}</b>
                    <b>{{ categoryById(product.category_id).name }}</b>
                  </span>
                </label>
              </li>
            </ul>
          </div>
        </div>

        <!-- Identity-->
        <div class="content-area content-area__identity">
          <div class="recommend-identity">
            <h2 class="recommend-section__title">Identity verification</h2>
            <div class="recommend-identity__content">
              <div class="recommend-identity__content_left-col">
                <div class="recommend-identity__photo">
                  <img :src="user_info.avatar" v-if="user_info.avatar" style="width: 256px">

                  <div @click.prevent="$refs.uploader && $refs.uploader.openDialog()" class="recommend-identity__photo-empty" :class="{'error': shouldShowErrorMessages && !user_info.avatar}" v-else>
                    <img src="/images/icon-empty-avatar.svg">
                  </div>
                  <file-uploader
                    v-if="!uuidUpdateRequest"
                    ref="uploader"
                    v-model="user_info.avatar"
                    crop="512x512"
                    class="button is-small is-block">
                    <span>Upload a photo</span>
                  </file-uploader>
                </div>
                <p class="recommend-identity__error">Warning: Your email domain must match your company or your review will not be published</p>
              </div>
            <div class="recommend-identity__form">
              <div class="recommend-identity__form-group">
                <div class="recommend-identity__form__field">
                  <input
                    type="text"
                    :disabled="uuidUpdateRequest"
                    :class="{cursorNotAllowedImportant: uuidUpdateRequest ?? false }"
                    v-model="user_info.first_name">
                  <span>First Name</span>
                </div>
                <div class="recommend-identity__form__field">
                  <input type="text"
                    :disabled="uuidUpdateRequest"
                    :class="{cursorNotAllowedImportant: uuidUpdateRequest}"
                    v-model="user_info.last_name">
                  <span>Last Name</span>
                </div>
              </div>
              <div class="recommend-identity__form__field">
                <input
                type="text"
                  :disabled="uuidUpdateRequest"
                  :class="{cursorNotAllowedImportant: uuidUpdateRequest}"
                  v-model="user_info.title">
                <span>Job Title</span>
              </div>
              <div class="recommend-identity__form__field">
                <input
                type="text"
                  :disabled="uuidUpdateRequest"
                  :class="{cursorNotAllowedImportant: uuidUpdateRequest}"
                  v-model="user_info.company">
                <span>Company</span>
              </div>
              <div class="recommend-identity__form__field" :class="{error: shouldShowErrorMessages && !isValidEmail}">
                <input
                type="email"
                  :disabled="uuidUpdateRequest"
                  :class="{cursorNotAllowedImportant: uuidUpdateRequest}"
                  v-model="user_info.email">
                <span>Professional Email</span>
              </div>
              <p class="text-error" v-if="shouldShowErrorMessages && isPersonalEmail">Personal email detected. Please enter your work email.</p>
              <div class="recommend-identity__form__field">
                <input
                  type="text"
                  :disabled="uuidUpdateRequest"
                  :class="{cursorNotAllowedImportant: uuidUpdateRequest}"
                  v-model="user_info.linkedin">
                <span>Linkedin Profile</span>
              </div>
            </div>
            </div>
          </div>
        </div>

        <!-- Recommendation-->
        <div class="content-area">
          <h2 class="recommend-section__title">Recommendation</h2>
          <user-field-input :force-show-error="shouldShowErrorMessages"
                            min-character="200" required="true" size="large" field-name="what_does_well"
                            :val="value.what_does_well" @valueUpdated="updateForm">
            <span slot="label">
              What does this product or service do well?
            </span>
          </user-field-input>

          <user-field-input :force-show-error="shouldShowErrorMessages"
                            min-character="200" required="true" size="large" field-name="difference"
                            :val="value.difference" @valueUpdated="updateForm">
            <span slot="label">
              What differentiates this product or service from the competition?
            </span>
          </user-field-input>
        </div>

        <div>
          <div>
            <div class="btn-hold text-center">
              <button class="btn btn-green recommend-step-next" @click.prevent="next">Next</button>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>
<style scoped>
.areYouDescription {
  font-size: 12px;
  color: #38427E;
  letter-spacing: 0;
  line-height: 13px;
  max-width: 33em;
  margin-bottom: inherit;
}
.areYouTitle {
  font-size: 14px;
  color: #38427E;
  letter-spacing: 0;
  font-weight: 600;
  margin-bottom: inherit;
}
.backgroundAreYouAHotelier {
  padding-left: 12px;
  padding-top: 8px;
  padding-right: 16px;
  padding-bottom: 14px;
  background: #F0F2FF;
}
.buttonLeaveReview {
  margin-left: 60px;
  margin-top: 11px;
}
@media screen and (max-width: 769px) {
.buttonLeaveReview {
  margin: inherit;
}
}
.flexBox {
  display: flex;
}

</style>
<script>
  import { assign, isEqual } from 'lodash'
  import { mapGetters, mapActions } from 'vuex'
  import UserFieldInput from '../../write-a-review/UserFieldInput.vue'
  import CheckEmail from '../../../../mixins/checkEmail.js';
  export default {
    props: ['value', 'company', 'uuidUpdateRequest'],
    mixins: [CheckEmail],
    filters: {
      productTitle (value) {
        return value.substring(value.indexOf('(') + 1, value.indexOf(')'))
      }
    },
    components: {
      UserFieldInput
    },
    created() {
      this.fetchProductsByCompanyId(this.company.id)
    },
    data() {
      return {
        selectedProducts: [],
        errorMessage: null,
        shouldShowErrorMessages: false,
        moreThanHundredCharacters: {},
        user_info: {
          first_name: window.creditPrefilledData.firstName,
          last_name: window.creditPrefilledData.lastName,
          title: window.creditPrefilledData.title,
          company: window.creditPrefilledData.company,
          avatar: window.creditPrefilledData.photo,
          email: window.creditPrefilledData.email,
          linkedin: window.creditPrefilledData.linkedin_url
        }
      }
    },
    mounted() {
      this.user_info = this.value.user_info
      this.selectedProducts = this.value.products
    },
    watch: {
      user_info: {
        deep: true,
        handler() {
          if(isEqual(this.value.user_info, this.user_info)) return;

          this.$emit('input', assign({}, this.value, { user_info: this.user_info }))
        }
      },
      selectedProducts() {
        if(isEqual(this.value.products, this.selectedProducts)) return;

        this.$emit('input', assign({}, this.value, { products: this.selectedProducts }))
      }
    },
    computed: {
      ...mapGetters(['productsByCompanyId', 'categoryById']),
      products () {
        return this.productsByCompanyId(+this.company.id)
      },
      isPersonalEmail() {
        return !this.checkBusinessEmail(this.user_info.email);
      },
      isValidEmail() {
        return this.checkEmail(this.user_info.email) && !this.isPersonalEmail
      },
    },
    methods: {
      ...mapActions(['fetchProductsByCompanyId']),
      next() {
        if (this.checkFormCompletion()) {
          this.$emit('nextStep')
        } else {
          window.scrollTo(0,0)
        }
      },
      updateForm(field) {
        let updated = {}
        updated[field.name] = field.value;

        this.$emit('input', assign({}, this.value, updated))
      },
      checkFormCompletion() {
        this.shouldShowErrorMessages = false;


        if(!this.value.difference || this.value.difference.length < 200
        || !this.value.what_does_well || this.value.what_does_well.length < 200) {
          this.errorMessage = 'Please make sure you have filled out the minimum length of each field.'
          this.shouldShowErrorMessages = true;

          return false
        }

        if(!this.value.products.length) {
          this.errorMessage = 'Please select at least one product.'
          this.shouldShowErrorMessages = true;

          return false
        }

        if(!this.isValidEmail) {
          this.errorMessage = 'Please provide valid business email.'
          this.shouldShowErrorMessages = true;

          return false
        }

        if(!this.user_info.avatar) {
          this.errorMessage = 'Please upload a photo thumbnail to help build your personal brand with hoteliers and verify your recommendation';
          this.shouldShowErrorMessages = true;

          return false
        }

        return true
      }
    }
  }
</script>
