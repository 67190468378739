<template>
  <modal :opened="visible" :on-close="closeModal">
    <div class="htr-form modal-access">
      <template v-if="isAuthenticated">
        <h2>Claiming {{ company.name }}'s profile</h2>
        <p class="modal-access__description">
          Loading...
        </p>
      </template>
      <template v-else>
        <h2>Claim {{ company.name }}'s profile</h2>

        <p class="modal-access__description">
          Create a free account to claim {{ company.name }}’s profile and gain access to the vendor dashboard to collect
          reviews, generate leads and more.
        </p>

        <div class="form-box">
          <div class="form-groups">
            <label class="form-group"
                   :class="{'not-empty': v$.form.first_name.$model, 'error': v$.form.first_name.$invalid && submitted}">
              <input v-model="v$.form.first_name.$model" type="text" class="form-control">
              <span class="custom-label">first name</span>
            </label>
            <label class="form-group"
                   :class="{'not-empty': v$.form.last_name.$model, 'error': v$.form.last_name.$invalid && submitted}">
              <input v-model="v$.form.last_name.$model" type="text" class="form-control">
              <span class="custom-label">last name</span>
            </label>
          </div>
          <label class="form-group" :class="{'not-empty': v$.form.email.$model, 'error': hasEmailError  }">
            <input v-model="v$.form.email.$model" type="text" class="form-control">
            <span class="custom-label">professional email</span>

            <span v-if="v$.form.email.$model && hasEmailError && isPersonalEmail">Personal email detected. Please enter your work email</span>
          </label>
          <label class="form-group"
                 :class="{'not-empty': v$.form.password.$model, 'error': v$.form.password.$invalid && submitted }">
            <input v-model="v$.form.password.$model" type="password" class="form-control">
            <span class="custom-label">create a password</span>

            <span v-if="v$.form.email.$model && hasEmailError && isPersonalEmail">Personal email detected. Please enter your work email</span>
            <span v-if="error('email')">{{ error('email')[0] }}</span>
          </label>
          <label class="form-group form-group-checkbox">
            <input v-model="form.subscribed" type="checkbox">
            <span class="custom-checkbox" />
            Sign up for theRewind, Hotel Tech Report's monthly newsletter
          </label>
        </div>


        <a class="btn btn-blue" @click.prevent="register" v-if="!loading">Create account and claim profile</a>
        <a class="btn btn-blue" v-else>Loading...</a>
        <p>Already have an account? <a href="/login">Login</a></p>
        <p class="form-group__note">By clicking the button above you agree to our
          <a href="https://help.hoteltechreport.com/legal/terms-conditions" target="_blank" rel="noreferrer">Terms of Use</a>
        </p>
      </template>
    </div>
  </modal>
</template>

<script>
import { useVuelidate } from '@vuelidate/core'
import { required, email } from '@vuelidate/validators'
  import checkEmail from '../../../../mixins/checkEmail'
  import HasErrors from '../../../../mixins/HasErrors.vue'
  import { mapActions, mapGetters } from 'vuex'

export default {
  mixins: [
    checkEmail,
    HasErrors
  ],
  props: ['visible'],
  setup () {
    return { v$: useVuelidate() }
  },
  data () {
    return {
      form: {
        first_name: '',
        last_name: '',
        email: '',
        subscribed: true,
        product_id: window.company.product.id,
        password: null,
      },
      shown: false,
      submitted: false,
      loading: false,
      company: window.company.company,
    }
  },
  watch: {
    visible() {
      if(this.visible && this.isAuthenticated) {
        this.loading = true
        this.postCompanyClaim({product_id: window.company.product.id}).then(() => {
          window.store.showAccessRequestedModal = true
          this.closeModal()
        }).catch(this.handleError).then(res => { this.loading = false })
      }
    }
  },
  validations: {
    form: {
      first_name: { required },
      last_name: { required },
      password: { required },
      email: { required, email },
    }
  },
  computed: {
    isAuthenticated() {
      return !! window.Laravel.userId
    },
    isPersonalEmail () {
      return !this.checkBusinessEmail(this.form.email)
    },
    hasEmailError () {
      return this.submitted && (this.isPersonalEmail || this.v$.form.email.$invalid || this.error('email'))
    }
  },
  methods: {
    ...mapActions([
      'postUnauthenticatedCompanyClaim',
      'postCompanyClaim'
    ]),
    closeModal () {
      window.store.showRequestEditAccessModal = false
    },
    register () {
      this.submitted = true
      if (this.v$.$invalid || this.loading) return

      this.loading = true

      this.resetErrors()
      this.postUnauthenticatedCompanyClaim(this.form).then(res => {
        window.store.showEmailConfirmationRequestedModal = true
        this.closeModal()
      }).catch(err => {
        if (err.response && err.response.data) this.setErrors(err.response.data.errors)
      }).then(res => {
        this.loading = false
      })
    }
  }
}
</script>
