<template>
  <div class="form-content form-content-recommendations">
    <div class="container flex-container">
      <form class="review-form">
        <steps step="2" />
        <div class="company-logo">
          <img :src="company.logo" :alt="company.name">
        </div>
        <h1 class="text-center">
          Leave a partner recommendation for {{ company.name }}
        </h1>

        <div v-if="shouldShowErrorMessages" class="error-alert">
          {{ errorMessage }}
        </div>
        <!-- Recommendation-->
        <div class="content-area content-area__identity">
          <h2 class="recommend-section__title recommend-section__title-experience">Experience Verification</h2>
          <div class="recommend-identity__form recommend-identity__form-multiselect row validation">
            <div class="box-select" :class="{ error: shouldShowErrorMessages && !experience }">
              <select v-model="experience" class="selectWithNoInfo" :class="{ boxSelected: experience }">
                <option :value="false" disabled hidden>
                  Which best describes your experience with the product?
                </option>
                <option v-for="option in experienceOptions" :key="option.value" :value="option.value">
                  {{ option.label }}
                </option>
              </select>
            </div>
          </div>
          <div class="recommend-identity__form recommend-identity__form-multiselect row validation">
            <div class="box-select" :class="{error: shouldShowErrorMessages && !relationship }">
              <select v-model="relationship" class="selectWithNoInfo" :class="{ boxSelected: experience }">
                <option :value="false" disabled hidden>
                  Which best describes your relationship with {{ company.name }}?
                </option>
                <option v-for="option in relationshipOptions" :key="option.value" :value="option.value">
                  {{ option.label }}
                </option>
              </select>
            </div>
          </div>
          <div class="recommend-identity__form recommend-identity__form-step-2">
            <div  class="recommend-identity__form__field" :class="{ row: true, validation: true, success: value.shared_clients, error: !value.shared_clients && shouldShowErrorMessages }">
              <input type="text" v-model="value.shared_clients">
              <span>approximately how many of your clients work with  {{ company.name }}</span>
            </div>
          </div>
          <user-field-input :force-show-error="shouldShowErrorMessages" min-character="200" required="true" size="large"
                            field-name="advice" :val="value.advice" @valueUpdated="updateForm">
            <span slot="label">
              Based on your experience with this product or service, if you could give one piece of advice to a hotelier considering this product or service, what would it be?
            </span>
          </user-field-input>
          <label class="recommend-accept">
            <input type="checkbox" v-model="confirmNoCompensation">
            <span class="fake-checkbox" />
            <span class="txt" :class="{error: !confirmNoCompensation && shouldShowErrorMessages}">I confirm that I am not receiving compensation for writing this review</span>
          </label>
          <label class="recommend-accept">
            <input type="checkbox" v-model="confirmHonest">
            <span class="fake-checkbox" />
            <span class="txt" :class="{error: !confirmHonest && shouldShowErrorMessages}">This review is my honest and open opinion</span>
          </label>
          <div>
            <div>
              <div class="btn-hold text-center btn-hold-recommend">
                <button class="btn btn-green recommend-step-next" @click.prevent="next">Next</button>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>
<style scoped>
.box-select {
  min-height: 33px;
  text-align: left;
  color: #35495e;
  border-radius: 2px;
  border: 1px solid #EBEBEB;
  width: 100%;
  position: relative;
}
.box-select::after {
    content: "";
    position: absolute;
    right: 16px;
    top: 50%;
    color: #999;
    border-style: solid;
    border-width: 5px 5px 0;
    border-color: #999 transparent transparent;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
}
.boxSelected {
  color: #303030;
}
.error {
  border: 1px solid #d20027; 
}
.selectWithNoInfo {
  width: 100%;
    height: 35px;
    border: none;
    background: transparent;
    -moz-appearance: none;
    -webkit-appearance: none;
    padding-left: 16px;
    padding-right: 33px;
    color: #929292;
}
</style>
<script>
  import { assign } from 'lodash'
  import { mapGetters, mapActions } from 'vuex'
  import Multiselect from 'vue-multiselect'
  import UserFieldInput from '../../write-a-review/UserFieldInput.vue'

  export default {
    props: ['value', 'company', 'uuidUpdateRequest'],
    filters: {
      productTitle (value) {
        return value.substring(value.indexOf('(') + 1, value.indexOf(')'))
      }
    },
    components: {
      Multiselect,
      UserFieldInput
    },
    created() {
      this.fetchProductsByCompanyId(this.company.id)
      this.experience = find(this.experienceOptions, option => option.id === this.value.experience)
      this.relationship = find(this.relationshipOptions, option => option.id === this.value.relationship)
    },
    data() {
      return {
        confirmNoCompensation: false,
        confirmHonest: false,
        experience: null,
        relationship: null,
        selectedProducts: [],
        errorMessage: null,
        shouldShowErrorMessages: false,
        moreThanHundredCharacters: {}
      }
    },
    mounted() {
      window.scrollTo(0,0)
      if (this.uuidUpdateRequest && this.value) {
        this.experience = this.value.experience
        this.relationship = this.value.relationship
      }
    },
    watch: {
      experience() {
        this.$emit('input', assign({}, this.value, { experience: this.experience }))
      },
      relationship() {
        this.$emit('input', assign({}, this.value, { relationship: this.relationship }))
      },
      selectedProducts() {
        this.$emit('input', assign({}, this.value, { products: this.selectedProducts }))
      }
    },
    computed: {
      ...mapGetters(['productsByCompanyId', 'categoryById']),
      products () {
        return this.productsByCompanyId(+this.company.id)
      },
      experienceOptions() {
        return window.Laravel.dictionaries.partner_experience
      },
      relationshipOptions() {
        return window.Laravel.dictionaries.partner_relationship
      },
    },
    methods: {
      ...mapActions(['fetchProductsByCompanyId']),
      next() {
        if (this.checkFormCompletion()) {
          this.$emit('nextStep')
        } else {
          window.scrollTo(0,0)
        }
      },
      updateForm(field) {
        let updated = {}
        updated[field.name] = field.value;

        this.$emit('input', assign({}, this.value, updated))
      },
      checkFormCompletion() {
        this.shouldShowErrorMessages = false;

        if(!this.value.experience || !this.value.relationship || !this.value.shared_clients || !this.confirmHonest || !this.confirmNoCompensation) {
          this.errorMessage = 'Please make sure you have filled out all the required fields.'
          this.shouldShowErrorMessages = true;

          return false
        }

        if(!this.value.advice || this.value.advice.length < 200) {
          this.errorMessage = 'Please make sure you have filled out the minimum length of each field.'
          this.shouldShowErrorMessages = true;

          return false
        }

        return true
      }
    }
  }
</script>
