<template>
  <div>
    <div class="category-posts">
      <article v-for="category in categories" :key="category.id" class="category-post">
        <a :href="category.url">
          <div class="img">
            <img :src="category.logo" width="50" height="50">
          </div>
          <div class="des">
            <h3>{{ category.name }}</h3>
            <p>{{ category.tagline }}</p>
          </div>
        </a>
        <div class="btn-col">
          <a :href="category.url" class="btn btn-hollow">Browse rankings</a>

          <a v-if="!category.parent_id" v-prevent-is-vendor :href="`/quiz/subcategory?category_id=${category.id}`"
             class="btn btn-green">
            Get custom recommendations
          </a>

          <a v-if="category.parent_id"
             v-prevent-is-vendor
             :href="`/quiz/integrate_with?category_id=${category.parent_id}&subcategory_id=${category.id }`" class="btn btn-green">
            Get custom recommendations
          </a>
        </div>
      </article>
    </div>
    <span class="load-more">
      <button v-show="page < lastPage" @click="load">Load more</button>
    </span>
  </div>
</template>

<script>
import axios from 'axios'

export default {
  props: {
    categoryId: {
      default: ''
    }
  },

  data: () => ({
    categories: [],
    page: 1,
    lastPage: 2
  }),

  methods: {
    load () {
      this.page++
      axios.get(`/api/popular-categories?page=${this.page}&category_id=${this.categoryId}`)
        .then(res => {
          this.lastPage = res.data.last_page
          this.categories = [...this.categories, ...res.data.data]
        })
    }
  }
}
</script>
