<template>
  <div>
    <editor v-model="internal" style="height: 500px" />
    <input type="hidden" name="text" :value="internal">
  </div>
</template>
<script>
export default {
  props: ['text'],
  data () {
    return {
      internal: ''
    }
  },
  created () {
    this.internal = this.text
  }
}
</script>