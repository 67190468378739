<template>
  <div class="modal-lead__body">
    <div class="progress-bar">
      <div class="active"
           :style="{ width: (countFullRequiredFields.length * (100/(requiredFields.length))) + '%'}" />
    </div>
    <div v-if="type === 'ask_a_question'"
         class="modal-lead__content__option option-ask">
      <div class="box-ask">
        <div class="form-group" :class="{'success': form.question_asked, 'error': !form.question_asked && shouldShowErrorMessages}">
          <textarea v-model="form.question_asked"
                    :placeholder="`Enter your question about ${company.name}`" />
        </div>
        <p class="option-description">
          Tell us about your property to help you get the best answer
        </p>
      </div>
    </div>
    <div class="modal-lead__content__option"
         :class="{'disabled': !form.question_asked && (type === 'ask_a_question')}">
      <div class="content__option__box-title">
        <img src="/images/icon-property-type.svg">
        <span class="content__option__title">Hotel Type</span>
      </div>
      <div class="content__option__box-fields">
        <div class="form-group" :class="{'success': (form.hotel_type), 'error': !form.hotel_type && shouldShowErrorMessages}">
          <div class="wrap-select">
            <select v-model="form.hotel_type" :class="{'is-empty': !form.hotel_type}">
              <option :value="null" disabled hidden selected>
                Hotel type
              </option>
              <option
                v-for="item in sortAlphabet(dictionary('hotel_types'))"
                :key="item.value"
                :value="item.value">
                {{ item.label }}
              </option>
            </select>
          </div>
        </div>
      </div>
    </div>
    <div class="modal-lead__content__option" :class="{'disabled': !form.hotel_type}">
      <div class="content__option__box-title">
        <img src="/images/icon-property-size.svg">
        <span class="content__option__title">Number of rooms</span>
      </div>
      <div class="content__option__box-fields">
        <div class="form-group" :class="{'success': form.property_size, 'error': !form.property_size && shouldShowErrorMessages}">
          <div class="wrap-select">
            <select v-model="form.property_size"
                    :class="{'is-empty': !form.property_size}">
              <option :value="null" disabled hidden selected>
                Property size
              </option>
              <option
                v-for="item in dictionary('property_size')"
                :key="item.value"
                :value="item.value">
                {{ item.label }}
              </option>
            </select>
          </div>
        </div>
      </div>
    </div>
    <div class="modal-lead__content__option"
         :class="{'disabled': !form.property_size}">
      <div class="content__option__box-title">
        <img src="/images/icon-region.svg">
        <span class="content__option__title">Region</span>
      </div>
      <div class="content__option__box-fields">
        <div class="form-group" :class="{'success': form.region, 'error': !form.region && shouldShowErrorMessages}">
          <div class="wrap-select">
            <select v-model="form.region"
                    :class="{'is-empty': !form.region}">
              <option :value="null" disabled hidden selected>
                Region
              </option>
              <option
                v-for="region in dictionary('markets')"
                :key="region.value"
                :value="region.value">
                {{ region.label }}
              </option>
            </select>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: [
    'type',
    'company',
    'value'
  ],

  data: () => ({
    form: {},

    shouldShowErrorMessages: false
  }),

  computed: {
    countFullRequiredFields () {
      return this.requiredFields.filter(key => !!this.form[key])
    },
    requiredFields () {
      let requeredFields = [
        'region',
        'hotel_type'
      ]

      if (this.type === 'ask_a_question') {
        requeredFields.push('question_asked')
      }

      requeredFields.push('property_size')

      return requeredFields
    }
  },

  watch: {
    value: {
      handler () {
        this.reset()
      },
      deep: true
    },

    form () {
      this.$emit('input', this.form)
    }
  },

  mounted () {
    this.reset()
  },

  methods: {
    reset () {
      if (this.value !== this.form) this.form = this.value
    },
    dictionary (key) {
      return (window.Laravel) ? window.Laravel.dictionaries[key] : []
    },
    nextStep () {
      this.shouldShowErrorMessages = false
      if (this.hasFilledAllRequiredFields()) {
        this.$emit('update')
      }
    },
    hasFilledAllRequiredFields () {
      for (let index = 0; index < this.requiredFields.length; index++) {
        if (!this.form[this.requiredFields[index]]) {
          this.shouldShowErrorMessages = true
          return false
        }
      }

      return true
    },
    sortAlphabet (array) {
      return array.sort((a, b) => {
        if (a.value === 'other') return 1
        if (b.value === 'other') return -1

        return a.label.localeCompare(b.label)
      })
    }
  }
}
</script>
