<template>
  <section class="box-search">
    <h2 class="box-search__title">Categories <span>({{ items.length }} results)</span></h2>

    <div class="category-posts search-category-posts">
      <article v-for="category in itemsFiltered" :key="category.id" class="category-post">
        <a :href="category.url" target="_blank">
          <div class="img">
            <img :src="category.logo" width="50" height="50">
          </div>
          <div class="des">
            <h3>{{ category.name }}</h3>
            <p>{{ category.tagline }}</p>
          </div>
        </a>
        <div class="btn-col">
          <a :href="category.url" class="btn btn-hollow" target="_blank">Browse rankings</a>

          <a v-if="!category.parent_id" v-prevent-is-vendor :href="`/quiz/subcategory?category_id=${category.id}`"
             class="btn btn-green" target="_blank">
            Get custom recommendations
          </a>

          <a v-if="category.parent_id" v-prevent-is-vendor
             :href="`/quiz/integrate_with?category_id=${category.parent_id}&subcategory_id=${category.id }`"
             class="btn btn-green" target="_blank">
            Get custom recommendations
          </a>
        </div>
      </article>
    </div>

    <div v-if="canShowMore" class="load-more">
      <button @click="load">
        View more
      </button>
    </div>
  </section>
</template>

<script>
import arrayPagination from '../Mixins/arrayPagination'
import {mapGetters} from "vuex";

export default {
  mixins: [
    arrayPagination
  ],

  data () {
    return {
      award_year: null,
    }
  },

  computed: {
    ...mapGetters(['getCategories']),
    items () {
      return this.getCategories
    },
  },

}
</script>
