<template>
  <div>
    <span class="company-map__title">{{ htrLanguage.most_popular_for || 'most_popular_for' }}</span>
    <div v-cloak class="scrollbar">
      <ul class="company-map-review-list">
        <li v-for="hotelType in hotelTypes" :key="hotelType.value" class="review-list__item">
          <div>
            <span class="list__item__title">{{ htrLanguage['hotel_type_' + hotelType.value] || 'hotel_type_' }}</span>
            <span v-if="!isPremium() && lederInSegment(hotelType.value)" class="list__item__subtitle">
              {{ htrLanguage.leader || 'leader' }}:
              <a :href="lederInSegment(hotelType.value).url" target="_blank">
                {{ lederInSegment(hotelType.value).name }}
              </a>
            </span>
            <span v-else
                  class="list__item__verified-reviews">{{ hotelTypeCount(hotelType.value) }} {{ htrLanguage.verified_reviews || 'verified_reviews' }}</span>
          </div>
          <div v-if="!isPremium()" class="review-list__box-mark">
            <div v-if="hotelTypeCount(hotelType.value)" class="review-list__item__mark">
              <i class="icon-circle-like-white" :class="{'dislike': recommendRank(hotelType.value) <= 60}" />
              <span class="list__item__mark">{{ recommendRank(hotelType.value) }}%</span>
            </div>
            <span v-else class="list__item__title-empty">{{ htrLanguage.not_recommended || 'not_recommended' }}</span>
            <span class="list__item__verified-reviews">{{ hotelTypeCount(hotelType.value) }} {{ htrLanguage.verified_reviews || 'verified_reviews' }}</span>
          </div>
          <div v-else class="review-list__box-mark">
            <div v-if="hotelTypeCount(hotelType.value)" class="review-list__item__mark">
              <i class="icon-circle-like-white" :class="{'dislike': recommendRank(hotelType.value) <= 60}" />
              <span class="list__item__mark">{{ recommendRank(hotelType.value) }}%</span>
            </div>
            <div v-else class="review-list__item-review-count">
              <i class="icon-circle-like-white disabled" />
              <span>n/a</span>
            </div>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import Premium from '../Mixins/Premium.vue'

export default {

  mixins: [
    Premium
  ],
  props: [
    'productId',
    'reviewsIds'
  ],

  data: () => ({
    company: window.company.company
  }),

  computed: {
    ...mapGetters([
      'reviewsByIds'
    ]),
    htrLanguage() {
      if (window && window.i18n && window.i18n.company) {
        return window.i18n.company
      }
      return {}
    },
    allReviews () {
      return this.reviewsByIds(this.reviewsIds)
    },
    hotelTypes () {
      let hotelTypes = this.dictionaries.hotel_types
      return hotelTypes
        .filter(hotelType => hotelType.value !== 'other')
        .sort(
          (a, b) => this.hotelTypeCount(b.value) - this.hotelTypeCount(a.value)
        )
    },
    dictionaries () {
      return window.Laravel.dictionaries
    },
    leadersInSegment () {
      return (window.productLaravel) ? window.productLaravel.leadersInSegments : []
    }
  },

  methods: {
    getReviewsByHotelType (hotelType) {
      return this.allReviews.filter(
        review => review.hotel && review.hotel.hotel_type && review.hotel.hotel_type === hotelType
      )
    },
    hotelTypeCount (hotelType) {
      return this.getReviewsByHotelType(hotelType).length
    },
    recommendRank (hotelTypes) {
      let reviewsByHotelType = this.getReviewsByHotelType(hotelTypes)
      let sumNpsReviews = reviewsByHotelType.reduce((a, b) => a + b.nps, 0)

      return (reviewsByHotelType.length > 0 && sumNpsReviews > 0)
        ? Math.ceil(sumNpsReviews * 50 / (reviewsByHotelType.length * 5)) : 100
    },
    lederInSegment (hotelType) {
      return this.leadersInSegment.find(segment => segment.hotel_type === hotelType)
    }
  }
}
</script>
