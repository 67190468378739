<template>
    <div class="aside__download aside__download-report">
      <h3 class="aside__download__title">
        {{ htrLanguage.reports.where_should_we_send || 'send_me_report' }}
      </h3>

      <div class="form-box">
        <div class="text-center">
          <div v-if="submitted && (this.v$.$invalid || isPersonalEmail )">
            <p v-if="someFieldIsMissing" class="error-text">
              {{ htrLanguage.reports.all_fields_are_required || 'all_fields_are_required' }}
            </p>
            <p v-if="v$.value.email.$invalid && !v$.value.email.required.$invalid" class="error-text">
              {{ htrLanguage.reports.valid_email_address || 'valid_email_address' }}
            </p>
          </div>
          <p v-if="isPersonalEmail && !v$.value.email.required.$invalid" class="error-text">
            {{ htrLanguage.reports.personal_email_detected || 'personal_email_detected' }}
          </p>
        </div>

        <div class="form-groups">
          <label class="form-group"
                 :class="{'not-empty': v$.value.first_name.$model, 'error': v$.value.first_name.$invalid && submitted}">
            <input v-model="v$.value.first_name.$model" type="text" class="form-control" :placeholder="htrLanguage.reports.first_name || 'first_name' ">
          </label>
          <label class="form-group"
                 :class="{'not-empty': v$.value.last_name.$model, 'error': v$.value.last_name.$invalid && submitted}">
            <input v-model="v$.value.last_name.$model" type="text" class="form-control" :placeholder="htrLanguage.reports.last_name || 'last_name'">
          </label>
        </div>

        <label class="form-group" :class="{'not-empty': v$.value.email.$model, 'error': hasEmailError}">
          <input v-model.lazy="v$.value.email.$model" type="email" class="form-control" :placeholder="htrLanguage.reports.professional_email || 'professional_email'">
        </label>

        <label class="form-group"
               :class="{'not-empty': v$.value.title.$model, 'error': v$.value.title.$invalid && submitted}">
          <input v-model="v$.value.title.$model" type="text" class="form-control" :placeholder="htrLanguage.reports.job_title || 'job_title'">
        </label>

        <label class="form-group"
               :class="{'not-empty': v$.value.company.$model, 'error': v$.value.company.$invalid && submitted}">
          <places-autocomplete :value="location" class="form-control" @input="updateLocation"  :placeholder="htrLanguage.reports.hotel_company"/>
        </label>

        <label class="form-group" :class="{'not-empty': v$.value.which_best_describes_you.$model, 'error':submitted && v$.value.which_best_describes_you.$invalid}">
          <div class="form-control-select">
            <select
              v-model="v$.value.which_best_describes_you.$model"
              class="form-control"
            >
              <option :value="null" selected disabled>{{ htrLanguage.buyersGuide.which_best_describes_you }}</option>
              <option v-for="(des, key) in whichBestDescribesYou" :key="key" :value="des">{{ htrLanguage.buyersGuide[des] }}</option>
            </select>
          </div>
        </label>

        <button class="btn-green btn" :class="{'btn-loading': loading }" @click="!loading && next()" v-text="!loading ? htrLanguage.reports.send_me_the_report : htrLanguage.buyersGuide['loading']" />
        <label class="form-group form-group-checkbox">
          <input v-model="value.send_me_tips" type="checkbox">
          <span class="custom-checkbox" />
          {{ htrLanguage.quiz.get_the_latest_tips || 'get_the_latest_tips' }}.
        </label>
      </div>
    <p class="note">
      {{ htrLanguage.quiz.contact_details_desc || 'contact_details_desc' }} a) {{ htrLanguage.quiz.contact_details_a_1 || 'contact_details_a_1' }} {{ htrLanguage.layout.privacy_policy || 'privacy_policy' }} {{ htrLanguage.quiz.contact_details_a_2 || 'contact_details_a_2' }} b) {{ htrLanguage.quiz.contact_details_b_1 || 'contact_details_b_1' }} Hotel Tech Report {{ htrLanguage.quiz.contact_details_b_2 || 'contact_details_b_2' }}
    </p>
  </div>
</template>

<script>
import { useVuelidate } from '@vuelidate/core'
import { required, email, helpers } from '@vuelidate/validators'
import checkEmail from '../../../../../mixins/checkEmail'
import axios from 'axios'

export default {
  mixins: [
    checkEmail
  ],

  props: [
    'value', 'productId', 'reportId', 'categoryId', 'categoryName', 'whichBestDescribesYou'
  ],

  setup () {
    return { v$: useVuelidate() }
  },

  data () {
    return {
      submitted: false,
      loading: false,
      locale: Laravel.languageLocale ? Laravel.languageLocale['code'] : '',
      errors: [],
      timer: null
    }
  },

  validations: {
    value: {
      first_name: { required },
      last_name: { required },
      title: { required },
      company: { required },
      which_best_describes_you: { required },
      email: {
        required,
        email,
        validEmail: helpers.withAsync(async function (value){
          return  await this.checkEmail(value)
        })
      }
    }
  },

  computed: {
    htrLanguage () {
      if (window && window.i18n) {
        const { buyersGuide, reports, layout, quiz } = window.i18n
        if (buyersGuide && reports && layout && quiz) {
          return { buyersGuide, reports, layout, quiz }
        }
      }
      return { buyersGuide: {}, reports: {}, layout: {}, quiz: {} }
    },
    someFieldIsMissing () {
      return (
        this.v$.value.first_name.required.$invalid ||
        this.v$.value.last_name.required.$invalid ||
        this.v$.value.company.required.$invalid ||
        this.v$.value.email.required.$invalid ||
        this.v$.value.title.required.$invalid ||
        this.v$.value.which_best_describes_you.required.$invalid
      )
    },
    isPersonalEmail () {
      return !this.checkBusinessEmailV2(this.value.email)
    },
    markets () {
      return window.Laravel.dictionaries.markets
    },
    hasEmailError () {
      return (this.v$.value.email.$invalid || this.isPersonalEmail) && this.submitted
    },
    location () {
      return {
        name: this.value.company
      }
    }
  },

  methods: {
    async next () {
      this.submitted = true
      this.loading = true
      await this.v$.$validate()

      if (this.v$.$invalid){
          this.loading = false
         return
      }

      axios.post(`/api${this.locale ? '/' + this.locale : ''}/reports/${this.reportId}/downloads`, this.value)
        .then((res) => {
          this.$emit('nextStep')
          this.loading = false
        }).catch((err) => {
          if (err.response.data.errors) {
            this.errors = err.response.data.errors
          }
          this.loading = false
        })
    },
    updateLocation (value) {
      this.v$.value.company.$model = value && value.name ? value.name : null

      if (value && value.name) {
        this.$emit('input', Object.assign(this.value, {
          city: value.city || null,
          country: value.country || null,
          country_iso_code: value.country_iso_code || null,
          state: value.state || null,
          company_phone: value.phone || null
        }))
      }
    },
    sortAlphabet (array) {
      return array.sort((a, b) => {
        if (a.value === 'other') return 1
        if (b.value === 'other') return -1

        return a.label.localeCompare(b.label)
      })
    },
    async checkEmail (value) {
      try {
        const response = await axios.post('/api/lead-requests/brite-verify-email', { email: value })
        return response.data['isValid'] ?? false
      } catch (error) {
        console.log(error)
        return false
      }
    }
  }
}
</script>
