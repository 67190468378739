<template>
  <div id="downloadBuyersGuide">
    <div :is="activeStep" v-model="form" :product-id="productId" :buyers-guide-id="buyersGuideId"
         :category-name="categoryName"
         :category-id="categoryId"
         :which-best-describes-you="whichBestDescribesYou"
         @nextStep="nextStep"
         @prevStep="prevStep"
    />
  </div>
</template>

<script>
import { min, max, pick, assign } from 'lodash'
import HotelDetails from './DownloadWizardSteps/HotelDetails.vue'
import ContactDetails from './DownloadWizardSteps/ContactDetails.vue'
import Success from './DownloadWizardSteps/Success.vue'
export default {
  props: ['productId', 'buyersGuideId', 'categoryId', 'categoryName', 'whichBestDescribesYou'],
  data () {
    return {
      steps: [ ContactDetails, Success],
      step: 0,
      form: {
        download_type: 'free',
        company: null,
        company_website: null,
        property_size: null,
        hotel_type: null,
        region: null,
        city: null,
        country: null,
        state: null,
        company_phone: null,
        need_soon: null,
        first_name: null,
        last_name: null,
        title: null,
        email: null,
        which_best_describes_you: null,
        send_me_tips: true
      }
    }
  },
  computed: {
    activeStep () {
      return this.steps[this.step]
    }
  },
  watch: {
    form: {
      deep: true,
      handler () {
        window.storeVisitorFields(this.form)
      }
    }
  },
  created () {
    this.form = assign({}, this.form, pick(window.getVisitorData(), Object.keys(this.form)))
  },
  methods: {
    nextStep () {
      this.step = min([this.steps.length - 1, this.step + 1])
    },
    prevStep () {
      this.step = max([0, this.step - 1])
    }
  }
}
</script>
