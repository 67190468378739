<template>
  <div class="notification-bar">
    <div class="container">
      <div class="txt">
        <strong>Unverified account:</strong>
        Please click the link in your email to your account or you will lose access in 7 days
      </div>
      <template v-if="success">
        <a class="btn btn-green" @click.prevent="">Thank you. Please check your&nbsp;inbox.</a>
      </template>
      <template v-else>
        <div class="input-addon">
          <input v-model="verification_email" type="email" class="input-field">
          <a class="btn btn-green" @click.prevent="submit">Resend link</a>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import swal from 'sweetalert2'

export default {
  props: ['email'],
  data () {
    return {
      success: false,
      verification_email: null
    }
  },
  created () {
    this.verification_email = this.email
  },
  methods: {
    submit () {
      axios.post('/api/user/email-verifications', { email: this.verification_email })
        .then(() => (this.success = true))
        .catch(err => {
          let message = 'Sorry, there was an error.<br> We\'re already looking into it!'
          swal('Oops!', err.response.data.message || message, 'error')
        })
    }
  }
}
</script>
