<template>
  <div class="form-content form-content-recommendations">
    <div class="container flex-container">
      <form class="review-form">
        <steps step="3" />
        <div class="company-logo">
          <img :src="company.logo" :alt="company.name">
        </div>
        <h1 class="text-center">
          Final step: Buyer fit advice
        </h1>

        <div v-if="shouldShowErrorMessages" class="error-alert">
          {{ errorMessage }}
        </div>

        <div class="content-area content-area-finish-step">
          <div class="list-checkbox">
            <div class="text-big fw-bold title-pannel title" :class="{ 'error': shouldShowErrorMessages && !value.hotel_types.length}">
              What types of hotels do you most recommend this product or service for? <span>(select up to 3)</span>
            </div>
            <ul>
              <li v-for="type in hotel_types" :key="type.value">
                <label class="recommend-accept">
                  <input v-model="selectedTypes" type="checkbox" :value="type.value">
                  <span class="fake-checkbox" />
                  <span class="txt">
                    <b>{{ type.label }}</b>
                  </span>
                </label>
              </li>
            </ul>
            <!--<button class="list-checkbox__view-more">Load more</button>-->
          </div>

          <div class="list-checkbox" v-if="features.length">
            <div class="text-big fw-bold title-pannel title" :class="{ 'error': shouldShowErrorMessages && !value.features.length}">
              Which 3 features of the product or service add the most value for clients? (select up to 3)
            </div>
            <ul>
              <li v-for="feature in features" :key="feature.id">
                <label class="recommend-accept">
                  <input v-model="selectedFeatures" type="checkbox" :value="feature.id">
                  <span class="fake-checkbox" />
                  <span class="txt">
                    <b>{{ feature.name }}</b>
                  </span>
                </label>
              </li>
            </ul>
            <!--<button class="list-checkbox__view-more">Load more</button>-->
          </div>

          <div class="btn-hold btn-hold-recommend text-center">
            <button class="btn btn-green recommend-step-next" @click.prevent="" v-if="sending">Sending...</button>
            <button class="btn btn-green recommend-step-next" @click.prevent="next" v-else>Next</button>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>
<script>
  import axios from 'axios';
  import {mapGetters, mapActions} from 'vuex'
  import {uniqBy, flatMap, assign, map} from 'lodash'
  import swal from 'sweetalert2'

  export default {
    props: ['value', 'company', 'uuidUpdateRequest'],
    data() {
      return {
        sending: false,
        selectedFeatures: [],
        selectedTypes: [],
        shouldShowErrorMessages: false,
        errorMessage: ''
      }
    },
    created() {
      this.selectedFeatures = this.value.features || [];
      this.selectedTypes = this.value.hotel_types || [];

      this.fetchProducts({ids: this.value.products}).then(res => {
        this.fetchFeatures({category_ids: map(res.data.data, 'category_id')})
      })
    },
    methods: {
      ...mapActions([
        'fetchProducts',
        'fetchCategories',
        'fetchFeatures'
      ]),
      checkFormCompletion() {
        this.shouldShowErrorMessages = false;
        if(!this.value.features.length || !this.value.hotel_types.length) {
          this.errorMessage = 'Please select at least a single feature and hotel type.'
          this.shouldShowErrorMessages = true;

          return false
        }
        return true;
      },
      next() {
        if (!this.checkFormCompletion()) {
          window.scrollTo(0,0)
          return false;
        }

        this.$emit('nextStep')
      }
    },
    watch: {
      selectedFeatures() {
        if(this.selectedFeatures.length > 3) this.selectedFeatures.shift()
        this.$emit('input', assign({}, this.value, { features: this.selectedFeatures }))
      },
      selectedTypes() {
        if(this.selectedTypes.length > 3) this.selectedTypes.shift()
        this.$emit('input', assign({}, this.value, { hotel_types: this.selectedTypes }))
      }
    },
    computed: {
      ...mapGetters([
        'productById',
        'featuresByCategoryId'
      ]),
      hotel_types() {
        return window.Laravel.dictionaries.hotel_types
      },
      features() {
        return uniqBy(flatMap(this.value.products, id => {
          let product = this.productById(id)
          return product ? this.featuresByCategoryId(product.category_id) : []
        }), 'id')
      }
    }
  }
</script>
