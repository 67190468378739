<template>
  <section class="box-search">
    <h2 class="box-search__title">Articles <span>({{ items.length }} results)</span></h2>

    <div class="articles">
      <article v-for="post in itemsFiltered" :key="post.id" class="article">
        <div class="img">
          <a :href="post.url">
            <img :src="post.cover_image" width="171" height="125">
          </a>
        </div>
        <div class="des">
          <ul v-if="post.category_name" class="category">
            <li><a :href="post.blog_url" target="_blank">{{ post.category_name }}</a></li>
          </ul>
          <h3><a class="article__shot-description" :href="post.url" target="_blank">{{ post.title }}</a></h3>
          <div class="author">
            by
            <div v-if="post.user_id" class="author-name">
              {{ post.user_first_name + post.user_last_name}}
            </div>
            <div v-if="!post.user_id" class="author-name">
              Hotel Tech Report
            </div>
          </div>
          <p class="article__shot-description">
            {{ post.seo_description }}
          </p>
        </div>
      </article>
    </div>

    <div v-if="canShowMore" class="load-more">
      <button @click="load">
        View more
      </button>
    </div>
  </section>
</template>

<script>
import arrayPagination from '../Mixins/arrayPagination'
import {mapGetters} from "vuex";
export default {
  mixins: [
    arrayPagination
  ],

  data () {
    return {
      award_year: null
    }
  },

  computed: {
    ...mapGetters(['getArticles']),
    items () {
      return this.getArticles
    },
  },

}
</script>
