<template>
  <div>
    <div class="modal-lead__header">
      <div v-if="!product">
        <span class="lds-spinner">
          <span v-for="i in 12" :key="i" />
        </span>
      </div>
      <div v-if="product">
        <div class="modal-lead__logo">
          <img :src="company.logo">
        </div>
        <h4 class="modal-lead__title">
          {{ modalInfo.title }}
        </h4>
        <p class="modal-lead__subtitle">
          {{ modalInfo.subtitle }}
        </p>
        <p class="modal-lead__description">
          {{ modalInfo.description }}
        </p>
      </div>
    </div>

    <div class="modal-lead__content" :class="{'modal-lead__content-email': steps[currentStep] === 'ResentEmailVerify'}">
      <div :is="steps[currentStep]"
           ref="activeStep"
           v-model="form"
           isStepWaittingForAction="isStepWaittingForAction"
           :product="product"
           :category-id="categoryId"
           :company="company" :type="type" @update="updateLead" @set-additional-products="setAdditionalProducts"
           :extraProducts="extraProducts"

           @complete="complete"
           @resend-verify="resendVerify" />
      <div>
        <div class="modal-lead__footer">
          <button v-if="currentStep + 1 === steps.length" class="modal-lead__btn-close" @click="close">
            Close this window
          </button>

          <button v-else class="modal-lead__btn" :disabled="isSubmitDisabled" @click="submit">
            Submit
            <span v-if="isLoader" class="lds-spinner">
              <span v-for="i in 12" :key="i" />
            </span>
          </button>
          <span class="modal-lead__privacy_text">
            By clicking the button above I agree that HTR and the companies that I request information about may contact me about my request.
            <a href="https://help.hoteltechreport.com/en/articles/1214349-user-privacy-policy" target="_blank">
              Privacy policy
            </a>
          </span>
          <span class="modal-lead__footer__title">As seen in</span>
          <div class="modal-lead__companies">
            <img v-for="i in 5" :key="i" class="modal-lead__companies__logo" :src="`/images/logo-company${i}.png`">
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import swal from 'sweetalert2'
import { mapGetters, mapActions } from 'vuex'
import { EventBus } from '../../../event-bus.js'

import { pick } from 'lodash'

import DataHotels from './LeadSteps/DataHotels.vue'
import DataHotelier from './LeadSteps/DataHotelier.vue'
import ResentEmailVerify from './LeadSteps/ResentEmailVerify.vue'
import Recommended from './LeadSteps/Recommended.vue'

export default {

  components: {
    DataHotels,
    DataHotelier,
    Recommended,
    ResentEmailVerify
  },
  props: [
    'productId',
    'extraProducts',
    'categoryId',
    'type',
    'isWithoutRedirect'
  ],

  data: () => ({
    steps: [
      'DataHotels',
      'Recommended',
      'DataHotelier',
      'ResentEmailVerify'
    ],

    form: {
      first_name: null,
      last_name: null,
      city: null,
      state: null,
      country: null,
      website: null,
      phone: null,
      full_name: null,
      email: null,
      demo_type: null,
      company: null,
      region: null,
      title: null,
      question_asked: null,
      hotel_chainscale: null,
      property_size: null,
      hotel_type: null,
      portfolio_size: null
    },

    leadId: null,
    userId: null,
    userVerified: null,

    currentStep: 0,
    isStepWaittingForAction: false,
    isLoader: false,
    isLoading: false,
    additionalProductsIds: []
  }),

  computed: {
    ...mapGetters([
      'productById',
      'companyById',
      'categoryById'
    ]),
    isSubmitDisabled () {
      return this.isLoader || this.isStepWaittingForAction
    },
    product () {
      return this.productById(this.productId)
    },
    category () {
      return this.categoryById(this.categoryId)
    },
    company () {
      return (this.product) ? this.companyById(this.product.company_id) : {}
    },
    modalInfo () {
      switch (this.currentStep) {
        case 0:
          return {
            subtitle: `We'll need some information to customize your ${this.company.name} ${this.setRequestType(this.type)}`
          }

        case 1:
          if (this.company.disable_direct_leads === 1) {
            return {
              title: `${this.company.name} is not able to receive your ${this.setRequestType(this.type)} request.`,
              description: `Consider one of the similar solutions below.`
            }
          } else {
            return {
              title: `Personalize your ${this.company.name} ${this.setRequestType(this.type)}`,
              description: `Every property is different and has unique needs, personalizing your ${this.setRequestType(this.type)} helps you find out if ${this.company.name} is the right fit for you`
            }
          }

        case 2:
          return {
            subtitle: `Where should we send your ${this.company.name} ${this.setRequestType(this.type)} details`
          }

        case 3:
          return {
            subtitle: `${this.company.name} ${this.setRequestType(this.type)} details sent`
          }
      }

      return {}
    },
    checkAuth () {
      return window.Laravel.userId
    },
    isPremium () {
      return this.company.tags && this.company.tags.length
    }
  },

  mounted () {
    if (!this.product) {
      this.fetchProductById(this.productId)
        .then(() => this.trackSegment())
    } else {
      this.trackSegment()
    }

    if(this.extraProducts && this.extraProducts.length) {
      this.additionalProductsIds = this.extraProducts
    }

    if (this.checkAuth) this.checkExistProject()
    this.reset()
    // register event handlers for the submit button
    EventBus.$on('modal_lead_step_wait_for_action', () => this.stepWaitForAction())
    EventBus.$on('modal_lead_step_ready_for_action', () => this.stepReadyForAction())
  },

  methods: {
    ...mapActions([
      'fetchProductById'
    ]),
    stepWaitForAction () {
      this.isStepWaittingForAction = true
    },
    stepReadyForAction () {
      this.isStepWaittingForAction = false
    },
    nextStep () {
      if (this.currentStep >= this.steps.length) return false
      this.currentStep++
      // disable submit button until we have a notice that it must be disabled via event
      if (this.currentStep === 1 && this.company.disable_direct_leads === 1) {
        this.isStepWaittingForAction = true
      }
    },
    close () {
      this.$emit('close')
    },
    createLead () {
      axios.post(`/api/lead-requests`, this.form)
        .then(res => {
          this.leadId = res.data.id
          this.userId = res.data.user_id

          if (res.data.user && res.data.user.verified) this.userVerified = true

          if (!this.userId || !this.isPremium) {
            this.nextStep()
          } else if (res.data.user && res.data.user.verified) {
            this.nextStep()
          } else {
            this.form.email = res.data.user ? res.data.user.email : null
            this.currentStep = this.steps.length - 1
          }
        })
    },
    redirectCreateProject () {
      this.isLoader = true
      if (this.additionalProductsIds.length) {
        window.location.href = `/leads/${this.leadId}/projects/create?additional_products=${this.additionalProductsIds}`
      } else {
        window.location.href = `/leads/${this.leadId}/projects/create`
      }
    },
    updateLead () {
      window.storeVisitorFields(this.form)

      if (!this.leadId) return this.createLead()
      axios.put(`/api/lead-requests/${this.leadId}`, this.form)
      this.nextStep()
    },
    async complete ({ params, callback }) {
      let firstProductId
      let localAdditionalProductsIds
      let localParams = Object.assign({},params)
      try {
        // we catch exceptions in case no elements in array
        localAdditionalProductsIds = [...this.additionalProductsIds]
        firstProductId = localAdditionalProductsIds.shift()
      } catch (error) {}
      this.isLoader = true
      if (firstProductId !== undefined) {
        let updateResponse = await axios.post(`/api/leads/${this.leadId}/main-project-lead`, { productId: firstProductId })
        // we must save the product id as the main lead
        localParams.product_id = updateResponse.data.product_id
        if (updateResponse.data.product_id !== firstProductId) {
          localAdditionalProductsIds.push(firstProductId)
        }
      }
      axios.post(`/api/leads/${this.leadId}/users`, localParams)
        .then(res => {
          let delay = null
          delay = axios.post(`/api/leads/${this.leadId}/additional-project`, {
            additional_products: localAdditionalProductsIds
          })
          this.sendGA()

          if (res.data.redirect_to !== undefined) {
            if (delay !== null) {
              delay.then(function () { window.location.href = res.data.redirect_to })
            } else {
              window.location.href = res.data.redirect_to
            }
            return false
          }

          this.userId = res.data.id
          this.currentStep++
        })
        .catch(callback)
        .finally(() => (this.isLoader = false))

      window.storeVisitorFields(this.form)
    },
    submit () {
      return this.$refs.activeStep.nextStep()
    },
    reset () {
      this.form = Object.assign({}, {
        first_name: null,
        last_name: null,
        email: null,
        region: null,
        company: null,
        city: null,
        title: null,
        state: null,
        country: null,
        website: null,
        phone: null,
        question_asked: null,
        property_size: null,
        hotel_type: null,
        product_id: this.productId,
        category_id: this.categoryId,
        source: this.type
      }, pick(window.getVisitorData(), Object.keys(this.form)))
    },
    resendVerify ({ params, callback }) {
      axios.put(`/api/leads/${this.leadId}/users/${this.userId}`, params)
        .then(() => {
          swal({
            title: 'New email sent to you by email',
            type: 'success',
            showCancelButton: false
          })
        })
        .catch(callback)
    },

    checkExistProject () {
      this.$emit('update:isLoading', true)

      axios.get(`/api/categories/${this.categoryId}/projects`)
        .then(res => this.createLeadInProject(res.data.id))
        .catch(() => {
          this.$emit('update:isLoading', false)
        })
    },
    createLeadInProject (projectId) {
      if (!projectId) {
        this.$emit('update:isLoading', false)
        return false
      }

      axios.post(`/api/projects/${projectId}/rooms`, {
        'product_id': this.productId,
        'type': this.type
      }).then(res => {
        if (!this.isWithoutRedirect) {
          window.location.href = `/projects/${res.data.uuid}`
        } else {
          this.close()
          this.$emit('confirm')
        }
      }).catch(res => this.findProjectAndRedirect(projectId))

      this.sendGA()
    },
    findProjectAndRedirect (projectId) {
      axios.get(`/api/projects/${projectId}`)
        .then(function (res) { window.location.href = `/projects/${res.data.uuid}` })
    },
    trackSegment () {
      analytics.track('cta clicked', {
        'page_title': document.title,
        'page_url': location.href,
        'page_type': 'product',
        'product_name': this.product.name,
        'category_name': this.company.name,
        'company_name': this.category.name,
        'cta_text': this.type,
        'cta_url': 'undefined'
      })
    },
    setAdditionalProducts (ids) {
      if (ids && ids.length) this.additionalProductsIds = ids

      this.nextStep()
    },
    setRequestType (type) {
      switch (type) {
        case 'request_demo':
          return 'demo'
        case 'request_quote':
          return 'price quote'
        case 'find_reference':
          return 'find a reference'
        case 'ask_a_question':
          return 'ask a question'
      }
    },
    sendGA () {
      switch (this.type) {
        case 'request_demo':
          ga('send', 'event', 'lead request', 'submit', 'demo')
          break

        case 'request_quote':
          ga('send', 'event', 'lead request', 'submit', 'price')
          break

        case 'find_reference':
          ga('send', 'event', 'lead request', 'submit', 'reference')
          break

        case 'ask_a_question':
          ga('send', 'event', 'lead request', 'submit', 'question')
          break
      }
    }
  }
}
</script>
