<template>
  <div>
    <div v-if="!isPost" class="form-subscribe">
      <form action="#" method="POST" @submit.prevent="subscribe">
        <input v-model="email" type="email" name="email" placeholder="name@company.com">
        <input type="submit" value="subscribe">
      </form>
    </div>
    <div v-else class="email-widget">
      <span class="email-widget__title">Stay ahead of the curve with Hotel Tech Report’s monthly newsletter</span>
      <form class="email-widget-form" @submit.prevent="subscribe">
        <input v-model="email" type="email" placeholder="Work email">
        <button class="btn btn-green">
          Submit
        </button>
      </form>
    </div>
  </div>
</template>

<script>
import swal from 'sweetalert2'
import axios from 'axios'

export default {
  props: {
    isPost: {
      default: false
    }
  },

  data () {
    return {
      email: ''
    }
  },

  methods: {
    subscribe () {
      if (this.hasEmail()) {
        axios.post('/blog-subscriptions', { email: this.email })
          .then(() => {
            swal({
              title: 'You have been successfully subscribed!',
              type: 'success',
              showCancelButton: false,
              confirmButtonText: 'back to HotelTechReport.com'
            })

            this.email = ''
          })
          .catch(error => {
            swal({
              title: (error.response.data.errors !== undefined)
                ? error.response.data.errors.email[0] : error.response.data.message,
              type: 'error',
              showCancelButton: false,
              confirmButtonText: 'back to HotelTechReport.com'
            })
          })
      }
    },
    hasEmail () {
      return this.email && /[A-Z0-9._%+-]+@[A-Z0-9.-]+.[A-Z]{2,4}/igm.test(this.email)
    }
  }
}

</script>