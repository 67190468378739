<template>
  <div>
    <div class="content__box content__box-review content__box-filter">
      <div class="content__box__header">
        <div class="box__header__left-col">
          <h3>{{ allReviews.length }} {{ htrLanguage.company.verified_camel_case || 'verified_camel_case' }} {{ company.name }} {{ htrLanguage.company.reviews_camel_case || 'reviews_camel_case' }}</h3>
        </div>
        <div :class="'box__header__right-col box__header__right-col_' + codeLanguage">
          <button @click="newReview">
            {{ htrLanguage.layout.write_a_review || 'write_a_review' }}
          </button>
        </div>
      </div>
      <div class="filter-box" v-on="userIsGuest ? { click: handleGuestClick } : {}">
        <div class="col-overall">
          <span class="filter__title">{{ htrLanguage.company.likelihood_to_recommend || 'likelihood_to_recommend' }}</span>

          <div class="scrollbar">
            <ul class="review-form-area">
              <li>
                <label class="checkbox">
                  <input v-model="nps" :value="[9, 10]" type="checkbox">
                  <span class="txt">9-10</span>
                </label>
                <div class="mark-line">
                  <div class="active" :style="{width: getPercent([9, 10])}" />
                </div>
              </li>
              <li>
                <label class="checkbox">
                  <input v-model="nps" :value="[7, 8]" type="checkbox">
                  <span class="txt">7-8</span>
                </label>
                <div class="mark-line">
                  <div class="active" :style="{width: getPercent([7, 8])}" />
                </div>
              </li>
              <li>
                <label class="checkbox">
                  <input v-model="nps" :value="[5, 6]" type="checkbox">
                  <span class="txt">5-6</span>
                </label>
                <div class="mark-line">
                  <div class="active" :style="{width: getPercent([5, 6])}" />
                </div>
              </li>
              <li>
                <label class="checkbox">
                  <input v-model="nps" :value="[3, 4]" type="checkbox">
                  <span class="txt">3-4</span>
                </label>
                <div class="mark-line">
                  <div class="active" :style="{width: getPercent([3, 4])}" />
                </div>
              </li>
              <li>
                <label class="checkbox">
                  <input v-model="nps" :value="[1, 2]" type="checkbox">
                  <span class="txt">1-2</span>
                </label>
                <div class="mark-line">
                  <div class="active" :style="{width: getPercent([1, 2])}" />
                </div>
              </li>
            </ul>
          </div>
        </div>
        <div class="col-filter">
          <span class="filter__title">{{ htrLanguage.company.filter_by_property_type || 'filter_by_property_type' }}</span>
          <div class="scrollbar">
            <ul class="review-form-area">
              <li v-for="type in dictionaries.hotel_types" :key="type.value">
                <label class="checkbox">
                  <input v-model="hotelTypes" :value="type.value" type="checkbox">
                  <span class="txt">{{ htrLanguage.company['hotel_type_' + type.value] || 'value]' }} ({{ hotelTypeCount(type.value) }})</span>
                </label>
              </li>
            </ul>
          </div>
        </div>
        <div class="col-filter-brand">
          <span class="filter__title">{{ htrLanguage.company.filter_by_geography || 'filter_by_geography' }}</span>
          <div class="scrollbar">
            <ul class="review-form-area">
              <li v-for="country in countries" :key="country">
                <label class="checkbox">
                  <input v-model="hotelCountries" :value="country" type="checkbox">
                  <span class="txt">{{ country }} ({{ countryCount(country) }})</span>
                </label>
              </li>
              <li>
                <label class="checkbox">
                  <input v-model="hotelCountries" :value="null" type="checkbox">
                  <span class="txt">{{ htrLanguage.company.hotel_type_other || 'hotel_type_other' }} ({{ countryCountOther() }})</span>
                </label>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="hr" />
      <div v-if="filteredReviews.length == 0" class="filter-empty">
        <p>
          <b>
            {{ htrLanguage.company.filtered_reviews_title_1 || 'filtered_reviews_title_1' }} {{ selectedHotelTypes }} {{ htrLanguage.company.filtered_reviews_title_2 || 'filtered_reviews_title_2' }} {{ company.name }} {{ htrLanguage.company.filtered_reviews_title_3 || 'filtered_reviews_title_3' }}
          </b>
          <br>
          {{ htrLanguage.company.filtered_reviews_desc_1 || 'filtered_reviews_desc_1' }} {{ (category) ? translate(category, 'name') : '' }} {{ htrLanguage.company.filtered_reviews_desc_2 || 'filtered_reviews_desc_2' }} {{ selectedHotelTypes }} {{ htrLanguage.company.filtered_reviews_desc_3 || 'filtered_reviews_desc_3' }}?
          <a :href="linkRedirect" target="_blank">{{ htrLanguage.company.click_here || 'click_here' }}</a>
        </p>
      </div>
    </div>

    <div v-if="!isPremiumProfile && category && getCategoryReviewVolumeAlert" class="profile-notification">
      <div class="profile-notification-col">
        <img class="profile-notification__icon" src="/images/notification.svg">
        <div class="profile-notification-content">
          <span class="profile-notification__title">{{ htrLanguage.company.low_review_volume_alert || 'low_review_volume_alert' }}</span>
          <p class="profile-notification__description">
            {{ htrLanguage.company.low_review_volume_alert_1 || 'low_review_volume_alert_1' }} {{ getCategoryReviewVolumeAlert }}% {{ htrLanguage.company.low_review_volume_alert_2 || 'low_review_volume_alert_2' }}
          </p>
        </div>
      </div>
      <!--<a :href="category.url" class="profile-notification__link">View alternatives</a>-->
    </div>

    <div v-if="!isPremiumProfile && category && categoryAvengageRecency > product.reviews_recency_count" class="profile-notification">
      <div class="profile-notification-col">
        <img class="profile-notification__icon profile-notification__icon-arrow" src="/images/ico-arrow-down.svg">
        <div class="profile-notification-content">
          <span class="profile-notification__title">{{ htrLanguage.company.declining_share_of_voice || 'declining_share_of_voice' }}</span>
          <p class="profile-notification__description">
            {{ htrLanguage.company.declining_share_of_voice_1 || 'declining_share_of_voice_1' }} {{ (category) ? translate(category, 'name') : '' }} {{ htrLanguage.company.declining_share_of_voice_2 || 'declining_share_of_voice_2' }}
          </p>
        </div>
      </div>
      <!--<a :href="category.url" class="profile-notification__link">View alternatives</a>-->
    </div>

    <div v-if="product" class="content__box-reviews">
      <div v-for="(review, index) in reviews" :key="review.id">
        <product-review :key="review.id" :review="review" :product="product"></product-review>
      </div>
    </div>

    <button v-if="quantity < allReviews.length && !isFiltered" class="btn-load-more" @click.prevent="quantity += 5">
      {{ htrLanguage.company.load_more || 'load_more' }} ({{ allReviews.length - quantity }})
    </button>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { map, orderBy, uniq } from 'lodash'
import ProductReview from './ProductReview.vue'
import { EventBus } from '../../../../htr/event-bus'

export default {
  components: {
    ProductReview
  },

  props: [
    'productId',
    'categoryAvengageRecency',
    'categoryReviewVolumeAlert',
    'ids'
  ],

  data () {
    return {
      s: null,
      expanded: false,
      quantity: 6,

      nps: [],
      hotelCountries: [],
      hotelTypes: []
    }
  },
  created () {
    if (!this.product && this.productId) {
      this.fetchProductById(this.productId)
    }
  },
  computed: {
    ...mapGetters([
      'reviewsByIds',
      'productById',
      'companyById',
      'categoryById'
    ]),
    htrLanguage() {
      if (window && window.i18n) {
        const { layout, company } = window.i18n
        if (layout && company) {
          return { layout, company }
        }
      }
      return { layout: {}, company: {} }
    },
    codeLanguage() {
      if (window.Laravel.languageLocale) {
        return window.Laravel.languageLocale.code
      }
      return 'en'
    },
    product () {
      return this.productById(this.productId)
    },
    company () {
      return (this.product) ? this.companyById(this.product.company_id) : {}
    },
    category () {
      return (this.product) ? this.categoryById(this.product.category_id) : null
    },
    allReviews () {
      return orderBy(this.reviewsByIds(this.ids), 'date', 'desc')
    },
    isFiltered () {
      return this.hotelCountries.length || this.hotelTypes.length || this.nps.length
    },
    isPremiumProfile () {
      if (this.company && this.company.rules && Array.isArray(this.company.rules)) {
        return this.company.rules.find((rule) => rule === 'premium_profiles') ? true : false
      }
      return false
    },
    selectedHotelTypes () {
      return this.hotelTypes.map(type => window.i18n.company['hotel_type_' + this.dictionaries.hotel_types.find(item => item.value === type).value])
        .join(', ')
    },
    linkRedirect () {
      if (this.category) {
        return this.category.url + '?property_type=' + this.selectedHotelTypesFirst
      }
      return '/companies?property_type=' + this.selectedHotelTypesFirst
    },
    selectedHotelTypesFirst () {
      return (this.hotelTypes[0]) ? this.hotelTypes[0] : null
    },
    reviews () {
      if (this.isFiltered) {
        return this.filteredReviews
      }

      return this.allReviews.slice(0, this.quantity)
    },
    filteredReviews () {
      return this.allReviews.filter(review => {
        let filtered = true

        if (review.hotel) {
          let hasHotelType = false
          if (this.hotelTypes.length > 0) {
            this.hotelTypes.forEach((item) => {
              hasHotelType = hasHotelType || this.checkIfHasHotelType(review, item)
            })
          }
          filtered = filtered &&
            ((this.hotelCountries.length) ? this.hotelCountries.includes(review.hotel.country) : true) &&
            ((this.hotelTypes.length) ? hasHotelType : true)
        } else if (this.hotelCountries.length || this.hotelTypes.length) {
          return false
        }

        if (this.nps.length) {
          let nps = [].concat.apply([], this.nps)
          filtered = filtered && nps.includes(review.nps)
        }
        return filtered
      })
    },
    filteredReviewsWithoutNps () {
      return this.allReviews.filter(review => {
        if (review.hotel) {
          let hasHotelType = false
          if (this.hotelTypes.length > 0) {
            this.hotelTypes.forEach((item) => {
              hasHotelType = hasHotelType || this.checkIfHasHotelType(review, item)
            })
          }
          return ((this.hotelCountries.length) ? this.hotelCountries.includes(review.hotel.country) : true) &&
            ((this.hotelTypes.length) ? hasHotelType : true)
        } else if (this.hotelCountries.length || this.hotelTypes.length) {
          return false
        }

        return true
      })
    },
    getCategoryReviewVolumeAlert () {
      if (this.categoryReviewVolumeAlert) {
        return this.categoryReviewVolumeAlert
      } else {
        return 0
      }
    },
    dictionaries () {
      return window.Laravel.dictionaries
    },
    countries () {
      return uniq(map(this.allReviews.filter(review => review.hotel), review => review.hotel.country))
    },
    userIsVendor () {
      return window.Laravel.isVendor
    },
    userIsGuest () {
      return !window.Laravel.userId
    },

  },

  methods: {
    ...mapActions(['fetchProductById']),
    checkIfHasHotelType(review, hotelType) {
      let hasHotelType = false
      if (review.hotel &&
        ((review.hotel.hotel_type === null && hotelType === 'other') ||
          (review.hotel.hotel_type && review.hotel.hotel_type === hotelType))) {
        hasHotelType = true
      }
      if (!hasHotelType &&
        review.hotel &&
        review.hotel.hotel_types_array &&
        typeof review.hotel.hotel_types_array === 'string') {
          try {
            if (JSON.parse(review.hotel.hotel_types_array).includes(hotelType)) {
              hasHotelType = true
            }
          } catch (e) {
            //Do nothing, the value was not a JSON value
          }
      }
      return hasHotelType
    },
    translate (entry, field) {
      let translation = entry[field]
      if (entry.translations && entry.translations.length > 0) {
        if (window.Laravel.languageLocale) {
          const searchTranslation = entry.translations.find((findTranslate) => findTranslate.language_id === window.Laravel.languageLocale.id)
          if (searchTranslation) {
            translation = searchTranslation[field]
          }
        }
      }
      return translation
    },
    openPopupIsVendor () {
      this.$root.addModalToQueue('modal-is-vendor')
    },
    newReview () {
      location.href = `/write-a-review/${this.company.slug}`
    },
    hotelTypeCount (hotelType) {
      return this.allReviews.filter((review) => {
        return this.checkIfHasHotelType(review, hotelType)
      }).length
    },
    countryCount (country) {
      return this.allReviews.filter(
        review => review.hotel && review.hotel.country && review.hotel.country === country
      ).length
    },
    countryCountOther () {
      return this.allReviews.filter(
        review => !review.hotel || (review.hotel && !review.hotel.country)
      ).length
    },
    getPercent (rating) {
      return Math.floor(
        (this.filteredReviewsWithoutNps.filter(review => rating.includes(review.nps)).length / this.filteredReviewsWithoutNps.length) * 100
      ) + '%'
    },
    handleGuestClick (e) {
      if (e.target.classList.contains('txt')) {
        e.preventDefault()

        this.openModalCreateAccount()
      }
    },
    openModalCreateAccount () {
      EventBus.$emit('open-modal-sidebar', {
        type: 'create-account',
        params: {
          redirectToCurrentPage: true
        }
      })
    }
  }
}
</script>
