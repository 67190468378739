<template>
  <div class="modal-create-account-body">
    <div class="form-group" :class="{success: form.email && isBusinessEmail}">
      <input v-model="form.email" type="email" :class="{'is-empty': !form.email, 'error': type('email')}">
      <span class="field__title">Work Email</span>
    </div>
    <div class="form-group" :class="{success: form.password}">
      <input v-model="form.password" type="password" :class="{'is-empty': !form.password, 'error': type('password')}">
      <span class="field__title">Password</span>
    </div>
    <label class="form-group form-group-checkbox">
      <input type="checkbox" v-model="form.remember">
      <span class="custom-checkbox"/>
      Remember me
    </label>
    <button class="btn-link" @click="openSidebarModal('forgot-password')">
      Forgot password?
    </button>
    <button class="btn-blue" @click="auth">
      Login
    </button>

  </div>
</template>

<script>
import { map } from 'lodash'
import checkEmail from '../../../../../mixins/checkEmail'

export default {
  mixins: [
    checkEmail
  ],

  data: () => ({
    form: {
      remember: true
    },
    errors: []
  }),
  computed: {
    isBusinessEmail () {
      return this.checkBusinessEmail(this.form.email)
    }
  },

  methods: {
    openSidebarModal (title) {
      this.$emit('open-sidebar-modal', title)
    },
    auth () {
      this.$emit('error-message', null)

      this.$emit('auth', {
        params: this.form,
        callback: error => {
          let message = 'An Error Occurred'
          if (error.response.data.errors) {
            this.errors = error.response.data.errors
            message = map(error.response.data.errors, errors => errors.join(', ')).join('<br>')
          }

          this.$emit('error-message', message)
        }
      })
    },
    type (field) {
      return this.message(field)
    },
    message (field) {
      return this.errors[field] && this.errors[field].join(', ')
    }
  }
}
</script>
