export default class StickyNavCategory {
  constructor () {
    if ($('.sub-nav-subcategory').length) {
      window.addEventListener('scroll', this.sidebarSticky.bind(this))
    }
  }

  sidebarSticky () {
    const nav = $('.sub-nav-subcategory-box')
    let navStart = $('.sub-nav-subcategory').offset().top
    let scroll = $(document).scrollTop()
    if (scroll > navStart) {
      nav.addClass('active')
    }
    if (scroll < navStart) {
      nav.removeClass('active')
    }
  }
}
