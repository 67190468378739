<template>
  <div>
    <div v-if="posts.length" class="articles">
      <article v-for="post in posts" :key="post.id" class="article">
        <div class="img">
          <a :href="post.url">
            <img :src="post.cover_image" width="171" height="125">
          </a>
        </div>
        <div class="des">
          <ul v-if="post.first_category" class="category">
            <li><a :href="post.first_category.blog_url">{{ post.first_category.name }}</a></li>
          </ul>
          <h3><a class="article__shot-description" :href="post.url">{{ post.title }}</a></h3>
          <div class="author">
            by
            <div v-if="post.is_claimed && post.user" class="author-name">
              {{ post.user.full_name }}
            </div>
            <div v-if="!post.is_claimed" class="author-name">
              Hotel Tech Report
            </div>
          </div>
          <p class="article__shot-description">
            {{ post.seo_description }}
          </p>
        </div>
      </article>
    </div>
    <span class="load-more">
      <button v-show="page < lastPage" @click="load">Load more</button>
    </span>
  </div>
</template>

<script>
import axios from 'axios'

export default {

  filters: {
    isShortText (text, length) {
      if (!text) return ''
      return text.substring(0, length) + '...'
    }
  },
  data: () => ({
    posts: [],
    page: 1,
    lastPage: 2
  }),

  methods: {
    load () {
      this.page++

      axios.get(`/api/recent-posts?page=${this.page}`)
        .then(res => {
          this.lastPage = res.data.last_page
          this.posts = [...this.posts, ...res.data.data]
        })
    }
  }
}
</script>
