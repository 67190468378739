<script>
import { EventBus } from '../../event-bus'

export default {
  methods: {
    openLeadModalProducts (categoryId, productIds, type) {
      EventBus.$emit('open-modal-sidebar', {
        type: 'modal-lead',
        params: {
          categoryId: categoryId,
          productId: productIds[0],
          extraProducts: productIds.slice(1),
          type: type
        }
      })
    },
    openLeadModal (categoryId, productId, type) {
      if (window.Laravel.isVendor) return this.$root.addModalToQueue('modal-is-vendor')

      EventBus.$emit('open-modal-sidebar', {
        type: 'modal-lead',
        params: {
          categoryId: categoryId,
          productId: productId,
          type: type
        }
      })
    },
  }
}
  </script>
