<template>
  <div v-show="countStart < allCount" class="box__header__right-col">
    <a v-if="value == countStart" href="#" @click.prevent="$emit('input', allCount)">
      <slot>
        View all ({{ allCount }})
      </slot>
    </a>
    <a v-else href="#" @click.prevent="$emit('input', countStart)">
      View less
    </a>
  </div>
</template>

<script>
export default {
  props: [
    'allCount',
    'value',
    'isAll'
  ],

  data: () => ({
    countStart: 10
  }),

  mounted () {
    this.countStart = this.value

    if (this.isAll) {
      this.$emit('input', this.allCount)
    }
  }
}
</script>
