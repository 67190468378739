<template>
  <div />
</template>
<script>
import swal from 'sweetalert2'
export default {
  data () {
    return {
    }
  },
  mounted () {
    this.setSaveEvent()
  },
  methods: {

    setSaveEvent () {
      // this is hacky, but it is fastest b/c they dont want us spending a lot of time

      $('.save-and-publish').on('click', e => {
        e.preventDefault()
        var form = $('#edit-content')
        form.submit()
      })

      if (window.location.search.indexOf('edit=success') > -1) {
        swal({
          title: 'You successfully edited the content of your post.',
          type: 'success',
          showCancelButton: false,
          confirmButtonText: 'back to HotelTechReport.com'
        })
      }
    }
  }
}
</script>