<script>
import CategoryFilter from './Blog/CategoryFilter.vue'

export default {
  components: {
    CategoryFilter
  },

  props: [
    'category'
  ],

  data: () => ({
    categorySelected: ''
  }),

  computed: {
    url () {
      if (this.categorySelected) {
        return '/category/' + this.categorySelected
      }

      return '/blog'
    }
  },

  mounted () {
    this.categorySelected = this.category
  },

  methods: {
    categorySelect (value) {
      this.categorySelected = value
      this.redirectCategoryPage()
    },
    redirectCategoryPage () {
      window.location.href = this.url
    }
  }
}
</script>
