<script>
import ToggleViewCount from '../Utils/ToggleViewCount.vue'
import ReadMore from './../Utils/ReadMore.vue'
import { EventBus } from '../../event-bus.js'

export default {
  components: {
    ToggleViewCount,
    ReadMore
  },

  data () {
    return {
      showCount: {
        features: 5,
        customers: 4,
        references: 1
      }
    }
  },

  methods: {
    openLeadModal (categoryId, productId, type) {
      if (window.Laravel.isVendor) return this.$root.addModalToQueue('modal-is-vendor')
      EventBus.$emit('open-modal-sidebar', {
        type: 'modal-lead',
        params: {
          categoryId: categoryId,
          productId: productId,
          type: type
        }
      })
    },
    openLeadModalProducts (categoryId, productIds, type) {
      EventBus.$emit('open-modal-sidebar', {
        type: 'modal-lead',
        params: {
          categoryId: categoryId,
          productId: productIds[0],
          extraProducts: productIds.slice(1),
          type: type
        }
      })
    }
  }
}
</script>
