import Vue from 'vue'

import vueSlider from 'vue-slider-component'
import CopyButton from '../components/CopyButton.vue'
import SquaresRating from '../components/SquaresRating.vue'
import Stars from './components/Utils/Stars.vue'
import IntercomButton from '../components/IntercomButton.vue'
import ProductAutocomplete from '../components/ProductAutocomplete.vue'
import EndorsementButton from './components/Utils/EndorsementButton.vue'
import UploadcareButton from './components/Utils/UploadcareButton.vue'
import Editor from '../components/Editor.vue'
import Modal from './components/Utils/Modal.vue'
import SwalModal from './components/SwalModal.vue'
import Places from './components/Utils/Places.vue'
import VerificationModal from './components/VerificationModal.vue'
import VerificationPanel from './components/VerificationPanel.vue'
import navbar from './components/Utils/VendorDashboardBar/navbar.vue'
import Product from './components/Pages/Product.vue'
import Compare from './components/Pages/Compare.vue'
import BuyersGuide from './components/Pages/BuyersGuide.vue'
import CompanyIntegrationsPage from './components/Pages/CompanyIntegrationsPage.vue'
import SearchPage from './components/Pages/Search.vue'
import Report from './components/Pages/Report.vue'
import CategoriesPage from './components/Pages/Categories.vue'
import Search from './components/Pages/Compare/Search.vue'
import FinalistAwards from './components/Pages/Awards/FinalistAwards.vue'
import Blog from './components/Pages/Blog.vue'
import Project from './components/Pages/Project.vue'
import BrowseCategories from './components/Pages/BrowseCategories.vue'
import IntegrationsSearch from './components/Pages/IntegrationsSearch.vue'
import SearchBar from './components/Pages/Integrations/SearchBar.vue'
import PostEditor from './components/PostEditor.vue'
import Steps from './components/write-a-review/Steps.vue'
import Step1 from './components/write-a-review/Step1.vue'
import Step2 from './components/write-a-review/Step2.vue'
import Step3 from './components/write-a-review/Step3.vue'
import Step4 from './components/write-a-review/Step4.vue'
import ReviewForm from './components/write-a-review/ReviewForm.vue'
import WriteRecommendation from './components/Pages/WriteRecommendation.vue'
import RequestAccessModal from './components/Pages/Product/RequestAccessModal.vue'
import RequestAccessSentModal from './components/Pages/Product/RequestAccessSentModal.vue'
import EmailConfirmationRequestedModal from './components/Pages/Product/EmailConfirmationRequestedModal.vue'
import BlogSubscribe from './components/BlogSubscribe.vue'
import EditBlogPost from './components/edit-blog-post/EditBlogPost.vue'
import ArticleCredit from './components/edit-blog-post/ArticleCredit.vue'
import DateSelector from './components/edit-blog-post/write-blog-post/DateSelector.vue'
import Location from './components/edit-blog-post/write-blog-post/Location.vue'
import CategorySelector from './components/edit-blog-post/write-blog-post/CategorySelector.vue'
import Subscribe from './components/Common/Subscribe.vue'
import FeaturedProductsSidebar from './components/Common/FeaturedProductsSidebar.vue'
import SoftwareFinders from './components/Common/SoftwareFinders.vue'
import ModalSidebar from './components/Utils/ModalSidebar.vue'
import PopupIsVendor from './components/Utils/PopupIsVendor.vue'
import InitLoader from './components/Utils/InitLoader.vue'
import ToggleViewCount from './components/Utils/ToggleViewCount.vue'
import ReadMore from './components/Utils/ReadMore.vue'
import ProductAlternatives from './components/Pages/ProductAlternatives.vue'
import ReviewsSuccessModal from './components/Common/ReviewsSuccessModal.vue'
import TripadvisorAutocomplete from './components/Pages/TripadvisorAutocomplete.vue'
/** Utils **/
Vue.component('slider', vueSlider)
Vue.component('copy-button', CopyButton)
Vue.component('squares-rating', SquaresRating)
Vue.component('stars-rating', Stars)
Vue.component('intercom-button', IntercomButton)
Vue.component('products-autocomplete', ProductAutocomplete)
Vue.component('endorsement-button', EndorsementButton)
Vue.component('file-uploader', UploadcareButton)
Vue.component('editor', Editor)
Vue.component('modal', Modal)
Vue.component('swal-modal', SwalModal)
Vue.component('places-autocomplete', Places)

Vue.component('verification-modal', VerificationModal)
Vue.component('verification-panel', VerificationPanel)
Vue.component('vendor-dashboard-navbar', navbar)

Vue.component('product-page', Product)
Vue.component('compare-page', Compare)

Vue.component('buyers-guide-page', BuyersGuide)

Vue.component('company-integrations-page', CompanyIntegrationsPage)

Vue.component('search-page', SearchPage)

Vue.component('report-page', Report)
Vue.component('categories-page', CategoriesPage)
Vue.component('search-for-compare', Search)
Vue.component('finalist-awards', FinalistAwards)
Vue.component('blog', Blog)
Vue.component('project-page', Project)
Vue.component('browse-categories', BrowseCategories)

Vue.component('integrations-search', IntegrationsSearch)
Vue.component('integrations-search-bar', SearchBar)

Vue.component('post-editor', PostEditor)

Vue.component('steps', Steps)
Vue.component('step-1', Step1)
Vue.component('step-2', Step2)
Vue.component('step-3', Step3)
Vue.component('step-4', Step4)
Vue.component('review-form', ReviewForm)

Vue.component('write-recommendation', WriteRecommendation)

Vue.component('request-access-modal', RequestAccessModal)
Vue.component('request-access-sent-modal', RequestAccessSentModal)
Vue.component('email-confirmation-requested-modal', EmailConfirmationRequestedModal)
Vue.component('blog-subscribe', BlogSubscribe)
Vue.component('edit-blog-post', EditBlogPost)
Vue.component('article-credit', ArticleCredit)
Vue.component('write-blog-post-date-selector', DateSelector)
Vue.component('write-blog-post-location', Location)
Vue.component('write-blog-post-category-selector', CategorySelector)
Vue.component('subscribe-block', Subscribe)
Vue.component('featured-product-sidebar', FeaturedProductsSidebar)
Vue.component('software-finders', SoftwareFinders)
Vue.component('modal-sidebar', ModalSidebar)
Vue.component('modal-is-vendor', PopupIsVendor)
Vue.component('init-loader', InitLoader)
Vue.component('toggle-view', ToggleViewCount)
Vue.component('read-more', ReadMore)
Vue.component('product-alternatives', ProductAlternatives)
Vue.component('review-success-modal', ReviewsSuccessModal)
Vue.component('htr-tripadvisor-autocomplete', TripadvisorAutocomplete)
