<template>
  <div class="modal-create-account">
    <div class="modal-create-account__top">
      <div v-if="activeStep === 'sign-in'">
        Don’t have an account?
        <button class="btn-link" @click="openPopup('create-account')">
          Sign Up
        </button>
      </div>
      <div v-if="activeStep === 'forgot-password'">
        Remember your password?
        <button class="btn-link" @click="setActiveStep('sign-in')">
          Login
        </button>
      </div>

      <button @click="close">
        <i class="icon-cross" />
      </button>
    </div>

    <p class="error" :class="{'active': errorMessage}" v-html="errorMessage" />

    <div v-if="activeStep === 'sign-in'">
      <div class="modal-lead__header">
        <h4 class="modal-lead__title">
          Login to your account
        </h4>
        <p class="modal-lead__description">
          Many features on Hotel Tech Report are only available to hoteliers.
          Sign up with your work email to access them. test
        </p>
      </div>
      <div class="modal-lead__content">
        <div :is="activeStep" @open-sidebar-modal="setActiveStep" @auth="auth"
             @error-message="setErrorMessage" />

        <div class="vendor">
          <i class="icon-shop" />
          <div>
            Are you a vendor?
            <a href="https://partners.hoteltechreport.com/list-a-product/" target="_blank">List a Product</a>
            or
            <a href="https://help.hoteltechreport.com/faqs/getting-started-faqs/how-to-claim-your-profiles"
               target="_blank">Claim Your Profile</a>
          </div>
        </div>

        <div class="featured-account">
          <span class="featured-account__title">Sign Up to Get:</span>
          <div class="featured-account__item">
            Personalized recommendations based on thousands of data points
            and
            reviews
          </div>
          <div class="featured-account__item">
            Free access to premium content, reports and buyers guides
          </div>
          <div class="featured-account__item">
            Free hotelier dashboard to unlock: price quotes, demos,
            references
            and vendor selection tools
          </div>
        </div>
        <div class="modal-lead__footer">
          <span class="modal-lead__footer__title">As seen in</span>
          <div class="modal-lead__companies">
            <img class="modal-lead__companies__logo" src="/images/company-logo2.png">
            <img class="modal-lead__companies__logo" src="/images/company-logo2.png">
            <img class="modal-lead__companies__logo" src="/images/company-logo2.png">
            <img class="modal-lead__companies__logo" src="/images/company-logo2.png">
          </div>
        </div>
      </div>
    </div>
    <div :is="activeStep"
         v-if="activeStep === 'forgot-password'"
         @open-sidebar-modal="setActiveStep"
         @reset-password="resetPassword"
         @error-message="setErrorMessage" />
  </div>
</template>

<script>
import axios from 'axios'
import swal from 'sweetalert2'

import SignIn from './AccountSteps/LogIn.vue'
import ForgotPassword from './AccountSteps/ForgotPassword.vue'

export default {

  components: {
    SignIn,
    ForgotPassword
  },
  props: [
    'step'
  ],

  data: () => ({
    activeStep: 'sign-in',
    userId: null,
    errorMessage: null
  }),

  mounted () {
    if (this.step) this.setActiveStep(this.step)
  },

  methods: {
    close () {
      this.$emit('close')
    },
    setActiveStep (step) {
      this.errorMessage = null
      this.activeStep = step
    },
    setErrorMessage (message) {
      this.errorMessage = message
    },
    openPopup (type) {
      this.$emit('open-popup', {
        type: type,
        params: {}
      })
    },
    auth ({ params, callback }) {
      axios.post('/login', params)
        .then(() => window.location.reload())
        .catch(callback)
    },
    resetPassword ({ params, callback }) {
      axios.post('/password/email', params)
        .then(res => {
          swal({
            title: 'Success',
            text: res.data.message,
            type: 'success',
            showCancelButton: false
          })
        })
        .catch(callback)
    }
  }
}
</script>
