<template>
  <div class="width865px marginTop50px marginBottom50px categoriesDescriptionContainer">
    <div class="marginBottom10px">
      <span class="awardsTitle">
        Top Rated {{ categoryName }} Products of {{ categoryYear }}
      </span>
    </div>
  </div>
</template>
<style scoped>
  .deleteBorderBottom {
    border-bottom: none;
  }
</style>
<script>
export default {
  props: [
    'categoryName',
    'categoryOrder',
    'categoryYear'
  ]
}
</script>
