import Vue from 'vue'

import './bootstrap'
import './components'
import './directives'
import '../chameleon'
import '../visitor-data-cache'
import '../filters'
import store from '../store'

import VueScrollTo from 'vue-scrollto'

import VueScrollactive from 'vue-scrollactive'

import canvasConfetti from 'canvas-confetti'

import Carousel3d from 'vue-carousel-3d';

// SHOULD BE CLEAN ABOVE THIS LINE
import MainMenu from './classes/MainMenu'
import MobileNavigation from './classes/MobileNavigation'
import FitVids from './classes/FitVids'
import MoreLessText from './classes/MoreLessText'
import SmoothAnchor from './classes/smoothAnchor'
import NonVendorsOnly from './classes/NonVendorsOnly'
import BlogPostClaim from './classes/BlogPostClaim'
import Marquee from './classes/Marquee'
import ProductHeader from './classes/ProductHeader'
import ProductSidebar from './classes/ProductSidebar'
import DropdownCategoriesHeader from './classes/DropdownCategoriesHeader'
import Ticker from './classes/Ticker'
import NewsSidebar from './classes/NewsSidebar'
import SlideScreenchots from './classes/SlideScreenchots'
import StickyNavCategory from './classes/StickyNavCategory'
import StickyNavCompare from './classes/StickyNavCompare'
import './classes/claimProfileModal'
import './classes/ChangeLanguage'

import './classes/ModalPriceDemo'
import './classes/CloseModal'

import './classes/ModalReviewSuccess'

import 'flowbite'

import { EventBus } from './event-bus'

import Buefy from 'buefy'
import Affix from 'vue-affix'

import VueInstantSearch from 'vue-instantsearch'
import {mapActions} from "vuex";
Vue.use(Buefy, { defaultIconPack: 'fa' })
Vue.use(VueInstantSearch)
Vue.config.ignoredElements = ['trix-editor']
Vue.use(VueScrollTo)
Vue.use(VueScrollactive)
Vue.use(Carousel3d)
Vue.use(Affix)
if (jQuery('.post-press-release').length > 0) {
  jQuery(document).ready(() => {
    jQuery('#submit-blog-post-form').validate({
      ignore: [],
      highlight: function (element, errorClass) {
        jQuery(element).parents('.validation').addClass('error')
        jQuery(element).parents('.review-type').addClass('error')
      },
      unhighlight: function (element, errorClass) {
        jQuery(element).parents('.validation').removeClass('error').addClass('success')
        jQuery(element).parents('.review-type').removeClass('error')
      }
    })
    jQuery('.submit-blog-post').on('click', (e) => {
      if (jQuery('#submit-blog-post-form').valid()) {

      } else {
        e.preventDefault()
        let tag = $('.review-form')
        $('html,body').animate({ scrollTop: tag.offset().top }, 'slow')
      }
    })
  })
}
window.confetti = canvasConfetti
window.showLoginModal = false
try {
  window.app = {
    initialized: true,
    isDebug: true
  }
} catch (e) {
  // IE makes some trouble with code above
}
window.addEventListener("load", (event) => {
  if (window.queue.length) {
    for (var i in window.queue) {
      window.queue[i]()
    }
  }
});

window.store = {
  showRequestEditAccessModal: false,
  showAccessRequestedModal: window.location.search.indexOf('access-request') > -1,
  showEmailConfirmationRequestedModal: false,
  company: window.company !== 'undefined' ? window.company : {}
}

/* eslint-disable no-new */
new Vue({
  el: '#app',

  store,

  data: {
    shared: window.store,
    activeModal: null,
    modalQueue: []
  },

  computed: {
    userId () {
      return window.Laravel.userId || null
    },
    query () {
      return window.Laravel.query || null
    },
    activeModalInQueue () {
      return this.modalQueue.length ? this.modalQueue[0] : null
    }
  },

  created () {
    EventBus.$on('set-active-modal', modalName => (this.activeModal = modalName))
    EventBus.$on('add-modal-to-queue', modal => {
      this.addModalToQueue(modal)
      this.modalQueue.push(modal)
    })
    if (this.query) {
      this.fetchSearchProducts(this.query)
      this.fetchSearchResources(this.query)
      this.fetchSearchArticles(this.query)
      this.fetchSearchCategories(this.query)
    }
  },

  methods: {
    ...mapActions([
      'fetchSearchProducts',
      'fetchSearchResources',
      'fetchSearchArticles',
      'fetchSearchCategories',
    ]),
    showRequestAccessModal () {
      this.shared.showRequestEditAccessModal = true
    },

    addModalToQueue (modal) {
      this.modalQueue.push(modal)
    },
    shiftModalQueue () {
      this.modalQueue.shift()
    },

    ctaRequestDemo (categoryId, productId) {
      this.openLeadModal('modal-lead', {
        categoryId: categoryId,
        productId: productId,
        type: 'request_demo'
      })
    },
    openLeadModal (type, params) {
      if (window.Laravel.isVendor) return this.addModalToQueue('modal-is-vendor')

      EventBus.$emit('open-modal-sidebar', {
        type: type,
        params: params
      })
    },
    openContact () {
      window.Intercom('showNewMessage')
    }
  }
})

// click event that prevents vendors from performing certain actions
// https://app.codemyviews.com/project/hotel-tech-reviews/to-dos/10802
NonVendorsOnly.init()

new MainMenu()
new MobileNavigation()
new FitVids()
new MoreLessText()
new SmoothAnchor()
new Marquee()
new ProductHeader()
new ProductSidebar()
new DropdownCategoriesHeader()
new Ticker()
new NewsSidebar()
new SlideScreenchots()
new StickyNavCompare()
new StickyNavCategory()

let boxItems = $('.blog-list li a')
let maxHeight = 0

BlogPostClaim.init()

boxItems.get().forEach((item, index) => {
  let box = $(item)

  if (box.height() > maxHeight) {
    maxHeight = box.height()
  }
})

boxItems.height(maxHeight)

$('.js-cta-form').on('click', (event) => {
  // Secure for vendor
  if (window.Laravel.isVendor) {
    event.preventDefault()
    return false
  }

  return true
})
/*
// NEW COMPONENTS
*/

// ----------------------------------------------
//  START - CATEGORIES - SECTION
// ----------------------------------------------
function printHomeSubcategories (element, viewAll) {
  let html = '';
  let categories;
  let subcategories;
  var hasMoreThanFive = false;
  const categoryId = element[0].id.split('_')[2];
  if (window.commonLaravel && Array.isArray(window.commonLaravel.categories)) {
    categories = window.commonLaravel.categories.find((category) => category.id === Number(categoryId));
    if (categories) {
      subcategories = categories.children;
    } else {
      subcategories = [];
    }
  } else {
    subcategories = [];
  }
  if (subcategories.length > 5) {
    hasMoreThanFive = true;
  }
  subcategories.forEach( (subcategory, index) => {
    if (index > 4 && viewAll === false) {
      html += '';
    } else {
      html += `
        <li id="${ subcategory.id }">
          <a href="${ subcategory.url }"> ${ subcategory.name } </a>
        </li>`;
    }
  });
  if (!hasMoreThanFive) {
    element.html(html);
    return true;
  }

  if (viewAll === true) {
    html += `
        <button>
          <span parent-id="${ element[0].id }" view-more="true" class="popular-categories-view-more"> ${ window.i18n.home.view_less } </span>
        </button>`;
  } else {
    html += `
        <button>
          <span parent-id="${ element[0].id }" view-more="false" class="popular-categories-view-more"> ${ window.i18n.home.view_more } </span>
        </button>`;
  }
  element.html(html);
  return true;
};

$("document").ready(() => {
  $('body').on('click', '.popular-categories-view-more', (event) => {
    const parentId = $(event.target).attr("parent-id");
    let parentUl = $(`#${parentId}`);

    if (!parentId || !parentUl) {
      return false;
    }
    const viewMoreState = parentUl.attr("view-more");
    if (viewMoreState === "false") {
      printHomeSubcategories(parentUl, true);
      parentUl.attr("view-more", "true");
    } else {
      printHomeSubcategories(parentUl, false);
      parentUl.attr("view-more", "false");
    }
    return true;
  });
  let params = new URLSearchParams(window.location.search)
  if (params.has('isReviewSuccess') && window.htr_showModalReviewSuccess) {
    window.htr_showModalReviewSuccess(params.get('isReviewSuccess'))
  }
});

// ----------------------------------------------
//  END - CATEGORIES - SECTION
// ----------------------------------------------

$("[collectible-image='collectible']").click(function(e) {
  var modal = document.getElementById("modal_container_" + e.target.id);
  var captionText = document.getElementById("collectible-caption");
  modal.style.display = "block";
  captionText.innerHTML = this.alt;
});

$("[collectible-image='modal']").click(function(event) {
  const modal = document.getElementById(event.target.getAttribute('modal-id'));
  modal.style.display = "none";

});

// ----------------------------------------------
//  END - POST TITLES - SECTION
// ----------------------------------------------


const slider = document.querySelector('.items');
let isDown = false;
let startX;
let scrollLeft;

if (slider) {
  slider.addEventListener('mousedown', (e) => {
    isDown = true;
    slider.classList.add('active');
    startX = e.pageX - slider.offsetLeft;
    scrollLeft = slider.scrollLeft;
  });
  slider.addEventListener('mouseleave', () => {
    isDown = false;
    slider.classList.remove('active');
  });
  slider.addEventListener('mouseup', () => {
    isDown = false;
    slider.classList.remove('active');
  });
  slider.addEventListener('mousemove', (e) => {
    if(!isDown) return;
    e.preventDefault();
    const x = e.pageX - slider.offsetLeft;
    const walk = (x - startX) * 3; //scroll-fast
    slider.scrollLeft = scrollLeft - walk;
  });
}


function integrateGoogleMaps(elementId) {
  if (!$('#'+elementId).length) {
    return 0;
  }

  const autocomplete = new google.maps.places.Autocomplete(document.getElementById(elementId))
  autocomplete.addListener('place_changed', () => {
    setAddressToWindowSelectData(autocomplete.getPlace());
  })
}

function setAddressToWindowSelectData (address) {
  const addressMappings = {
    locality: 'city',
    country: 'country',
    route: 'region',
    administrative_area_level_1: 'state',
    administrative_area_level_3: 'city',
    sublocality_level_2: 'city'
  }

  const location = {
    address: address.formatted_address,
    city: null,
    country: null,
    state: null,
    region: null,
    phone: address.international_phone_number || address.formatted_phone_number || null,
    website: address.website,
    name: address.name || null
  }

  address.address_components.forEach( item => {
    if (addressMappings.hasOwnProperty(item.types[0])) {
      location[addressMappings[item.types[0]]] = item.long_name || null
    }
  })
  $('#hotel_finish_account').val(JSON.stringify(location))
}

$('#continue-signup').click(() => {
  const email = $('#sign-up-email').val()
  if (email.includes('@') && email.split('@').length === 2 && email.split('@')[1]) {
    if ($('#checkbox-stay-in-loop').is(':checked')) {
      const url = window.location.href
      $.post('/register-neswleter', {
        'email': email,
        'signup_url': url
      })
    }
  } else {
    $('#error-validated').show()
    $('#sign-up-email').addClass('login-page-error-validation')
  }
})

$('#send-verification-login-page').click(() => {
  if (!$('#first_name').val()) {
    $('#first_name').addClass('login-page-error-validation')
  }

  if (!$('#last_name').val()) {
    $('#last_name').addClass('login-page-error-validation')
  }

  if (!$('#job_title').val()) {
    $('#job_title').addClass('login-page-error-validation')
  }
})

$('#login-login-page').click(() => {
  const email = $('#email').val()
  if (!email || !email.includes('@') || !email.split('@').length === 2 || !email.split('@')[1]) {
    $('#error-validated-email').show()
    $('#email').addClass('login-page-error-validation')
  }
  if (!$('#password').val() || $('#password').val().length < 8) {
    $('#error-validated-password').show()
    $('#password').addClass('login-page-error-validation')
  }
})

$('#hotelier').click(() => {
  if ($('#hotelier').is(':checked')) {
    const checkboxes = $('.checkbox-finish-account')
    checkboxes.get().forEach((checkbox) => {
      let item = $(checkbox)
      if (item.attr('id') !== 'hotelier') {
        item.prop('checked', false)
      }
    })
  }
})

$('#vendor').click(() => {
  if ($('#vendor').is(':checked')) {
    const checkboxes = $('.checkbox-finish-account')
    checkboxes.get().forEach((checkbox) => {
      let item = $(checkbox)
      if (item.attr('id') !== 'vendor') {
        item.prop('checked', false)
      }
    })
  }
})

$('#other').click(() => {
  if ($('#other').is(':checked')) {
    const checkboxes = $('.checkbox-finish-account')
    checkboxes.get().forEach((checkbox) => {
      let item = $(checkbox)
      if (item.attr('id') !== 'other') {
        item.prop('checked', false)
      }
    })
  }
})

$('#send-verification-email').click(() => {
  const email = $('#forgot-email').val()
  localStorage.setItem('tempEmailResetPw', email)
})

integrateGoogleMaps('login-google-places-autocomplete')
