<template>
  <modal :opened="visible">
    <div class="htr-form modal-access modal-access-sent">
      <div class="icon-hold">
        <img src="/images/access-sent-modal.svg" alt="HotelTechReport">
      </div>
      <h2>Profile claim request submitted</h2>
      <p class="modal-access__description">We’ll notify you within 48-hours once we’ve been able to verify you as an employee of {{ company.name }} and will send along instructions of how to access the vendor dashboard.</p>
      <a href="#" class="btn btn-blue" @click.prevent="closeModal()">Back to Hotel Tech Report</a>
    </div>
  </modal>
</template>
<script>
export default {
  props: ['visible'],
  data () {
    return {
      company: window.company
    }
  },
  methods: {
    closeModal () {
      window.store.showAccessRequestedModal = false
    }
  }
}
</script>
