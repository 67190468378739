<template>
  <div class="recommendation-page">
    <section class="recommendations-hero">
      <div class="container">
        <div>
          <h1 class="recommendations-hero__title">Partner recommendations</h1>
          <p class="recommendations-hero__subtitle">Educate buyers and strengthen your partnerships by sharing your
            expertise and insights through partner recommendations.
          </p>
          <a href="#search" class="btn-green">Recommend a Partner</a>
        </div>
        <img src="/images/recommendations-hero.png">
      </div>
    </section>
    <div class="recommendations-how-works">
      <div class="container">
        <h3 class="recommendations-how-works__title">How it works</h3>
        <div class="recommendations-how-works__item">
          <div class="recommendations-how__item__title">Recommend your partners</div>
          <p>Strengthen your partnerships by recommending them on HTR</p>
        </div>
        <div class="recommendations-how-works__item">
          <div class="recommendations-how__item__title">Become a thought leader</div>
          <p>Educate in-market buyers and build your brand as a thought leader</p>
        </div>
        <div class="recommendations-how-works__item">
          <div class="recommendations-how__item__title">Get recommended back</div>
          <p>Recommend your partners and get recommended back</p>
        </div>
      </div>
    </div>

    <div class="container container-sm">
      <br>
      <br>
      <company-picker @selected="select" id="search">
        <h2 slot="header">Select a provider to recommend</h2>
      </company-picker>
    </div>
  </div>
</template>

<script>
  import CompanyPicker from '../../Common/CompanyPicker.vue'

  export default {
    components: {
      CompanyPicker
    },
    methods: {
      select (company) {
        window.location = '/recommend/' + company.slug
      }
    }
  }
</script>
