<template>
  <div class="recommend___all-answer">
    <div class="recommend__author">
      <img :src="recommend.user_info.avatar || `/images/default-avatar.png`">
      <div>
        <b>{{ recommend.user_info.first_name }} {{recommend.user_info.last_name }}</b>
        <span><span v-html="recommend.user_info.title"></span> @ {{ recommend.user_info.company }}</span>
        <p>{{ createdAtRecommend }}, {{ recommend.user_info.first_name }} {{ htrLanguage['experiences_' + recommend.experience] || 'experiences_' }} {{ company.name }}</p>
      </div>
    </div>
    <read-more :text="translate(recommend, keyField)" :max-chars="260" more-str="See more" class="recommend__text">
      <div class="recommend__translate" v-if="canSeeOriginalVersion()">
        <button class="button-white-google_translate" @click="alreadyLanguageOriginal = true">{{ htrLanguage.view_original || 'view_original' }}</button>
        <div class="text-google_translate">
          <span>Google</span>&nbsp;<span>Translate</span>
        </div>
      </div>
    </read-more>
  </div>
</template>
<script>
import ReadMore from '../../Utils/ReadMore.vue'

export default {
  components: {
    ReadMore
  },

  props: [
    'recommend',
    'company',
    'createdAtRecommend',
    'keyField'
  ],

  data () {
    return {
      alreadyLanguageOriginal: false
    }
  },

  computed: {
    htrLanguage() {
      if (window && window.i18n && window.i18n.company) {
        return window.i18n.company
      }
      return {}
    },
    idLanguageLocale () {
      if (window.Laravel.languageLocale) {
        return window.Laravel.languageLocale.id
      }
      return undefined
    }
  },

  methods: {
    canSeeOriginalVersion () {
      if (this.alreadyLanguageOriginal) {
        return false
      }

      if (this.recommend.base_language_id && this.recommend.base_language_id !== this.idLanguageLocale) {
        return true
      }

      if (!this.idLanguageLocale) {
        return false
      }

      if (!this.recommend.base_language_id && this.recommend.translations.find(translation => translation.language_id === this.idLanguageLocale)) {
        return true
      }

      return false
    },
    translate (entry, field) {
      let translation = entry[field]
      if (entry.translations && entry.translations.length > 0) {
        const searchTranslation = entry.translations.find((findTranslate) => findTranslate.language_id === (this.alreadyLanguageOriginal ? entry.base_language_id : this.idLanguageLocale))
        if (searchTranslation) {
          translation = searchTranslation[field]
        }
      }
      return translation
    }
  }
}
</script>
