<template>
  <div>
    <article class="vendor-post" id="companies" v-if="product && isVisible">
      <div class="vendor-post-top">
        <span v-if="showBadge">
          <span class="htr-tooltip winner-badge-new">
            <div :class="`htooltip ${ !isWinner ? 'htooltip-finalist' : '' } htooltip-${ isWinner ? 'winner' : 'finalist' }-${ window.Laravel.languageLocale ? 'en' : window.Laravel.languageLocale.code } htrtooltip`">
              <img :src="`/images/${ isWinner ? 'winner' : ('finalist' + numberFinalist) }-badge.${ isWinner ? 'png' : 'svg' }`">
              <div class="htooltip-sponsor-title"><b>{{ productBestOrFinalist }}</b> <a href="https://hoteltechreport.com/news/best-hotel-tech-list/2024" target="_blank" class="htooltip-anchor" v-if="isWinner">Hotel Software</a><span v-if="!isWinner">HotelTechAwards</span></div>
              <div class="tooltiptext">
                <span>{{ htrLanguage.company.product_winner_desc_1 || 'product_winner_desc_1' }} {{ company.name }} {{ htrLanguage.company.product_winner_desc_2 || 'product_winner_desc_2' }} {{ isWinner ? '#1' : '#' + numberFinalist }} {{ translate(category, 'name') }} {{ productWinnerDesc }} HotelTechAwards <br>
                  <a href="https://hoteltechreport.com/news/best-hotel-tech-list/2024" target="_blank">{{ htrLanguage.company.learn_more || 'learn_more' }}</a>
                </span>
              </div>
            </div>
          </span>
        </span>
        <a :href="`/compare/${ product.slug }`" target="_blank" class="vendor-post__compare">
          <span class="vendor-post__compare__box"></span>
          {{ htrLanguage.home.compare || 'compare' }}
        </a>
      </div>

      <div class="vendor-post-company__logo">
        <a :href="product.url">
          <img :src="company.logo" :alt="product.name" width="52" height="52">
        </a>
        <div class="htooltip ptooltip-shield" v-if="product.is_excellence">
          <span class="shield"><img src="/images/ico-shield.svg" width="17" height="22" alt=""></span>
          <div class="tooltiptext">
                <span>{{ htrLanguage.company.is_excellence_desc || 'is_excellence_desc' }}<br>
                  <a href="https://help.hoteltechreport.com/en/articles/1911426-how-to-earn-the-certificate-of-excellence-badge" target="_blank">{{ htrLanguage.company.learn_more || 'learn_more' }}</a>
                </span>
          </div>
        </div>
        <div class="htooltip ptooltip-shield" v-else>
          <span class="shield"><i class="icon-check-1"></i></span>
          <div class="tooltiptext">
                <span>{{ htrLanguage.company.not_is_excellence_desc || 'not_is_excellence_desc' }}<br>
                  <a href="https://help.hoteltechreport.com/en/articles/1911426-how-to-earn-the-certificate-of-excellence-badge" target="_blank">{{ htrLanguage.company.learn_more || 'learn_more' }}</a>
                </span>
          </div>
        </div>
        <div v-if="product.is_addon" class="htooltip htooltip-box-add">
          <div class="box-add-on-indic">{{ htrLanguage.company.add_on || 'add_on' }}</div>
          <div class="tooltiptext">
            <span>{{ htrLanguage.company.add_on_desc_1 || 'add_on_desc_1' }} {{ primaryProductCompanyName }}{{ htrLanguage.company.edit_profile_grammar || 'edit_profile_grammar' }} {{ primaryProductCategoryName }} {{ htrLanguage.company.add_on_desc_2 || 'add_on_desc_2' }}</span>
          </div>
        </div>
      </div>

      <div class="vendor-post-company__content">
        <h3 class="vendor-post-company__title">
          <a :href="product.url">{{ product.name }}</a>
          <a :href="company.support_certification_link" target="_blank" class="company-support" v-if="company.is_verified_support">
            <i class="icon-check4"></i>
          </a>
        </h3>
        <div class="vendor-post-company__rating" v-if="product.reviews_count >= 10">
          <div class="rating-info">
            <a class="no-decoration" :href="product.url" target="_blank">
              <div class="stars">
                <div class="star" v-for="i in 5">
                  <img src="/images/star-half-blue.svg" width="19" v-if="i > product.recommend_rank / 20 && i <= Math.round((Math.round((product.recommend_rank / 20) * 10) /10))">
                  <img src="/images/star-full-blue.svg" width="19" v-if="i <= product.recommend_rank / 20">
                  <img src="/images/star-empty-blue.svg" width="19" v-if="i > product.recommend_rank / 20 && i > Math.round((Math.round((product.recommend_rank / 20) * 10) /10))">
                </div>
              </div>
            </a>
          </div>
          <img class="htrscoreMarginLeft htscoreMarginRight" src="/images/htscoreicon.svg">
          <div class="htscoreInline htscoreSizeLanguages">
            <strong class="htscoreBold htscoreMarginRight htscoreSize14px">{{ htrLanguage.company.ht_score_upper_case || 'ht_score_upper_case' }}</strong>
            <div class="htooltip">
              <span class="htscoreNoLinkUnderline htscoreTextBlue htscoreSize14px">{{ Math.round(product.rank) }} {{ htrLanguage.company.out_of || 'out_of' }} 100</span>
              <div class="tooltiptext">
                <span>Hotel Tech Score {{ htrLanguage.company.ht_score_tooltip || 'ht_score_tooltip' }}<br>
                  <a
                    href="https://help.hoteltechreport.com/en/articles/979295-ht-score-overall-rankings"
                    target="_blank">{{ htrLanguage.company.learn_more || 'learn_more' }}</a>
                </span>
              </div>
            </div>
          </div>
        </div>
        <p class="vendor-post-company__desc">
          <read-more class="product-description" :text="translate(product, 'description', 'safe_description')" :max-chars="180" :read-more-keep-going="true"></read-more>
        </p>
        <div class="vendor-post-company__info">
          <div class="ptooltip"
               :data-label="htrLanguage.company.this_vendor_has_active_customers || 'this_vendor_has_active_customers'"
               v-if="product.countries_count < 3 && product.reviews_count >= 10"
          >
            <span class="vendor-post-company__info__geography">{{ htrLanguage.companyCategory.geography_regional || 'geography_regional' }}</span>
          </div>

          <span class="vendor-post-company__info__geography" v-else>{{ htrLanguage.companyCategory.geography_global || 'geography_global' }}</span>
          <span class="vendor-post-company__info__geography">
            {{ htrLanguage.company.verified_customer_support || 'verified_customer_support' }}:
            <a :href="company.support_certification_link" target="_blank" class="company-support" v-if="company.is_verified_support">{{ htrLanguage.companyCategory.yes || 'yes' }}</a>
            <span v-else>{{ htrLanguage.companyCategory.no || 'no' }}</span>
          </span>

          <span v-if="showBadge">
          <span class="htr-tooltip winner-badge-new winner-badge-mobile">
            <div :class="`htooltip htooltip-${ isWinner ? 'winner' : 'finalist' }-${ window.Laravel.languageLocale ? 'en' : window.Laravel.languageLocale.code } htrtooltip`">
              <img :src="`/images/${ isWinner ? 'winner' : ('finalist' + numberFinalist) }-badge.${ isWinner ? 'png' : 'svg' }`">
              <div class="htooltip-sponsor-title"><b>{{ productBestOrFinalist }}</b> <a href="https://hoteltechreport.com/news/best-hotel-tech-list/2024" target="_blank" class="htooltip-anchor" v-if="isWinner">Hotel Software</a><span v-if="!isWinner">HotelTechAwards</span></div>
              <div class="tooltiptext">
                <span>{{ htrLanguage.company.product_winner_desc_1 || 'product_winner_desc_1' }} {{ company.name }} {{ htrLanguage.company.product_winner_desc_2 || 'product_winner_desc_2' }} {{ isWinner ? '#1' : '#' + numberFinalist }} {{ translate(category, 'name') }} {{ productWinnerDesc }} HotelTechAwards <br>
                  <a href="https://hoteltechreport.com/news/best-hotel-tech-list/2024" target="_blank">{{ htrLanguage.company.learn_more || 'learn_more' }}</a>
                </span>
              </div>
            </div>
          </span>
        </span>
        </div>
        <div class="vendor-post-company__bottom">
          <a :href="product.url">{{ htrLanguage.company.read || 'read' }} {{ company.name }} {{ htrLanguage.company.reviews_camel_case || 'reviews_camel_case' }}</a>
          <div class="quick-links">
            <a href="#" @click="openLeadModal(product.category_id, product.id, 'request_quote')">
              {{ htrLanguage.company.price_quote || 'price_quote' }}
            </a>
            <a href="#" @click="openLeadModal(product.category_id, product.id,  'request_demo')">
              {{ htrLanguage.company.demo || 'demo' }}
            </a>
          </div>
        </div>
      </div>
    </article>
  </div>
</template>
<script>
  import {mapGetters, mapActions} from 'vuex'
  import axios from "axios";

  export default {
    props: ['productId', 'sourceProduct', 'openLeadModal', 'addonsVisible'],
    data () {
      return {
        award_year: null
      }
    },
    created() {
      if(this.sourceProduct) {
        this.batchProcessProducts([this.sourceProduct])
      }
      axios.get(`/api/settings`).then(res => {
        if (res.data.awards_winners_and_finalists_current_year) {
          this.award_year = res.data.awards_winners_and_finalists_current_year
        }
      })
    },
    methods: {
      ...mapActions([
        'batchProcessProducts'
      ]),
      translate (entry, field, alternativeField) {
        let translation = entry[alternativeField || field]
        if (entry.translations && entry.translations.length > 0) {
          if (window.Laravel.languageLocale) {
            const searchTranslation = entry.translations.find((findTranslate) => findTranslate.language_id === window.Laravel.languageLocale.id)
            if (searchTranslation) {
              translation = searchTranslation[field]
            }
          }
        }
        return translation
      }
    },
    computed: {
      ...mapGetters([
        'companyById',
        'productById',
        'categoryById'
      ]),
      htrLanguage() {
        if (window && window.i18n) {
          const { companyCategory, layout, company } = window.i18n
          if (companyCategory && layout && company) {
            return { companyCategory, layout, company }
          }
        }
        return { companyCategory: {}, layout: {}, company: {} }
      },
      codeLanguage() {
        if (window.Laravel.languageLocale) {
          return window.Laravel.languageLocale.code
        }
        return 'en'
      },
      showBadge () {
        if (this.product) {
          return this.product.is_winner || this.product.is_finalist2 || this.product.is_finalist3
        }
        return false
      },
      isWinner () {
        if (this.product) {
          return this.product.is_winner
        }
        return false
      },
      numberFinalist () {
        if (this.product) {
          return this.product.is_finalist2 ? '2' : '3'
        }
        return '2'
      },
      isVisible() {
        return !this.product.is_addon || this.addonsVisible
      },
      product() {
        return this.productById(+this.productId)
      },
      company() {
        return this.companyById(this.product.company_id)
      },
      category() {
        return this.categoryById(this.product.category_id)
      },
      primaryProduct() {
        return this.productById(+this.product.primary_product_id)
      },
      primaryProductCompany() {
        return this.primaryProduct && this.companyById(this.primaryProduct.company_id)
      },
      primaryProductCompanyName() {
        return this.primaryProduct && this.primaryProductCompany ? this.primaryProductCompany.name : '-'
      },
      primaryProductCategory() {
        return this.primaryProduct && this.categoryById(this.primaryProduct.category_id)
      },
      primaryProductCategoryName() {
        return this.primaryProduct && this.primaryProductCategory ? this.primaryProductCategory.name : '-'
      },
      productWinnerDesc() {
        let text = this.htrLanguage.company.product_winner_desc_1 || 'product_winner_desc_1'
        return text.replace(':award_year', this.award_year)
      },
      productBestOrFinalist() {
        let text = this.htrLanguage.company['product_' + (this.isWinner ? 'best' : 'finalist')] || ('product_' + (this.isWinner ? 'winner' : 'finalist'))
        return text.replace(':award_year', this.award_year)
      },
    }
  }
</script>
