<template>
  <div v-show="countStart < allCount" class="box__header__right-col">
    <a v-if="value == countStart" href="#" @click.prevent="$emit('input', allCount)">
      <slot name="more">
        {{ htrLanguage.view_all || 'view_all' }} ({{ allCount }})
      </slot>
    </a>
    <a v-else href="#" @click.prevent="$emit('input', countStart)">
      {{ htrLanguage.view_less || 'view_all' }}
    </a>
  </div>
</template>

<script>
export default {
  props: [
    'allCount',
    'value',
    'isAll'
  ],

  data: () => ({
    countStart: 10
  }),

  mounted () {
    this.countStart = this.value

    if (this.isAll) {
      this.$emit('input', this.allCount)
    }
  },

  computed: {
    htrLanguage() {
      if (window && window.i18n && window.i18n.home) {
        return window.i18n.home
      }
      return {}
    }
  }
}
</script>
