<template>
  <div class="holder">
    <div class="logo-area">
      <img src="/images/the-rewind.svg" width="110" alt="theRewind">
    </div>
    <div class="txt-wrap">
      <p>
        {{ htrLanguage.get_the_latest_tips }}
      </p>
    </div>
    <form class="subscription-form" @submit.prevent="subscribe">
      <input v-model="email" type="email" :placeholder="htrLanguage.your_email" class="form-elem">
      <input type="submit" :value="htrLanguage.subscribe" class="btn">
    </form>
  </div>
</template>

<script>
import swal from 'sweetalert2'
import axios from 'axios'

export default {
  data () {
    return {
      email: ''
    }
  },

  computed: {
    htrLanguage() {
      if (window && window.i18n && window.i18n.layout) {
        return window.i18n.layout
      }
      return {}
    }
  },

  methods: {
    subscribe () {
      if (this.hasEmail()) {
        axios.post('/blog-subscriptions', { email: this.email })
          .then(() => {
            swal({
              title: 'You have been successfully subscribed!',
              type: 'success',
              showCancelButton: false,
              confirmButtonText: 'back to HotelTechReport.com'
            })

            this.email = ''
          })
          .catch(error => {
            swal({
              title: (error.response.data.errors !== undefined)
                ? error.response.data.errors.email[0] : error.response.data.message,
              type: 'error',
              showCancelButton: false,
              confirmButtonText: 'back to HotelTechReport.com'
            })
          })
      }
    },
    hasEmail () {
      return this.email && /[A-Z0-9._%+-]+@[A-Z0-9.-]+.[A-Z]{2,4}/igm.test(this.email)
    }
  }
}

</script>
