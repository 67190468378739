<template>
  <div class="integration-card" :class="{'empty-state' : isEmpty}">
    <div class="logos">
      <a class="logo" :href="`${base.url}`">
        <img :src="`${base.company.logo}`">
      </a>
      <span class="plus" />
      <a class="logo" :href="`${target.url}`">
        <img :src="`${target.company.logo}`">
      </a>
    </div>
    <h3>Integrate {{ base.company.name }} and {{ target.company.name }}</h3>
    <p>{{ base.company.name }} that integrates with {{ target.company.name }}</p>
    <a class="browse-link" :href="`/integrations/${base.slug}/${target.slug}`">View integration <i
      class="icon-chevron-right" /></a>

    <div v-if="isEmpty" class="empty-state-overlay">
      <div class="holder">
        <div class="frame">
          <p>{{ base.company.name }} and {{ target.company.name }} don’t have an integration yet</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ['integration'],
  computed: {
    isEmpty () {
      return !this.integration.id || (this.base.pivot.status !== 'verified' && this.target.pivot.status !== 'verified')
    },
    base () {
      return this.integration.products[0]
    },
    target () {
      return this.integration.products[1]
    },
    authenticated () {
      return !!window.Laravel.userId
    }
  }
}
</script>
