<template>
  <div class="row validation">
    <div class="title-pannel">
      <label class="text-big required"><strong>Location</strong></label>
    </div>
    <places-autocomplete v-model="value" class-names="form-control required" placeholder="City, Country" />
    <input name="location" type="hidden" :value="location">
  </div>
</template>

<script>
export default {
  data: () => ({
    value: null
  }),

  computed: {
    location () {
      if (!this.value) return null
      return [this.value.city || null, this.value.country].join(', ')
    }
  }
}
</script>
