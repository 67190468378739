<template>
  <section class="box-search">
    <h2 class="box-search__title">Products <span>({{ items.length }} results)</span></h2>

    <div class="product-posts search-product-posts">
      <article v-for="product in itemsFiltered" :key="product.id" class="product-post">
        <div class="img">
          <a :href="product.url">
            <img v-if="product.company_logo" :src="product.company_logo" :alt="product.name" width="74" height="75">
            <img v-else src="/images/company-default-logo.png" :alt="product.name" width="74" height="75">
          </a>
        </div>
        <div class="des">
          <h3><a :href="product.url">{{ product.name }}</a></h3>
          <p>{{ product.tagline }}</p>
          <div class="des-footer">
            <div class="col">
              <a :href="product.url + '#reviews'" class="cta">
                <span class="icon-chat-thread" aria-hidden="true" />
                <span class="txt">{{ product.reviews_count }}</span>
              </a>
            </div>
            <div class="col">
              <a :href="product.url" class="cta" target="_blank">
                <span class="for-sr">Open in new window</span>
                <span class="icon-share-icon" aria-hidden="true" />
              </a>
            </div>
            <div v-if="product.public_ht_score" class="col">
              Ranked {{ product.last_rank_in_category | nth }} in
              <a :href="product.category_url">{{ product.category_name }}</a>
            </div>
            <div v-else class="col">
              Unranked in
              <a :href="product.category_url">{{ product.category_name }}</a>
            </div>
          </div>
        </div>
        <div class="btn-col">
          <div class="btn-box">
            <a :href="`${product.url}#profileReviewsSection`" class="btn btn-hollow">Read reviews</a>
            <button class="btn btn-green" @click="openLeadModal(product.category_id, product.id)">
              <span class="icon-click" aria-hidden="true" />GET DEMO
            </button>
          </div>
          <div v-if="product.is_winner" class="htr-tooltip sponsor">
            <div class="htooltip">
              <img src="/images/winner-badge.png"> {{ award_year }} WINNER
              <div class="tooltiptext">
                <span>
                  Hoteliers voted {{ product.company_name }} as the #1 {{ product.category_name }} provider in the {{ award_year }} HotelTechAwards.<br>
                  <a href="https://hoteltechreport.com/news/best-hotel-tech-list/2024" target="_blank">
                    Learn more
                  </a>
                </span>
              </div>
            </div>
          </div>
        </div>
      </article>
    </div>

    <div v-if="canShowMore" class="load-more">
      <button @click="load">
        View more
      </button>
    </div>
  </section>
</template>

<script>
import { EventBus } from '../../../event-bus'
import arrayPagination from '../Mixins/arrayPagination'
import { mapGetters } from 'vuex'
import axios from 'axios'

export default {
  mixins: [
    arrayPagination
  ],
  data () {
    return {
      award_year: null
    }
  },

  computed: {
    ...mapGetters(['getProducts']),
    items () {
      return this.getProducts
    }
  },

  created () {
    axios.get(`/api/get-current-award-year`).then(res => {
      if (res.data) {
        this.award_year = res.data
      }
    })
  },

  methods: {
    openLeadModal (categoryId, productId) {
      EventBus.$emit('open-modal-sidebar', {
        type: 'modal-lead',
        params: {
          categoryId: categoryId,
          productId: productId,
          type: 'request_demo'
        }
      })
    }
  }
}
</script>
