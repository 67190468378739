<template>
  <div class="width865px marginTop50px marginBottom50px">
    <div>
      <span class="awardsTitle">
        Top Rated {{ category.name }} Products of {{ category.year }}
      </span>
    </div>
    <div class="width100percent">
      <product-information v-for="subcategory in subcategories" :key="subcategory.order" :subcategory="subcategory" />
    </div>
    <div v-if="openFinalist">
      <finalist-striped-table :subcategories="subcategories" :year="category.year" :category-name="category.name" />
    </div>

    <div class="backgroundWhite borderSolidGray borderSolidGrayBottom textAlignCenter cursorPointer padding10px" @click="() => openFinalist = !openFinalist">
      <span class="finalists">
        {{ !openFinalist ? `View ${category.name ? category.name : ''} Finalists (${totalFinalists})` : 'Hide Finalists' }}
      </span>
    </div>
  </div>
</template>

<style scoped>
  .finalists {
    font-size: 14px;
    color: #9E9E9E;
  }
</style>

<script>
import ProductInformation from './ProductInformation.vue'
import FinalistStripedTable from './FinalistStripedTable.vue'

export default {
  components: {
    ProductInformation,
    FinalistStripedTable
  },
  props: [
    'category'
  ],
  computed: {
    totalFinalists () {
      let count = 0
      if (!this.category || !this.category.subcategories) {
        return 0
      }
      Object.keys(this.category.subcategories).forEach((subcategory) => {
        if (this.category.subcategories[subcategory].finalists) {
          count += this.category.subcategories[subcategory].finalists.length
        }
      })
      return count
    },
    subcategories () {
      let subcategoriesAux = Object.values(this.category.subcategories)
      subcategoriesAux.sort((a,b) => (a.reviews_count < b.reviews_count) ? 1 : ((b.reviews_count < a.reviews_count) ? -1 : 0))
      return subcategoriesAux
    }
  },
  data () {
    return {
      openFinalist: true
    }
  }
}
</script>
