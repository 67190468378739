class BlogPostClaim {
  static init () {
    $('.claim-article').on('click', e => {
      e.preventDefault()
      $('#claim-article').show()
    })

    $('.modal-overlay').on('click', function (e) {
      if (e.target !== this) { return }

      $(this).hide()
    })

    $('.close-modal').on('click', e => {
      e.preventDefault()
      $('.modal-overlay').hide()
    })

    if (/[?&]claim-request=/.test(location.search)) {
      $('#request-sent').show()
    }

    if (/[?&]about-you/.test(location.search)) {
      $('#about-you-modal').show()
    }
  }
}

export default BlogPostClaim
