<template>
  <div>
    <div class="verify-email">
      <span class="verify-email__title">Awesome!</span>
      <p>We just sent a confirmation email to {{ value.email }}. Click the verification link to finish setting up your account</p>
      <div class="form-group">
        <input v-model="form.email" placeholder="name@domain.com" class="form-control"
               :class="{success: form.email, error: shouldShowErrorMessagesEmail}">
        <button @click="resend">
          RESEND
        </button>
      </div>
    </div>
    <p class="verify-email__note">
      Be sure to check spam, on the off-chance it’s been filtered. You can also directly
      write in via the 24/7 on-site live chat for any assistance.
    </p>
  </div>
</template>

<script>
import checkEmail from '../../../../../mixins/checkEmail'

export default {
  mixins: [
    checkEmail
  ],

  props: [
    'value'
  ],

  data: () => ({
    form: {},
    shouldShowErrorMessagesEmail: false
  }),

  computed: {
    isBusinessEmail () {
      return this.checkBusinessEmail(this.form.email)
    }
  },

  mounted () {
    this.reset()
  },

  methods: {
    reset () {
      if (this.value !== this.form) this.form = Object.assign({}, this.value)
    },
    resend () {
      this.shouldShowErrorMessagesEmail = false

      if (this.hasIsNotBusinessEmail()) {
        this.$emit('resend-verify', {
          params: this.form,
          callback: () => {
            this.shouldShowErrorMessagesEmail = true
            this.$emit('errors', 'The email has already been taken.')
          }
        })
      }
    },
    hasIsNotBusinessEmail () {
      if (this.form.email && !this.isBusinessEmail) {
        this.shouldShowErrorMessagesEmail = true
        this.$emit('errors', 'Personal email detected. Please use a work email address or login to your account to access premium content.')
        return false
      }
      return true
    }
  }
}
</script>