<template>
  <div>
    <ul class="step-no">
      <li v-for="step in stepsCount" :key="step" :class="{ active: step === activeStep, completed: step < activeStep }">
        {{ step }}
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  props: [
    'stepsCount',
    'activeStep'
  ]
}
</script>
