<template>
  <div class="modal-create-account">
    <div class="modal-create-account__top">
      <div>
        Have an account?
        <button class="btn-link" @click="openPopup('auth-user')">
          Log In
        </button>
      </div>
      <button @click="close">
        <i class="icon-cross" />
      </button>
    </div>
    <p class="error" :class="{'active': errors}">
      {{ errors }}
    </p>
    <div class="modal-lead__header">
      <h4 class="modal-lead__title">
        Create a free hotelier account
      </h4>
      <p class="modal-lead__description">
        Many features on Hotel Tech Report are only available to hoteliers.
        Sign up with your work email to access them.
      </p>
    </div>
    <div class="modal-lead__content">
      <div :is="activeStep"
           v-model="form"
           @errors="setErrors"
           @resend-verify="resendVerify"
           @create-user="createUser" />

      <div v-if="activeStep !== 'verify-email'" class="vendor">
        <i class="icon-shop" />
        <div>
          Are you a vendor?
          <a href="https://partners.hoteltechreport.com/list-a-product/" target="_blank">List a Product</a>
          or
          <a href="https://help.hoteltechreport.com/faqs/getting-started-faqs/how-to-claim-your-profiles"
             target="_blank">Claim Your Profile</a>
        </div>
      </div>

      <div class="featured-account">
        <span class="featured-account__title">Sign Up to Get:</span>
        <div class="featured-account__item">
          Personalized recommendations based on thousands of data points
          and
          reviews
        </div>
        <div class="featured-account__item">
          Free access to premium content, reports and buyers guides
        </div>
        <div class="featured-account__item">
          Free hotelier dashboard to unlock: price quotes, demos,
          references
          and vendor selection tools
        </div>
      </div>
      <div class="modal-lead__footer">
        <span class="modal-lead__footer__title">As seen in</span>
        <div class="modal-lead__companies">
          <img class="modal-lead__companies__logo" src="/images/company-logo2.png">
          <img class="modal-lead__companies__logo" src="/images/company-logo2.png">
          <img class="modal-lead__companies__logo" src="/images/company-logo2.png">
          <img class="modal-lead__companies__logo" src="/images/company-logo2.png">
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import swal from 'sweetalert2'

import SignUp from './AccountSteps/NewAccount.vue'
import VerifyEmail from './AccountSteps/VerifyEmail.vue'

export default {

  components: {
    SignUp,
    VerifyEmail
  },
  props: [
    'redirectToCurrentPage'
  ],

  data: () => ({
    form: {},

    activeStep: 'sign-up',
    userId: null,
    errors: null
  }),

  methods: {
    close () {
      this.$emit('close')
    },
    setErrors (error) {
      this.errors = error
    },
    createUser ({ params, callback }) {
      axios.post('/api/create-users', this.handleParams(params))
        .then(res => {
          this.userId = res.data.id
          this.errors = null
          this.activeStep = 'verify-email'
        })
        .catch(callback)
    },
    resendVerify ({ params, callback }) {
      axios.put(`/api/create-users/${this.userId}`, this.handleParams(params))
        .then(() => {
          swal({
            title: 'New email sent to you by email',
            type: 'success',
            showCancelButton: false
          })
        })
        .catch(callback)
    },
    handleParams (params) {
      if (!this.redirectToCurrentPage) return params

      return Object.assign({}, params, { redirect_url: this.redirectToCurrentPage ? location.href : null })
    },
    openPopup (type) {
      this.$emit('open-popup', {
        type: type,
        params: {}
      })
    }
  }
}
</script>
