<template>
  <ul class="rating">
    <li><img :src="star(1)" width="16"></li>
    <li><img :src="star(2)" width="16"></li>
    <li><img :src="star(3)" width="16"></li>
    <li><img :src="star(4)" width="16"></li>
    <li><img :src="star(5)" width="16"></li>
  </ul>
</template>

<script>
export default {
  props: ['value', 'color'],
  computed: {
    fullStar () {
      return this.color === 'blue' ? '/images/star-full-blue.svg' : '/images/star-fill.svg'
    },
    halfStar () {
      return this.color === 'blue' ? '/images/star-half-blue.svg' : '/images/star-half.svg'
    },
    emptyStar () {
      return this.color === 'blue' ? '/images/star-empty-blue.svg' : '/images/star-empty.svg'
    }
  },
  methods: {
    star (n) {
      if (n <= this.value) return this.fullStar
      if (n > this.value && n <= Math.round(this.value)) return this.halfStar
      return this.emptyStar
    }
  }
}
</script>
