<script>
import { mapActions, mapGetters } from 'vuex'
import { map } from 'lodash'
import ScrollTo from 'vue-scrollto'
import { Carousel, Slide } from 'vue-carousel'
import axios from 'axios'
import { EventBus } from '../../event-bus.js'

import AcceptInvitation from './Product/AcceptInvitation.vue'
import AcceptIntegrationInvitation from './Product/AcceptIntegrationInvitation.vue'
import Integrations from './Product/Integrations.vue'
import ProductReviews from './Product/ProductReviews.vue'
import ToggleViewCount from './Product/ToggleViewCount.vue'
import SegmentReviews from './Product/SegmentReviews.vue'
import MapReviews from './Product/MapReviews.vue'
import ModalVideo from './Product/ModalVideo.vue'
import PopupIsVendor from './../Utils/PopupIsVendor.vue'
import HospitalityNetNews from './Product/HospitalityNetNews.vue'
import Recommendations from './Product/Recommendations.vue'

export default {
  components: {
    AcceptInvitation,
    AcceptIntegrationInvitation,
    Integrations,
    ProductReviews,
    ToggleViewCount,
    SegmentReviews,
    MapReviews,
    ModalVideo,
    PopupIsVendor,
    HospitalityNetNews,
    Carousel,
    Slide,
    Recommendations
  },

  props: [
    'tab',
    'productId',
    'companyId',
    'categoryId'
  ],

  data () {
    return {
      activeTab: null,
      product: window.company.product,
      shared: window.store,
      commentLess: [],
      reviewsIds: [],

      showMissionStatement: false,
      showAboutProduct: false,
      showComment: false,
      showFullCustomersLove: false,
      showFullCustomersDoesLove: false,
      showFullFeatures: true,
      showFullRating: false,
      activePopup: null,
      activeModal: null,
      showCount: {
        expertReviews: 1,
        testimonials: 2,
        customersLove: 4,
        studies: 2,
        recentCoverages: 3,
        screenshots: 3,
        posts: 5,
        features: 8,
        featuresGroup: 5,
        integrates: 3,
        clients: 6,
        certifications: 6,
        news: 4,
        articles: 3
      },
      projectId: null
    }
  },

  computed: {
    ...mapGetters([
      'companyById'
    ]),
    company () {
      return this.companyById(this.companyId)
    },
    authUserVendor () {
      return !window.Laravel.isVendor || null
    },
    userIsGuest () {
      return !window.Laravel.userId
    }
  },

  mounted () {
    this.checkExistProject()
    this.activeLinkSidebar()
  },

  created () {
    this.activeTab = this.tab
    this.fetchCompanyById(this.companyId)
    this.fetchReviewsByProductId(this.productId).then(res => {
      this.reviewsIds = map(res.data, item => item.id)
    })
  },

  methods: {
    ...mapActions([
      'fetchCompanyById',
      'fetchReviewsByProductId'
    ]),
    switchTab (target, section = null) {
      this.activeTab = target
      if (section) {
        this.$nextTick(() => {
          let $el = $(`#${section}`)
          if (!$el.length) return false

          $('html, body').animate({ scrollTop: $el.offset().top + 'px' }, 'fast')
        })
      }

      let tab = this.activeTab === 'reviews' ? '' : this.activeTab
      window.history.pushState({}, null, `/company/${this.product.slug}/${tab}`)
    },
    toggleCustomerFullComment (id) {
      return (!this.hasCustomerCommentFull(id))
        ? this.commentLess.push(id) : this.commentLess.splice(this.commentLess.indexOf(id))
    },
    hasCustomerCommentFull (id) {
      return this.commentLess.includes(id)
    },
    newReview () {
      location.href = `/write-a-review/${this.company.slug}`
    },
    closePopups () {
      this.activePopup = null
    },
    closeModals () {
      this.activeModal = null
    },
    openModalLead (type) {
      if (window.Laravel.isVendor) return this.$root.addModalToQueue('modal-is-vendor')

      if (this.projectId && type !== 'ask_a_question') {
        this.createLeadRedirectPost(type)
      } else {
        EventBus.$emit('open-modal-sidebar', {
          type: 'modal-lead',
          params: {
            categoryId: this.categoryId,
            productId: this.productId,
            type: type
          }
        })
      }
    },
    checkExistProject () {
      axios.get(`/api/categories/${this.categoryId}/projects`)
        .then(res => (this.projectId = res.data.id))
    },
    openPopup (title, scrollToElem) {
      if (scrollToElem !== undefined) {
        ScrollTo.scrollTo(scrollToElem, {
          offset: -100
        })
      }

      if (this.authUserVendor) {
        this.activePopup = title
      }
    },
    openVideo (url) {
      EventBus.$emit('open-modal-video', url)
    },
    createLeadRedirectPost (source) {
      let form = document.createElement('form')

      form.method = 'post'
      form.action = `/projects/${this.projectId}/rooms`

      const data = { source: source, product_id: this.productId }

      for (let name in data) {
        let input = document.createElement('input')
        input.type = 'hidden'
        input.name = name
        input.value = data[name]
        form.appendChild(input)
      }

      document.body.appendChild(form)

      form.submit()
    },

    handleGuestClick (e) {
      e.preventDefault()
      this.openModalCreateAccount()
    },
    openModalCreateAccount () {
      EventBus.$emit('open-modal-sidebar', {
        type: 'create-account',
        params: {
          redirectToCurrentPage: true
        }
      })
    },
    activeLinkSidebar () {
      const scrollDistance = jQuery(window).scrollTop()

      jQuery('.product-section').each(function (i) {
        if (jQuery(this).position().top <= scrollDistance) {
          jQuery('#sidebar-profile .sidebar-profile__nav li a').removeClass('active')
          jQuery('#sidebar-profile .sidebar-profile__nav li a').eq(i).addClass('active')
        }
      })
    }
  }
}
</script>
