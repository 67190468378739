<template>
  <div>
    <div class="product-posts01">
      <article v-for="product in products" :key="product.id" class="product-post01">
        <a :href="product.url">
          <div class="img">
            <img v-if="product.company.logo" :src="product.company.logo" :alt="product.name" width="42"
                 height="43">
            <img v-else src="/images/company-default-logo.png" :alt="product.name" width="42" height="43">
          </div>
          <div class="des">
            <h3>{{ product.name }}</h3>
            <div class="des-footer">
              <ul class="rating">
                <li v-for="n in 5" :key="n">
                  <img v-if="product.recommend_rank / 20 >= n" src="/images/star-full-blue.svg"
                       width="18">
                  <img v-if="(product.recommend_rank / 20 >= (n-1) + 0.2 && product.recommend_rank / 20 < n)" src="/images/star-full-blue.svg"
                       width="18">
                  <img v-if="(product.recommend_rank / 20 < (n-1) + 0.2 && product.recommend_rank / 20 < n)" src="/images/star-empty-blue.svg">
                </li>
              </ul>
              <span class="reviews-number-info">{{ product.reviews_count }} verified reviews</span>
            </div>
          </div>
        </a>
      </article>
    </div>
    <span class="load-more">
      <button v-show="page < lastPage" @click="load">Load more</button>
    </span>
  </div>
</template>

<script>
import axios from 'axios'
import { EventBus } from '../../event-bus'

export default {
  props: {
    categoryId: {
      default: ''
    }
  },

  data: () => ({
    products: [],
    page: 1,
    lastPage: 2
  }),

  methods: {
    load () {
      this.page++

      axios.get(`/api/popular-products?page=${this.page}&category_id=${this.categoryId}`)
        .then(res => {
          this.lastPage = res.data.last_page
          this.products = [...this.products, ...res.data.data]
        })
    },
    openLeadModal (categoryId, productId) {
      EventBus.$emit('open-modal-sidebar', {
        type: 'modal-lead',
        params: {
          categoryId: categoryId,
          productId: productId,
          type: 'request_demo'
        }
      })
    }
  }
}
</script>
