<script>
import Categories from './Search/Categories.vue'
import Products from './Search/Products.vue'
import Articles from './Search/Articles.vue'
import Resources from './Search/Resources.vue'

import NavBar from './Search/NavBar.vue'

export default {
  components: {
    Categories,
    Products,
    Articles,
    Resources,
    NavBar
  },

  props: {
    defaultSection: {
      default: null
    }
  },

  data: () => ({
    activeSection: null,
    availableSections: [
      'categories',
      'products',
      'articles',
      'buyers_guides',
      'resources'
    ]
  }),

  created () {
    if (this.defaultSection && this.availableSections.includes(this.defaultSection)) {
      this.activeSection = this.defaultSection
    }
  },

  methods: {
    canShow (section) {
      return !this.activeSection || this.activeSection === section
    }
  }
}
</script>
