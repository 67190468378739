export default class MoreLessText {
  constructor () {
    this.registerEvents()
  }
  registerEvents () { 
    $('body').on('click', '.more-or-less a', this.onToggleClick.bind(this))
  }
  onToggleClick (e) {
    e.preventDefault()
    const $this = $(e.target)
    $this.closest('.more-or-less').toggleClass('expanded')
  }
}