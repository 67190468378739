<template>
  <div class="modal-create-account-body">
    <div class="form-group"
         :class="{success: form.email && isBusinessEmail && !shouldShowErrorMessagesEmail, 'error': !isBusinessEmail && shouldShowErrorMessagesEmail}">
      <input v-model="form.email" type="email"
             :class="{'is-empty': !form.email, 'error': shouldShowErrorMessagesEmail}">
      <span class="field__title">Work Email</span>
    </div>
    <button class="btn-blue" :disabled="isLoading" @click="submit">
      Get started!
      <span v-if="isLoading" class="lds-spinner">
        <span v-for="i in 12" :key="i" />
      </span>
    </button>
    <span class="modal-create-terms-of-use-text">
      By proceding you agree to our 
      <a href="https://help.hoteltechreport.com/en/articles/1214405-terms-conditions" target="_blank">
        Terms of use
      </a>
      and our
      <a href="https://help.hoteltechreport.com/en/articles/1214349-user-privacy-policy" target="_blank">
        Privacy policy
      </a>
    </span>
  </div>
</template>

<script>
import checkEmail from '../../../../../mixins/checkEmail'

export default {
  mixins: [
    checkEmail
  ],

  props: [
    'value'
  ],

  data: () => ({
    form: {},
    isLoading: false,

    shouldShowErrorMessagesEmail: false
  }),

  computed: {
    isBusinessEmail () {
      return this.checkBusinessEmail(this.form.email)
    }
  },

  watch: {
    value () {
      this.reset()
    },
    form () {
      this.$emit('input', this.form)
    }
  },

  mounted () {
    this.reset()
  },

  methods: {
    reset () {
      if (this.value !== this.form) this.form = this.value
    },
    openSidebarModal (title) {
      this.$emit('open-sidebar-modal', title)
    },
    submit () {
      this.shouldShowErrorMessagesEmail = false
      this.$emit('errors', null)

      if (this.hasIsNotBusinessEmail()) {
        this.createUser()
      }
    },
    hasIsNotBusinessEmail () {
      if (!this.form.email || !this.isBusinessEmail) {
        this.shouldShowErrorMessagesEmail = true
        this.$emit('errors', 'Personal email detected. Please use a work email address or login to your account to access premium content.')
        return false
      }

      return true
    },
    createUser () {
      this.isLoading = true
      this.$emit('create-user', {
        params: this.form,
        callback: () => {
          this.shouldShowErrorMessagesEmail = true
          this.$emit('errors', 'The email has already been taken.')
          this.isLoading = false
        }
      })
    }
  }
}
</script>
