<template>
  <div>
    <div>
      <top-navigation-sticky :percent-advanced="percentedAvancedStepThree" />
    </div>
    <div class="container flex-container !max-w-screen-lg">
      <form class="container-step" method="POST" action="/review/step-3" ref="form">
        <div class="review-form step-3-widths">
          <input type="hidden" name="review_id" :value="reviewId">
          <input type="hidden" name="company_slug" :value="companySlug">

          <div class="company-logo">
            <img :src="companyLogo" alt="companyName">
          </div>
          <h1 class="text-center">
            {{ (htrLanguage.writeReview.complete_your_review || 'complete_your_review').replace(':company_name',
        companyName) }}
          </h1>

          <div v-if="shouldShowErrorMessages" class="error-alert">
            {{ errorMessage }}
          </div>

          <div class="product-rating product-rating-review">
            <h3>{{ htrLanguage.writeReview.product_ratings || 'product_ratings' }}</h3>
            <ul class="product-rating-list">
              <li :class="{ validation: true, success: easeOfUse, error: shouldShowErrorMessages && !easeOfUse }">
                <star-rating :notAllowHalfStars="true" type="step-3"
                  :title="htrLanguage.company.ease_of_use || 'ease_of_use'" input-name="easeOfUse" :value="easeOfUse"
                  @rated="ratingUpdated" />
              </li>
              <li
                :class="{ validation: true, success: customerSupport, error: shouldShowErrorMessages && !customerSupport }">
                <star-rating :notAllowHalfStars="true" type="step-3"
                  :title="htrLanguage.company.customer_support || 'customer_support'" input-name="customerSupport"
                  :value="customerSupport" @rated="ratingUpdated" />
              </li>
              <li :class="{ validation: true, success: roi, error: false }">
                <star-rating :notAllowHalfStars="true" type="step-3" optional="true"
                  :title="htrLanguage.writeReview.value_for_money || 'value_for_money'" input-name="roi" :value="roi"
                  @rated="ratingUpdated" />
              </li>
              <li :class="{ validation: true, success: implementation, error: false }">
                <star-rating :notAllowHalfStars="true" type="step-3" optional="true"
                  :title="htrLanguage.writeReview.implementation_onboarding || 'implementation_onboarding'"
                  input-name="implementation" :value="implementation" @rated="ratingUpdated" />
              </li>
            </ul>
            <div v-if="hasPerfectScore() && !hasClosedPerfectScoreTooltip" class="alert-popup" style="display: block;">
              <a href="#" class="close" @click.prevent="hideTooltip"><i class="icon-cross" /></a>
              <h3>{{ htrLanguage.writeReview.leave_all_scores || 'leave_all_scores' }}</h3>
              <p>
                {{ htrLanguage.writeReview.leave_all_scores_description || 'leave_all_scores_description' }}
              </p>
            </div>
          </div>
          <about-you :should-show-errors="shouldShowErrorMessages" :user-data="userData" :data-about-you="dataAboutYou"
            @valueUpdates="updateAboutYou" @newHotelSelected="newHotelSelected" />

          <div
            :class="{ 'alert-checkbox': shouldShowErrorMessages && !compliance_opt_in, 'compliance-check-box': true }">
            <input id="check" v-model="compliance_opt_in" type="checkbox" :class="checkboxClass">
            <label for="check" class="compliance-text">
              {{ htrLanguage.writeReview.certify_1 || 'certify_1' }}
              <a href="https://help.hoteltechreport.com/en/articles/987415-our-guidelines-for-hotelier-reviews"
                target="_blank" class="reviewguidelines">{{ htrLanguage.writeReview.review_guidelines ||
        'review_guidelines' }}</a>.
              {{ htrLanguage.writeReview.certify_2 || 'certify_2' }}
            </label>
          </div>

          <div class="btn-hold text-center">
            <input :disabled="disableButton" type="submit" name="submit" class="btn btn-green btn-large btnWriteAReview"
              :value="htrLanguage.writeReview.finish_and_submit || 'finish_and_submit'" @click="submitReview">
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import StarRating from './StarRating.vue'
import ReviewSidebar from './ReviewSidebar.vue'
import TopNavigationSticky from './TopNavigationSticky.vue'
import AboutYou from './AboutYou.vue'
import axios from 'axios'
import { each } from 'lodash'

export default {
  components: { StarRating, AboutYou, ReviewSidebar, TopNavigationSticky },
  props: ['companyName', 'companySlug', 'reviewRequestId', 'companyLogo', 'reviewId'],
  data() {
    return {
      disableButton: false,
      easeOfUse: null,
      roi: null,
      customerSupport: null,
      implementation: null,
      perfectScoreChecks: ['easeOfUse', 'customerSupport', 'roi', 'implementation'],
      shouldShowErrorMessages: false,
      requiredFields: ['easeOfUse', 'customerSupport', 'compliance_opt_in'],
      requiredFieldsAboutYou: ['hotelTypes', 'propertySize', 'hotel'],
      errorMessage: null,
      hasClosedPerfectScoreTooltip: false,
      userData: window.userInfo,
      initialized: false,
      city: null,
      country: null,
      country_iso_code: null,
      state: null,
      region: null,
      dataAboutYou: {
        hotel: null,
        hotelTypes: null,
        propertySize: null
      },
      review: {},
      compliance_opt_in: false
    }
  },
  computed: {
    htrLanguage() {
      if (window && window.i18n) {
        const { writeReview, company, layout } = window.i18n
        if (writeReview && company && layout) {
          return { writeReview, company, layout }
        }
      }
      return { writeReview: {}, company: {}, layout: {} }
    },
    countWords() {
      return (this.review.cons ? this.review.cons.split(' ').filter(word => word !== '').length : 0) + (this.review.pros ? this.review.pros.split(' ').filter(word => word !== '').length : 0)
    },
    checkboxClass() {
      return [
        'form-checkbox h-5 w-5 rounded m-auto',
        'focus:outline-none',
        'focus:ring-0',
        this.compliance_opt_in ? 'text-blue-500 bg-blue-500 border-blue-500 border-2' : 'bg-transparent border-gray-400 border-2'
      ];
    },
    percentedAvancedStepThree() {
      let percent = 50
      const sum = 8.334
      if (this.easeOfUse) {
        percent += sum
      }
      if (this.customerSupport) {
        percent += sum
      }
      if (this.dataAboutYou) {
        if (this.dataAboutYou.hotel) {
          percent += sum
        }
        if (this.dataAboutYou.hotelTypes) {
          percent += sum
        }
        if (this.dataAboutYou.propertySize) {
          percent += sum
        }
      }
      if (this.compliance_opt_in) {
        percent += sum
      }
      return percent
    }
  },
  watch: {
    easeOfUse() {
      this.persistInStorage()
    },
    customerSupport() {
      this.persistInStorage()
    },
    roi() {
      this.persistInStorage()
    },
    implementation() {
      this.persistInStorage()
    }
  },
  created() {
    this.city = this.userData.city
    this.dataAboutYou.hotel = this.userData.hotel
    this.dataAboutYou.hotelTypes = this.userData.hotelTypes
    this.dataAboutYou.propertySize = this.userData.propertySize
    this.restoreFromStorage()
    axios.get(`/api/reviews-register-information/${this.reviewId}`)
      .then((data) => {
        this.review = data.data
        if (this.review && this.review.status === 'pending') {
          window.location = '/write-a-review/' + this.companySlug
        }
        this.initialized = true
      })
      .catch((err) => {
        window.location = '/write-a-review/' + this.companySlug
      })
  },
  methods: {
    restoreFromStorage() {
      let cacheDate = new Date().getTime()
      if (window.localStorage.getItem('review.cacheDate')) {
        cacheDate = JSON.parse(window.localStorage.getItem('review.cacheDate'))
      }
      const currentDate = new Date().getTime()
      if (currentDate - cacheDate >= 7200000) {
        return
      }
      each(['easeOfUse', 'customerSupport', 'roi', 'implementation'], (key) => {
        if (window.localStorage.getItem(`review.${key}`) && window.localStorage.getItem(`review.${key}`) !== 'undefined') {
          this[key] = JSON.parse(window.localStorage.getItem(`review.${key}`)) || null
        }
      })
    },
    persistInStorage() {
      if (!this.initialized) return false
      this.cacheDate = new Date().getTime()
      each(['easeOfUse', 'customerSupport', 'roi', 'implementation', 'cacheDate'], (key) => {
        window.localStorage.setItem(`review.${key}`, JSON.stringify(this[key]))
      })
    },
    emptyStorage() {
      each(['overallRating', 'experience', 'likeMost', 'likeLeast', 'selectedProducts', 'easeOfUse', 'customerSupport', 'roi', 'implementation', 'isEmailWorkHotelCompanyYes', 'isEmailWorkHotelCompanyNo', 'easyOptionLinkedin', 'easyOptionInvoice', 'easyOptionScreenshot', 'invoice', 'screenshot', 'linkedin', 'auth', 'cacheDate'], (key) => {
        window.localStorage.removeItem(`review.${key}`)
      })
    },
    hasFilledAllRequiredFields() {
      for (let index = 0; index < this.requiredFields.length; index++) {
        if (this[this.requiredFields[index]] === null || this[this.requiredFields[index]] === '') {
          this.errorMessage = this.htrLanguage.writeReview.please_fill_out_fields || 'please_fill_out_fields'
          return false
        }
      }

      for (let index = 0; index < this.requiredFieldsAboutYou.length; index++) {
        if (this.dataAboutYou[this.requiredFieldsAboutYou[index]] === null || this.dataAboutYou[this.requiredFieldsAboutYou[index]] === '') {
          this.errorMessage = this.htrLanguage.writeReview.please_fill_out_fields || 'please_fill_out_fields'
          return false
        }
      }

      for (let key in this.moreThanHundredCharacters) {
        if (this.moreThanHundredCharacters[key] === false) {
          this.errorMessage = this.htrLanguage.writeReview.please_filled_minium_length || 'please_filled_minium_length'
          return false
        }
      }
      if (!this.compliance_opt_in) {
        this.errorMessage = this.htrLanguage.writeReview.please_fill_out_fields || 'please_fill_out_fields'
        return false
      }

      return true
    },

    submitReview(e) {
      this.disableButton = true
      this.shouldShowErrorMessages = false
      if (!this.hasFilledAllRequiredFields()) {
        this.disableButton = false
        this.shouldShowErrorMessages = true
        this.scrollTo('.product')
        e.preventDefault()
      } else {
        this.$refs.form.requestSubmit()
      }

      this.emptyStorage()
    },
    updateValue(data) {
      this[data.name] = data.value

      if (this.hasFilledAllRequiredFields()) {
        this.shouldShowErrorMessages = false
      }
    },
    updateMinimumLengthValidation(data) {
      this.moreThanHundredCharacters[data.name] = data.moreThanHundredCharacters
    },
    ratingUpdated(data) {
      this[data.name] = data.rating
    },
    hasPerfectScore() {
      let hasPerfectScore = true
      this.perfectScoreChecks.forEach(item => {
        if (this[item] !== '5') {
          hasPerfectScore = false
        }
      })
      return hasPerfectScore
    },
    hideTooltip(event) {
      this.hasClosedPerfectScoreTooltip = true
      $(event.target).parents('.alert-popup').fadeOut(200)
    },
    scrollTo(element) {
      let tag = $(element)
      $('html,body').animate({ scrollTop: tag.offset().top }, 'slow')
    },
    updateAboutYou(data) {
      if (data.field === 'location' && data.value) {
        this.location = this.userData.location = data.value

        const locationMappings = {
          locality: 'city',
          country: 'country',
          route: 'region',
          administrative_area_level_1: 'state',
          administrative_area_level_3: 'city',
          sublocality_level_2: 'city'
        }

        this.address = this.location.formatted_address
        this.city = null
        this.country = null
        this.country_iso_code = null
        this.state = null
        this.region = null
        this.phone = this.location.international_phone_number || this.location.formatted_phone_number || null
        this.website = this.location.website
        this.name = this.location.name || null

        this.location.address_components.forEach((item) => {
          if (locationMappings.hasOwnProperty(item.types[0])) {
            if (item.types[0] === locationMappings.country) {
              this.country_iso_code = item.short_name || null
            }
            this[locationMappings[item.types[0]]] = item.long_name || null
          }
        })
      } else {
        this.dataAboutYou[data.field] = data.value
      }
    },
    newHotelSelected(data) {
      this.dataAboutYou.hotel = data.hotel
      this.dataAboutYou.hotelTypes = data.property_type
      this.dataAboutYou.propertySize = data.property_size
    }
  }
}
</script>

<style scoped>
.btnWriteAReview {
  background: #8BC34A;
  min-height: 42.5px;
}

.btnWriteAReview:hover {
  background: #85B948;
}

.review-form {
  margin-top: 30px;
}

.compliance-check-box {
  background: #f9f7fa;
  margin: 0px 40px 20px 0px;
  padding: 10px 10px 10px 10px;
  display: flex;
  position: relative;
}

.compliance-text {
  padding-left: 10px;
  font-size: 100%;
}

.reviewguidelines {
  color: inherit;
  text-decoration: underline;
}

.alert-checkbox {
  box-shadow: 0px 0px 0px 2px rgba(255, 0, 0, 1);

}

.checkbox-size {
  -ms-transform: scale(1.5);
  /* IE */
  -moz-transform: scale(1.5);
  /* FF */
  -webkit-transform: scale(1.5);
  /* Safari and Chrome */
  -o-transform: scale(1.5);
  /* Opera */
  padding: 10px;
  margin-top: 5px;
}

.alert-checkbox::after {
  width: 20px;
  height: 20px;
  content: '!';
  font: 15px/18px icomoon;
  text-align: center;
  position: absolute;
  left: -28px;
  top: -2px;
  background: #d20027;
  color: white;
  border-radius: 50%;
}

.checkbox-checked:checked {
  --tw-text-opacity: 1;
  color: rgba(255, 255, 255, var(--tw-text-opacity));
}
</style>
