<script>

import {mapActions} from 'vuex'
import {assign, map} from 'lodash'
import CategoryFilter from './Categories/CategoryFilter.vue'
import ProductTile from './Categories/ProductTile.vue'
import { EventBus } from '../../../htr/event-bus.js'
import RecentPost from '../Common/RecentPost.vue'
import PopularCategories from '../Common/PopularCategories.vue'
import PopularProducts from '../Common/FeaturedProducts.vue'
export default {
  components: {
    CategoryFilter,
    RecentPost,
    PopularCategories,
    PopularProducts,
    ProductTile
  },

  props: [
    'category',
    'subCategory',
    'search',
    'sortby',
    'propertyType',
    'q',
    'pages'
  ],

  data: () => ({
    categorySelected: '',
    subCategorySelected: '',
    productIds: [],
    page: 1,
    loading: false,
    addonsVisible: false,
    query: {
      per_page: 10
    }
  }),

  computed: {
    hasMore() {
      return this.pages && (this.pages > this.page)
    },
    url () {
      let url = '/companies'

      if (this.categorySelected) {
        url += '/' + this.categorySelected + ((this.subCategorySelected) ? '/' + this.subCategorySelected : '') + window.location.search
      } else if (this.search) {
        url = '/s/' + this.search
      }

      if (this.propertyType) {
        url += '?property_type=' + this.propertyType
      }

      return url
    },
    categories () {
      return (window.companiesLaravel) ? window.companiesLaravel.categories : []
    },
    userIsGuest () {
      return !window.Laravel.userId
    }
  },

  mounted () {
    this.categorySelected = this.category
    this.subCategorySelected = this.subCategory
    assign(this.query, this.q || {})
  },

  methods: {
    ...mapActions(['fetchProducts']),
    loadMore() {
      if(this.loading) return;

      this.loading = true;
      this.fetchProducts(assign(this.query, {page: ++this.page, include_primary_product: true})).then(res => {
        this.productIds = [...this.productIds, ...map(res.data.data, product => product.id)]
        this.loading = false;
      })
    },
    propertyTypeSelect (value) {
      window.location.href = window.location.hash + `?sortby=${this.sortby}&property_type=${value}`
    },
    addOnVisibilityChange(value) {
      this.addonsVisible = !value
    },
    sortSelect (value) {
      if (this.propertyType) {
        window.location.href = window.location.hash + `?sortby=${value}&property_type=${this.propertyType}`
        return
      }

      window.location.href = window.location.hash + `?sortby=${value}`
    },
    categorySelect (value) {
      this.categorySelected = value
      this.redirectCategoryPage()
    },
    subCategorySelect (value) {
      this.subCategorySelected = value
      this.redirectCategoryPage()
    },
    redirectCategoryPage () {
      if (this.categorySelected !== this.category || this.subCategorySelected !== this.subCategory) {
        if (this.subCategorySelected === this.subCategory) {
          this.subCategorySelected = ''
        }

        window.location.href = this.url
      }
    },
    handleGuestClick (e) {
      e.preventDefault()

      this.openModalCreateAccount()
    },
    openModalCreateAccount () {
      EventBus.$emit('open-modal-sidebar', {
        type: 'create-account',
        params: {
          redirectToCurrentPage: true
        }
      })
    },
    openLeadModal (categoryId, productId, type) {
      if (window.Laravel.isVendor) return this.$root.addModalToQueue('modal-is-vendor')

      EventBus.$emit('open-modal-sidebar', {
        type: 'modal-lead',
        params: {
          categoryId: categoryId,
          productId: productId,
          type: type
        }
      })
    }
  }

}
</script>
