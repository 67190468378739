<template>
  <modal :opened="shown">
    <div class="htr-form modal-access">
      <div class="icon-hold">
        <img src="/images/invitation-modal.svg" alt="HotelTechReport">
      </div>
      <form :action="`/join/${invitationId}`" method="POST" @submit="onSubmit" class="modal-access__form">
        <h2>Great, just add a password so you can login to your account in the future</h2>
        <div class="form-box">
          <label class="form-group not-empty">
            <input :value="email" type="text" class="form-control" disabled>
            <span class="custom-label">professional email</span>
          </label>
          <label class="form-group"
                 :class="{'not-empty': v$.form.password.$model, 'error': v$.form.password.$invalid && submitted }">
            <input name="password" v-model="v$.form.password.$model" type="password" class="form-control">
            <span class="custom-label">create a password</span>
          </label>
        </div>
        <input type="submit" class="btn btn-blue" value="Create account and go to dashboard">
      </form>
    </div>
  </modal>
</template>

<script>
import { useVuelidate } from '@vuelidate/core'
import { required, email } from '@vuelidate/validators'
  import { mapActions, mapGetters } from 'vuex'

export default {
  mixins: [
  ],
  props: ['invitationId'],
  setup () {
    return { v$: useVuelidate() }
  },
  data () {
    return {
      form: {
        password: null,
        invitation_id: this.invitationId
      },
      email: null,
      shown: false,
      submitted: false,
      loading: false,
    }
  },
  validations: {
    form: {
      password: { required },
    }
  },
  computed: {
    ...mapGetters(['invitationById', 'companyById', 'userById']),
    invitation () {
      return this.invitationById(this.invitationId)
    },
    sender () {
      return this.userById(this.invitation.sender_id)
    },
    company () {
      return this.companyById(this.invitation.company_id)
    }
  },

  created () {
    this.fetchInvitationById(this.invitationId).then(res => {
      this.email = res.data.email
      this.shown = true
    })
  },

  methods: {
    ...mapActions([
      'fetchInvitationById',
    ]),
    onSubmit(e) {
      this.submitted = true
      if(this.v$.$invalid) {
        e.preventDefault()
        return false;
      }
      return true;
    }
  }
}
</script>
